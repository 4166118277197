export const FileBigSvg = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.3092 39.7794H11.84C10.9189 39.7794 10.0356 39.4131 9.38434 38.761C8.73307 38.1089 8.36719 37.2244 8.36719 36.3022V4.47722C8.36719 3.555 8.73307 2.67056 9.38434 2.01845C10.0356 1.36635 10.9189 1 11.84 1H21.6902C22.5719 1.0018 23.4221 1.3285 24.0786 1.91778L35.5684 12.259C35.9462 12.5973 36.2484 13.0117 36.4553 13.475C36.6622 13.9383 36.7691 14.4401 36.769 14.9477V36.3022C36.769 37.2222 36.4049 38.1047 35.7564 38.7564C35.1079 39.4081 34.2279 39.776 33.3092 39.7794ZM11.84 3.58529C11.6037 3.58529 11.3771 3.67926 11.2101 3.84653C11.043 4.0138 10.9492 4.24067 10.9492 4.47722V36.3022C10.9492 36.5387 11.043 36.7656 11.2101 36.9329C11.3771 37.1001 11.6037 37.1941 11.84 37.1941H33.3092C33.5454 37.1941 33.772 37.1001 33.939 36.9329C34.1061 36.7656 34.1999 36.5387 34.1999 36.3022V14.883C34.1997 14.7415 34.1697 14.6015 34.1118 14.4724C34.054 14.3432 33.9697 14.2277 33.8643 14.1333L22.3744 3.79212C22.1793 3.64172 21.9359 3.56814 21.6902 3.58529H11.84Z"
      fill="#9DABC0"
    />
    <path
      d="M35.4822 15.1397H24.1085C23.3587 15.1397 22.6396 14.8414 22.1093 14.3105C21.5791 13.7796 21.2812 13.0596 21.2812 12.3088V3.01465H23.8632V12.3088C23.8632 12.3739 23.8891 12.4364 23.9351 12.4824C23.9811 12.5285 24.0435 12.5544 24.1085 12.5544H35.4822V15.1397Z"
      fill="#9DABC0"
    />
    <path
      d="M6.21457 34.169C5.3607 34.1589 4.54506 33.813 3.94366 33.2059C3.34226 32.5989 3.00333 31.7795 3 30.9245V9.85435C2.9999 9.37509 3.10627 8.9018 3.31139 8.46878C3.51651 8.03576 3.81526 7.65385 4.18599 7.3507C4.55671 7.04755 4.99014 6.83076 5.45487 6.71603C5.9196 6.6013 6.404 6.59151 6.87298 6.68736L9.64861 7.15272L9.22258 9.73801L6.42113 9.23388C6.31986 9.20638 6.21363 9.20257 6.11066 9.22274C6.00768 9.24291 5.91072 9.28653 5.82727 9.35022C5.7512 9.41041 5.68964 9.48698 5.64718 9.57426C5.60471 9.66153 5.58243 9.75727 5.58198 9.85435V30.9245C5.58389 31.0209 5.6069 31.1158 5.64939 31.2023C5.69189 31.2889 5.75284 31.365 5.82793 31.4254C5.90303 31.4858 5.99045 31.529 6.08402 31.5519C6.17759 31.5748 6.27504 31.5768 6.36949 31.5579L9.28713 31.1701L9.62279 33.7554L6.79552 34.1303C6.60381 34.1639 6.40905 34.1769 6.21457 34.169Z"
      fill="#9DABC0"
    />
  </svg>
);
