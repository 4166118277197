import type { FC } from 'react';
import { ViewSvg } from '../../../../components/svg/view-svf';
import { FileSvg } from '../../../../components/svg/file-svg';
// import { ActivitySvg } from '../../../../components/svg/activity-svg';

export const tabList: { id: string; title: string; icon?: FC }[] = [
  { id: 'views', title: 'Огляд', icon: ViewSvg },
  // { id: 'activity', title: 'Активність', icon: ActivitySvg },
  { id: 'files', title: 'Файли', icon: FileSvg }
];
