import styled from 'styled-components';

export const PartnerSidebarStyled = styled.div`
  height: 100%;
`;

export const PartnerSidebarHeader = styled.div`
  padding: 30px 40px 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.table};
`;

export const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  :first-child {
    margin-bottom: 50px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    :first-child {
      margin-bottom: 40px;
    }
  }
`;

export const UserId = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;
