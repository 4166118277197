export const InfoSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.4182 12.4182 16 8 16C3.58172 16 0 12.4182 0 8C0 3.58172 3.58172 0 8 0C12.4182 0 16 3.58172 16 8ZM8.8 4.8C8.8 5.24182 8.44184 5.6 8 5.6C7.55816 5.6 7.2 5.24182 7.2 4.8C7.2 4.35818 7.55816 4 8 4C8.44184 4 8.8 4.35818 8.8 4.8ZM8 6.4C7.55816 6.4 7.2 6.75816 7.2 7.2V11.2C7.2 11.6418 7.55816 12 8 12C8.44184 12 8.8 11.6418 8.8 11.2V7.2C8.8 6.75816 8.44184 6.4 8 6.4Z"
      fill="#9DABC0"
    />
  </svg>
);
