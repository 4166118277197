export const NonEventsSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66016 29C7.66016 29.5527 8.10791 30 8.66016 30H25.0498C26.9521 30 28.5 28.4521 28.5 26.5498V9.97998C28.5 9.42773 28.0522 8.97998 27.5 8.97998C26.9478 8.97998 26.5 9.42773 26.5 9.97998V26.5498C26.5 27.3496 25.8496 28 25.0498 28H8.66016C8.10791 28 7.66016 28.4473 7.66016 29Z"
      fill="#306FE3"
    />
    <path
      d="M12.9302 15H10.7598C10.2075 15 9.75977 15.4478 9.75977 16C9.75977 16.5522 10.2075 17 10.7598 17H12.9302C13.4824 17 13.9302 16.5522 13.9302 16C13.9302 15.4478 13.4824 15 12.9302 15Z"
      fill="#306FE3"
    />
    <path
      d="M21.2402 22.2402C21.7925 22.2402 22.2402 21.793 22.2402 21.2402C22.2402 20.6875 21.7925 20.2402 21.2402 20.2402H16.4199C15.8677 20.2402 15.4199 20.6875 15.4199 21.2402C15.4199 21.793 15.8677 22.2402 16.4199 22.2402H21.2402Z"
      fill="#306FE3"
    />
    <path
      d="M16.0005 9.75732H10.7578C10.2056 9.75732 9.75781 10.2051 9.75781 10.7573C9.75781 11.3096 10.2056 11.7573 10.7578 11.7573H16.0005C16.5527 11.7573 17.0005 11.3096 17.0005 10.7573C17.0005 10.2051 16.5527 9.75732 16.0005 9.75732Z"
      fill="#306FE3"
    />
    <path
      d="M28.293 2.29297L24.6084 5.97754L20.5337 2.26123C20.3493 2.08581 20.1019 2.02777 19.8599 2H6.9502C5.04785 2 3.5 3.54785 3.5 5.4502C3.5 5.4502 3.51801 26.8792 3.54242 27.0435L2.29297 28.293C1.90234 28.6836 1.90234 29.3164 2.29297 29.707C2.48828 29.9023 2.74414 30 3 30C3.25586 30 3.51172 29.9023 3.70703 29.707L29.707 3.70703C30.0977 3.31641 30.0977 2.68359 29.707 2.29297C29.3164 1.90234 28.6836 1.90234 28.293 2.29297ZM21.735 8.85089C21.2197 8.62592 20.8599 8.12744 20.8599 7.54004V5.26562L23.1927 7.39319L21.735 8.85089ZM5.5 5.4502C5.5 4.65039 6.15039 4 6.9502 4H18.8599V7.54004C18.8599 8.67267 19.414 9.68213 20.2733 10.3127L5.5 25.0859V5.4502Z"
      fill="#306FE3"
    />
  </svg>
);
