import type { ReactNode } from 'react';
import { LabelStyled } from './style';

interface LabelProps {
  children: ReactNode;
}

export const Label = ({ children }: LabelProps) => (
  <LabelStyled>{children}</LabelStyled>
);
