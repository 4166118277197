import styled from 'styled-components';

export const AccordionItemStyled = styled.div`
  padding: 12px 40px;
  :first-child {
    margin-top: -8px;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.grey.light};
`;

export const Data = styled.div`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  a {
    color: ${(p) => p.theme.colors.blue.primary};
  }
`;
