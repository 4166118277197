import styled from 'styled-components';

export const AuthBtnStyled = styled.button`
  width: 100%;
  height: 48px;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${(p) => p.theme.colors.white};
  background: ${(p) => p.theme.colors.primary};
  border-radius: 6px;
  cursor: pointer;
  text-transform: uppercase;
  :disabled {
    background: ${(p) => p.theme.colors.grey.bg};
    color: ${(p) => p.theme.colors.grey.light};
    cursor: default;
  }
`;
