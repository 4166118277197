import styled from 'styled-components';

export const FilterBadgesStyled = styled.div`
  padding: 0 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`;

export const ExpandBtn = styled.div<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.colors.blue.primary};
  cursor: pointer;
  svg {
    margin-left: 4px;
    transform: ${(p) => (p.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    path {
      fill: ${(p) => p.theme.colors.blue.primary};
      stroke: ${(p) => p.theme.colors.blue.primary};
    }
  }
`;
