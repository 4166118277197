export const EditSvg = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.69333 13.0137C2.28667 13.0137 1.90667 12.8737 1.63333 12.6137C1.28667 12.287 1.12 11.7937 1.18 11.2604L1.42667 9.10036C1.47333 8.69369 1.72 8.15369 2.00667 7.86036L7.48 2.06702C8.84667 0.620358 10.2733 0.580358 11.72 1.94703C13.1667 3.31369 13.2067 4.74036 11.84 6.18703L6.36667 11.9804C6.08667 12.2804 5.56667 12.5604 5.16 12.627L3.01333 12.9937C2.9 13.0004 2.8 13.0137 2.69333 13.0137ZM9.62 1.94036C9.10667 1.94036 8.66 2.26036 8.20667 2.74036L2.73333 8.54036C2.6 8.68036 2.44667 9.01369 2.42 9.20703L2.17333 11.367C2.14667 11.587 2.2 11.767 2.32 11.8804C2.44 11.9937 2.62 12.0337 2.84 12.0004L4.98667 11.6337C5.18 11.6004 5.5 11.427 5.63333 11.287L11.1067 5.49369C11.9333 4.61369 12.2333 3.80036 11.0267 2.66702C10.4933 2.15369 10.0333 1.94036 9.62 1.94036Z"
      fill="#9DABC0"
    />
    <path
      d="M10.56 7.30036C10.5467 7.30036 10.5267 7.30036 10.5133 7.30036C8.43333 7.09369 6.76 5.51369 6.44 3.44703C6.4 3.17369 6.58667 2.92036 6.86 2.87369C7.13333 2.83369 7.38667 3.02036 7.43333 3.29369C7.68667 4.90703 8.99333 6.14703 10.62 6.30703C10.8933 6.33369 11.0933 6.58036 11.0667 6.85369C11.0333 7.10703 10.8133 7.30036 10.56 7.30036Z"
      fill="#9DABC0"
    />
    <path
      d="M13 15.167H1C0.726667 15.167 0.5 14.9404 0.5 14.667C0.5 14.3937 0.726667 14.167 1 14.167H13C13.2733 14.167 13.5 14.3937 13.5 14.667C13.5 14.9404 13.2733 15.167 13 15.167Z"
      fill="#9DABC0"
    />
  </svg>
);
