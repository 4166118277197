export const CheckSvg = () => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 10 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.125 1.375L3.875 6.625L1.25 4"
      stroke="white"
      strokeWidth="1.53"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
