import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const ActivityItemStyled = styled.div`
  padding: 24px 24px 24px 32px;
  margin-top: 12px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

export const ActivityItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActivityItemTitle = styled.div`
  position: relative;
  max-width: calc(100% - 260px);
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  span {
    font-weight: 700;
  }
  cursor: pointer;
`;

export const TitleAngle = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  top: 3px;
  width: 16px;
  height: 16px;
  left: -24px;
  ${centerFlex}
  transform: ${(p) => (p.isOpen ? 'rotate(0)' : 'rotate(-90deg)')};
  svg {
    path {
      stroke: ${(p) => p.theme.colors.blue.primary};
    }
  }
`;

export const HeaderDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.grey.input};
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
  gap: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.primary};
`;

export const ActionBtn = styled.div`
  width: 16px;
  height: 16px;
  ${centerFlex}
  svg {
    path {
      fill: ${(p) => p.theme.colors.blue.primary};
    }
  }
  cursor: pointer;
`;

export const EditForm = styled.form`
  margin-top: 8px;
`;

export const EditBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 12px;
`;
