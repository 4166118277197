import {
  useState,
  type ChangeEventHandler,
  type Dispatch,
  type FormEventHandler,
  type SetStateAction,
  useEffect
} from 'react';
import { FormBtns } from '../../../components/form-btns';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import type { CommonContact } from '../../../models/contact';
import type { OptionItem } from '../../../common/types/option-item';
import {
  CHILD_GENDER,
  EDUCATION,
  EMPLOYMENT,
  FAMILY_STATUS,
  GENDER,
  QUALIFICATION,
  STATUS
} from '../../../common/constants';
import { InputFile } from '../../../components/input-file';
import type { ServerFile } from '../../../common/types/server-file';
import { FilesFromServer } from '../../../components/files-from-server';
import { useGetUserMeQuery, useGetUsersQuery } from '../../../api/user-api';
import { useGetContactsQuery } from '../../../api/contact-api';
import type { Project } from '../../../models/project';
import { InputWithRemove } from '../../events/events-form/style';
import { mapUsersToProps } from '../../../helpers/map-users-to-option';
import { IconContainer } from '../../../components/icon-container';
import { CloseEditSvg } from '../../../components/svg/close-edit-svg';
import { AddItem } from '../../../components/add-item/indes';
import { TooltipFixed } from '../../../components/tooltip/tooltip-fixed';
import { AppRateDischarge } from '../../../common/enums/app/app-rate-discharge.enum';
import { InputDate } from '../../../components/input-date';
import { calculateAge } from '../../../helpers/calculate-age';
import { isFieldAllowedForm } from '../../../helpers/is-field-allowed';
import { SpanTwoColumns } from '../../users/user-form/style';
import { TextArea } from '../../../components/text-area';
import {
  AddContactStyled,
  FiredUser,
  InputsContainer,
  InputsGroupDouble,
  InputsGroupDoubleTitle,
  InputsGroupSingle,
  InputsGroupTitle,
  InputsGroupTripleTitle
} from './style';

interface ContactFormProps {
  fields: CommonContact;
  onSubmit: FormEventHandler<HTMLFormElement>;
  handleFieldsChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  updateFields: Dispatch<SetStateAction<CommonContact>>;
  gender: OptionItem | null;
  setGender: Dispatch<SetStateAction<OptionItem | null>>;
  familyStatus: OptionItem | null;
  setFamilyStatus: Dispatch<SetStateAction<OptionItem | null>>;
  education: OptionItem | null;
  setEducation: Dispatch<SetStateAction<OptionItem | null>>;
  employment: OptionItem | null;
  setEmployment: Dispatch<SetStateAction<OptionItem | null>>;
  childrenCount: OptionItem | null;
  setChildrenCount: Dispatch<SetStateAction<OptionItem | null>>;
  childrenList:
    | {
        age: number;
        gender: OptionItem;
        birth_date: string | null;
      }[]
    | null;
  setChildrenList: Dispatch<
    SetStateAction<
      { age: number; gender: OptionItem; birth_date: string | null }[] | null
    >
  >;
  isLoading: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  status: OptionItem | null;
  setStatus: Dispatch<SetStateAction<OptionItem | null>>;
  qualification: OptionItem | null;
  setQualification: Dispatch<SetStateAction<OptionItem | null>>;
  respManagers: OptionItem[] | null;
  setRespManagers: Dispatch<SetStateAction<OptionItem[] | null>>;
  passportFiles: File[];
  setPassportFiles: Dispatch<SetStateAction<File[]>>;
  serverPassportFiles?: ServerFile[];
  setServerPassportFiles?: Dispatch<SetStateAction<ServerFile[]>>;
  innFiles: File[];
  setInnFiles: Dispatch<SetStateAction<File[]>>;
  serverInnFiles?: ServerFile[];
  setServerInnFiles?: Dispatch<SetStateAction<ServerFile[]>>;
  termSpouse: string;
  setTermSpouse: Dispatch<SetStateAction<string>>;
  spouse: OptionItem;
  setSpouse: Dispatch<SetStateAction<OptionItem>>;
  channels: OptionItem[];
  setChannels: Dispatch<SetStateAction<OptionItem[]>>;
  isEdit?: boolean;
  activitiesCount?: number;
  createdAt?: string;
  projects: OptionItem<Project>[];
  setProjects: Dispatch<SetStateAction<OptionItem<Project>[]>>;
  events: OptionItem[];
  setEvents: Dispatch<SetStateAction<OptionItem[]>>;
  isManager?: boolean;
  discharged_user?: boolean | null;
  rate_discharged?: number | null;
  allowedFields?: string[];
  tags: OptionItem[];
  setTags: Dispatch<SetStateAction<OptionItem[]>>;
  categories: OptionItem[];
  setCategories: Dispatch<SetStateAction<OptionItem[]>>;
  additionalPhones: { phone: string }[];
  setAdditionalPhones: Dispatch<SetStateAction<{ phone: string }[]>>;
}

export const ContactForm = ({
  fields,
  onSubmit,
  handleFieldsChange,
  gender,
  setGender,
  familyStatus,
  setFamilyStatus,
  education,
  setEducation,
  employment,
  setEmployment,
  childrenCount,
  setChildrenCount,
  childrenList,
  setChildrenList,
  isLoading,
  setShow,
  status,
  setStatus,
  qualification,
  setQualification,
  passportFiles,
  setPassportFiles,
  serverPassportFiles,
  setServerPassportFiles,
  innFiles,
  setInnFiles,
  serverInnFiles,
  setServerInnFiles,
  respManagers,
  setRespManagers,
  termSpouse,
  setTermSpouse,
  spouse,
  setSpouse,
  channels,
  setChannels,
  isEdit,
  activitiesCount,
  createdAt,
  projects,
  setProjects,
  events,
  setEvents,
  isManager,
  discharged_user,
  rate_discharged,
  updateFields,
  allowedFields,
  tags,
  setTags,
  categories,
  setCategories,
  additionalPhones,
  setAdditionalPhones
}: ContactFormProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const [debounceSpouseTerm, setDebounceSpouseTerm] = useState('');
  const { data: users } = useGetUsersQuery({ params: '?size=100' });
  const { data: spouseOptions, isFetching: isFetchingSpouse } =
    useGetContactsQuery(
      {
        params: `?search=${debounceSpouseTerm}`
      },
      { refetchOnMountOrArgChange: true }
    );

  const handleChangeChildrenCount = (value: OptionItem) => {
    setChildrenList((state) =>
      state
        ? state.length
          ? state.length >= +value.id
            ? [...state.slice(0, +value.id)]
            : [
                ...state,
                ...new Array(+value.id - state.length).fill('').map(() => ({
                  age: 0,
                  gender: CHILD_GENDER[0],
                  birth_date: null
                }))
              ]
          : new Array(+value.id).fill('').map(() => ({
              age: 0,
              gender: CHILD_GENDER[0],
              birth_date: null
            }))
        : []
    );
    setChildrenCount(value);
  };

  const handleChangeChildrenBirthday = (date: string | null, i: number) => {
    setChildrenList((state) =>
      state
        ? state.map((child, j) =>
            i === j
              ? { ...child, birth_date: date, age: calculateAge(date) }
              : child
          )
        : []
    );
  };

  const handleChangeBirthday = (date: string) => {
    updateFields((state) => ({
      ...state,
      birth_date: date,
      age: calculateAge(date)
    }));
  };

  useEffect(() => {
    setEvents((state) =>
      projects
        .filter((item) => item.checked)
        .map((item) =>
          state
            ? item.restFields?.events.map((event) => ({
                id: event.id,
                title: event.name,
                checked: state.some((prevEvent) => prevEvent.id === event.id)
                  ? state.find((prevEvent) => prevEvent.id === event.id)
                      ?.checked
                  : false
              })) || []
            : []
        )
        .flat()
    );
  }, [projects, setEvents]);

  const handleDeleteManager = (i: number) => {
    if (respManagers && respManagers.length === 1) {
      setRespManagers([{ id: '', title: '' }]);
    } else {
      setRespManagers((state) =>
        state ? state.filter((_, j) => j !== i) : []
      );
    }
  };

  const handleChangeAdditoanlPhones = (phone: string, idx: number) => {
    setAdditionalPhones((state) =>
      state.map((item, i) => (i === idx ? { phone } : item))
    );
  };

  const handleDeleteAdditionalPhone = (i: number) => {
    setAdditionalPhones((state) => state.filter((_, j) => j !== i));
  };

  if (!userMe) {
    return null;
  }

  return (
    <AddContactStyled onSubmit={onSubmit}>
      <InputsContainer>
        <InputsGroupTripleTitle>
          Інформація про контакт
          {discharged_user && (
            <FiredUser>
              <span>Звільнений співробітник</span>
              <span>|</span>
              <TooltipFixed
                position="topRight"
                title={rate_discharged ? AppRateDischarge[rate_discharged] : ''}
                offset={
                  innerWidth > 1500
                    ? {
                        top: 0,
                        left: innerWidth * -0.255
                      }
                    : {
                        top: 0,
                        left: innerWidth * -0.18
                      }
                }
              >
                <span style={{ display: 'block' }}>
                  Оцінка: {rate_discharged}
                </span>
              </TooltipFixed>
            </FiredUser>
          )}
        </InputsGroupTripleTitle>
        {status &&
          isFieldAllowedForm('status', allowedFields, userMe, isEdit) && (
            <InputsGroupSingle>
              <Select
                label="Статус"
                value={status}
                setValue={setStatus}
                options={STATUS}
              />
            </InputsGroupSingle>
          )}
        {qualification &&
          isFieldAllowedForm(
            'qualification',
            allowedFields,
            userMe,
            isEdit
          ) && (
            <InputsGroupSingle>
              <Select
                label="Кваліфікатор"
                value={qualification}
                setValue={setQualification}
                options={QUALIFICATION}
              />
            </InputsGroupSingle>
          )}
        {!isManager &&
          respManagers &&
          isFieldAllowedForm(
            'resp_managers',
            allowedFields,
            userMe,
            isEdit
          ) && (
            <InputsGroupSingle>
              {respManagers.map((item, i) => (
                <InputWithRemove key={i}>
                  <Select
                    label="Власник контакту"
                    value={item}
                    setValue={(value) =>
                      setRespManagers((state) =>
                        state
                          ? state.map((manager, j) =>
                              i === j ? value : manager
                            )
                          : null
                      )
                    }
                    options={mapUsersToProps(users?.results, respManagers)}
                  />
                  {respManagers.length === 1 && !respManagers[0].id ? (
                    ''
                  ) : (
                    <IconContainer
                      m="0 0 11px 0"
                      onClick={() => handleDeleteManager(i)}
                    >
                      <CloseEditSvg />
                    </IconContainer>
                  )}
                </InputWithRemove>
              ))}
              {respManagers.filter((item) => item.id).length ===
                respManagers.length &&
              mapUsersToProps(users?.results, respManagers).length ? (
                <AddItem
                  onClick={() =>
                    setRespManagers((state) =>
                      state ? [...state, { id: '', title: '' }] : null
                    )
                  }
                  title="+ Додати менеджера"
                />
              ) : (
                ''
              )}
            </InputsGroupSingle>
          )}
        {isManager &&
          isFieldAllowedForm('channels', allowedFields, userMe, isEdit) && (
            <InputsGroupSingle>
              <Select
                label="Канал приходу контакту"
                multipleOptions={channels}
                setMultipleOptions={setChannels}
              />
            </InputsGroupSingle>
          )}
        {isEdit && (
          <>
            <Input
              label="Кількість дотиків"
              value={''}
              onChange={() => {}}
              disabled
              placeholder={`${activitiesCount}`}
            />
            <Input
              label="Дата створення"
              value={''}
              onChange={() => {}}
              disabled
              placeholder={createdAt}
            />
          </>
        )}
        {!isManager &&
          isFieldAllowedForm('channels', allowedFields, userMe, isEdit) && (
            <InputsGroupSingle>
              <Select
                label="Канал приходу контакту"
                multipleOptions={channels}
                setMultipleOptions={setChannels}
              />
            </InputsGroupSingle>
          )}
        {!isEdit && (
          <>
            <div></div>
            <div></div>
          </>
        )}
        {!isManager &&
          isFieldAllowedForm('projects', allowedFields, userMe, isEdit) && (
            <>
              {' '}
              <InputsGroupSingle>
                <InputsGroupTitle>Проєкти</InputsGroupTitle>
                <Select<Project>
                  label="Назва проєкту"
                  multipleOptions={projects}
                  setMultipleOptions={setProjects}
                />
              </InputsGroupSingle>
              {!!projects.filter((item) => item.checked).length && (
                <InputsGroupSingle>
                  <InputsGroupTitle>Події</InputsGroupTitle>
                  <Select
                    label="Назва події"
                    multipleOptions={events || []}
                    setMultipleOptions={setEvents}
                  />
                </InputsGroupSingle>
              )}
            </>
          )}
        <InputsGroupTripleTitle>Персональні дані</InputsGroupTripleTitle>
        <InputsGroupSingle>
          {isFieldAllowedForm('last_name', allowedFields, userMe, isEdit) && (
            <Input
              label="Прізвище"
              value={fields.last_name}
              onChange={handleFieldsChange}
              name="last_name"
            />
          )}
          {isFieldAllowedForm('first_name', allowedFields, userMe, isEdit) && (
            <Input
              label="Ім'я *"
              value={fields.first_name}
              onChange={handleFieldsChange}
              name="first_name"
              required
            />
          )}
          {isFieldAllowedForm('patronymic', allowedFields, userMe, isEdit) && (
            <Input
              label="По Батькові"
              value={fields.patronymic}
              onChange={handleFieldsChange}
              name="patronymic"
            />
          )}
          {isFieldAllowedForm('birth_date', allowedFields, userMe, isEdit) &&
            fields.birth_date !== null && (
              <InputDate
                value={fields.birth_date || ''}
                setValue={handleChangeBirthday}
                maxDate={new Date()}
                label="Дата народження"
              />
            )}
        </InputsGroupSingle>
        <InputsGroupSingle>
          {isFieldAllowedForm('birth_date', allowedFields, userMe, isEdit) &&
            isFieldAllowedForm('age', allowedFields, userMe, isEdit) && (
              <Input
                label="Вік"
                value={`${fields.birth_date ? fields.age : ''}`}
                onChange={() => {}}
                name="age"
                disabled
                asPlaceholder
              />
            )}
          {gender &&
            isFieldAllowedForm('gender', allowedFields, userMe, isEdit) && (
              <Select
                label="Гендер"
                value={gender}
                setValue={setGender}
                options={GENDER}
              />
            )}
          {familyStatus &&
            isFieldAllowedForm(
              'family_status',
              allowedFields,
              userMe,
              isEdit
            ) && (
              <Select
                label="Сімейний статус"
                value={familyStatus}
                setValue={setFamilyStatus}
                options={FAMILY_STATUS}
              />
            )}
          {familyStatus?.id === 2 &&
            isFieldAllowedForm('spouse', allowedFields, userMe, isEdit) && (
              <Select
                label="Чоловік/дружина"
                value={spouse}
                setValue={setSpouse}
                options={[
                  { id: '', title: 'Не вказувати' },
                  ...(spouseOptions?.results.map((item) => ({
                    id: item.id,
                    title: item.full_name,
                    additionalTitle: `(${item.phone.slice(
                      0,
                      3
                    )}...${item.phone.slice(10)})`
                  })) || [])
                ]}
                isLoading={isFetchingSpouse}
                isSearch
                term={termSpouse}
                setTerm={setTermSpouse}
                setDebounceTerm={setDebounceSpouseTerm}
              />
            )}
        </InputsGroupSingle>
        <InputsGroupSingle>
          {education &&
            isFieldAllowedForm('education', allowedFields, userMe, isEdit) && (
              <Select
                label="Освіта"
                value={education}
                setValue={setEducation}
                options={EDUCATION}
              />
            )}
          {employment &&
            isFieldAllowedForm('employment', allowedFields, userMe, isEdit) && (
              <Select
                label="Діяльність"
                value={employment}
                setValue={setEmployment}
                options={EMPLOYMENT}
              />
            )}
          {!!categories.length &&
            isFieldAllowedForm('categories', allowedFields, userMe, isEdit) && (
              <Select
                label="Пільгові групи"
                multipleOptions={categories}
                setMultipleOptions={setCategories}
              />
            )}
        </InputsGroupSingle>
        {isFieldAllowedForm('comment', allowedFields, userMe, isEdit) && (
          <SpanTwoColumns>
            <TextArea
              label="Коментар"
              value={fields.comment}
              onChange={handleFieldsChange}
              name="comment"
              placeholder="Опишіть коротко про контакт"
              autoHeight
            />
          </SpanTwoColumns>
        )}
        {!!tags.length && (
          <Select
            label="ТЕГ"
            multipleOptions={tags}
            setMultipleOptions={setTags}
          />
        )}
      </InputsContainer>
      {isFieldAllowedForm('children_num', allowedFields, userMe, isEdit) && (
        <InputsContainer>
          <InputsGroupTripleTitle>Діти</InputsGroupTripleTitle>
          {childrenCount && (
            <InputsGroupSingle>
              <Select
                label="Діти (к-сть)"
                placeholder="Оберіть зі списку"
                value={childrenCount}
                setValue={handleChangeChildrenCount}
                options={new Array(10)
                  .fill('')
                  .map((_, i) => ({ id: i, title: `${i}` }))}
              />
            </InputsGroupSingle>
          )}
          <div></div>
          <div></div>
          {childrenList?.map((item, i) => (
            <InputsGroupSingle space="12px" key={i}>
              <InputsGroupTitle fz="14px">Дитина {i + 1}</InputsGroupTitle>
              <Select
                label="Стать"
                value={item.gender}
                setValue={(value) =>
                  setChildrenList((state) =>
                    state
                      ? state.map((child, j) =>
                          i === j ? { ...child, gender: value } : child
                        )
                      : null
                  )
                }
                options={CHILD_GENDER}
              />
              <InputDate
                value={item.birth_date || ''}
                setValue={(date) => handleChangeChildrenBirthday(date, i)}
                maxDate={new Date()}
                label="Дата народження"
              />
              <Input
                label="Вік"
                disabled
                value={`${item.age}`}
                onChange={() => {}}
                asPlaceholder
              />
            </InputsGroupSingle>
          ))}
        </InputsContainer>
      )}
      <InputsContainer>
        <InputsGroupSingle>
          <InputsGroupTitle>Контактні та паспортні дані</InputsGroupTitle>
          {isFieldAllowedForm('phone', allowedFields, userMe, isEdit) && (
            <>
              <Input
                label="Телефон *"
                value={fields.phone}
                onChange={handleFieldsChange}
                name="phone"
                required
                mask="+38 999 999 99 99"
              />
              {additionalPhones.map((item, i) => (
                <InputWithRemove key={i}>
                  <Input
                    label={`Додатковий телефон ${i ? i + 1 : ''}`}
                    value={item.phone}
                    onChange={(e) =>
                      handleChangeAdditoanlPhones(e.target.value, i)
                    }
                    name={`additional_phone_${i}`}
                    mask="+38 999 999 99 99"
                  />
                  <IconContainer
                    m="0 0 11px 0"
                    onClick={() => handleDeleteAdditionalPhone(i)}
                  >
                    <CloseEditSvg />
                  </IconContainer>
                </InputWithRemove>
              ))}
              <AddItem
                onClick={() =>
                  setAdditionalPhones((state) => [...state, { phone: '' }])
                }
                title="+ Додати ще один номер телефону"
              />
            </>
          )}
          {isFieldAllowedForm('email', allowedFields, userMe, isEdit) && (
            <Input
              label="Email"
              value={fields.email}
              onChange={handleFieldsChange}
              name="email"
            />
          )}
        </InputsGroupSingle>
        <InputsGroupDouble>
          <InputsGroupDoubleTitle>Адреса проживання</InputsGroupDoubleTitle>
          {isFieldAllowedForm('liv_country', allowedFields, userMe, isEdit) && (
            <Input
              label="Країна"
              value={fields.liv_country}
              onChange={handleFieldsChange}
              name="liv_country"
            />
          )}
          {isFieldAllowedForm('liv_region', allowedFields, userMe, isEdit) && (
            <Input
              label="Область"
              value={fields.liv_region}
              onChange={handleFieldsChange}
              name="liv_region"
            />
          )}
          {isFieldAllowedForm('liv_city', allowedFields, userMe, isEdit) && (
            <Input
              label="Місто / СМТ / Село"
              value={fields.liv_city}
              onChange={handleFieldsChange}
              name="liv_city"
            />
          )}
          {isFieldAllowedForm(
            'liv_district',
            allowedFields,
            userMe,
            isEdit
          ) && (
            <Input
              label="Район / ОТГ"
              value={fields.liv_district}
              onChange={handleFieldsChange}
              name="liv_district"
            />
          )}
          {isFieldAllowedForm('liv_street', allowedFields, userMe, isEdit) && (
            <Input
              label="Вулиця"
              value={fields.liv_street}
              onChange={handleFieldsChange}
              name="liv_street"
            />
          )}
          {isFieldAllowedForm(
            'liv_building',
            allowedFields,
            userMe,
            isEdit
          ) && (
            <Input
              label="Будинок"
              value={fields.liv_building}
              onChange={handleFieldsChange}
              name="liv_building"
            />
          )}
          {isFieldAllowedForm('liv_unit', allowedFields, userMe, isEdit) && (
            <Input
              label="Корпус"
              value={fields.liv_unit}
              onChange={handleFieldsChange}
              name="liv_unit"
            />
          )}
          {isFieldAllowedForm(
            'liv_apartment',
            allowedFields,
            userMe,
            isEdit
          ) && (
            <Input
              label="Квартира"
              value={fields.liv_apartment}
              onChange={handleFieldsChange}
              name="liv_apartment"
            />
          )}
        </InputsGroupDouble>
        <InputsGroupDouble>
          <InputsGroupDoubleTitle>Адреса реєстрації</InputsGroupDoubleTitle>
          {isFieldAllowedForm('reg_country', allowedFields, userMe, isEdit) && (
            <Input
              label="Країна"
              value={fields.reg_country}
              onChange={handleFieldsChange}
              name="reg_country"
            />
          )}

          {isFieldAllowedForm('reg_region', allowedFields, userMe, isEdit) && (
            <Input
              label="Область"
              value={fields.reg_region}
              onChange={handleFieldsChange}
              name="reg_region"
            />
          )}

          {isFieldAllowedForm('reg_city', allowedFields, userMe, isEdit) && (
            <Input
              label="Місто / СМТ / Село"
              value={fields.reg_city}
              onChange={handleFieldsChange}
              name="reg_city"
            />
          )}

          {isFieldAllowedForm(
            'reg_district',
            allowedFields,
            userMe,
            isEdit
          ) && (
            <Input
              label="Район / ОТГ"
              value={fields.reg_district}
              onChange={handleFieldsChange}
              name="reg_district"
            />
          )}

          {isFieldAllowedForm('reg_street', allowedFields, userMe, isEdit) && (
            <Input
              label="Вулиця"
              value={fields.reg_street}
              onChange={handleFieldsChange}
              name="reg_street"
            />
          )}

          {isFieldAllowedForm(
            'reg_building',
            allowedFields,
            userMe,
            isEdit
          ) && (
            <Input
              label="Будинок"
              value={fields.reg_building}
              onChange={handleFieldsChange}
              name="reg_building"
            />
          )}

          {isFieldAllowedForm('reg_unit', allowedFields, userMe, isEdit) && (
            <Input
              label="Корпус"
              value={fields.reg_unit}
              onChange={handleFieldsChange}
              name="reg_unit"
            />
          )}

          {isFieldAllowedForm(
            'reg_apartment',
            allowedFields,
            userMe,
            isEdit
          ) && (
            <Input
              label="Квартира"
              value={fields.reg_apartment}
              onChange={handleFieldsChange}
              name="reg_apartment"
            />
          )}
        </InputsGroupDouble>
        <div>
          <InputsGroupTitle>Документи</InputsGroupTitle>
          {isFieldAllowedForm('passport', allowedFields, userMe, isEdit) && (
            <>
              <Input
                mt="20px"
                label="Паспорт"
                value={fields.passport}
                onChange={handleFieldsChange}
                name="passport"
                placeholder="Ввведіть серію та номер паспорту"
              />
              {isFieldAllowedForm(
                'passport_files',
                allowedFields,
                userMe,
                isEdit
              ) && (
                <>
                  {serverPassportFiles?.length && setServerPassportFiles ? (
                    <FilesFromServer
                      files={serverPassportFiles}
                      setFiles={setServerPassportFiles}
                    />
                  ) : (
                    ''
                  )}
                  <InputFile
                    files={passportFiles}
                    setFiles={setPassportFiles}
                  />
                </>
              )}
            </>
          )}
        </div>
        {isFieldAllowedForm('id_number', allowedFields, userMe, isEdit) && (
          <div>
            <Input
              mt="42px"
              label="ІПН"
              value={fields.id_number}
              onChange={handleFieldsChange}
              placeholder="Ввведіть номер ІПН"
              name="id_number"
              mask="9999999999"
              maskChar=" "
            />
            {isFieldAllowedForm('inn_files', allowedFields, userMe, isEdit) && (
              <>
                {serverInnFiles?.length && setServerInnFiles ? (
                  <FilesFromServer
                    files={serverInnFiles}
                    setFiles={setServerInnFiles}
                  />
                ) : (
                  ''
                )}
                <InputFile files={innFiles} setFiles={setInnFiles} />
              </>
            )}
          </div>
        )}
      </InputsContainer>
      <FormBtns disabled={isLoading} onCancel={() => setShow(false)} />
    </AddContactStyled>
  );
};
