import styled from 'styled-components';
import { centerFlex } from '../../../style/helpers';

export const LetterStyled = styled.div`
  padding: 24px 24px 24px 32px;
  margin-top: 12px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

export const LetterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LetterTitle = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  span {
    font-weight: 700;
  }
  cursor: pointer;
`;

export const TitleAngle = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  top: 3px;
  width: 16px;
  height: 16px;
  left: -24px;
  ${centerFlex}
  transform: ${(p) => (p.isOpen ? 'rotate(0)' : 'rotate(-90deg)')};
  svg {
    path {
      stroke: ${(p) => p.theme.colors.blue.primary};
    }
  }
`;

export const HeaderDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.grey.input};
`;

export const IframeWrapper = styled.div`
  max-height: 400px;
  padding: 20px 0;
  iframe {
    width: 100%;
    min-height: 400px;
    border: none;
    pointer-events: none;
  }
`;
