import { useCallback, useEffect, useState } from 'react';
import { ActivityItem } from '../../activity-item';
import { ConfirmPopup } from '../../confirm-popup';
import { Input } from '../../input';
import { Select } from '../../select';
import { InputDate } from '../../input-date';
import type { OptionItem } from '../../../common/types/option-item';
import { dateToUIFormat } from '../../../helpers/date-helpers';
import { MEET_RESULT, SATISFACTION } from '../../../common/constants';
import { BtnSecondary } from '../../btn-secondary';
import { BtnPrimary } from '../../btn-primary';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import type { Meet as IMeet } from '../../../models/meet';
import { ActivityTextArea } from '../../activity-textarea';
import type {
  DeleteMeet,
  EditMeet
} from '../../../common/types/rtk-triggers/meet';
import { EditBtns, InputContainer, Message, TextareaWrapper } from './style';

interface MeetProps {
  contactFullName: string;
  meet: IMeet;
  editMeet: EditMeet;
  deleteMeet: DeleteMeet;
  isPartner?: boolean;
}

export const Meet = ({
  meet,
  contactFullName,
  editMeet,
  deleteMeet,
  isPartner
}: MeetProps) => {
  const notification = useNotification();
  const [result, setResult] = useState<OptionItem>(meet.result);
  const [satisfaction, setSatisfaction] = useState<OptionItem>(
    meet.satisfaction
  );
  const [message, setMessage] = useState(meet.text);
  const [topic, setTopic] = useState(meet.topic);
  const [date, setDate] = useState(dateToUIFormat(new Date(meet.date)));
  const [edit, setEdit] = useState(false);
  const [expand, setExpand] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleCancel = useCallback(() => {
    setEdit(false);
    setResult(meet.result);
    setSatisfaction(meet.satisfaction);
    setMessage(meet.text);
    setDate(dateToUIFormat(new Date(meet.date)));
  }, [meet]);

  const handleEdit = () => {
    editMeet({
      id: meet.id,
      body: {
        result: result.id,
        satisfaction: satisfaction.id,
        text: message,
        topic,
        date: date.split('/').reverse().join('-')
      }
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Зустріч відредаговано',
          message: 'Ви успішно змінили зустріч'
        });
        setEdit(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleDelete = () => {
    deleteMeet({ id: meet.id })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Зустріч видалено',
          message: 'Ви успішно видалили зустріч'
        });
        setEdit(false);
        setShowConfirm(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  useEffect(() => {
    if (!edit) {
      handleCancel();
    }
  }, [edit, handleCancel]);

  return (
    <>
      <ActivityItem
        expand={expand}
        setExpand={setExpand}
        setEdit={setEdit}
        setShowConfirm={setShowConfirm}
        ownerId={meet.contact_meet_owner?.id}
        createdAt={meet.created_at}
        title={
          <>
            <span>Персональний контакт/зустріч</span> від{' '}
            {meet.contact_meet_owner?.full_name ||
              meet.contact_meet_owner?.username ||
              meet.partner_meet_owner?.full_name ||
              meet.partner_meet_owner?.username}{' '}
            з {contactFullName}
          </>
        }
      >
        {expand && (
          <>
            {!edit ? (
              <Message>{meet.text}</Message>
            ) : (
              <TextareaWrapper>
                <ActivityTextArea
                  value={message}
                  setValue={setMessage}
                  placeholder="Опишіть Зустріч..."
                  required
                />
              </TextareaWrapper>
            )}
            <InputContainer>
              <Input
                label={isPartner ? 'Партнер' : 'Контакт'}
                disabled
                value={contactFullName}
                onChange={() => {}}
              />
              <InputDate
                disabled={!edit}
                value={date}
                setValue={setDate}
                required
                openUp
              />
              <Select
                label="Результат"
                value={result}
                options={MEET_RESULT}
                setValue={setResult}
                disabled={!edit}
              />
              <div></div>
              <Select
                value={satisfaction}
                setValue={setSatisfaction}
                label="Настрій"
                options={SATISFACTION}
                disabled={!edit}
              />
              <Input
                label="Тема"
                value={topic}
                disabled={!edit}
                onChange={(e) => {
                  setTopic(e.target.value);
                }}
                required
              />
            </InputContainer>
            {edit && (
              <EditBtns>
                <BtnSecondary onClick={handleCancel}>Відмінити</BtnSecondary>
                <BtnPrimary disabled={!message} onClick={handleEdit}>
                  Зберегти
                </BtnPrimary>
              </EditBtns>
            )}
          </>
        )}
      </ActivityItem>
      <ConfirmPopup
        show={showConfirm}
        setShow={setShowConfirm}
        onSubmit={handleDelete}
        title="Ви дійсно бажаєте видалити зустріч?"
        needTranslate
      />
    </>
  );
};
