import styled, { keyframes } from 'styled-components';
import { centerFlex } from '../../style/helpers';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderWrapper = styled.div<{ ac?: boolean }>`
  ${(p) => (p.ac ? centerFlex : '')}
`;

export const LoaderSmallStyled = styled.span`
  width: 30px;
  height: 30px;
  border: 2px dotted ${(p) => p.theme.colors.blue.primary};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotate} 2s linear infinite;
  z-index: 10;
`;
