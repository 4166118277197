import type { FC, ReactNode } from 'react';
import { IconContainer } from '../icon-container';
import { UsersSvg } from '../svg/sidebar/users-svg';
import { Count, CounterStyled } from './style';

interface CounterProps {
  children: ReactNode;
  icon?: FC;
}

export const Counter = ({ children, icon: Icon }: CounterProps) => (
  <CounterStyled>
    <IconContainer>{Icon ? <Icon /> : <UsersSvg />}</IconContainer>
    <Count>{children}</Count>
  </CounterStyled>
);
