import { useState } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ChartsLayout } from '../components/charts-layout';
import { ChartContainer } from '../components/chart-container';
import { useGetAudienceQuery } from '../../../api/dashboard-api';
import { useTypedSelector } from '../../../api/store';
import { DoubleChart } from '../components/double-chart';
import { Select } from '../../../components/select';
import type { OptionItem } from '../../../common/types/option-item';
import { ChartsSlider } from '../components/charts-slider';
import { BarChart } from '../components/bar-chart';
import { AppRoute } from '../../../common/enums/app/app-route.enum';
import { Loader } from '../../../components/loader';
import { groupByRegion } from '../helpers/group-by-region';
import { useGetVolunteerCitiesQuery } from '../../../api/contact-api';
import { CategoriesChart } from '../components/categories-chart';
import { transformCityList } from '../helpers';
import { SelectCityWrapper } from './style';

export const DashboardVolunteersPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeCity, setActiveCity] = useState({ id: '', name: '' });
  const [activeRegion, setActiveRegion] = useState({ id: '', name: '' });
  const { date } = useTypedSelector((state) => state.dashboard);

  const [city, setCity] = useState<OptionItem<{ count: number }>>({
    id: '',
    title: ''
  });

  const { data, isFetching } = useGetAudienceQuery(
    {
      params: `?liv_city=${activeCity.name}&liv_region=${activeRegion.name}&created_start=${date.start}&created_end=${date.end}&is_volunteer=true`
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: volunteerCitites } = useGetVolunteerCitiesQuery('');

  if (!data) {
    return <Loader />;
  }

  return (
    <ChartsLayout
      activeCity={activeCity}
      activeRegion={activeRegion}
      setActiveCity={setActiveCity}
      setActiveRegion={setActiveRegion}
      total={data.data.adults}
      mt="0"
      isFetching={isFetching}
      dataByRegion={groupByRegion(data.data.map)}
    >
      <ChartContainer title="Фактичне місцезнаходження волонтерів">
        <SelectCityWrapper>
          <Select
            label="Місто"
            options={transformCityList(volunteerCitites || [])}
            placeholder="Оберіть зі списку"
            value={city}
            setValue={setCity}
          />
          {!!city.id && (
            <BarChart
              tickAlign="center"
              margin={{ left: -50, top: 40 }}
              tickMaxWidth={80}
              barCount={1}
              aspect={1}
              color={theme.colors.primary}
              data={[
                {
                  name: city.title,
                  value: city.restFields?.count || 0
                }
              ]}
            />
          )}
        </SelectCityWrapper>
      </ChartContainer>
      <ChartContainer title="Вік та гендер волонтерів">
        <DoubleChart
          legends={[
            { name: 'Чоловіки', value: data.data.men_count },
            { name: 'Жінки', value: data.data.women_count }
          ]}
          colors={['#306FE3', theme.colors.primary]}
          data={data.data.age_and_gender.map((item) => ({
            name: item.age_gradation,
            firstValue: item.men,
            secondValue: item.women
          }))}
        />
      </ChartContainer>
      <ChartsSlider
        title="Освіта"
        data={[
          {
            title: '',
            chart: (
              <BarChart
                tickAlign="center"
                tickMaxWidth={80}
                aspect={2}
                color={theme.colors.primary}
                data={data.data.education_count.slice(1, 5).map((item) => {
                  const [_, [name, value]] = Object.entries(item);
                  if (typeof value === 'number') {
                    return { name, value };
                  }

                  return { name: '', value: 0 };
                })}
              />
            )
          },
          {
            title: '',
            chart: (
              <BarChart
                tickAlign="center"
                tickMaxWidth={80}
                color={theme.colors.primary}
                data={[
                  ...data.data.education_count.slice(5).map((item) => {
                    const [_, [name, value]] = Object.entries(item);
                    if (typeof value === 'number') {
                      return { name, value };
                    }

                    return { name: '', value: 0 };
                  }),
                  {
                    name: 'Інше',
                    value: data.data.education_count[0]['Інше'] as number
                  }
                ]}
              />
            )
          }
        ]}
      />
      <ChartContainer title="Сімейний стан">
        <DoubleChart
          legends={[
            { name: 'Чоловіки', value: data.data.men_count },
            { name: 'Жінки', value: data.data.women_count }
          ]}
          colors={['#306FE3', theme.colors.primary]}
          data={data.data.family_status_count.map((item) => {
            const stausArr = Object.entries(item);
            const name = stausArr[1][0];
            const men = Array.isArray(stausArr[2][1]) ? stausArr[2][1][1] : 0;
            const women = Array.isArray(stausArr[2][1]) ? stausArr[2][1][2] : 0;

            return {
              name,
              firstValue: typeof men === 'number' ? 0 : men['Чоловік'],
              secondValue: typeof women === 'number' ? 0 : women['Жінка']
            };
          })}
        />
      </ChartContainer>
      <ChartContainer title="Діяльність">
        <DoubleChart
          legends={[
            { name: 'Чоловіки', value: data.data.men_count },
            { name: 'Жінки', value: data.data.women_count }
          ]}
          colors={['#306FE3', theme.colors.primary]}
          data={data.data.employment_count.map((item) => {
            const stausArr = Object.entries(item);
            const name = stausArr[1][0];
            const men = Array.isArray(stausArr[2][1]) ? stausArr[2][1][1] : 0;
            const women = Array.isArray(stausArr[2][1]) ? stausArr[2][1][2] : 0;

            return {
              name,
              firstValue: typeof men === 'number' ? 0 : men['Чоловік'],
              secondValue: typeof women === 'number' ? 0 : women['Жінка']
            };
          })}
        />
      </ChartContainer>
      <CategoriesChart
        withAnyCategory={data.data.with_any_category}
        categoriesCount={data.data.categories_count}
        contactsCount={data.data.adults}
      />
      <ChartContainer title="Документи">
        <BarChart
          aspect={1.5}
          tickMaxWidth={80}
          barCount={2}
          color={theme.colors.primary}
          data={[
            { name: 'Є', value: data.data.docs_count.with_docs },
            {
              name: 'Немає',
              value: data.data.docs_count.no_docs,
              onClick: () =>
                navigate(`${AppRoute.CONTACTS}/?is_volunteer=true&no_docs=true`)
            }
          ]}
        />
      </ChartContainer>
    </ChartsLayout>
  );
};
