import type { TooltipProps } from 'recharts';
import {
  Bar,
  BarChart as BarRechart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import type {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent';
import type { Margin } from 'recharts/types/util/types';
import { StringCutter } from '../../../../components/string-cutter';
import { BarChartStyled, CustomTooltip, TickStyled } from './style';

interface DoubleChartProps {
  color: string | string[];
  data: { name: string; value: number; onClick?: () => void }[];
  barCount?: number;
  aspect?: number;
  tickAlign?: 'left' | 'center';
  tickMaxWidth?: number;
  margin?: Margin;
  yMax?: number;
  withTooltip?: boolean;
}

export const BarChart = ({
  color,
  data,
  barCount,
  aspect,
  tickAlign = 'left',
  tickMaxWidth = 100,
  margin,
  yMax,
  withTooltip
}: DoubleChartProps) => {
  const renderTooltip = ({
    active,
    payload,
    label
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltip>
          <p>{label}</p>
          <p>{payload[0].value}</p>
        </CustomTooltip>
      );
    }

    return null;
  };

  // if (!data.filter((item) => item.value).length) {
  //   return <NoData>Недестатньо даних</NoData>;
  // }

  return (
    <BarChartStyled
      style={{ maxWidth: barCount ? barCount * 80 + 60 : '100%' }}
    >
      <ResponsiveContainer aspect={aspect}>
        <BarRechart margin={margin} data={data}>
          {yMax ? <YAxis hide domain={[0, yMax]} /> : ''}
          <XAxis
            dataKey="name"
            strokeWidth={0}
            interval={0}
            tickLine={false}
            tick={({ payload, x, y }) =>
              data.length > 5 ? (
                <text></text>
              ) : (
                <foreignObject
                  width={tickMaxWidth}
                  height="50"
                  x={x - tickMaxWidth / 2}
                  y={y}
                >
                  <TickStyled $textAlign={tickAlign}>
                    <StringCutter lines="2">{payload.value}</StringCutter>
                  </TickStyled>
                </foreignObject>
              )
            }
          />
          {(data.length > 5 || withTooltip) && (
            <Tooltip viewBox={{ width: 80 }} content={renderTooltip} />
          )}
          <Bar label={{ position: 'top' }} barSize={80} dataKey="value">
            {data.map((item, i) => (
              <Cell
                style={{
                  cursor: item.onClick ? 'pointer' : 'default'
                }}
                key={`cell-${i}`}
                onClick={item.onClick}
                fill={
                  color ? (typeof color === 'string' ? color : color[i]) : ''
                }
              />
            ))}
          </Bar>
        </BarRechart>
      </ResponsiveContainer>
    </BarChartStyled>
  );
};
