import { useGetUserMeQuery } from '../../../api/user-api';
import {
  Avatar,
  HeaderUserStyled,
  UserColor,
  UserEmail,
  UserName
} from './style';

export const HeaderUser = () => {
  const { data } = useGetUserMeQuery('', {
    refetchOnMountOrArgChange: true
  });

  return (
    <HeaderUserStyled>
      <Avatar>{data?.username[0]}</Avatar>
      <div>
        <UserName>{data?.username}</UserName>
        <UserEmail>{data?.email}</UserEmail>
      </div>
      <UserColor color={data?.role?.color} />
    </HeaderUserStyled>
  );
};
