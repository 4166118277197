import { hasOwnProperty } from './has-own-property';

export const getApiError = (err: unknown, showAllError?: boolean) => {
  if (!window.navigator.onLine) {
    return 'Відсутнє інтернет з`єднання';
  }
  if (
    err &&
    typeof err === 'object' &&
    hasOwnProperty(err, 'data') &&
    err.data &&
    typeof err.data === 'object'
  ) {
    const [, errors] = Object.entries(err.data)[0];

    return typeof errors === 'string' || showAllError ? errors : errors[0];
  }

  if (
    err &&
    typeof err === 'object' &&
    hasOwnProperty(err, 'data') &&
    err.data &&
    typeof err.data === 'string' &&
    err.data.indexOf('<html') === -1
  ) {
    return err.data;
  }

  return 'Internal Error';
};
