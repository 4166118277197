import { useRef, type Dispatch, type SetStateAction, useEffect } from 'react';
import { DateSelectOptionsStyled, OptionItem } from './style';

interface DateSelectOptionsProps {
  options: (string | number)[];
  onChoose: (i: number) => void;
  setShowOptions: Dispatch<SetStateAction<boolean>>;
  isYear?: boolean;
}

export const DateSelectOptions = ({
  options,
  onChoose,
  setShowOptions,
  isYear
}: DateSelectOptionsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleChoose = (i: number) => {
    onChoose(i);
    setShowOptions(false);
  };

  useEffect(() => {
    if (isYear && ref.current) {
      ref.current.scrollTo(0, 270);
    }
  }, [isYear]);

  return (
    <DateSelectOptionsStyled ref={ref}>
      {options.map((item, i) => (
        <OptionItem onClick={() => handleChoose(i)} key={i}>
          {item}
        </OptionItem>
      ))}
    </DateSelectOptionsStyled>
  );
};
