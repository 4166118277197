import { ApiPath } from '../common/enums/http/api-path.enum';
import type { AddressBook } from '../models/addressbook';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import { ApiTag } from '../common/enums/http/api-tag.enum';
import type { ILetter } from '../models/letter';
import type { LetterBd } from '../models/letter-bd';
import { commonApi } from './common-api';

export const sendPulsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAddressBooks: builder.query<AddressBook[], string>({
      query: () => `${ApiPath.ADDRESS_BOOKS}${ApiPath.ROOT}`,
      providesTags: [ApiTag.ADDRESS_BOOKS]
    }),
    getLetters: builder.query<ILetter[], { contactId: string | number }>({
      query: ({ contactId }) =>
        `${ApiPath.CONTACT_LETTERS}${ApiPath.ROOT}${contactId}`
    }),
    getBdLetters: builder.query<LetterBd[], { contactId: string | number }>({
      query: ({ contactId }) =>
        `${ApiPath.CONTACT_BD_LETTERS}${ApiPath.ROOT}${contactId}`,
      providesTags: [ApiTag.LETTER]
    }),
    createAddressBook: builder.mutation<AddressBook, { name: string }>({
      query: (body) => ({
        url: ApiPath.CREATE_ADDRESS_BOOK,
        method: HttpMethod.POST,
        body
      })
    }),
    createLetter: builder.mutation<
      unknown,
      { contact_id: string | number; subject: string; html: string }
    >({
      query: (body) => ({
        url: ApiPath.CREATE_LETTER,
        method: HttpMethod.POST,
        body: { ...body, text: '' }
      }),
      invalidatesTags: [ApiTag.LETTER]
    }),
    addContactsToAddressBook: builder.mutation<
      unknown,
      { bookId: string | number; contactsIds: (string | number)[] }
    >({
      query: ({ bookId, contactsIds }) => ({
        url: `${ApiPath.ADD_CONTACTS_TO_ADDRESSBOOK}${ApiPath.ROOT}${bookId}`,
        method: HttpMethod.POST,
        body: { contacts_ids: contactsIds }
      }),
      invalidatesTags: [ApiTag.ADDRESS_BOOKS]
    })
  }),
  overrideExisting: false
});

export const {
  useGetAddressBooksQuery,
  useCreateAddressBookMutation,
  useAddContactsToAddressBookMutation,
  useGetLettersQuery,
  useCreateLetterMutation,
  useGetBdLettersQuery
} = sendPulsApi;
