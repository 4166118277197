import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const UserInfoStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div<{ isBig?: boolean }>`
  margin-right: 16px;
  width: ${(p) => (p.isBig ? '72px' : '56px')};
  min-width: ${(p) => (p.isBig ? '72px' : '56px')};
  height: ${(p) => (p.isBig ? '72px' : '56px')};
  border-radius: 100%;
  ${centerFlex}
  font-weight: 600;
  font-size: ${(p) => (p.isBig ? '28px' : '22px')};
  line-height: 1.4;
  color: ${(p) => p.theme.colors.blue.dark};
  background: ${(p) => p.theme.colors.avatar};

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    width: ${(p) => (p.isBig ? '60px' : '46px')};
    min-width: ${(p) => (p.isBig ? '60px' : '46px')};
    height: ${(p) => (p.isBig ? '60px' : '46px')};
    font-size: ${(p) => (p.isBig ? '22px' : '18px')};
  }
`;

export const UserName = styled.div<{ isBig?: boolean }>`
  font-weight: 700;
  font-size: ${(p) => (p.isBig ? '26px' : '22px')};
  line-height: 120%;
  color: ${(p) => p.theme.colors.blue.dark};

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    font-size: ${(p) => (p.isBig ? '22px' : '18px')};
  }
`;
