import styled, { css } from 'styled-components';
import { centerFlex } from '../../style/helpers';

type ActivityState = 'big' | 'default' | 'small';

interface NewActivityProps {
  state: ActivityState;
  customHeight?: string;
  expanded?: boolean;
}

const formCss = (state: ActivityState, height: string) => {
  switch (state) {
    case 'default':
      return css`
        height: ${height};
      `;
    case 'small':
      return css`
        height: 0;
        overflow: hidden;
      `;
    case 'big':
      return css`
        height: 67vh;
      `;
  }
};

export const NewActivityOverlay = styled.div<{ expand?: boolean }>`
  position: fixed;
  visibility: ${(p) => (p.expand ? 'visible' : 'hidden')};
  opacity: ${(p) => (p.expand ? '1' : '0')};
  left: calc(-17vw);
  top: 0;
  width: 100vw;
  height: 100vw;
  background: ${(p) => p.theme.colors.popup.bg};
  transition: opacity 0.4s;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    left: calc(-10vw);
  }
`;

export const NewActivityStyled = styled.div<NewActivityProps>`
  position: fixed;
  right: ${(p) => (p.state === 'big' ? '9vw' : '40px')};
  bottom: ${(p) => (p.state === 'big' ? '12vh' : '40px')};
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 4px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  z-index: 11;
  width: 100%;
  max-width: ${(p) => (p.state === 'big' ? '80vw' : '680px')};
  transition: all 0.2s;
`;

export const ActivityHeader = styled.div<NewActivityProps>`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid
    ${(p) => (p.state === 'small' ? 'transparent' : p.theme.colors.grey.input)};
`;

export const ActivityHeaderTitle = styled.div<NewActivityProps>`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${(p) =>
    p.state === 'small'
      ? p.theme.colors.blue.primary
      : p.theme.colors.blue.dark};
  cursor: ${(p) => (p.expanded ? 'default' : 'pointer')};
  :hover {
    color: ${(p) =>
      p.expanded ? p.theme.colors.blue.dark : p.theme.colors.blue.primary};
  }
  svg {
    transform: ${(p) => (p.state === 'small' ? 'rotate(0)' : 'rotate(90deg)')};
    transition: transform 0.2s;
  }
`;

export const ActivityActionBtn = styled.div`
  position: relative;
  margin-left: 20px;
  width: 14px;
  height: 14px;
  ${centerFlex}
  svg {
    display: block;
    path {
      fill: ${(p) => p.theme.colors.grey.light};
    }
  }
  cursor: pointer;
`;

export const FormStyled = styled.form<NewActivityProps>`
  transition: height 0.2s;
  ${(p) => formCss(p.state, p.customHeight || '214px')}
`;

export const TextareaWrapper = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.input};
  height: calc(100% - 76px);
`;

export const NoteFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;
