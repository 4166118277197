import type { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import { useRef, useState } from 'react';
import { Label } from '../label';
import { StringCutter } from '../string-cutter';
import { AngleSvg } from '../svg/angle-svg';
import { useOutsideClick } from '../../hooks/use-outside-click';
import type { OptionItem } from '../../common/types/option-item';
import { CheckBox } from '../checkbox';
import { SearchSvg } from '../svg/search-svg';
import { useDebounce } from '../../hooks/use-debounce';
import { Badge } from '../badge';
import {
  LoaderStyled,
  MultipleHeader,
  OptionsScroolWrapper,
  OverflowContainer,
  SearchIcon,
  SearchInput,
  SearchStyled,
  SelectHeader,
  SelectHeaderTitle,
  SelectOptionItem,
  SelectOptions,
  SelectWrapper
} from './style';

interface SelectProps<T> {
  label?: string;
  value?: OptionItem<T>;
  setValue?: (value: OptionItem<T>) => void;
  options?: OptionItem<T>[];
  placeholder?: string;
  size?: {
    width?: string;
    height?: string;
  };
  fz?: string;
  isStatic?: boolean;
  mt?: string;
  mb?: string;
  multipleOptions?: OptionItem<T>[];
  setMultipleOptions?: Dispatch<SetStateAction<OptionItem<T>[]>>;
  openUp?: boolean;
  disabled?: boolean;
  isSearch?: boolean;
  term?: string;
  setTerm?: Dispatch<SetStateAction<string>>;
  isLoading?: boolean;
  setDebounceTerm?: Dispatch<SetStateAction<string>>;
}

export const Select = <T,>({
  label,
  value,
  options,
  setValue,
  placeholder = 'Оберіть зі списку',
  size,
  mt,
  mb,
  isStatic,
  multipleOptions,
  setMultipleOptions,
  fz,
  openUp,
  disabled,
  isSearch,
  setTerm,
  term,
  isLoading,
  setDebounceTerm = () => {}
}: SelectProps<T>) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => setShowOptions(false));
  const debounce = useDebounce(setDebounceTerm, 500);

  const [showOptions, setShowOptions] = useState(false);

  const handleShowOptions = () => {
    if (!disabled) {
      setShowOptions((state) => !state);
    }
  };

  const handleSelect = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: OptionItem<T>
  ) => {
    e.stopPropagation();
    if (setValue) {
      setValue(value);
      setShowOptions(false);
    }
  };

  const handleChangeTerm: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (setTerm) {
      setTerm(e.target.value);
      debounce(e.target.value);
    }
  };

  const currentOptions = multipleOptions || options;

  return (
    <SelectWrapper ref={ref} mt={mt} mb={mb}>
      {label && <Label>{label}</Label>}
      <SelectHeader
        isActive={showOptions}
        onClick={handleShowOptions}
        size={size}
        disabled={disabled}
      >
        {multipleOptions && setMultipleOptions ? (
          <MultipleHeader>
            {multipleOptions.filter((item) => item.checked).length ? (
              <OverflowContainer>
                {multipleOptions
                  .filter((item) => item.checked)
                  .map((item) => (
                    <Badge
                      onRemove={() =>
                        setMultipleOptions((state) =>
                          state.map((el) =>
                            el.id === item.id ? { ...el, checked: false } : el
                          )
                        )
                      }
                      key={item.id}
                    >
                      {item.title}
                    </Badge>
                  ))}
              </OverflowContainer>
            ) : (
              <span>{placeholder}</span>
            )}
          </MultipleHeader>
        ) : (
          <SelectHeaderTitle fz={fz}>
            <StringCutter lines="1">
              {`${value?.id}` ? value?.title : <span>{placeholder}</span>}
            </StringCutter>
          </SelectHeaderTitle>
        )}
        <AngleSvg />
      </SelectHeader>
      {showOptions && (
        <SelectOptions openUp={openUp} isStatic={isStatic}>
          {isSearch && setTerm && (
            <SearchStyled>
              <SearchInput value={term} onChange={handleChangeTerm} />
              <SearchIcon>
                <SearchSvg />
              </SearchIcon>
              {isLoading && <LoaderStyled></LoaderStyled>}
            </SearchStyled>
          )}
          <OptionsScroolWrapper>
            {currentOptions?.map((item) => (
              <SelectOptionItem
                openUp={openUp}
                onClick={(e) => handleSelect(e, item)}
                key={item.id}
              >
                {multipleOptions && setMultipleOptions ? (
                  <CheckBox
                    fullWidth
                    alignStart
                    label={item.title}
                    setChecked={(checked) =>
                      setMultipleOptions((state) =>
                        state.map((optionItem) =>
                          item.id === optionItem.id
                            ? { ...optionItem, checked }
                            : optionItem
                        )
                      )
                    }
                    checked={!!item?.checked}
                  />
                ) : (
                  <>
                    {item.title} <span>{item.additionalTitle}</span>
                  </>
                )}
              </SelectOptionItem>
            ))}
          </OptionsScroolWrapper>
        </SelectOptions>
      )}
    </SelectWrapper>
  );
};
