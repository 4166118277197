import styled from 'styled-components';
import { scrollBar } from '../../../../style/helpers';

export const ChartsLayoutStyled = styled.div<{
  $mt?: string;
  $isProject?: boolean;
}>`
  position: relative;
  margin-top: ${(p) => p.$mt || '20px'};
  display: grid;
  grid-template-columns: auto 60%;
  gap: 14px;
  min-height: 300px;
  height: ${(p) =>
    p.$isProject ? 'calc(100vh - 330px)' : 'calc(100vh - 112px)'};

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    height: ${(p) =>
      p.$isProject ? 'calc(100vh - 255px)' : 'calc(100vh - 112px)'};
  }

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

export const ChartsColumnAdaptHeight = styled.div<{
  $secondChartIsOpen?: boolean;
}>`
  padding-right: 6px;
  height: 100%;
  display: grid;
  grid-template-rows: ${(p) =>
    p.$secondChartIsOpen
      ? 'auto clamp(220px, calc(100% - 378px), 400px)'
      : 'auto'};
  gap: 20px;
  ${scrollBar}
  ::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
  }
  align-content: start;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    grid-template-rows: ${(p) =>
      p.$secondChartIsOpen
        ? 'auto clamp(220px, calc(100% - 278px), 400px)'
        : 'auto 68px'};
  }
`;

export const ChartsColumn = styled.div`
  padding-right: 6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${scrollBar}
  ::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
  }
`;
