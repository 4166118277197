import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { TabItem } from '../tab-item';
import { TabsStyled } from './style';

interface TabsProps {
  tabList: { path: string; title: string; icon?: FC }[];
}

export const Tabs = ({ tabList }: TabsProps) => {
  const location = useLocation();

  return (
    <TabsStyled>
      {tabList.map((item, i) => (
        <TabItem
          $isActive={location.pathname === item.path}
          {...item}
          key={i}
        />
      ))}
    </TabsStyled>
  );
};
