import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonAdd } from '../../components/button-add';
import { Counter } from '../../components/counter';
import { FlexContainer } from '../../components/flex-container';
import { PageTitle } from '../../components/page-title';
import { SubHeader } from '../../components/subheader';
import { Tabs } from '../../components/tabs';
import { AppRoute, UsersRoute } from '../../common/enums/app/app-route.enum';
import { TabDataSvg } from '../../components/svg/tabs/tab-data-svg';
import { RolesSvg } from '../../components/svg/tabs/roles-svg';
import { ProtectionSvg } from '../../components/svg/tabs/protection-svg';
import { Popup } from '../../components/popup';
import {
  useGetUserMeQuery,
  useGetUserRolesQuery,
  useGetUsersQuery
} from '../../api/user-api';
import { NoData } from '../../components/no-data';
import { SubheaderSelect } from '../../components/subheader-select';
import { Filters } from '../../components/filters';
import { FilterSvg } from '../../components/svg/filter-svg';
import {
  setFiltersFromQueryString,
  transformFilterToObj
} from '../../components/filters/helpers';
import { FilterBadges } from '../../components/filter-badges';
import type { Filter } from '../../common/types/filters';
import { setBadges } from '../../components/filter-badges/helpers';
import { useTypedSelector } from '../../api/store';
import { filterQueryParams } from '../../helpers/filter-query-params';
import { Loader } from '../../components/loader';
import { AddUser } from './add-user';
import { initialFilters } from './data';

export const tabs = [
  { path: AppRoute.USERS, title: 'Дані співробітників', icon: TabDataSvg },
  {
    path: `${AppRoute.USERS}/${UsersRoute.ROLES}`,
    title: 'Ролі CRM ',
    icon: RolesSvg
  },
  {
    path: `${AppRoute.USERS}/${UsersRoute.ACCESS}`,
    title: 'Управління доступами ',
    icon: ProtectionSvg
  }
];

export const UsersPage = () => {
  const [searchParams, setSearhParams] = useSearchParams();
  const { data: userMe } = useGetUserMeQuery('');
  const { data: roles } = useGetUserRolesQuery('');
  const { pageSize } = useTypedSelector((state) => state['page-data']);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showfilter, setShowfilter] = useState(false);
  const [showAllBadges, setShowAllBadges] = useState(false);
  const [filters, setFilters] = useState<Filter[]>(initialFilters);
  const { pathname } = useLocation();
  const [initialFiltersWitRoles, setInitialFilterWithRoles] =
    useState<Filter[]>(initialFilters);
  const { data } = useGetUsersQuery(
    {
      params: `?size=${pageSize}&${filterQueryParams(
        searchParams.toString(),
        'id'
      )}`
    },
    { refetchOnMountOrArgChange: true }
  );

  const allowedTabs = useMemo(() => {
    if (userMe?.is_superuser) {
      return tabs;
    }
    if (!userMe?.role?.change_role) {
      return [tabs[0], tabs[1]];
    }

    return tabs;
  }, [userMe]);

  const resetFilters = useCallback(() => {
    Object.keys(transformFilterToObj(filters)).forEach((item) => {
      searchParams.delete(item);
    });
    setSearhParams(searchParams);
    setShowfilter(false);
    setFilters(initialFiltersWitRoles);
  }, [setSearhParams, filters, searchParams, initialFiltersWitRoles]);

  const aplyFilter = () => {
    setSearhParams(transformFilterToObj(filters));
    setShowfilter(false);
  };

  useEffect(() => {
    if (roles) {
      setFilters(() =>
        setFiltersFromQueryString(searchParams.toString(), [
          {
            ...initialFilters[0],
            type: 'checkbox',
            checkList: roles.results.map((item) => ({
              id: item.id,
              checked: false,
              title: item.name,
              name: `${item.id}`
            }))
          },
          ...initialFilters.slice(1)
        ])
      );
      setInitialFilterWithRoles(() =>
        setFiltersFromQueryString(searchParams.toString(), [
          {
            ...initialFilters[0],
            type: 'checkbox',
            checkList: roles.results.map((item) => ({
              id: item.id,
              checked: false,
              title: item.name,
              name: `${item.id}`
            }))
          },
          ...initialFilters.slice(1)
        ])
      );
    }
  }, [searchParams, roles]);

  const canView = userMe?.role?.view_user || userMe?.is_superuser;
  const canViewRole = userMe?.role?.view_role || userMe?.is_superuser;
  const filtersLength = setBadges(filters, '').length;
  const badges = setBadges(initialFiltersWitRoles, searchParams.toString());

  if (!userMe || !canView) {
    return null;
  }

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <SubHeader>
        <FlexContainer>
          <PageTitle>Співробітники</PageTitle>
          {((userMe && userMe?.role?.view_user) || userMe.is_superuser) && (
            <Counter>{data?.count}</Counter>
          )}
          {pathname === AppRoute.USERS && (
            <SubheaderSelect
              show={showfilter}
              setShow={setShowfilter}
              icon={FilterSvg}
              title={`Фільтри (${filtersLength})`}
              isActive={!!filtersLength}
            >
              <Filters
                setFilters={setFilters}
                filters={filters}
                setShow={setShowfilter}
                onCancel={resetFilters}
                onSubmit={aplyFilter}
                disabled={!filtersLength && !badges.length}
              />
            </SubheaderSelect>
          )}
        </FlexContainer>
        {((userMe && userMe?.role?.add_user) || userMe.is_superuser) && (
          <ButtonAdd onClick={() => setShowAddUser(true)}>
            Новий співробітник
          </ButtonAdd>
        )}
      </SubHeader>
      <FilterBadges
        showAll={showAllBadges}
        setShowAll={setShowAllBadges}
        badges={badges}
      />
      {!userMe?.role?.view_role &&
      !userMe?.role?.view_user &&
      !userMe?.is_superuser ? (
        <NoData />
      ) : (
        <>
          {canViewRole && <Tabs tabList={allowedTabs} />}
          <Outlet
            context={{
              badgesLength: badges.length,
              showAllBadges: showAllBadges
            }}
          />
        </>
      )}
      <Popup
        outsideOnlyEscape
        show={showAddUser}
        setShow={setShowAddUser}
        title="Новий співробітник"
      >
        <AddUser setShow={setShowAddUser} />
      </Popup>
    </>
  );
};
