import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const HistoryItemStyled = styled.div`
  width: 100%;
  padding: 16px 0;
  height: 56px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.table};
  display: flex;
  justify-content: space-between;
  align-items: center;
  :last-child {
    border-bottom: none;
  }
`;

export const HistoryIcon = styled.div`
  margin-right: 10px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.grey.light};
  ${centerFlex}
  svg {
    max-width: 10px;
  }
`;

export const HistoryTitle = styled.div<{ isBinotel?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  max-width: ${(p) => (p.isBinotel ? '60%' : 'unset')};
  span,
  a {
    color: ${(p) => p.theme.colors.blue.primary};
    font-weight: 600;
  }
`;

export const HistoryDate = styled.div<{ isBinotel?: boolean }>`
  display: ${(p) => (p.isBinotel ? 'flex' : 'block')};
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.grey.light};
`;
