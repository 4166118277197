export const PauseSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_5130_353968)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5078 20 20 15.5078 20 10C20 4.49219 15.5078 0 10 0C4.49219 0 0 4.49219 0 10C0 15.5078 4.49219 20 10 20Z"
        fill="#E2C32B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.92187 6.08984V13.9141C8.92187 14.7539 8.23438 15.4453 7.39062 15.4453C6.54687 15.4453 5.85938 14.7578 5.85938 13.9141V6.08984C5.85938 5.24609 6.54687 4.55859 7.39062 4.55859C8.23438 4.55469 8.92187 5.24609 8.92187 6.08984ZM12.6094 4.55469C11.7656 4.55469 11.0781 5.24219 11.0781 6.08594V13.9102C11.0781 14.75 11.7656 15.4414 12.6094 15.4414C13.4492 15.4414 14.1406 14.7539 14.1406 13.9102V6.08984C14.1406 5.24609 13.4492 4.55469 12.6094 4.55469Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5130_353968">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
