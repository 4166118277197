import type { Filter, SelectList } from '../../common/types/filters';
import type { OptionItem } from '../../common/types/option-item';
import type { CheckList, FilterText } from '../../pages/users/types';
import { setFiltersFromQueryString } from '../filters/helpers';

export interface IBadge {
  id?: string | number;
  title: string | number;
  checked?: boolean;
  value?: string | OptionItem;
  type: string;
  parent?: string;
  name?: string;
  isSingle?: string;
  parentId?: string;
  sub?: {
    id: string | number;
    title: string;
    checked: boolean;
    label: string;
    name: string;
  }[];
}

export const setBadges = (filters: Filter[], query: string): IBadge[] => {
  const apliedFilters = setFiltersFromQueryString(query, filters);
  const flatFilter = apliedFilters
    .map((item) =>
      item.type === 'sublist'
        ? item.list.map((listItem) => ({ ...listItem, parent: item.title }))
        : item
    )
    .flat();

  const badges = flatFilter
    .map((item) => {
      // if (item.type === 'checkbox') {
      //   return item.checkList.map((checkListItem) =>
      //     item.isSingly
      //       ? { ...checkListItem, type: 'checkbox' }
      //       : {
      //           ...checkListItem,
      //           parent: item.title,
      //           parentId: item.name,
      //           type: 'checkbox'
      //         }
      //   );
      // }

      if (item.type === 'checkbox') {
        return item.checkList.map((checkListItem) =>
          item.isSingly
            ? { ...checkListItem, parent: item.title, type: 'checkbox' }
            : {
                ...checkListItem,
                parent: item.title,
                parentId: item.name,
                type: 'checkbox'
              }
        );
      }

      if (item.type === 'select') {
        return item.selectList.map((selectListItem) => ({
          ...selectListItem,
          type: 'select'
        }));
      }

      return item;
    })
    .flat()
    .filter((item) =>
      item.type === 'select'
        ? (item as SelectList).value?.id
        : (item as CheckList).checked || (item as FilterText).value
    );

  return badges;
};
