import styled from 'styled-components';

export const BtnPrimaryStyled = styled.button<{
  isActivity?: boolean;
  fz?: string;
  p?: string;
}>`
  padding: ${(p) => p.p || '11px 28px'};
  font-weight: 500;
  font-size: ${(p) => p.fz || '16px'};
  line-height: 19px;
  color: ${(p) => p.theme.colors.white};
  height: 40px;
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 6px;
  transition: background-color 0.2s;
  cursor: pointer;
  :disabled {
    color: ${(p) => p.theme.colors.btn.disabledText};
    border: 1px solid
      ${(p) => (p.isActivity ? p.theme.colors.grey.input : 'transparent')};
    background-color: ${(p) =>
      p.isActivity ? 'transparent' : p.theme.colors.btn.disabledBg};
    cursor: default;
  }
  :hover:not(:disabled) {
    background-color: ${(p) => p.theme.colors.primaryHover};
    transition: background-color 0.2s;
  }
`;
