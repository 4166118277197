import type { ReactNode } from 'react';
import { Transition } from 'react-transition-group';
import {
  defaultStyle,
  duration,
  transitionStyles
} from '../../common/transition/fade-transition';
import { TooltipStyled } from './style';

interface TooltipProps {
  children: ReactNode;
  isDown?: boolean;
  show: boolean;
  canBreak?: boolean;
}

export const Tooltip = ({ children, isDown, show, canBreak }: TooltipProps) => (
  <Transition in={show} mountOnEnter unmountOnExit timeout={duration}>
    {(state) => (
      <TooltipStyled
        canBreak={canBreak}
        style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}
        isDown={isDown}
      >
        {children}
      </TooltipStyled>
    )}
  </Transition>
);
