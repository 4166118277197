import { EVENT_FORMAT } from '../../common/constants';
import type { HeaderItem } from '../../components/data-table';
import type { Filter } from '../users/types';
import type { EventsTable } from './types';

export const tableHeaders: HeaderItem<EventsTable>[] = [
  { id: 'id', title: 'ID', checked: true },
  { id: 'name', title: 'назва події', checked: true },
  { id: 'description', title: 'опис події', checked: true },
  { id: 'start_date', title: 'ДАТА початку', checked: true },
  { id: 'end_date', title: 'ДАТА завершення', checked: true },
  { id: 'project_chief_manager', title: 'КЕРІВНИК ПРОЄКТУ', checked: true },
  { id: 'project_managers', title: 'МЕНЕДЖЕРИ події', checked: true },
  { id: 'region', title: 'Регіон', checked: true },
  { id: 'format', title: 'Формат події', checked: true },
  { id: 'budget', title: 'Бали події', checked: true },
  { id: 'contacts_count', title: 'КІЛЬКІСТЬ КОНТАКТІВ', checked: true },
  { id: 'meta_tag', title: 'Тег ПОДІЇ', checked: true }
];

export const initialFilters: Filter[] = [
  { type: 'text', title: 'Регіон', value: '', name: 'region' },
  {
    type: 'checkbox',
    checkList: [],
    title: 'Менеджер події (відповідальний)',
    name: 'managers'
  },
  {
    type: 'checkbox',
    checkList: EVENT_FORMAT.map((item) => ({ ...item, checked: false })),
    title: 'Формат події',
    name: 'event_format'
  }
];
