import styled from 'styled-components';

export const ProjectFormStyled = styled.form`
  padding-top: 20px;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const InputsContainer = styled.div`
  margin: 20px 0 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 30px;
  align-items: start;
`;

export const InputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

export const ManagerItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 16px;
  gap: 12px;
  align-items: center;
  svg {
    max-width: 11px;
    path {
      fill: ${(p) => p.theme.colors.grey.light};
    }
  }
`;
