import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { useGetUserMeQuery } from '../../../api/user-api';
import { AppRoute } from '../../../common/enums/app/app-route.enum';
import { SwitcherKanban } from '../../../components/switcher-kanban';
import { Switcher, UsersAccessStyled } from './style';
import { ActionAccess } from './action-access';
import { FieldsAccess } from './fields-access';

export const UsersAccess = () => {
  const { data: userMe } = useGetUserMeQuery('');
  const [isFields, setIsFields] = useState(false);

  if (!userMe) {
    return null;
  }

  if (
    (!userMe.role?.view_role || !userMe.role?.change_role) &&
    !userMe?.is_superuser
  ) {
    return <Navigate to={{ pathname: AppRoute.USERS }} />;
  }

  return (
    <UsersAccessStyled>
      <Switcher>
        <SwitcherKanban
          isRightOption={isFields}
          setIsRightOptins={setIsFields}
          leftTitle="Дії"
          rightTitle="Дані"
        />
      </Switcher>
      {isFields ? <FieldsAccess /> : <ActionAccess />}
    </UsersAccessStyled>
  );
};
