import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';

export const useScrollHeight = (
  ref: RefObject<HTMLElement>,
  initialHeight?: number
) => {
  const [scrollHeight, setScrollHeight] = useState(initialHeight || 500);
  const [hasScrollBar, setHasScrollBar] = useState(false);

  useEffect(() => {
    const checkHeight = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        setScrollHeight(window.innerHeight - top - 1);
        setHasScrollBar(ref.current.scrollHeight > ref.current.offsetHeight);
      }
    };
    checkHeight();

    window.addEventListener('resize', checkHeight);

    return () => {
      window.removeEventListener('resize', checkHeight);
    };
  }, [ref]);

  const manuallySetScrollHeight = useCallback(() => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top;
      setScrollHeight(window.innerHeight - top - 1);
      setHasScrollBar(ref.current.scrollHeight > ref.current.offsetHeight);
    }
  }, [ref]);

  return { scrollHeight, manuallySetScrollHeight, hasScrollBar };
};
