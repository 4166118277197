import { Outlet, useParams } from 'react-router-dom';
import { ProjectTab } from '../components/project-tab';
import {
  useGetEventsStatistcsQuery,
  useGetProjectsStatistcsQuery
} from '../../../api/dashboard-api';
import { useTypedSelector } from '../../../api/store';
import {
  AppRoute,
  DashboardRoute
} from '../../../common/enums/app/app-route.enum';
import { Tabs } from './style';

export const DashboardProjectsPage = () => {
  const { colors } = useTypedSelector((state) => state.dashboard);
  const { id } = useParams();

  const { data } = useGetProjectsStatistcsQuery(
    {
      params: ''
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: events } = useGetEventsStatistcsQuery('');

  if (!data || !events) {
    return null;
  }

  return (
    <>
      <Tabs>
        {data.map((item, i) => (
          <ProjectTab
            key={item.id}
            color={colors[i]?.color || ''}
            path={`${AppRoute.DASHBOARD}/${DashboardRoute.PROJECTS}/${item.id}`}
          >
            <span>Проєкт</span> <br />
            {item.name}
          </ProjectTab>
        ))}
      </Tabs>
      <Tabs>
        {events
          .filter((item) => (id ? item.project.id.toString() === id : true))
          .map((item) => (
            <ProjectTab
              isSmall
              color={
                colors
                  .find(
                    (color) =>
                      color.projectId.toString() === item.project.id.toString()
                  )
                  ?.events.find((eventColor) => eventColor.eventId === item.id)
                  ?.color || ''
              }
              key={item.id}
              path={`${AppRoute.DASHBOARD}/${DashboardRoute.PROJECTS}/${item.project.id}/${item.id}`}
            >
              <span>Подія</span> <br /> {item.name}
            </ProjectTab>
          ))}
      </Tabs>
      <Outlet />
    </>
  );
};
