import type { FormEventHandler } from 'react';
import { useState, type Dispatch, type SetStateAction, useEffect } from 'react';
import { Popup } from '../../../components/popup';
import { useGetUsersQuery } from '../../../api/user-api';
import type { OptionItem } from '../../../common/types/option-item';
import { Select } from '../../../components/select';
import { mapUsersToProps } from '../../../helpers/map-users-to-option';
import { IconContainer } from '../../../components/icon-container';
import { CloseEditSvg } from '../../../components/svg/close-edit-svg';
import { AddItem } from '../../../components/add-item/indes';
import { FormBtns } from '../../../components/form-btns';
import { useDelegateProjectMutation } from '../../../api/projects-api';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import {
  AddItemWrapper,
  FormStyled,
  ManagerItem,
  ManagersWrapper,
  Subtitle
} from './style';

interface DelegateProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  projectIds: number[];
}

export const Delegate = ({ show, setShow, projectIds }: DelegateProps) => {
  const notification = useNotification();
  const [managers, setManagers] = useState<OptionItem[]>([
    { id: '', title: '' }
  ]);
  const { data: users } = useGetUsersQuery({ params: '?size=100' });
  const [delegateProjects] = useDelegateProjectMutation();

  const handleDeleteManager = (i: number) => {
    if (managers.length === 1) {
      setManagers([{ id: '', title: '' }]);
    } else {
      setManagers((state) => state.filter((_, j) => j !== i));
    }
  };

  const handleCancel = () => {
    setManagers([{ id: '', title: '' }]);
    setShow(false);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    delegateProjects({
      projects_ids: projectIds.join(','),
      managers_ids: managers.map((item) => item.id).join(',')
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Проєкти делеговано!',
          message: 'Ви успішно делегували обрані проєкти. '
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  useEffect(() => {
    setManagers([{ id: '', title: '' }]);
  }, [show]);

  return (
    <Popup
      maxContentWidth="448px"
      title="Делегувати"
      show={show}
      setShow={setShow}
    >
      <FormStyled onSubmit={handleSubmit}>
        <Subtitle>
          Оберіть менеджера на якого Ви бажаєте делегувати обрані проєкти.
        </Subtitle>
        <ManagersWrapper>
          {managers.map((item, i) => (
            <ManagerItem key={i}>
              <Select
                label="Залучений менеджер"
                value={item}
                setValue={(value) =>
                  setManagers((state) =>
                    state.map((manager, j) => (i === j ? value : manager))
                  )
                }
                options={mapUsersToProps(users?.results)}
              />
              {managers.length === 1 && !managers[0].id ? (
                ''
              ) : (
                <IconContainer
                  m="24px 0 0 0"
                  onClick={() => handleDeleteManager(i)}
                >
                  <CloseEditSvg />
                </IconContainer>
              )}
            </ManagerItem>
          ))}
          {managers.filter((item) => item.id).length === managers.length ? (
            <AddItemWrapper>
              <AddItem
                mt="12px"
                onClick={() =>
                  setManagers((state) => [...state, { id: '', title: '' }])
                }
                title="+ Додати менеджера"
              />
            </AddItemWrapper>
          ) : (
            ''
          )}
        </ManagersWrapper>
        <FormBtns onCancel={handleCancel} />
      </FormStyled>
    </Popup>
  );
};
