import styled from 'styled-components';
import { centerFlex, scrollBar } from '../../style/helpers';

export const SidebarStyled = styled.div<{ isOpen?: boolean }>`
  position: relative;
  padding-bottom: 20px;
  width: ${(p) => (p.isOpen ? '232px' : '56px')};
  min-width: ${(p) => (p.isOpen ? '232px' : '56px')};
  border-right: 1px solid ${(p) => p.theme.colors.grey.table};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  transition: width 0.4s, min-width 0.4s;
`;

export const SidebarToggleBtn = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  top: 55px;
  right: -17px;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.05))
    drop-shadow(4px 0px 20px rgba(0, 0, 0, 0.05));
  cursor: pointer;
  display: grid;
  place-items: center;
  z-index: 1;
  background: ${(p) => p.theme.colors.white};
  transform: ${(p) => (p.isOpen ? 'rotate(0)' : 'rotate(180deg)')};

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    display: none;
  }
`;

export const Logo = styled.div<{ isOpen?: boolean }>`
  padding: ${(p) => (p.isOpen ? '30px' : '20px 12px')};
  ${centerFlex}
  svg {
    width: ${(p) => (p.isOpen ? '60px' : '32px')};
    height: ${(p) => (p.isOpen ? '60px' : '32px')};
    transition: all 0.4s;
  }
  transition: all 0.4s;
`;

export const Menu = styled.ul<{ isOpen?: boolean }>`
  padding: 8px 10px;
  height: calc(100vh - 208px);
  ${scrollBar}
  ::-webkit-scrollbar {
    ${(p) => (!p.isOpen ? 'display: none' : '')}
  }
`;

export const SidebarFooter = styled.div``;
