import type { Filter } from '../../common/types/filters';

export const initialFilters: Filter[] = [
  { type: 'checkbox', checkList: [], title: 'Роль CRM', name: 'role' },
  {
    type: 'sublist',
    title: 'Адреса (проживання)',
    list: [
      { type: 'text', title: 'Область', value: '', name: 'liv_region' },
      { type: 'text', title: 'Район / ОТГ', value: '', name: 'liv_district' },
      {
        type: 'text',
        title: 'Місто / СМТ / Село',
        value: '',
        name: 'liv_city'
      },
      { type: 'text', title: 'Вулиця', value: '', name: 'liv_street' },
      { type: 'text', title: 'Будинок', value: '', name: 'liv_building' },
      { type: 'text', title: 'Корпус', value: '', name: 'liv_unit' },
      { type: 'text', title: 'Квартира', value: '', name: 'liv_apartment' }
    ],
    name: 'liv_address'
  }
];
