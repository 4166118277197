import { NoDataPanaSvg } from '../svg/no-data-pana-svg';
import { NoDataIcon, NoDataStyled, NoDataSubtitle, NoDataTitle } from './style';

interface NoDataProps {
  title?: string;
  subTitle?: string;
  customHeight?: string;
}

export const NoData = ({
  title = 'Немає що відображати тут',
  subTitle,
  customHeight
}: NoDataProps) => (
  <NoDataStyled customHeight={customHeight}>
    <NoDataTitle>{title}</NoDataTitle>
    {subTitle && <NoDataSubtitle>{subTitle}</NoDataSubtitle>}
    <NoDataIcon>
      <NoDataPanaSvg />
    </NoDataIcon>
  </NoDataStyled>
);
