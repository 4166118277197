import { useTheme } from 'styled-components';
import { useRef, useState } from 'react';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { CheckSvg } from '../svg/check-svg';
import {
  ColorPalette,
  ColorPaletteItem,
  ColorPaletteItemInner,
  ColorPickerInner,
  ColorPickerStyled
} from './style';

interface ColorPickerProps {
  color?: string;
  setColor: (color: string) => void;
  disabled?: boolean;
}

export const ColorPicker = ({
  color,
  setColor,
  disabled
}: ColorPickerProps) => {
  const ref = useRef(null);
  const theme = useTheme();
  const [showPalette, setShowPallete] = useState(false);
  useOutsideClick(ref, () => setShowPallete(false));

  return (
    <ColorPickerStyled
      ref={ref}
      onClick={() => setShowPallete((state) => !state)}
      bg={color}
      isActive={showPalette && !disabled}
      disabled={disabled}
    >
      <ColorPickerInner bg={color} />
      {showPalette && !disabled && (
        <ColorPalette>
          {theme.colors.rolePicker.map((item, i) => (
            <ColorPaletteItem isActive={color === item} bg={item} key={i}>
              <ColorPaletteItemInner
                isActive={color === item}
                onClick={() => setColor(item)}
                bg={item}
              >
                <CheckSvg />
              </ColorPaletteItemInner>
            </ColorPaletteItem>
          ))}
        </ColorPalette>
      )}
    </ColorPickerStyled>
  );
};
