import { AddItemStyled } from './style';

interface AddItemProps {
  title: string;
  onClick: () => void;
  mt?: string;
}

export const AddItem = ({ title, onClick, mt }: AddItemProps) => (
  <AddItemStyled mt={mt} onClick={onClick}>
    {title}
  </AddItemStyled>
);
