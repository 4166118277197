import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { useState } from 'react';
import { useChangeFields } from '../../../hooks/use-change-fields';
import type { CreateUser } from '../../../models/user';
import { useCreateUserMutation } from '../../../api/user-api';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import type { OptionItem } from '../../../common/types/option-item';
import { UserForm } from '../user-form';

interface AddUserProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const AddUser = ({ setShow }: AddUserProps) => {
  const notification = useNotification();
  const [passportFiles, setPassportFiles] = useState<File[]>([]);
  const [innFiles, setInnFiles] = useState<File[]>([]);
  const [gender, setGender] = useState<OptionItem>({ id: '', title: '' });
  const { fields, handleFieldsChange } = useChangeFields<CreateUser>({
    password: '',
    username: '',
    first_name: '',
    last_name: '',
    patronymic: '',
    email: '',
    phone: '',
    liv_country: '',
    liv_region: '',
    liv_district: '',
    liv_city: '',
    liv_street: '',
    liv_building: '',
    liv_unit: '',
    liv_apartment: '',
    reg_country: '',
    reg_region: '',
    reg_district: '',
    reg_city: '',
    reg_street: '',
    reg_building: '',
    reg_unit: '',
    reg_apartment: '',
    organization: '',
    passport: '',
    id_number: '',
    binotel: ''
  });

  const [createUser, { isLoading }] = useCreateUserMutation();

  const [role, setRole] = useState<OptionItem>({
    id: '',
    title: ''
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!gender.id) {
      notification({
        type: 'error',
        title: 'Помилка',
        message: 'Обовʼязково вкажіть гендер'
      });

      return;
    }
    if (!role?.id) {
      notification({
        type: 'error',
        title: 'Помилка',
        message: 'Обовʼязково вкажіть роль користувача'
      });

      return;
    }
    const eneredData = Object.entries(fields).filter((item) => item[1]);
    const formData = new FormData();
    eneredData.forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('crm_type', '1');
    formData.append('gender', `${gender.id}`);
    formData.append('role', `${role?.id}`);
    if (passportFiles.length) {
      passportFiles.forEach((item) => {
        formData.append('passport_files', item);
      });
    }
    if (innFiles.length) {
      innFiles.forEach((item) => {
        formData.append('inn_files', item);
      });
    }
    createUser(formData)
      .unwrap()
      .then(() => {
        setShow(false);
        notification({
          type: 'success',
          title: 'Співробітника додано!',
          message: 'Ви успішно створили нового співробітника.'
        });
      })
      .catch((err) => {
        console.log(err);
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  return (
    <UserForm
      onSubmit={handleSubmit}
      handleFieldsChange={handleFieldsChange}
      fields={fields}
      role={role}
      setRole={setRole}
      passportFiles={passportFiles}
      setPassportFiles={setPassportFiles}
      innFiles={innFiles}
      setInnFiles={setInnFiles}
      onCancel={() => setShow(false)}
      isLoading={isLoading}
      gender={gender}
      setGender={setGender}
    />
  );
};
