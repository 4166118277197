import { useGetContactChangesQuery } from '../../api/contact-api';
import { MONTHS } from '../../common/constants';
import type {
  CallActivity,
  LetterDbActivity,
  LetterSPActivity,
  MeetActivity,
  NoteActivity
} from '../../models/activity';
import { hasOwnProperty } from '../../helpers/has-own-property';
import { Note } from '../notes/note';
import type { Note as INote } from '../../models/note';
import { Call } from '../calls/call';
import type { Call as ICall } from '../../models/call';
import { Meet } from '../meets/meet';
import type { Change } from '../../models/changes';
import type { Meet as IMeet } from '../../models/meet';
import { ActivityItem } from '../activity-item';
import type {
  DeleteCall,
  EditCall
} from '../../common/types/rtk-triggers/call';
import type {
  DeleteMeet,
  EditMeet
} from '../../common/types/rtk-triggers/meet';
import type {
  DeleteNote,
  EditNote
} from '../../common/types/rtk-triggers/note';
import { useGetPartnerChangesQuery } from '../../api/partner-api';
import { Letter } from '../letters/letter';
import type { LetterBd } from '../../models/letter-bd';
import type { ILetter } from '../../models/letter';
import { LoaderSmall } from '../loader-small';
import { ChamgeMessage, GroupByMonthTitle, Loader } from './style';
import { GroupByMonth } from './style';
import { ActivityListStyled } from './style';

interface ActivityListProps {
  contactId: string | number;
  contactFullName: string;
  deleteCall: DeleteCall;
  editCall: EditCall;
  editMeet: EditMeet;
  deleteMeet: DeleteMeet;
  editNote: EditNote;
  deleteNote: DeleteNote;
  isPartner?: boolean;
}

export const ActivityList = ({
  contactId,
  contactFullName,
  deleteCall,
  editCall,
  editMeet,
  deleteMeet,
  editNote,
  deleteNote,
  isPartner
}: ActivityListProps) => {
  const { data: contactChanges, isLoading: contactLoading } =
    useGetContactChangesQuery(
      { id: contactId },
      { skip: !contactId || isPartner }
    );
  const { data: partnerChanges, isLoading: partnerLoading } =
    useGetPartnerChangesQuery(
      { id: contactId },
      { skip: !contactId || !isPartner }
    );

  const data = isPartner ? partnerChanges : contactChanges;

  const activitiesByMonth = () => {
    let tempArr: {
      title: string;
      list: (
        | CallActivity
        | NoteActivity
        | MeetActivity
        | LetterDbActivity
        | LetterSPActivity
        | Change
      )[];
    }[] = [];
    data?.activities?.forEach(([date, item]) => {
      const currentTitle = `${MONTHS[new Date(date).getMonth()]} ${new Date(
        date
      ).getFullYear()}`;

      const currentObj = tempArr.find((obj) => obj.title === currentTitle);
      if (!currentObj) {
        tempArr = [...tempArr, { title: currentTitle, list: [item] }];
      } else {
        tempArr = tempArr.map((el) =>
          el.title === currentTitle ? { ...el, list: [...el.list, item] } : el
        );
      }
    });

    return tempArr;
  };

  return (
    <ActivityListStyled>
      {(contactLoading || partnerLoading) && (
        <Loader>
          <LoaderSmall />
        </Loader>
      )}
      {activitiesByMonth().map((item, i) => (
        <GroupByMonth key={i}>
          <GroupByMonthTitle>{item.title}</GroupByMonthTitle>
          {item.list.map((activityItem, j) => {
            if (hasOwnProperty(activityItem, 'note')) {
              return (
                <Note
                  editNote={editNote}
                  deleteNote={deleteNote}
                  key={j}
                  note={activityItem.note as INote}
                />
              );
            }
            if (hasOwnProperty(activityItem, 'call')) {
              return (
                <Call
                  key={j}
                  call={activityItem.call as ICall}
                  fullName={contactFullName}
                  deleteCall={deleteCall}
                  editCall={editCall}
                />
              );
            }
            if (hasOwnProperty(activityItem, 'meet')) {
              return (
                <Meet
                  key={j}
                  meet={activityItem.meet as IMeet}
                  contactFullName={contactFullName}
                  editMeet={editMeet}
                  deleteMeet={deleteMeet}
                />
              );
            }

            if (hasOwnProperty(activityItem, 'letter')) {
              return (
                <Letter
                  key={j}
                  body={(activityItem.letter as LetterBd).html}
                  date={(activityItem.letter as LetterBd).send_date}
                  letterName={(activityItem.letter as LetterBd).subject}
                  isDBLetter
                />
              );
            }

            if (hasOwnProperty(activityItem, 'campaign')) {
              return (
                <Letter
                  key={j}
                  link={(activityItem.campaign as ILetter).permalink}
                  body={(activityItem.campaign as ILetter).message.body}
                  date={(activityItem.campaign as ILetter).send_date}
                  letterName={(activityItem.campaign as ILetter).name}
                />
              );
            }

            return (
              <ActivityItem
                key={j}
                title={<span>{activityItem.name}</span>}
                createdAt={activityItem.date}
              >
                <ChamgeMessage>{activityItem.message}</ChamgeMessage>
              </ActivityItem>
            );
          })}
        </GroupByMonth>
      ))}
    </ActivityListStyled>
  );
};
