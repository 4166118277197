import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface SidebarMenuItemStyledProps {
  $iconStrokeColored?: boolean;
  $isActive?: boolean;
  $menuIsOpen: boolean;
}

const activeCss = css<SidebarMenuItemStyledProps>`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  svg {
    display: block;
    path {
      ${(p) =>
        p.$iconStrokeColored
          ? `stroke: ${p.theme.colors.white};`
          : `fill: ${p.theme.colors.white};`}
    }
  }
  :hover {
    color: ${(p) => p.theme.colors.white};
    svg {
      path {
        ${(p) =>
          p.$iconStrokeColored
            ? `stroke: ${p.theme.colors.white};`
            : `fill: ${p.theme.colors.white};`}
      }
    }
  }
`;

export const SidebarMenuItemStyled = styled.li<SidebarMenuItemStyledProps>`
  .active {
    ${activeCss}
  }
`;

export const SidebarMenuItemLink = styled(NavLink)<SidebarMenuItemStyledProps>`
  padding: ${(p) => (p.$menuIsOpen ? '12px 10px' : '9px')};
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.colors.grey.light};
  border-radius: ${(p) => (p.$menuIsOpen ? '6px' : '4px')};
  span {
    margin-left: 8px;
  }
  transition: color 0.2s;
  :hover {
    color: ${(p) => p.theme.colors.primary};
    transition: color 0.2s;
    svg {
      path {
        ${(p) =>
          p.$iconStrokeColored
            ? `stroke: ${p.theme.colors.primary};`
            : `fill: ${p.theme.colors.primary};`}
      }
    }
  }
  ${(p) => (p.$isActive ? activeCss : '')}
`;

export const AngleWrapper = styled.div<{
  isActive?: boolean;
  isCurrentPage?: boolean;
  menuIsOpen: boolean;
  show?: boolean;
}>`
  display: ${(p) => (p.show ? 'flex' : 'none')};
  justify-content: center;
  margin: ${(p) => (p.menuIsOpen ? '0' : '6px 0')};
  svg {
    transform: ${(p) => (p.isActive ? 'rotate(180deg)' : 'rotate(0)')};
    display: block;
    path {
      stroke: ${(p) =>
        p.isActive || p.isCurrentPage
          ? p.theme.colors.primary
          : p.theme.colors.grey.light};
    }
  }
  cursor: pointer;
`;

export const SidebarMenuSubItemHeader = styled.div<{
  isActive?: boolean;
  menuIsOpen: boolean;
}>`
  padding: 12px 10px;
  display: flex;
  flex-direction: ${(p) => (p.menuIsOpen ? 'row' : 'column')};
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(p) =>
    p.isActive ? p.theme.colors.primary : p.theme.colors.grey.light};
  border-radius: ${(p) => (p.menuIsOpen ? '6px' : '4px 4px 0 0')};
  border: ${(p) =>
    !p.menuIsOpen && p.isActive
      ? `1px solid ${p.theme.colors.blue.bg}`
      : 'none'};
  cursor: pointer;
  span {
    margin-left: 8px;
  }
  svg {
    path {
      fill: ${(p) =>
        p.isActive ? p.theme.colors.primary : p.theme.colors.grey.light};
    }
  }

  :hover {
    color: ${(p) => p.theme.colors.primary};
    transition: color 0.2s;
    svg {
      path {
        fill: ${(p) => p.theme.colors.primary};
      }
    }
    ${AngleWrapper} {
      svg {
        path {
          stroke: ${(p) => p.theme.colors.primary};
        }
      }
    }
  }
`;

export const SubMenuWrapper = styled.div<{
  menuIsOpen: boolean;
}>`
  border-radius: ${(p) => (p.menuIsOpen ? '6px' : '0 0 4px 4px')};
  border: ${(p) =>
    !p.menuIsOpen ? `1px solid ${p.theme.colors.blue.bg}` : 'none'};
  border-top: none;
`;
