export const DasboardChildrenSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_5502_361547)">
      <path
        d="M6.85489 8.87274C6.85489 8.02103 6.16199 7.32812 5.31028 7.32812C4.45857 7.32812 3.76562 8.02103 3.76562 8.87274H4.70312C4.70312 8.53797 4.97551 8.26562 5.31028 8.26562C5.64505 8.26562 5.9174 8.53797 5.9174 8.87274H6.85489Z"
        fill="#9DABC0"
      />
      <path
        d="M10.6852 7.32812C9.83354 7.32812 9.14062 8.02103 9.14062 8.87274H10.0781C10.0781 8.53797 10.3505 8.26562 10.6852 8.26562C11.02 8.26562 11.2924 8.53797 11.2924 8.87274H12.2299C12.2299 8.02103 11.537 7.32812 10.6852 7.32812Z"
        fill="#9DABC0"
      />
      <path
        d="M8.00002 11.6186C7.40731 11.6186 6.85158 11.3008 6.5496 10.7891L5.74219 11.2655C6.21195 12.0616 7.0771 12.5561 7.99999 12.5561C8.92287 12.5561 9.78802 12.0616 10.2578 11.2655L9.45037 10.7891C9.14846 11.3008 8.59269 11.6186 8.00002 11.6186Z"
        fill="#9DABC0"
      />
      <path
        d="M15.39 7.21463C15.0915 6.91641 14.7165 6.71922 14.3096 6.642C13.4307 3.92615 10.8812 2.05624 8 2.05624H7.68572C6.93099 2.05624 6.31698 1.4422 6.31698 0.6875H5.37948C5.37948 1.19963 5.54748 1.67312 5.83096 2.05624H5.53395C4.77922 2.05624 4.1652 1.4422 4.1652 0.6875H3.22771C3.22771 1.7061 3.89171 2.57198 4.80938 2.87649C3.35802 3.67441 2.22042 5.00426 1.69045 6.642C1.28349 6.71922 0.908469 6.91647 0.609651 7.21498C0.216508 7.60853 0 8.13148 0 8.6875C0 9.23669 0.21186 9.75483 0.596592 10.1465C0.897275 10.4526 1.27701 10.6546 1.69045 10.733C2.56926 13.4489 5.11873 15.3188 8 15.3188C10.8813 15.3188 13.4307 13.4489 14.3095 10.733C14.723 10.6546 15.1027 10.4526 15.4034 10.1465C15.7881 9.75483 16 9.23669 16 8.6875C16 8.13148 15.7835 7.60853 15.39 7.21463ZM13.9402 9.83192L13.5884 9.8386L13.4966 10.1782C12.8273 12.6529 10.5671 14.3812 7.99997 14.3812C5.43285 14.3812 3.17262 12.6529 2.50343 10.1782L2.41157 9.8386L2.05979 9.83192C1.44093 9.82022 0.937465 9.30686 0.937465 8.6875C0.937465 8.38169 1.05658 8.09404 1.27249 7.87788C1.48305 7.66753 1.76265 7.54861 2.05969 7.54304L2.41154 7.53643L2.5034 7.19673C3.17262 4.72211 5.43289 2.99377 8 2.99377C8.84728 2.99377 9.53659 3.68308 9.53659 4.53036C9.53659 5.37764 8.84728 6.06698 8 6.06698C7.15272 6.06698 6.46341 5.37767 6.46341 4.53039H5.52591C5.52591 5.89461 6.63578 7.00448 8 7.00448C9.36422 7.00448 10.4741 5.89461 10.4741 4.53039C10.4741 4.14215 10.384 3.77468 10.2239 3.44734C11.7962 4.11616 13.0312 5.47589 13.4966 7.19676L13.5884 7.53647L13.9403 7.54308C14.2374 7.54864 14.5169 7.66757 14.7271 7.87753C14.9434 8.09407 15.0625 8.38172 15.0625 8.68753C15.0625 9.30689 14.559 9.82026 13.9402 9.83192Z"
        fill="#9DABC0"
      />
    </g>
    <defs>
      <clipPath id="clip0_5502_361547">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
