import { CheckSvg } from '../svg/check-svg';
import { CheckBoxLabel, CheckBoxStyled } from './style';

interface CheckBoxProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  disabled?: boolean;
  label?: string;
  fw?: string;
  fz?: string;
  alignStart?: boolean;
  fullWidth?: boolean;
}

export const CheckBox = ({
  checked,
  setChecked,
  disabled,
  label,
  fw,
  fz,
  alignStart,
  fullWidth
}: CheckBoxProps) => (
  <CheckBoxLabel
    fullWidth={fullWidth}
    alignStart={alignStart}
    fw={fw}
    fz={fz}
    disabled={disabled}
  >
    <CheckBoxStyled
      alignStart={alignStart}
      checked={checked}
      disabled={disabled}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        disabled={disabled}
      />
      <CheckSvg />
    </CheckBoxStyled>
    {label && <span>{label}</span>}
  </CheckBoxLabel>
);
