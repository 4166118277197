import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { centerFlex } from '../../style/helpers';

interface TabItemStyled {
  $isActive?: boolean;
}

const tabCss = css<TabItemStyled>`
  position: relative;
  padding: 17px;
  cursor: pointer;
  ${centerFlex}
  :after {
    content: '';
    position: absolute;
    display: ${(p) => (p.$isActive ? 'block' : 'none')};
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
    background: ${(p) => p.theme.colors.blue.primary};
  }
  :first-child {
    border-radius: 6px 0 0 6px;
  }
  :last-child {
    border-radius: 0 6px 6px 0;
    border-right-width: 1px;
    :after {
      display: none;
    }
  }
  border: 1px solid
    ${(p) =>
      p.$isActive ? p.theme.colors.blue.primary : p.theme.colors.grey.table};
  border-right-width: 0;
  background: ${(p) => (p.$isActive ? p.theme.colors.blue.bg : 'transparent')};
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) =>
    p.$isActive ? p.theme.colors.blue.primary : p.theme.colors.grey.light};
  span {
    margin-left: 6px;
  }
  transition: color background-color 0.2s;
  svg {
    path {
      fill: ${(p) =>
        p.$isActive ? p.theme.colors.blue.primary : p.theme.colors.grey.light};
      transition: fill 0.2s;
    }
  }
  :hover {
    background-color: ${(p) =>
      p.$isActive ? p.theme.colors.blue.bg : p.theme.colors.blue.hover};
    color: ${(p) => p.theme.colors.blue.primary};
    transition: color background-color 0.2s;
    svg {
      path {
        fill: ${(p) => p.theme.colors.blue.primary};
        transition: fill 0.2s;
      }
    }
  }
`;

export const TabItemLink = styled(Link)<TabItemStyled>`
  ${tabCss}
`;

export const TabItemStyled = styled.div`
  ${tabCss}
`;
