import type { FC, ReactNode } from 'react';
import { FlexContainer } from '../flex-container';
import { AudioPlayer } from '../audio-player';
import { NoWrap } from '../no-wrap';
import {
  HistoryDate,
  HistoryIcon,
  HistoryItemStyled,
  HistoryTitle
} from './style';

interface HistoryItemProps {
  icon: FC;
  title: ReactNode;
  date?: string;
  isBinotel?: boolean;
  callId?: string | number;
  recordUploaded?: boolean;
}

export const HistoryItem = ({
  icon: Icon,
  title,
  date,
  isBinotel,
  callId,
  recordUploaded
}: HistoryItemProps) => (
  <HistoryItemStyled>
    <FlexContainer>
      <HistoryIcon>
        <Icon />
      </HistoryIcon>
      <HistoryTitle>{title}</HistoryTitle>
    </FlexContainer>
    {date && (
      <HistoryDate isBinotel={isBinotel}>
        {recordUploaded && callId && <AudioPlayer callId={callId} />}
        <NoWrap>{date}</NoWrap>
      </HistoryDate>
    )}
  </HistoryItemStyled>
);
