import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useContactsDelegateMutation,
  useDeleteContactMutation,
  useGetContactCategoriesQuery,
  useGetContactChannelsQuery,
  useGetContactsQuery,
  useGetCurrentContactQuery
} from '../../api/contact-api';
import { ButtonAdd } from '../../components/button-add';
import type { HeaderItem } from '../../components/data-table';
import { DataTable } from '../../components/data-table';
import { FlexContainer } from '../../components/flex-container';
import { PageTitle } from '../../components/page-title';
import { SubHeader } from '../../components/subheader';
import type { DynamicContact } from '../../models/contact';
import { Popup } from '../../components/popup';
import { setYearsLabel } from '../../helpers/set-yars-label';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { ConfirmPopup } from '../../components/confirm-popup';
import { NoData } from '../../components/no-data';
import { Counter } from '../../components/counter';
import { SubheaderSelect } from '../../components/subheader-select';
import { CalendarSvg } from '../../components/svg/calendar-svg';
import { DateRange } from '../../components/date-range';
import { Filters } from '../../components/filters';
import { FilterSvg } from '../../components/svg/filter-svg';
import {
  setFiltersFromQueryString,
  transformFilterToObj
} from '../../components/filters/helpers';
import type { Filter, FilterCheckbox } from '../../common/types/filters';
import { useGetUserMeQuery, useGetUsersQuery } from '../../api/user-api';
import { FilterBadges } from '../../components/filter-badges';
import {
  dateToApiFormat,
  dateToUIFormat,
  isValidNewDate
} from '../../helpers/date-helpers';
import { EditColumns } from '../../components/edit-colums';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { setBadges } from '../../components/filter-badges/helpers';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { ExportImport } from '../../components/export-import';
import { setContactColumns } from '../../api/page-data-slice';
import { useGetProjectsQuery } from '../../api/projects-api';
import { filterQueryParams } from '../../helpers/filter-query-params';
import { MailingListPopup } from '../../components/mailing-list-popup';
import { checkIsManager } from '../../helpers/is-manager';
import { isFieldAllowed } from '../../helpers/is-field-allowed';
import { Loader } from '../../components/loader';
import {
  commonInitialFilters,
  managerInitialFilters,
  tableHeaders
} from './data';
import {
  allowedFilters,
  setCategoriesFilters,
  setChannelsFilters,
  setProjectFilters,
  setUsersFilter,
  transformcontactData
} from './helpers';
import { AddContact } from './add-contact';
import { Delegate } from './delegate';

export const ContactsPage = () => {
  const dispatch = useAppDispatch();
  const tableRef = useRef(null);
  const { scrollHeight, manuallySetScrollHeight } = useScrollHeight(tableRef);
  const { contactColumns, pageSize } = useTypedSelector(
    (state) => state['page-data']
  );
  const { data: userMe } = useGetUserMeQuery('');
  const initialFilters = checkIsManager(userMe)
    ? managerInitialFilters
    : commonInitialFilters;
  const { data: projects } = useGetProjectsQuery(
    { params: '?size=100' },
    { skip: !userMe || checkIsManager(userMe) }
  );
  const [searchParams, setSearhParams] = useSearchParams();
  const notification = useNotification();
  const [showfilter, setShowfilter] = useState(false);
  const [showMailingList, setShowMailingList] = useState(false);
  const [showDelegate, setShowDelegate] = useState(false);
  const [filters, setFilters] = useState<Filter[]>(
    allowedFilters(initialFilters, userMe)
  );
  const [showAllBadges, setShowAllBadges] = useState(false);
  const [initialFiltersWitUsers, setInitialFilterWithUsers] = useState<
    Filter[]
  >(allowedFilters(initialFilters, userMe));
  const [showCalendar, setShowCalendar] = useState(false);
  const { data, isLoading, isFetching, isSuccess } = useGetContactsQuery(
    {
      params: `?size=${pageSize}&${filterQueryParams(
        searchParams.toString(),
        'contact_id'
      )}`
    },
    { refetchOnMountOrArgChange: true }
  );
  const [showAddContact, setShowAddContact] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [checkedContactId, setCheckedContactId] = useState<number | null>(null);
  const [starDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [deleteContact] = useDeleteContactMutation();
  const [delegate] = useContactsDelegateMutation();
  const { data: checkedContact } = useGetCurrentContactQuery(
    { id: checkedContactId },
    { skip: !checkedContactId, refetchOnMountOrArgChange: true }
  );
  const { data: users } = useGetUsersQuery(
    { params: '?size=100' },
    { skip: !userMe || checkIsManager(userMe) }
  );
  const { data: channels } = useGetContactChannelsQuery('');
  const { data: categories } = useGetContactCategoriesQuery('');

  const findMaxChildren = () => {
    if (data?.results.length) {
      return Math.max(
        ...data.results.map((item) =>
          item.children_num ? +item.children_num : 0
        )
      );
    }

    return 0;
  };

  const handleDelete = () => {
    deleteContact({ ids: checkedList })
      .unwrap()
      .then(() => {
        setCheckedList([]);
        setConfirmMessage('');
        setShowConfirm(false);
        setCheckedContactId(null);
        notification({
          type: 'success',
          title:
            checkedList.length === 1
              ? 'Контакт видалено'
              : `${checkedList.length} Контактів видалено`,
          message: ''
        });
        const currentPage = searchParams.get('page');
        if (
          checkedList.length >= (data?.results.length || 0) &&
          currentPage &&
          currentPage !== '1' &&
          !isNaN(+currentPage)
        ) {
          searchParams.set('page', `${+currentPage - 1}`);
          setSearhParams(searchParams);
        }
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleConfirm = () => {
    setShowConfirm(true);
    if (checkedList.length === 1) {
      if (checkedContact && checkedList[0] === checkedContactId) {
        setConfirmMessage(
          `Чи дійсно бажаєте видалити контакт ${checkedContact.full_name}`
        );
      } else {
        setCheckedContactId(checkedList[0]);
      }

      return;
    }
    setConfirmMessage(
      `Чи дійсно бажаєте видалити ${checkedList.length} контактів`
    );
  };

  useEffect(() => {
    if (checkedContact) {
      setConfirmMessage(
        `Чи дійсно бажаєте видалити контакт ${checkedContact.full_name}`
      );
    }
  }, [checkedContact]);

  useEffect(() => {
    if (userMe) {
      const canViewUsers =
        (userMe.role?.view_user || userMe.is_superuser) &&
        checkIsManager(userMe);
      const canViewProjects = userMe.role?.view_project || userMe.is_superuser;
      if (canViewUsers && !users) {
        return;
      }
      if (canViewProjects && !projects) {
        return;
      }
      if (!channels || !categories) {
        return;
      }
      setFilters(
        setFiltersFromQueryString(
          searchParams.toString(),
          allowedFilters(initialFilters, userMe).map((item) => {
            switch (item.name) {
              case 'resp_manager':
                return setUsersFilter(item, users);
              case 'projects':
                return setProjectFilters(item, projects);
              case 'channels':
                return setChannelsFilters(item, channels);
              case 'categories':
                return setCategoriesFilters(item, categories);
              default:
                return item;
            }
          })
        )
      );
      setInitialFilterWithUsers(() =>
        setFiltersFromQueryString(
          searchParams.toString(),
          allowedFilters(initialFilters, userMe).map((item) => {
            switch (item.name) {
              case 'resp_manager':
                return setUsersFilter(item, users);
              case 'projects':
                return setProjectFilters(item, projects);
              case 'channels':
                return setChannelsFilters(item, channels);
              case 'categories':
                return setCategoriesFilters(item, categories);
              default:
                return item;
            }
          })
        )
      );

      if (isValidNewDate(searchParams.get('created_start'))) {
        setStartDate(new Date(searchParams.get('created_start') as string));
      } else {
        setStartDate(null);
      }
      if (isValidNewDate(searchParams.get('created_end'))) {
        setEndDate(new Date(searchParams.get('created_end') as string));
      } else {
        setEndDate(null);
      }
    }
  }, [
    searchParams,
    users,
    projects,
    initialFilters,
    channels,
    categories,
    userMe
  ]);

  const resetFilters = useCallback(() => {
    Object.keys(transformFilterToObj(filters)).forEach((item) => {
      searchParams.delete(item);
    });
    setSearhParams(searchParams);
    setShowfilter(false);
    setFilters(initialFiltersWitUsers);
    setCheckedList([]);
  }, [setSearhParams, filters, searchParams, initialFiltersWitUsers]);

  const aplyFilter = () => {
    setSearhParams({
      ...transformFilterToObj(filters),
      created_start: dateToApiFormat(starDate),
      created_end: dateToApiFormat(endDate)
    });
    setShowfilter(false);
    setCheckedList([]);
  };

  const applyDate = () => {
    if (starDate) {
      searchParams.set('created_start', dateToApiFormat(starDate));
      searchParams.set('page', '1');
    }
    if (endDate) {
      searchParams.set('created_end', dateToApiFormat(endDate));
      searchParams.set('page', '1');
    }
    setSearhParams(searchParams);
    setShowCalendar(false);
    setCheckedList([]);
  };

  const resetDate = () => {
    setStartDate(null);
    setEndDate(null);
    searchParams.delete('created_start');
    searchParams.delete('created_end');
    searchParams.set('page', '1');
    setSearhParams(searchParams);
    setShowCalendar(false);
    setCheckedList([]);
  };

  const setCustomColumns = useCallback(
    (columns: HeaderItem<DynamicContact>[] | null) => {
      dispatch(setContactColumns(columns));
    },
    [dispatch]
  );

  const resetCustomColumns = useCallback(() => {
    dispatch(setContactColumns(null));
  }, [dispatch]);

  const handleDelegate = ({
    manager,
    project,
    event
  }: {
    manager: string | number;
    project: string | number;
    event: string | number;
  }) => {
    delegate({
      delegate: checkedList.join(','),
      new_owner: manager,
      to_project: project,
      to_event: event
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Контакти делеговано!',
          message: 'Ви успішно делегували обрані контакти. '
        });
        setShowDelegate(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const filtersLength =
    setBadges(filters, '').length +
    ((
      filters.find(
        (item) => item.name === 'projects' && item.type === 'checkbox'
      ) as FilterCheckbox
    )?.checkList
      .map((item) => item.sub)
      .flat()
      .filter((item) => item?.checked).length || 0);
  const badges = setBadges(initialFiltersWitUsers, searchParams.toString());

  useEffect(() => {
    if (isSuccess) {
      manuallySetScrollHeight();
    }
  }, [badges.length, showAllBadges, manuallySetScrollHeight, isSuccess]);

  const canView = userMe?.role?.view_contact || userMe?.is_superuser;
  const isManager = checkIsManager(userMe);

  const columns =
    contactColumns?.filter((item) => item.checked) || tableHeaders;

  if (!userMe || !canView) {
    return null;
  }

  const canRemove = userMe.role?.delete_contact || userMe.is_superuser;
  const canImport = userMe.role?.import_contact || userMe.is_superuser;
  const canExport = userMe.role?.export_contact || userMe.is_superuser;
  const canAdd = userMe.role?.add_contact || userMe.is_superuser;

  if (!data) {
    return <Loader />;
  }

  return (
    <>
      <SubHeader>
        <FlexContainer>
          <PageTitle>Контакти</PageTitle>
          <Counter>{data?.count}</Counter>
          <SubheaderSelect
            show={showCalendar}
            setShow={setShowCalendar}
            icon={CalendarSvg}
            title="Дата створення"
            isActive={!!(starDate || endDate)}
          >
            <DateRange
              startDate={starDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onSubmit={applyDate}
              onReset={resetDate}
            />
          </SubheaderSelect>
          <SubheaderSelect
            show={showfilter}
            setShow={setShowfilter}
            icon={FilterSvg}
            title={`Розширені фільтри (${filtersLength})`}
            isActive={!!filtersLength}
          >
            <Filters
              setFilters={setFilters}
              filters={filters}
              setShow={setShowfilter}
              onCancel={resetFilters}
              onSubmit={aplyFilter}
              disabled={!badges && !filtersLength}
            />
          </SubheaderSelect>
          <EditColumns<DynamicContact>
            onSubmit={setCustomColumns}
            initialHeaders={tableHeaders.filter((item) =>
              isFieldAllowed(item.id as string, userMe)
            )}
            columns={contactColumns}
            onReset={resetCustomColumns}
          />
        </FlexContainer>
        <FlexContainer>
          {!canImport && !canExport ? (
            ''
          ) : (
            <ExportImport canExport={canExport} canImport={canImport} />
          )}
          {canAdd && (
            <ButtonAdd onClick={() => setShowAddContact(true)}>
              Новий контакт
            </ButtonAdd>
          )}
        </FlexContainer>
      </SubHeader>
      <FilterBadges
        showAll={showAllBadges}
        setShowAll={setShowAllBadges}
        badges={badges}
      />
      {(!userMe?.role?.view_contact && !userMe?.is_superuser) ||
      (data && !data.results.length) ? (
        <NoData subTitle="" />
      ) : (
        <div ref={tableRef}>
          <DataTable<DynamicContact>
            onRowClick={(id) => {
              searchParams.set('contact_id', `${id}`);
              setSearhParams(searchParams);
            }}
            isFetching={isLoading || isFetching}
            actions={
              isManager
                ? [
                    {
                      title: 'Додати до розсилки',
                      handler: () => setShowMailingList(true)
                    }
                  ]
                : canRemove
                ? [
                    {
                      title: 'Додати до розсилки',
                      handler: () => setShowMailingList(true)
                    },
                    {
                      title: 'Додати в проєкт',
                      handler: () => setShowDelegate(true)
                    },
                    { title: 'Видалити', handler: handleConfirm }
                  ]
                : [
                    {
                      title: 'Додати до розсилки',
                      handler: () => setShowMailingList(true)
                    },
                    {
                      title: 'Додати в проєкт',
                      handler: () => setShowDelegate(true)
                    }
                  ]
            }
            customHeight={
              data.count >= pageSize ? scrollHeight - 64 : scrollHeight
            }
            headers={columns
              .filter((item) =>
                isFieldAllowed(item.id as string, userMe, item.subFields)
              )
              .map((item) =>
                item.id === 'children_num'
                  ? {
                      ...item,
                      subFields: new Array(findMaxChildren())
                        .fill('')
                        .map((_, i) => ({
                          id: `child${i + 1}`,
                          title: `Дит ${i + 1}`
                        }))
                    }
                  : item
              )}
            data={transformcontactData(data?.results).map((item) =>
              item.children_num
                ? {
                    ...item,
                    ...Object.assign(
                      {},
                      ...item.children.map((child, i) => ({
                        [`child${i + 1}`]: (
                          <>
                            {child.gender.title}{' '}
                            <span style={{ whiteSpace: 'nowrap' }}>
                              {child.birth_date
                                ? dateToUIFormat(new Date(child.birth_date))
                                : ''}
                            </span>
                            {child.age} {setYearsLabel(child.age)}
                          </>
                        )
                      }))
                    )
                  }
                : item
            )}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            count={data.count}
            withCheckAllPages
            params={`?${filterQueryParams(
              searchParams.toString(),
              'contact_id'
            )}`}
          />
          <ConfirmPopup
            show={showConfirm}
            title={confirmMessage}
            setShow={setShowConfirm}
            onSubmit={handleDelete}
          />
        </div>
      )}
      <Popup
        show={showAddContact}
        maxContentWidth="1304px"
        setShow={setShowAddContact}
        title="Новий контакт"
        outsideOnlyEscape
      >
        <AddContact setShow={setShowAddContact} />
      </Popup>
      <Delegate
        onSubmit={handleDelegate}
        show={showDelegate}
        setShow={setShowDelegate}
      />
      <MailingListPopup
        show={showMailingList}
        setShow={setShowMailingList}
        contactIds={checkedList}
      />
    </>
  );
};
