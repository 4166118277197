export const AngleTableSvg = () => (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.79289 5L4.43934 5.35355L1.14645 8.64645C0.951185 8.84171 0.951184 9.15829 1.14645 9.35355L4.79289 5ZM4.79289 5L4.43934 4.64645M4.79289 5L4.43934 4.64645M4.43934 4.64645L1.14645 1.35355M4.43934 4.64645L0.792893 1.70711L1.14645 1.35355M1.14645 1.35355C0.951184 1.15829 0.951184 0.841709 1.14645 0.646446M1.14645 1.35355L1.14645 0.646446M1.14645 0.646446C1.34171 0.451185 1.65829 0.451185 1.85355 0.646447M1.14645 0.646446L1.85355 0.646447M1.85355 0.646447L5.85355 4.64645M1.85355 0.646447L5.85355 4.64645M5.85355 4.64645C6.04882 4.84171 6.04882 5.15829 5.85355 5.35355M5.85355 4.64645L5.85355 5.35355M5.85355 5.35355L1.85355 9.35355M5.85355 5.35355L1.85355 9.35355M1.85355 9.35355C1.65829 9.54882 1.34171 9.54882 1.14645 9.35355L1.85355 9.35355Z"
      fill="#171C3D"
      stroke="#171C3D"
    />
  </svg>
);
