import { useTheme } from 'styled-components';

interface EmailSvgProps {
  $focus?: boolean;
}

export const EmailSvg = ({ $focus }: EmailSvgProps) => {
  const theme = useTheme();

  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 0.875H15.5M0.5 0.875V11.5C0.5 11.6658 0.565848 11.8247 0.683058 11.9419C0.800269 12.0592 0.95924 12.125 1.125 12.125H14.875C15.0408 12.125 15.1997 12.0592 15.3169 11.9419C15.4342 11.8247 15.5 11.6658 15.5 11.5V0.875M0.5 0.875L8 7.75L15.5 0.875"
        stroke={$focus ? theme?.colors.blue.dark : theme?.colors.grey.light}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
