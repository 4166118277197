export const KanbanSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3563_224612)">
      <rect
        x="9.1974"
        y="0.533333"
        width="6.26667"
        height="6.26667"
        rx="1.46667"
        stroke="#E2C32B"
        strokeWidth="1.06667"
      />
      <rect
        x="9.1974"
        y="9.2013"
        width="6.26667"
        height="6.26667"
        rx="1.46667"
        stroke="#E2C32B"
        strokeWidth="1.06667"
      />
      <rect
        x="0.533333"
        y="0.533333"
        width="6.26667"
        height="6.26667"
        rx="1.46667"
        stroke="#E2C32B"
        strokeWidth="1.06667"
      />
      <rect
        x="0.533333"
        y="9.2013"
        width="6.26667"
        height="6.26667"
        rx="1.46667"
        stroke="#E2C32B"
        strokeWidth="1.06667"
      />
    </g>
    <defs>
      <clipPath id="clip0_3563_224612">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
