import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderStyled = styled.span`
  position: fixed;
  top: calc(50% - 20px);
  left: calc(50% + 115px);
  width: 40px;
  height: 40px;
  border: 2px dotted ${(p) => p.theme.colors.blue.primary};
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: ${rotate} 2s linear infinite;
  z-index: 10;
`;
