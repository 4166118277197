import type { FC } from 'react';
import { TooltipFixed } from '../../tooltip/tooltip-fixed';
import { SidebarFooterIcon, SidebarFooterItemStyled } from './style';

interface SidebarFooterItemProps {
  icon: FC;
  title: string;
  onClick?: () => void;
  menuIsOpen?: boolean;
}

export const SidebarFooterItem = ({
  icon: Icon,
  title,
  onClick,
  menuIsOpen
}: SidebarFooterItemProps) => (
  <TooltipFixed
    offset={{ left: -12, top: 0 }}
    disabled={menuIsOpen}
    position="right"
    title={title}
  >
    <SidebarFooterItemStyled onClick={onClick}>
      <SidebarFooterIcon>
        <Icon />
      </SidebarFooterIcon>
      {menuIsOpen && title}
    </SidebarFooterItemStyled>
  </TooltipFixed>
);
