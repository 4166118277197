export const PlaySvg = ({ onClick }: { onClick?: () => void }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    onClick={onClick}
  >
    <g clipPath="url(#clip0_5130_353465)">
      <path
        d="M20 10.0001C20 4.47684 15.5228 2.72214e-10 10.0001 2.72214e-10C4.4772 -4.03095e-05 0 4.47676 0 10.0001C0 15.5226 4.4772 19.9998 10.0002 19.9998C15.5228 19.9998 20 15.5226 20 10.0001Z"
        fill="#306FE3"
      />
      <path
        d="M14.9502 9.48218L7.89801 5.15195C7.70549 5.03368 7.45214 5.02856 7.25503 5.13897C7.05433 5.25123 6.92969 5.46382 6.92969 5.69371V14.32C6.92969 14.5495 7.05396 14.7619 7.25402 14.8743C7.34887 14.9275 7.45654 14.9558 7.56541 14.9558C7.68219 14.9558 7.79655 14.9236 7.89615 14.8629L14.9482 10.5669C15.1359 10.4526 15.2528 10.245 15.2532 10.0251C15.2536 9.8053 15.1375 9.59723 14.9502 9.48218Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5130_353465">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
