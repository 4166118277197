import type { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import { StringCutter } from '../string-cutter';
import { CloseFileSvg } from '../svg/close-file-svg';
import {
  CloseIcon,
  FileName,
  InputFileStyled,
  InputFileWrapper
} from './style';

interface InputFileProps {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
}

export const InputFile = ({ files, setFiles }: InputFileProps) => {
  const handleChangeFiles: ChangeEventHandler<HTMLInputElement> = (e) => {
    const currentFiles = e.target.files;
    if (currentFiles) {
      setFiles((state) => Array.from([...state, ...Array.from(currentFiles)]));
    }
  };

  const handleRemoveFile = (i: number) => {
    setFiles((state) => state.filter((_, j) => i !== j));
  };

  return (
    <InputFileWrapper>
      {files.map((item, i) => (
        <FileName key={i}>
          <StringCutter lines="1">{item.name}</StringCutter>
          <CloseIcon onClick={() => handleRemoveFile(i)}>
            <CloseFileSvg />
          </CloseIcon>
        </FileName>
      ))}
      <InputFileStyled>
        <input
          accept=".jpg, .pdf, .tif, .txt, .doc, .docx, .xls, .xlsx, .bmp, .png, .heif, .heic"
          onChange={handleChangeFiles}
          multiple
          type="file"
        />
        + Додати файл
      </InputFileStyled>
    </InputFileWrapper>
  );
};
