import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useTypedSelector } from '../../../../api/store';
import { useGetCurrentEventStatistcsQuery } from '../../../../api/dashboard-api';
import { ChartsLayout } from '../../components/charts-layout';
import { ChartContainer } from '../../components/chart-container';
import { DoubleChart } from '../../components/double-chart';
import { BarChart } from '../../components/bar-chart';
import { Range } from '../../components/range';
import { RangeHorisontalContainer } from '../../components/range-horisontal-container';
import { ChartsSlider } from '../../components/charts-slider';
import { transformContactWord } from '../../../../helpers/transform-words';
import { useClearDate } from '../../hooks/use-clear-date';
import { Loader } from '../../../../components/loader';
import { groupByRegion } from '../../helpers/group-by-region';
import { CategoriesChart } from '../../components/categories-chart';

export const CurrentDashboardEvent = () => {
  useClearDate();
  const { eventId, id } = useParams();
  const theme = useTheme();
  const { colors, date } = useTypedSelector((state) => state.dashboard);
  const projectColorObj = colors.find(
    (item) => item.projectId.toString() === id
  );
  const eventColor =
    projectColorObj?.events.find((item) => item.eventId.toString() === eventId)
      ?.color || theme.colors.primary;

  const [activeCity, setActiveCity] = useState({ id: '', name: '' });
  const [activeRegion, setActiveRegion] = useState({ id: '', name: '' });

  const { data, isFetching } = useGetCurrentEventStatistcsQuery(
    {
      id: eventId || '',
      params: `?liv_city=${activeCity.name}&liv_region=${activeRegion.name}&created_start=${date.start}&created_end=${date.end}`
    },
    { refetchOnMountOrArgChange: true, skip: !eventId }
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <ChartsLayout
      activeCity={activeCity}
      activeRegion={activeRegion}
      setActiveCity={setActiveCity}
      setActiveRegion={setActiveRegion}
      total={data.contacts_count}
      isProject
      isFetching={isFetching}
      dataByRegion={groupByRegion(data.map)}
    >
      <ChartContainer title="Вартість події">
        <Range
          total={Math.floor(data.budget)}
          totalAlign="center"
          totalTitle={`${Math.floor(data.budget)} бонусів`}
          value={data.budget}
          color={eventColor}
        />
      </ChartContainer>
      <ChartContainer title="Кількість зібраних контактів">
        <Range
          total={data.contacts_count}
          totalAlign="center"
          totalTitle={`${data.contacts_count} ${transformContactWord(
            data.contacts_count
          )}`}
          value={data.contacts_count}
          color={eventColor}
        />
      </ChartContainer>
      <ChartContainer title="Кількість контактів, які відвідали подію">
        <BarChart
          aspect={1}
          barCount={2}
          color={eventColor}
          data={[
            { name: 'Діти', value: data.visitors_kids_count },
            { name: 'Дорослі', value: data.visitors }
          ]}
        />
      </ChartContainer>
      <ChartContainer title="Етапи події">
        <BarChart
          aspect={2}
          tickAlign="center"
          tickMaxWidth={80}
          color={eventColor}
          data={data.by_event_steps.map((item) => {
            const [_, [name, value]] = Object.entries(item);

            return {
              name,
              value
            };
          })}
        />
      </ChartContainer>
      <ChartContainer title="Вартість одного контакту по події">
        <RangeHorisontalContainer
          data={[
            {
              label: 'Всі відвідувачі',
              total:
                data.visitors === 0
                  ? data.contacts_count === 0
                    ? 0
                    : Math.floor(data.budget / data.contacts_count)
                  : Math.floor(data.budget / data.visitors),
              value:
                data.contacts_count === 0
                  ? 0
                  : Math.floor(data.budget / data.contacts_count)
            },
            {
              label: 'Отримав послугу',
              total:
                data.visitors === 0
                  ? 0
                  : Math.floor(data.budget / data.visitors),
              value:
                data.visitors === 0
                  ? 0
                  : Math.floor(data.budget / data.visitors)
            }
          ]}
        />
      </ChartContainer>
      <ChartContainer title="Вік та гендер відвідувачів">
        <DoubleChart
          legends={[
            { name: 'Чоловіки', value: data.men_count },
            { name: 'Жінки', value: data.women_count }
          ]}
          colors={[
            projectColorObj?.color || '',
            theme.colors.primary === projectColorObj?.color
              ? '#306FE3'
              : theme.colors.primary
          ]}
          data={data.age_and_gender.map((item) => ({
            name: item.age_gradation,
            firstValue: item.men,
            secondValue: item.women
          }))}
        />
      </ChartContainer>
      <ChartContainer title="Вік та стать дітей">
        <DoubleChart
          legends={[
            { name: 'Хлопчики', value: data.boys_count },
            { name: 'Дівчата', value: data.girls_count }
          ]}
          colors={[
            projectColorObj?.color || '',
            theme.colors.primary === projectColorObj?.color
              ? '#306FE3'
              : theme.colors.primary
          ]}
          data={data.kids_age_and_gender.map((item) => ({
            name: item.age_gradation,
            firstValue: item.men,
            secondValue: item.women
          }))}
        />
      </ChartContainer>
      <CategoriesChart
        withAnyCategory={data.with_any_category}
        categoriesCount={data.categories_count}
        contactsCount={data.contacts_count}
      />
      <ChartsSlider
        title="Статус (П Н Н)"
        data={[
          {
            title: 'Загальна кількість людей ',
            chart: (
              <BarChart
                aspect={2}
                color={projectColorObj?.color || ''}
                data={[
                  ...data.satisfaction_count
                    .filter((item) => item.id)
                    .map((item) => {
                      const [_, [name, value]] = Object.entries(item);

                      return { name, value };
                    }),
                  {
                    name: 'Всі статуси',
                    value: data.satisfaction_count
                      .filter((item) => item.id)
                      .map((item) => Object.values(item)[1])
                      .reduce((a, b) => a + b, 0)
                  }
                ]}
              />
            )
          },
          {
            title: 'Загальна кількість людей, які отримали послугу',
            chart: (
              <BarChart
                aspect={2}
                color={projectColorObj?.color || ''}
                data={[
                  ...data.visitors_satisfaction_count
                    .filter((item) => item.id)
                    .map((item) => {
                      const [_, [name, value]] = Object.entries(item);

                      return { name, value };
                    }),
                  {
                    name: 'Всі статуси',
                    value: data.visitors_satisfaction_count
                      .filter((item) => item.id)
                      .map((item) => Object.values(item)[1])
                      .reduce((a, b) => a + b, 0)
                  }
                ]}
              />
            )
          }
        ]}
      />
    </ChartsLayout>
  );
};
