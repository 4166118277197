import { useParams } from 'react-router-dom';
import { AppRoute } from '../../../common/enums/app/app-route.enum';
import { ProjectHeader } from '../../../components/project-header';
import { useGetCurrentEventQuery } from '../../../api/events-api';
import { DataTable } from '../../../components/data-table';
import { StatiscsStyled } from './style';
import type { StatisticsTable } from './types';
import { tableHeaders } from './data';

export const Statistics = () => {
  const params = useParams();
  const { data: event } = useGetCurrentEventQuery(
    { id: params.eventId || '' },
    { skip: !params.eventId }
  );

  if (!event) {
    return null;
  }

  return (
    <StatiscsStyled>
      <ProjectHeader
        onFinish={() => {}}
        withOutEdit
        onEdit={() => {}}
        title="Статистика події"
        backLink={`${AppRoute.PROJECTS}${AppRoute.EVENTS}/${event.id}`}
        onlyTitle
      />
      <DataTable<StatisticsTable>
        mt="20px"
        actions={[]}
        count={0}
        headers={
          event.statistics.length
            ? [
                ...tableHeaders,
                ...event.statistics[0].steps.map((item, i) => ({
                  id: `step${i}`,
                  title: item.name
                }))
              ]
            : []
        }
        data={event.statistics.map((item) => ({
          id: +item.manager.id,
          manager: item.manager.full_name,
          count: item.count,
          ...Object.fromEntries(
            item.steps.map((step, i) => [`step${i}`, step.count])
          )
        }))}
        checkedList={[]}
        setCheckedList={() => {}}
        withOutCheck
      />
    </StatiscsStyled>
  );
};
