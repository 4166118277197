import styled from 'styled-components';

export const MainLayoutStyled = styled.div`
  display: flex;
`;

export const Content = styled.div<{ menuIsOpen: boolean }>`
  position: relative;
  width: ${(p) =>
    p.menuIsOpen ? 'calc(100vw - 232px)' : 'calc(100vw - 56px)'};
  height: 100vh;
  transition: all 0.4s;
`;
