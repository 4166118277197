import { Link } from 'react-router-dom';
import { useGetUserMeQuery } from '../../api/user-api';
import { activityDate } from '../../helpers/date-helpers';
import { hasOwnProperty } from '../../helpers/has-own-property';
import type {
  Activity,
  CallActivity,
  LetterDbActivity,
  LetterSPActivity,
  MeetActivity,
  NoteActivity
} from '../../models/activity';
import type { Call } from '../../models/call';
import type { Note } from '../../models/note';
import { HistoryItem } from '../history-item';
import { ActionsCallSvg } from '../svg/actions/actions-call-svg';
import { ActionsNoteSvg } from '../svg/actions/actions-note-svg';
import { InfoSvg } from '../svg/info-svg';
import { NonEventsSvg } from '../svg/non-events-svg';
import { TooltipFixed } from '../tooltip/tooltip-fixed';
import type { Meet } from '../../models/meet';
import { ActionsEditSvg } from '../svg/actions/actons-edit-svg';
import type { LetterBd } from '../../models/letter-bd';
import { ActionsMailSvg } from '../svg/actions/actions-mail-svg';
import type { ILetter } from '../../models/letter';
import { LoaderSmall } from '../loader-small';
import { checkIsManager } from '../../helpers/is-manager';
import {
  Descr,
  HistoryContainer,
  HistoryNoData,
  HistoryStyled,
  Info,
  Title
} from './style';

interface HistoryProps {
  histories: Activity;
  isUser?: boolean;
  isLoading: boolean;
}

export const History = ({ histories, isUser, isLoading }: HistoryProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const canViewUser = userMe?.role?.view_user || userMe?.is_superuser;
  const canViewContact = userMe?.role?.view_contact || userMe?.is_superuser;
  const isManager = checkIsManager(userMe);
  const renderHistories = (
    [date, item]: [
      string,
      (
        | CallActivity
        | NoteActivity
        | MeetActivity
        | LetterDbActivity
        | LetterSPActivity
      )
    ],
    i: number
  ) => {
    if (hasOwnProperty(item, 'note')) {
      return (
        <HistoryItem
          key={i}
          icon={ActionsEditSvg}
          date={activityDate(date)}
          title={
            <>
              {isUser ? (
                (item.note as Note).contact_note_owner?.full_name ||
                (item.note as Note).contact_note_owner?.username
              ) : canViewUser &&
                !isManager &&
                (
                  item.note as Note
                )?.contact_note_owner?.username?.toLowerCase() !== 'admin' ? (
                <Link
                  to={`/users?id=${
                    (item.note as Note)?.contact_note_owner?.id
                  }`}
                >
                  {(item.note as Note).contact_note_owner?.full_name ||
                    (item.note as Note).contact_note_owner?.username}
                </Link>
              ) : (
                (item.note as Note).contact_note_owner?.full_name ||
                (item.note as Note).contact_note_owner?.username
              )}{' '}
              додав/ла нотатку про{' '}
              {isUser && canViewContact ? (
                <Link to={`?contact_id=${(item.note as Note)?.contact?.id}`}>
                  {(item.note as Note)?.contact?.full_name}
                </Link>
              ) : (
                (item.note as Note)?.contact?.full_name
              )}
            </>
          }
        />
      );
    }
    if (hasOwnProperty(item, 'call')) {
      return (
        <HistoryItem
          key={i}
          icon={ActionsCallSvg}
          date={activityDate(date)}
          title={
            <>
              {isUser ? (
                (item.call as Call).contact_call_owner?.full_name ||
                (item.call as Call).contact_call_owner?.username
              ) : canViewUser &&
                !isManager &&
                (item.call as Call).contact_call_owner.username !== 'admin' ? (
                <Link
                  to={`/users?id=${(item.call as Call).contact_call_owner.id}`}
                >
                  {(item.call as Call).contact_call_owner?.full_name ||
                    (item.call as Call).contact_call_owner?.username}
                </Link>
              ) : (
                (item.call as Call).contact_call_owner?.full_name ||
                (item.call as Call).contact_call_owner?.username
              )}{' '}
              вніс/ла дзвінок до{' '}
              {isUser && canViewContact ? (
                <Link to={`/contacts?id=${(item.call as Call).contact.id}`}>
                  {(item.call as Call).contact.full_name}
                </Link>
              ) : (
                (item.call as Call).contact.full_name
              )}
            </>
          }
        />
      );
    }
    if (hasOwnProperty(item, 'meet')) {
      return (
        <HistoryItem
          key={i}
          icon={ActionsNoteSvg}
          date={activityDate(date)}
          title={
            <>
              {isUser ? (
                (item.meet as Meet).contact_meet_owner?.full_name ||
                (item.meet as Meet).contact_meet_owner?.username
              ) : canViewUser &&
                !isManager &&
                (item.meet as Meet).contact_meet_owner.username !== 'admin' ? (
                <Link
                  to={`/users?id=${(item.meet as Meet).contact_meet_owner.id}`}
                >
                  {(item.meet as Meet).contact_meet_owner?.full_name ||
                    (item.meet as Meet).contact_meet_owner?.username}
                </Link>
              ) : (
                (item.meet as Meet).contact_meet_owner?.full_name ||
                (item.meet as Meet).contact_meet_owner?.username
              )}{' '}
              вніс/ла зустріч з{' '}
              {isUser && canViewContact ? (
                <Link to={`/contacts?id=${(item.meet as Meet).contact.id}`}>
                  {(item.meet as Meet).contact.full_name}
                </Link>
              ) : (
                (item.meet as Meet).contact.full_name
              )}
            </>
          }
        />
      );
    }
    if (hasOwnProperty(item, 'letter')) {
      return (
        <HistoryItem
          key={i}
          icon={ActionsMailSvg}
          date={activityDate(date)}
          title={
            <>
              {(item.letter as LetterBd).letter_author?.full_name ||
                (item.letter as LetterBd).letter_author?.username}{' '}
              надіслав/ла email до {(item.letter as LetterBd).contact.full_name}
            </>
          }
        />
      );
    }

    if (hasOwnProperty(item, 'campaign')) {
      return (
        <HistoryItem
          key={i}
          icon={ActionsMailSvg}
          date={activityDate(date)}
          title={<>Розсилка - {(item.campaign as ILetter).name}</>}
        />
      );
    }

    return null;
  };

  return (
    <HistoryStyled>
      <Title>
        Історія комунікації (дотиків){' '}
        <TooltipFixed
          offset={
            innerWidth > 1500
              ? {
                  top: 0,
                  left: -innerWidth * 0.17
                }
              : {
                  top: 0,
                  left: -innerWidth * 0.1
                }
          }
          canBreak
          title="Історія комунікації включає останні електронні листи, дзвінки, зустрічі в цьому записі."
        >
          <Info>
            <InfoSvg />
          </Info>
        </TooltipFixed>
      </Title>
      {isLoading ? (
        <LoaderSmall ac />
      ) : histories.activities.length ? (
        <HistoryContainer>
          {histories.activities.map(renderHistories)}
        </HistoryContainer>
      ) : (
        <HistoryNoData>
          <NonEventsSvg />
          <Descr>Жодних дотиків наразі</Descr>
        </HistoryNoData>
      )}
    </HistoryStyled>
  );
};
