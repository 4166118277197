import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  useEditArchProjectMutation,
  useGetCurrentArchProjectQuery
} from '../../../../api/projects-api';
import { ProjectHeader } from '../../../../components/project-header';
import { EventsPage } from '../../../events';
import { AppRoute } from '../../../../common/enums/app/app-route.enum';
import { ConfirmPopup } from '../../../../components/confirm-popup';
import { useNotification } from '../../../../hooks/use-notification';
import { getApiError } from '../../../../helpers/get-api-error';

export const CurrentArchProject = () => {
  const notification = useNotification();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { data: project } = useGetCurrentArchProjectQuery(
    { id: projectId },
    { refetchOnMountOrArgChange: true, skip: !projectId }
  );
  const [editProject] = useEditArchProjectMutation();
  const [recoverProject, setRecoverProject] = useState(false);

  const handleRecoverProject = () => {
    editProject({
      id: project?.id || '',
      body: { finished: false, end_date: null }
    })
      .unwrap()
      .then(() => {
        notification({
          title: 'Проєкт відновлено!',
          message: 'Ви успішно відновили проєкт. ',
          type: 'success'
        });
        navigate(AppRoute.PROJECTS);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  return (
    <div>
      <ProjectHeader
        title={`Проєкт “${project?.name || ''}”`}
        idTitle={`ID Проєкту: ${project?.id || ''}`}
        onEdit={() => {}}
        onFinish={() => setRecoverProject(true)}
        backLink={AppRoute.PROJECTS_ARCH}
        canFinish
        withOutEdit
        finishTitle="Відновити проєкт"
      />
      <EventsPage isArchived projectId={projectId || ''} />
      <ConfirmPopup
        title={`Ви дійсно бажаєте відновити проєкт?`}
        show={recoverProject}
        setShow={setRecoverProject}
        onSubmit={handleRecoverProject}
      />
    </div>
  );
};
