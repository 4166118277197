import { ApiPath, AuthApiPath } from '../common/enums/http/api-path.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import { commonApi } from './common-api';

const verifyTokenApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyToken: builder.mutation<string, { token: string }>({
      query: (credentials) => ({
        url: `${ApiPath.AUTH}${AuthApiPath.VERIFY}`,
        method: HttpMethod.POST,
        body: credentials
      })
    })
  })
});

export const { useVerifyTokenMutation } = verifyTokenApi;
