import styled from 'styled-components';

interface StyledProps {
  $focus?: boolean;
  type?: 'email' | 'password' | 'text';
  error?: boolean;
}

export const AuthInputWrapper = styled.div<StyledProps>`
  position: relative;
  height: 46px;
  padding-left: 42px;
  border: 1px solid
    ${(p) =>
      p.error
        ? p.theme.colors.error
        : p.$focus
        ? p.theme.colors.blue.primary
        : p.theme.colors.grey.input};
  border-radius: 6px;
`;

export const AuthInputStyled = styled.input<StyledProps>`
  display: block;
  padding: 12.5px 42px 12px 0;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${(p) =>
    p.$focus ? p.theme.colors.blue.dark : p.theme.colors.grey.light};
  border: none;
  outline: none;
  border-radius: 6px;
  &::placeholder {
    color: ${(p) => p.theme.colors.grey.light};
  }
`;

export const AuthInputMainIcon = styled.div<StyledProps>`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  transform: translateY(-50%);
  left: 17px;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const AuthInputEyeIcon = styled(AuthInputMainIcon)`
  width: 17.5px;
  left: unset;
  right: 15px;
  cursor: pointer;
  svg {
    display: block;
  }
`;

export const HelpText = styled.div<{ fullWidth?: boolean }>`
  min-height: 15px;
  margin-top: 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: ${(p) => p.theme.colors.error};
  text-align: left;
  max-width: ${(p) => (p.fullWidth ? '100%' : '75%')};
`;
