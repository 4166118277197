import styled from 'styled-components';
import { centerFlex } from '../../../style/helpers';

export const AddEventStyled = styled.div``;

export const Tabs = styled.div`
  position: relative;
  margin-top: 20px;
  padding: 13px 0;
  background: ${(p) => p.theme.colors.blue.hover};
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  overflow: hidden;
`;

export const TabItem = styled.div<{
  isActive: boolean;
  withTemplate?: boolean;
}>`
  position: relative;
  padding: 0 20px;
  background: ${(p) => p.theme.colors.blue.hover};
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${(p) =>
    p.isActive ? p.theme.colors.primary : p.theme.colors.grey.light};
  :not(:first-child) {
    :after {
      content: '';
      position: absolute;
      right: 100%;
      width: ${(p) => (p.withTemplate ? '150px' : '320px')};
      top: calc(50% - 0.5px);
      height: 1px;
      z-index: -1;
      background: ${(p) =>
        p.isActive ? p.theme.colors.primary : p.theme.colors.grey.light};
    }
  }
`;

export const TabNum = styled.div<{ isActive: boolean }>`
  margin-right: 10px;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: ${(p) =>
    p.isActive ? p.theme.colors.primary : p.theme.colors.grey.light};
  ${centerFlex}
  font-weight: 600;
  font-size: 15px;
  color: ${(p) => p.theme.colors.white};
`;
