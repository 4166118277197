import styled from 'styled-components';
import type { StringCutterStyledProps } from './types';

export const StringCutterStyled = styled.div<StringCutterStyledProps>`
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: ${(p) => p.lines};
  -webkit-line-clamp: ${(p) => p.lines};
  -webkit-box-orient: vertical;
  white-space: normal;
  word-break: break-word;
`;

export const OneLine = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
