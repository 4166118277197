import { useCallback } from 'react';
import type { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition
} from '@reduxjs/toolkit/dist/query';
import { DropFile } from '../drop-file';
import type { ServerFile } from '../../common/types/server-file';
import { FileItem } from '../file-item';
import { setFileNameFromUrl } from '../../helpers/file-helpers';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { dateWithTime } from '../../helpers/date-helpers';
import type { ApiTag } from '../../common/enums/http/api-tag.enum';
import { splitByMonth } from '../../helpers/split-by-month';
import { PageFilesStyled, FileGroup, FileGroupTitle, FileList } from './style';

interface PageFilesProps<T> {
  files?: ServerFile[];
  passportFiles?: ServerFile[];
  innFiles?: ServerFile[];
  dbFiles?: ServerFile[];
  contactId: string | number;
  editTrigger: MutationTrigger<
    MutationDefinition<
      {
        id: string | number;
        body: Record<string, unknown> | FormData;
      },
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      ApiTag,
      T,
      'api'
    >
  >;
  isLoading: boolean;
  isDeleteLoading: boolean;
}

export const PageFiles = <T,>({
  files,
  passportFiles,
  innFiles,
  contactId,
  editTrigger,
  isLoading,
  dbFiles,
  isDeleteLoading
}: PageFilesProps<T>) => {
  const notification = useNotification();

  const handleUpload = useCallback(
    (files: File[]) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files_add', file);
      });
      editTrigger({ id: contactId, body: formData })
        .unwrap()
        .then(() => {
          notification({
            type: 'success',
            title: 'Файл додано',
            message: ''
          });
        })
        .catch((err) => {
          notification({
            type: 'error',
            title: 'Помилка',
            message: getApiError(err)
          });
        });
    },
    [contactId, editTrigger, notification]
  );

  const handleDeleteFile = (
    type: 'files_save_ids' | 'passport_save_ids' | 'inn_save_ids',
    id: string
  ) => {
    const formData = new FormData();
    switch (type) {
      case 'files_save_ids':
        formData.append(
          'files_save_ids',
          JSON.stringify(
            files?.map((item) => item.id).filter((item) => item !== id) || []
          )
        );
        break;
      case 'passport_save_ids':
        formData.append(
          'passport_save_ids',
          JSON.stringify(
            passportFiles
              ?.map((item) => item.id)
              .filter((item) => item !== id) || []
          )
        );
        break;
      case 'inn_save_ids':
        formData.append(
          'inn_save_ids',
          JSON.stringify(
            innFiles?.map((item) => item.id).filter((item) => item !== id) || []
          )
        );
        break;
    }

    editTrigger({ id: contactId, body: formData })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Файл видалено',
          message: ''
        });
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  return (
    <PageFilesStyled>
      <DropFile onUpload={handleUpload} isLoading={isLoading} />
      <FileList>
        {!!passportFiles?.length && (
          <FileGroup>
            <FileGroupTitle>Паспорт</FileGroupTitle>
            {passportFiles.map((item) => (
              <FileItem
                fileLink={item.file}
                onDelete={() => handleDeleteFile('passport_save_ids', item.id)}
                key={item.id}
                fileName={setFileNameFromUrl(item.file)}
                date={dateWithTime(item.created_at)}
                isDeleteLoading={isDeleteLoading}
              />
            ))}
          </FileGroup>
        )}
        {!!innFiles?.length && (
          <FileGroup>
            <FileGroupTitle>ІПН</FileGroupTitle>
            {innFiles.map((item) => (
              <FileItem
                fileLink={item.file}
                onDelete={() => handleDeleteFile('inn_save_ids', item.id)}
                key={item.id}
                fileName={setFileNameFromUrl(item.file)}
                date={dateWithTime(item.created_at)}
                isDeleteLoading={isDeleteLoading}
              />
            ))}
          </FileGroup>
        )}
        {splitByMonth<ServerFile>(
          [...(files || []), ...(dbFiles || [])] || []
        ).map((item, i) => (
          <FileGroup key={i}>
            <FileGroupTitle>{item.title}</FileGroupTitle>
            {item.list.map((item) => (
              <FileItem
                fileLink={item.file}
                onDelete={() => handleDeleteFile('files_save_ids', item.id)}
                key={item.id}
                fileName={setFileNameFromUrl(item.file)}
                date={dateWithTime(item.created_at)}
                isDBFile={item.from_db}
                isDeleteLoading={isDeleteLoading}
              />
            ))}
          </FileGroup>
        ))}
      </FileList>
    </PageFilesStyled>
  );
};
