import styled from 'styled-components';

export const EventTemplateStyled = styled.div`
  padding: 30px 0 0;
`;

export const StepsWrapper = styled.div`
  min-height: 360px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TemplateTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const InputsContainer = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  row-gap: 20px;
  column-gap: 30px;
`;

export const Step = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 16px;
  gap: 12px;
  align-items: center;
  svg {
    max-width: 11px;
    path {
      fill: ${(p) => p.theme.colors.grey.light};
    }
  }
  :first-child {
    margin-top: 0;
  }
`;
