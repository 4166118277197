export const FileSvg = () => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00036 0.727051C2.59436 0.727051 0.636719 2.35841 0.636719 4.36341V12.318C0.636719 13.9471 2.22726 15.2725 4.18217 15.2725C6.13708 15.2725 7.72763 13.9471 7.72763 12.318V5.27251C7.72763 4.01932 6.50417 2.99978 5.00036 2.99978C3.49654 2.99978 2.27308 4.01932 2.27308 5.27251V11.1816C2.27308 11.433 2.5169 11.6361 2.81854 11.6361C3.12017 11.6361 3.36399 11.433 3.36399 11.1816V5.27251C3.36399 4.52069 4.09817 3.90887 5.00036 3.90887C5.90254 3.90887 6.63672 4.52069 6.63672 5.27251V12.318C6.63672 13.4457 5.53545 14.3634 4.18217 14.3634C2.8289 14.3634 1.72763 13.4457 1.72763 12.318V4.36341C1.72763 2.85932 3.19545 1.63614 5.00036 1.63614C6.80526 1.63614 8.27308 2.85932 8.27308 4.36341V11.1816C8.27308 11.433 8.5169 11.6361 8.81854 11.6361C9.12017 11.6361 9.36399 11.433 9.36399 11.1816V4.36341C9.36399 2.35841 7.40636 0.727051 5.00036 0.727051Z"
      fill="#9DABC0"
    />
  </svg>
);
