export const EventsSvg = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 4.5H11.5M9 0.5V2.5M3 0.5V2.5M1 1.5H11C11.2761 1.5 11.5 1.72386 11.5 2V12C11.5 12.2761 11.2761 12.5 11 12.5H1C0.723858 12.5 0.5 12.2761 0.5 12V2C0.5 1.72386 0.723858 1.5 1 1.5Z"
      stroke="#9DABC0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
