export const CloseFilterSvg = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.09938 6.00973L11.7719 1.33703C12.076 1.03309 12.076 0.541661 11.7719 0.237721C11.468 -0.0662194 10.9766 -0.0662194 10.6726 0.237721L5.99993 4.91042L1.32737 0.237721C1.02329 -0.0662194 0.532002 -0.0662194 0.228062 0.237721C-0.0760206 0.541661 -0.0760206 1.03309 0.228062 1.33703L4.90062 6.00973L0.228062 10.6824C-0.0760206 10.9864 -0.0760206 11.4778 0.228062 11.7817C0.379534 11.9334 0.578696 12.0095 0.777716 12.0095C0.976736 12.0095 1.17576 11.9334 1.32737 11.7817L5.99993 7.10904L10.6726 11.7817C10.8243 11.9334 11.0233 12.0095 11.2223 12.0095C11.4213 12.0095 11.6203 11.9334 11.7719 11.7817C12.076 11.4778 12.076 10.9864 11.7719 10.6824L7.09938 6.00973Z"
      fill="#9DABC0"
    />
  </svg>
);
