export const ImportBigSvg = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6546 19.8897H4.91998C4.45946 19.8897 4.0178 19.7065 3.69217 19.3805C3.36653 19.0544 3.18359 18.6122 3.18359 18.1511V2.23861C3.18359 1.7775 3.36653 1.33528 3.69217 1.00923C4.0178 0.683175 4.45946 0.5 4.91998 0.5H9.84511C10.2859 0.500901 10.711 0.664249 11.0393 0.95889L16.7842 6.12948C16.9731 6.29865 17.1242 6.50584 17.2277 6.7375C17.3311 6.96916 17.3845 7.22007 17.3845 7.47383V18.1511C17.3845 18.6111 17.2025 19.0523 16.8782 19.3782C16.554 19.7041 16.114 19.888 15.6546 19.8897ZM4.91998 1.79265C4.80185 1.79265 4.68857 1.83963 4.60504 1.92327C4.52151 2.0069 4.47459 2.12033 4.47459 2.23861V18.1511C4.47459 18.2694 4.52151 18.3828 4.60504 18.4664C4.68857 18.5501 4.80185 18.5971 4.91998 18.5971H15.6546C15.7727 18.5971 15.886 18.5501 15.9695 18.4664C16.053 18.3828 16.1 18.2694 16.1 18.1511V7.44151C16.0998 7.37073 16.0848 7.30077 16.0559 7.23619C16.027 7.1716 15.9848 7.11383 15.9321 7.06665L10.1872 1.89606C10.0897 1.82086 9.96794 1.78407 9.84511 1.79265H4.91998Z"
      fill="#313654"
    />
    <path
      d="M16.7411 7.56935H11.0543C10.6793 7.56935 10.3198 7.42022 10.0547 7.15478C9.78956 6.88933 9.64062 6.5293 9.64062 6.1539V1.50684H10.9316V6.1539C10.9316 6.18647 10.9445 6.21771 10.9675 6.24074C10.9905 6.26377 11.0217 6.2767 11.0543 6.2767H16.7411V7.56935Z"
      fill="#313654"
    />
    <path
      d="M2.10728 17.085C1.68035 17.0799 1.27253 16.907 0.971831 16.6035C0.671131 16.3 0.501664 15.8902 0.5 15.4627V4.92766C0.499951 4.68803 0.553134 4.45139 0.655695 4.23488C0.758257 4.01837 0.90763 3.82741 1.09299 3.67584C1.27836 3.52426 1.49507 3.41587 1.72744 3.3585C1.9598 3.30114 2.202 3.29624 2.43649 3.34417L3.8243 3.57685L3.61129 4.86949L2.21056 4.61743C2.15993 4.60368 2.10682 4.60177 2.05533 4.61186C2.00384 4.62195 1.95536 4.64375 1.91364 4.6756C1.8756 4.70569 1.84482 4.74398 1.82359 4.78762C1.80236 4.83125 1.79122 4.87912 1.79099 4.92766V15.4627C1.79194 15.5109 1.80345 15.5584 1.8247 15.6016C1.84595 15.6449 1.87642 15.683 1.91397 15.7132C1.95151 15.7434 1.99522 15.765 2.04201 15.7764C2.0888 15.7879 2.13752 15.7889 2.18474 15.7794L3.64357 15.5855L3.81139 16.8782L2.39776 17.0656C2.3019 17.0825 2.20452 17.089 2.10728 17.085Z"
      fill="#313654"
    />
  </svg>
);
