import { ApiPath, DashboardApiPath } from '../common/enums/http/api-path.enum';
import type {
  DashboardAudience,
  DashboardCurrentEvent,
  DashboardCurrentProject,
  DashboardEvents,
  DashboardPartners,
  DashboardProjects
} from '../models/dashboard';
import { commonApi } from './common-api';

export const dashboardApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjectsStatistcs: builder.query<
      DashboardProjects[],
      { params: string }
    >({
      query: ({ params }) =>
        `${ApiPath.DASHBOARD}${DashboardApiPath.PROJECTS}/${params}`
    }),
    getCurrentProjectStatistcs: builder.query<
      DashboardCurrentProject,
      { id: string; params: string }
    >({
      query: ({ params, id }) =>
        `${ApiPath.DASHBOARD}${DashboardApiPath.PROJECTS}/${id}/${params}`
    }),
    getEventsStatistcs: builder.query<DashboardEvents[], string>({
      query: () => `${ApiPath.DASHBOARD}${DashboardApiPath.EVENTS}/`
    }),
    getCurrentEventStatistcs: builder.query<
      DashboardCurrentEvent,
      { id: string; params: string }
    >({
      query: ({ params, id }) =>
        `${ApiPath.DASHBOARD}${DashboardApiPath.EVENTS}/${id}/${params}`
    }),
    getAudience: builder.query<DashboardAudience, { params: string }>({
      query: ({ params }) =>
        `${ApiPath.DASHBOARD}${DashboardApiPath.AUDIENCE}/${params}`
    }),
    getParnersStatistics: builder.query<DashboardPartners, { params: string }>({
      query: ({ params }) =>
        `${ApiPath.DASHBOARD}${DashboardApiPath.PARTNERS}/${params}`
    })
  }),

  overrideExisting: false
});

export const {
  useGetProjectsStatistcsQuery,
  useGetCurrentProjectStatistcsQuery,
  useGetCurrentEventStatistcsQuery,
  useGetAudienceQuery,
  useGetParnersStatisticsQuery,
  useGetEventsStatistcsQuery
} = dashboardApi;
