import type { RefObject } from 'react';
import { useEffect } from 'react';

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
  options?: {
    onlyEscape?: boolean;
  }
) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(e.target as HTMLElement) &&
        (e.target as HTMLDivElement)?.firstElementChild?.tagName !== 'TABLE'
      ) {
        callback();
      }
    };

    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        callback();
      }
    };

    document.addEventListener(
      'mousedown',
      options?.onlyEscape ? () => {} : handleClickOutside
    );
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [ref, callback, options]);
};
