import styled from 'styled-components';

export const FileName = styled.div`
  margin-top: 8px;
  width: 100%;
  padding: 4px 12px;
  background: ${(p) => p.theme.colors.blue.bg};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.blue.dark};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :first-child {
    margin-top: 12px;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;
