import { Transition } from 'react-transition-group';
import type { Dispatch, SetStateAction } from 'react';
import { useRef } from 'react';
import {
  defaultStyle,
  duration,
  transitionStyles
} from '../../common/transition/fade-transition';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { ImagePreviewStyled } from './style';

interface ImagePreviewProps {
  path: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const ImagePreview = ({ path, show, setShow }: ImagePreviewProps) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => setShow(false));

  return (
    <Transition in={show} timeout={duration} unmountOnExit mountOnEnter>
      {(state) => (
        <ImagePreviewStyled
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          <img ref={ref} src={path} alt="preview" />
        </ImagePreviewStyled>
      )}
    </Transition>
  );
};
