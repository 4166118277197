import styled from 'styled-components';

interface ColorPickerStyledProps {
  bg?: string;
  isActive?: boolean;
  disabled?: boolean;
}

export const ColorPickerStyled = styled.div<ColorPickerStyledProps>`
  position: relative;
  padding: ${(p) => (p.isActive ? '3px' : 0)};
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid ${(p) => p.bg || p.theme.colors.rolePicker[9]};
  transition: padding 0.3s;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  :hover {
    padding: ${(p) => (p.disabled ? '0' : '3px')};
    transition: padding 0.3s;
  }
`;

export const ColorPickerInner = styled.div<ColorPickerStyledProps>`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: ${(p) => p.bg || p.theme.colors.rolePicker[9]};
`;

export const ColorPalette = styled.div`
  padding: 12px;
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 4px 21px rgba(63, 81, 126, 0.14);
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(5, 24px);
  grid-auto-rows: 24px;
  column-gap: 6px;
  row-gap: 10px;
  z-index: 10;
`;

export const ColorPaletteItem = styled.div<ColorPickerStyledProps>`
  height: 100%;
  padding: ${(p) => (p.isActive ? '2px' : 0)};
  border-radius: 6px;
  border: 1px solid ${(p) => p.bg};
  cursor: pointer;
  transition: padding 0.3s;
  :hover {
    padding: 2px;
    transition: padding 0.3s;
  }
`;

export const ColorPaletteItemInner = styled.div<ColorPickerStyledProps>`
  border-radius: 5px;
  background: ${(p) => p.bg};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    display: ${(p) => (p.isActive ? 'block' : 'none')};
  }
`;
