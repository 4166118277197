import type { Dispatch, SetStateAction } from 'react';
import { type ReactNode } from 'react';
import { UkraineMap } from '../ukraine-map';
import { Loader } from '../../../../components/loader';
import {
  ChartsColumn,
  ChartsColumnAdaptHeight,
  ChartsLayoutStyled
} from './style';

interface ChartsContainerProps {
  children: ReactNode;
  activeCity: { id: string; name: string };
  setActiveCity: Dispatch<SetStateAction<{ id: string; name: string }>>;
  activeRegion: { id: string; name: string };
  setActiveRegion: Dispatch<SetStateAction<{ id: string; name: string }>>;
  total: number;
  secondChartIsOpen?: boolean;
  mt?: string;
  onlyCityClickable?: boolean;
  isProject?: boolean;
  isFetching?: boolean;
  dataByRegion?: { region: string; count: number }[];
}

export const ChartsLayout = ({
  children,
  activeCity,
  activeRegion,
  setActiveCity,
  setActiveRegion,
  total,
  secondChartIsOpen,
  mt,
  onlyCityClickable,
  isProject,
  isFetching,
  dataByRegion
}: ChartsContainerProps) => (
  <ChartsLayoutStyled $mt={mt} $isProject={isProject}>
    {isFetching && <Loader withoutOverlay />}
    {secondChartIsOpen !== undefined ? (
      <ChartsColumnAdaptHeight $secondChartIsOpen={secondChartIsOpen}>
        {children}
      </ChartsColumnAdaptHeight>
    ) : (
      <ChartsColumn>{children}</ChartsColumn>
    )}
    <UkraineMap
      activeCity={activeCity}
      activeRegion={activeRegion}
      setActiveCity={setActiveCity}
      setActiveRegion={setActiveRegion}
      total={total}
      onlyCityClickable={onlyCityClickable}
      dataByRegion={dataByRegion}
    />
  </ChartsLayoutStyled>
);
