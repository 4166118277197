import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { BtnPrimary } from '../../../components/btn-primary';
import { BtnSecondary } from '../../../components/btn-secondary';
import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useGetUserMeQuery,
  useGetUserRolesQuery
} from '../../../api/user-api';
import type { Role } from '../../../models/role';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { ConfirmPopup } from '../../../components/confirm-popup';
import { AppRoute } from '../../../common/enums/app/app-route.enum';
import { AppRole } from '../../../common/enums/app/app-role.enum';
import { useTypedSelector } from '../../../api/store';
import { BtnsContainer, UserRolesContainer, UserRolesStyled } from './style';
import { UserRole } from './user-role';

export const UserRoles = () => {
  const { data: userMe } = useGetUserMeQuery('');
  const notification = useNotification();
  const { data, isSuccess } = useGetUserRolesQuery('');
  const [createRole, { isLoading }] = useCreateRoleMutation();
  const [deleteRole] = useDeleteRoleMutation();
  const [editRole] = useEditRoleMutation();
  const [roles, setRoles] = useState<Role[]>([]);
  const [newRole, setNewRole] = useState<{
    name: string;
    color: string;
  } | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState<{
    id?: string | number;
    name: string;
  }>({ id: '', name: '' });

  const { menuIsOpen } = useTypedSelector((state) => state['page-data']);

  const roleHasBeenChanged =
    JSON.stringify(data?.results) !== JSON.stringify(roles);

  useEffect(() => {
    if (data) {
      setRoles(data.results);
    }
    if (isSuccess) {
      setNewRole(null);
    }
  }, [data, isSuccess]);

  const handleEditRole = (
    e: React.ChangeEvent<HTMLInputElement> | string,
    idx: number
  ) => {
    if (typeof e === 'string') {
      setRoles((state) =>
        state.map((item, i) => (i === idx ? { ...item, name: '' } : item))
      );
    } else {
      setRoles((state) =>
        state.map((item, i) =>
          i === idx ? { ...item, name: e.target.value } : item
        )
      );
    }
  };

  const handleEditColor = (color: string, idx: number) => {
    setRoles((state) =>
      state.map((item, i) => (i === idx ? { ...item, color } : item))
    );
  };

  const handleCreateRole = () => {
    if (newRole && newRole.name) {
      createRole(newRole)
        .unwrap()
        .then(() => {
          setShowConfirm(false);
          setRoleToDelete({ id: '', name: '' });
          notification({
            type: 'success',
            title: 'Роль додано!',
            message: 'Ви успішно створили нову роль.'
          });
        })
        .catch((err) => {
          console.log(err);
          notification({
            type: 'error',
            title: 'Помилка',
            message: getApiError(err)
          });
        });
    }
  };

  const handleConfirmDelete = ({
    id,
    name
  }: {
    id?: string | number;
    name: string;
  }) => {
    setShowConfirm(true);
    setRoleToDelete({ id, name });
  };

  const handleDeleteRole = () => {
    if (roleToDelete.id) {
      deleteRole({ id: roleToDelete.id })
        .unwrap()
        .then(() => {
          setShowConfirm(false);
          notification({
            type: 'success',
            title: 'Роль видалено',
            message: `Ви успішно видалили роль ${roleToDelete.name}`
          });
        })
        .catch((err) =>
          notification({
            type: 'error',
            title: 'Помилка',
            message: getApiError(err)
          })
        );
    } else {
      setShowConfirm(false);
      setNewRole(null);
    }
  };

  const handleCancel = () => {
    setRoles(data?.results || []);
    setNewRole(null);
  };

  const handleSubmit = () => {
    handleCreateRole();
    if (roleHasBeenChanged) {
      roles.forEach((item, i) => {
        if (
          JSON.stringify(item) !== JSON.stringify(data?.results[i]) &&
          item.name
        ) {
          editRole({
            id: item.id,
            body: { name: item.name, color: item.color }
          })
            .unwrap()
            .then(() => {
              notification({
                type: 'success',
                title: 'Роль відредаговано',
                message: 'Ви успішно відредагували роль'
              });
            })
            .catch((err) => {
              notification({
                type: 'error',
                title: 'Помилка',
                message: getApiError(err)
              });
            });
        }
      });
    }
  };

  if (!userMe) {
    return null;
  }

  if (!userMe.role?.view_role && !userMe?.is_superuser) {
    return <Navigate to={{ pathname: AppRoute.USERS }} />;
  }

  return (
    <UserRolesStyled>
      <UserRolesContainer>
        {roles.map((item, i) => (
          <UserRole
            key={item.id}
            name={item.name}
            onChange={(e) => handleEditRole(e, i)}
            onClearInput={() => {
              handleEditRole('', i);
            }}
            color={item.color}
            disabledDelete={
              !(userMe.role?.delete_role || userMe.is_superuser) ||
              item.name === AppRole.DIRECTOR ||
              item.name === AppRole.MANAGER ||
              item.name === AppRole.ADMIN
            }
            disabledEdit={
              !(userMe.role?.change_role || userMe.is_superuser) ||
              item.name === AppRole.DIRECTOR ||
              item.name === AppRole.MANAGER ||
              item.name === AppRole.ADMIN
            }
            disabledColorEdit={
              !(userMe.role?.change_role || userMe.is_superuser)
            }
            setColor={(color) => handleEditColor(color, i)}
            onDeleteRole={handleConfirmDelete}
            id={item.id}
          />
        ))}
        {newRole && (
          <UserRole
            name={newRole.name || ''}
            onClearInput={() =>
              setNewRole((state) => (state ? { ...state, name: '' } : null))
            }
            onChange={(e) =>
              setNewRole((state) =>
                state ? { ...state, name: e.target.value } : null
              )
            }
            color={newRole.color}
            setColor={(color) =>
              setNewRole((state) => (state ? { ...state, color } : null))
            }
            onDeleteRole={handleConfirmDelete}
          />
        )}
      </UserRolesContainer>
      {!newRole &&
        ((userMe && userMe?.role?.add_role) || userMe?.is_superuser) && (
          <BtnPrimary
            onClick={() => setNewRole({ name: '', color: '#557CD1' })}
          >
            Додати нову роль
          </BtnPrimary>
        )}
      {(newRole || roleHasBeenChanged) && (
        <BtnsContainer menuIsOpen={menuIsOpen}>
          <BtnSecondary onClick={handleCancel}>Відмінити</BtnSecondary>
          <BtnPrimary
            onClick={handleSubmit}
            disabled={
              roleHasBeenChanged
                ? roles.filter((item) => item.name).length !== roles.length
                : !newRole?.name || isLoading
            }
          >
            Зберегти
          </BtnPrimary>
        </BtnsContainer>
      )}
      <ConfirmPopup
        show={showConfirm}
        title={`Чи дійсно бажаєте видалити роль ${roleToDelete.name}`}
        setShow={setShowConfirm}
        onSubmit={handleDeleteRole}
      />
    </UserRolesStyled>
  );
};
