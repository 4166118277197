import type { ReactNode } from 'react';
import { useState } from 'react';
import { Transition } from 'react-transition-group';
import { IconContainer } from '../icon-container';
import { AngleAccordion } from '../svg/angle-accordion';
import { AccordionBlockStyled, AccordionHeader } from './style';

interface AccordionBlockProps {
  title: string;
  children: ReactNode;
  maxHeight?: number;
}

export const AccordionBlock = ({
  title,
  children,
  maxHeight = 800
}: AccordionBlockProps) => {
  const [open, setOpen] = useState(true);

  const duration = 300;

  const defaultStyle = {
    transition: `max-height ${duration}ms ease-in-out`,
    maxHeight: '0px',
    overflow: 'hidden'
  };

  const transitionStyles = {
    entering: { maxHeight: `${maxHeight}px` },
    entered: { maxHeight: `${maxHeight}px` },
    exiting: { maxHeight: 0 },
    exited: { maxHeight: 0 },
    unmounted: { maxHeight: 0 }
  };

  return (
    <AccordionBlockStyled>
      <AccordionHeader isOpen={open} onClick={() => setOpen((state) => !state)}>
        <IconContainer m="0 12px 0 0">
          <AngleAccordion />
        </IconContainer>
        {title}
      </AccordionHeader>
      <Transition in={open} timeout={duration} mountOnEnter unmountOnExit>
        {(state) => (
          <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
            {children}
          </div>
        )}
      </Transition>
    </AccordionBlockStyled>
  );
};
