import styled from 'styled-components';

export const FileItemStyled = styled.div`
  margin-top: 12px;
  padding: 24px 32px;
  background: #ffffff;
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

export const FileItemHeader = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FileItemHeaderTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const FileItemHeaderDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.grey.input};
`;

export const FileData = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const FileName = styled.div<{ isImage?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.primary};
  max-width: 80%;
  cursor: ${(p) => (p.isImage ? 'pointer' : 'default')};
`;

export const ActionContainer = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

export const ActionItem = styled.div`
  padding: 0 8px;
  border-right: 1px solid ${(p) => p.theme.colors.grey.light};
  cursor: pointer;
  :last-child {
    border-right: none;
  }
`;
