export const DragKanbanSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33333 4.0026C6.06971 4.0026 6.66667 3.40565 6.66667 2.66927C6.66667 1.93289 6.06971 1.33594 5.33333 1.33594C4.59695 1.33594 4 1.93289 4 2.66927C4 3.40565 4.59695 4.0026 5.33333 4.0026Z"
      fill="black"
    />
    <path
      d="M5.33333 9.33073C6.06971 9.33073 6.66667 8.73378 6.66667 7.9974C6.66667 7.26102 6.06971 6.66406 5.33333 6.66406C4.59695 6.66406 4 7.26102 4 7.9974C4 8.73378 4.59695 9.33073 5.33333 9.33073Z"
      fill="black"
    />
    <path
      d="M5.33333 14.6667C6.06971 14.6667 6.66667 14.0697 6.66667 13.3333C6.66667 12.597 6.06971 12 5.33333 12C4.59695 12 4 12.597 4 13.3333C4 14.0697 4.59695 14.6667 5.33333 14.6667Z"
      fill="black"
    />
    <path
      d="M10.6654 4.0026C11.4017 4.0026 11.9987 3.40565 11.9987 2.66927C11.9987 1.93289 11.4017 1.33594 10.6654 1.33594C9.92898 1.33594 9.33203 1.93289 9.33203 2.66927C9.33203 3.40565 9.92898 4.0026 10.6654 4.0026Z"
      fill="black"
    />
    <path
      d="M10.6654 9.33073C11.4017 9.33073 11.9987 8.73378 11.9987 7.9974C11.9987 7.26102 11.4017 6.66406 10.6654 6.66406C9.92898 6.66406 9.33203 7.26102 9.33203 7.9974C9.33203 8.73378 9.92898 9.33073 10.6654 9.33073Z"
      fill="black"
    />
    <path
      d="M10.6654 14.6667C11.4017 14.6667 11.9987 14.0697 11.9987 13.3333C11.9987 12.597 11.4017 12 10.6654 12C9.92898 12 9.33203 12.597 9.33203 13.3333C9.33203 14.0697 9.92898 14.6667 10.6654 14.6667Z"
      fill="black"
    />
  </svg>
);
