import { useState, type FC } from 'react';
import { useLocation } from 'react-router-dom';
import { IconContainer } from '../../icon-container';
import { FlexContainer } from '../../flex-container';
import { AngleSvg } from '../../svg/angle-svg';
import { TooltipFixed } from '../../tooltip/tooltip-fixed';
import { AppRoute } from '../../../common/enums/app/app-route.enum';
import {
  AngleWrapper,
  SidebarMenuItemLink,
  SidebarMenuItemStyled,
  SidebarMenuSubItemHeader,
  SubMenuWrapper
} from './style';

interface SidebarMenuItemProps {
  icon: FC;
  title: string;
  path?: string;
  iconStrokeColored?: boolean;
  list?: {
    icon: FC;
    title: string;
    path: string;
    iconStrokeColored?: boolean;
  }[];
  menuIsOpen: boolean;
}

export const SidebarMenuItem = ({
  icon: Icon,
  title,
  path,
  iconStrokeColored,
  list,
  menuIsOpen
}: SidebarMenuItemProps) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const { pathname } = useLocation();

  return !list && path ? (
    <SidebarMenuItemStyled
      $menuIsOpen={menuIsOpen}
      $iconStrokeColored={iconStrokeColored}
    >
      <TooltipFixed disabled={menuIsOpen} position="right" title={title}>
        <SidebarMenuItemLink
          $menuIsOpen={menuIsOpen}
          $isActive={
            (pathname.indexOf(path) > -1 && path !== '/') ||
            (path === AppRoute.DASHBOARD && pathname === AppRoute.ROOT)
          }
          $iconStrokeColored={iconStrokeColored}
          to={path}
        >
          <IconContainer>
            <Icon />
          </IconContainer>
          {menuIsOpen && <span>{title}</span>}
        </SidebarMenuItemLink>
      </TooltipFixed>
    </SidebarMenuItemStyled>
  ) : (
    <li>
      <TooltipFixed position="right" disabled={menuIsOpen} title={title}>
        <SidebarMenuSubItemHeader
          menuIsOpen={menuIsOpen}
          isActive={
            showSubMenu ||
            list?.some((item) => pathname.indexOf(item.path) > -1)
          }
          onClick={() => setShowSubMenu((state) => !state)}
        >
          <FlexContainer>
            {' '}
            <IconContainer>
              <Icon />
            </IconContainer>
            {menuIsOpen && <span>{title}</span>}
          </FlexContainer>
          <AngleWrapper
            show={menuIsOpen || !showSubMenu}
            menuIsOpen={menuIsOpen}
            isActive={showSubMenu}
            isCurrentPage={list?.some(
              (item) => pathname.indexOf(item.path) > -1
            )}
          >
            <AngleSvg />
          </AngleWrapper>
        </SidebarMenuSubItemHeader>
      </TooltipFixed>
      {showSubMenu && list ? (
        <SubMenuWrapper menuIsOpen={menuIsOpen}>
          <ul>
            {list.map(({ path, title, icon: Icon, iconStrokeColored }, i) => (
              <SidebarMenuItemStyled
                $menuIsOpen={menuIsOpen}
                key={i}
                $iconStrokeColored={iconStrokeColored}
              >
                <TooltipFixed
                  position="right"
                  disabled={menuIsOpen}
                  title={title}
                >
                  <SidebarMenuItemLink
                    $iconStrokeColored={iconStrokeColored}
                    $menuIsOpen={menuIsOpen}
                    to={path}
                  >
                    <IconContainer>
                      <Icon />
                    </IconContainer>
                    {menuIsOpen && <span>{title}</span>}
                  </SidebarMenuItemLink>
                </TooltipFixed>
              </SidebarMenuItemStyled>
            ))}
          </ul>
          <AngleWrapper
            show={!menuIsOpen && showSubMenu}
            menuIsOpen={menuIsOpen}
            isActive={showSubMenu}
            isCurrentPage={list?.some(
              (item) => pathname.indexOf(item.path) > -1
            )}
            onClick={() => setShowSubMenu(false)}
          >
            <AngleSvg />
          </AngleWrapper>
        </SubMenuWrapper>
      ) : (
        ''
      )}
    </li>
  );
};
