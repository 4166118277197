export const ContactsSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.334 14.5H4.66732C1.72732 14.5 0.833984 13.6067 0.833984 10.6667V5.33333C0.833984 2.39333 1.72732 1.5 4.66732 1.5H11.334C14.274 1.5 15.1673 2.39333 15.1673 5.33333V10.6667C15.1673 13.6067 14.274 14.5 11.334 14.5ZM4.66732 2.5C2.28065 2.5 1.83398 2.95333 1.83398 5.33333V10.6667C1.83398 13.0467 2.28065 13.5 4.66732 13.5H11.334C13.7207 13.5 14.1673 13.0467 14.1673 10.6667V5.33333C14.1673 2.95333 13.7207 2.5 11.334 2.5H4.66732Z"
      fill="#9DABC0"
    />
    <path
      d="M12.6673 5.83398H9.33398C9.06065 5.83398 8.83398 5.60732 8.83398 5.33398C8.83398 5.06065 9.06065 4.83398 9.33398 4.83398H12.6673C12.9407 4.83398 13.1673 5.06065 13.1673 5.33398C13.1673 5.60732 12.9407 5.83398 12.6673 5.83398Z"
      fill="#9DABC0"
    />
    <path
      d="M12.6667 8.5H10C9.72667 8.5 9.5 8.27333 9.5 8C9.5 7.72667 9.72667 7.5 10 7.5H12.6667C12.94 7.5 13.1667 7.72667 13.1667 8C13.1667 8.27333 12.94 8.5 12.6667 8.5Z"
      fill="#9DABC0"
    />
    <path
      d="M12.6673 11.166H11.334C11.0607 11.166 10.834 10.9393 10.834 10.666C10.834 10.3927 11.0607 10.166 11.334 10.166H12.6673C12.9407 10.166 13.1673 10.3927 13.1673 10.666C13.1673 10.9393 12.9407 11.166 12.6673 11.166Z"
      fill="#9DABC0"
    />
    <path
      d="M5.6676 8.02661C4.7276 8.02661 3.96094 7.25995 3.96094 6.31995C3.96094 5.37995 4.7276 4.61328 5.6676 4.61328C6.6076 4.61328 7.37427 5.37995 7.37427 6.31995C7.37427 7.25995 6.6076 8.02661 5.6676 8.02661ZM5.6676 5.61328C5.28094 5.61328 4.96094 5.93328 4.96094 6.31995C4.96094 6.70661 5.28094 7.02661 5.6676 7.02661C6.05427 7.02661 6.37427 6.70661 6.37427 6.31995C6.37427 5.93328 6.05427 5.61328 5.6676 5.61328Z"
      fill="#9DABC0"
    />
    <path
      d="M7.99994 11.3872C7.7466 11.3872 7.5266 11.1938 7.49994 10.9338C7.4266 10.2138 6.8466 9.63383 6.11994 9.56716C5.81327 9.5405 5.5066 9.5405 5.19994 9.56716C4.47327 9.63383 3.89327 10.2072 3.81994 10.9338C3.79327 11.2072 3.5466 11.4138 3.27327 11.3805C2.99994 11.3538 2.79994 11.1072 2.8266 10.8338C2.9466 9.63383 3.89994 8.68049 5.1066 8.57383C5.47327 8.54049 5.8466 8.54049 6.21327 8.57383C7.41327 8.68716 8.37327 9.64049 8.49327 10.8338C8.51994 11.1072 8.31994 11.3538 8.0466 11.3805C8.03327 11.3872 8.01327 11.3872 7.99994 11.3872Z"
      fill="#9DABC0"
    />
  </svg>
);
