import styled from 'styled-components';

export const FormStyled = styled.form`
  padding-top: 20px;
`;

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const BtnsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px 0;
  border-top: 1px solid ${(p) => p.theme.colors.popup.border};
`;
