import styled from 'styled-components';
import { centerFlex } from '../../../style/helpers';

export const SidebarFooterItemStyled = styled.div`
  padding: 8px 20px;
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.colors.grey.light};
  cursor: pointer;
  :hover {
    color: ${(p) => p.theme.colors.primary};
    svg {
      path {
        stroke: ${(p) => p.theme.colors.primary};
      }
    }
  }
`;

export const SidebarFooterIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 8px;
  ${centerFlex}
  svg {
    max-width: 100%;
  }
`;
