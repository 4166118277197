import { useState, type ReactNode, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Sidebar } from '../../components/sidebar';
import { Header } from '../../components/header';
import { Notification } from '../../components/notification';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { SearchPage } from '../../pages/search';
import { Currentcontact } from '../../pages/contacts/current-contact';
import { closeMenu } from '../../api/page-data-slice';
import { useGetUserMeQuery } from '../../api/user-api';
import { Loader } from '../../components/loader';
import { Content, MainLayoutStyled } from './style';

interface MainlayoutProps {
  children: ReactNode;
  customHeader?: boolean;
}

const MainLayout = ({ children, customHeader }: MainlayoutProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [showResults, setShowResults] = useState(false);
  const [searchParams] = useSearchParams();
  const { show: showNotification } = useTypedSelector(
    (state) => state.notification
  );

  const { menuIsOpen, phoneSearchTerm } = useTypedSelector(
    (state) => state['page-data']
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1300) {
        dispatch(closeMenu());
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  if (!userMe) {
    return <Loader />;
  }

  return (
    <MainLayoutStyled>
      <Sidebar />
      <Content menuIsOpen={menuIsOpen}>
        {!customHeader && (
          <Header
            showResults={showResults}
            setShowResults={setShowResults}
            withOutGlobalSearch={pathname.indexOf('events') > -1}
          />
        )}
        {phoneSearchTerm && pathname.indexOf('events') === -1 ? (
          <SearchPage />
        ) : (
          children
        )}
      </Content>
      {showNotification && <Notification />}
      <Currentcontact id={searchParams.get('contact_id')} />
    </MainLayoutStyled>
  );
};

export { MainLayout };
