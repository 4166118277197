import {
  BENEFIT,
  EDUCATION,
  EMPLOYMENT,
  FAMILY_STATUS,
  GENDER,
  MONTHS,
  QUALIFICATION
} from '../../common/constants';
import { SATISFACTION } from '../../common/constants';
import { STATUS } from '../../common/constants';
import type { Filter } from '../../common/types/filters';
import type { HeaderItem } from '../../components/data-table';
import type { DynamicContact } from '../../models/contact';

export const tableHeaders: HeaderItem<DynamicContact>[] = [
  { id: 'id', title: 'ID', checked: true },
  { id: 'last_name', title: 'Прізвище', checked: true },
  { id: 'first_name', title: "ім'я", checked: true },
  { id: 'patronymic', title: 'ПО БАТЬКОВІ', checked: true },
  { id: 'type', title: 'ВИД контакту', checked: true },
  { id: 'satisfaction', title: 'Рівень задоволеності', checked: true },
  { id: 'created_at', title: 'ДАТА СТВОрЕння', checked: true },
  { id: 'status', title: 'Статус', checked: true },
  { id: 'resp_managers', title: 'Власник', checked: true },
  { id: 'phone', title: 'ТЕЛЕФОН', checked: true },
  { id: 'birth_date', title: 'ДАТА НАРОДЖЕННЯ', checked: true },
  { id: 'age', title: 'Вік', checked: true },
  {
    id: 'address',
    title: 'Адреса Проживання',
    checked: true,
    subFields: [
      { id: 'liv_country', title: 'Країна' },
      { id: 'liv_region', title: 'Область' },
      { id: 'liv_city', title: 'Місто / СМТ / СЕЛО' },
      { id: 'liv_district', title: 'Район / ОТГ' },
      { id: 'liv_street', title: 'Вулиця' },
      { id: 'liv_building', title: 'Будинок' },
      { id: 'liv_apartment', title: 'Квартира' }
    ]
  },
  {
    id: 'reg_address',
    title: 'Адреса Реєстрації',
    checked: true,
    subFields: [
      { id: 'reg_country', title: 'Країна' },
      { id: 'reg_region', title: 'Область' },
      { id: 'reg_city', title: 'Місто / СМТ / СЕЛО' },
      { id: 'reg_district', title: 'Район / ОТГ' },
      { id: 'reg_street', title: 'Вулиця' },
      { id: 'reg_building', title: 'Будинок' },
      { id: 'reg_apartment', title: 'Квартира' }
    ]
  },
  { id: 'gender', title: 'Гендер', checked: true },
  { id: 'family_status', title: 'Сімейний статус', checked: true },
  { id: 'children_num', title: 'Діти', checked: true },
  { id: 'categories', title: 'Пільгові групи', checked: true },
  { id: 'employment', title: 'Діяльність', checked: true },
  { id: 'education', title: 'Освіта', checked: true },
  { id: 'email', title: 'EMAIL', checked: true },
  { id: 'project', title: 'ПРОЄКТ', checked: true },
  { id: 'comment', title: 'Коментар', checked: true },
  { id: 'channels', title: 'Канал приходу контакту', checked: true },
  { id: 'utm', title: 'UTM мітка', checked: true }
];

export const commonInitialFilters: Filter[] = [
  {
    type: 'checkbox',
    isSingly: true,
    checkList: [
      {
        id: 'is_volunteer',
        checked: false,
        title: 'Волонтер'
      }
      // {
      //   id: 'is_partner',
      //   checked: false,
      //   title: 'Партнер'
      // }
    ],
    title: 'Вид контакту',
    name: 'type'
  },
  {
    type: 'checkbox',
    checkList: STATUS.map((item) => ({ ...item, checked: false })),
    title: 'Статус',
    name: 'status'
  },
  {
    type: 'checkbox',
    checkList: [],
    title: 'Власник контакту',
    name: 'resp_manager'
  },
  {
    type: 'checkbox',
    checkList: SATISFACTION.map((item) => ({ ...item, checked: false })),
    title: 'Рівень задоволеності',
    name: 'satisfaction'
  },
  {
    type: 'checkbox',
    checkList: QUALIFICATION.map((item) => ({ ...item, checked: false })),
    title: 'Кваліфікатор',
    name: 'qualification'
  },
  {
    type: 'select',
    selectList: [
      {
        id: 'birth_year',
        value: { id: '', title: '' },
        options: new Array(100).fill('').map((_, i) => ({
          id: `${new Date().getFullYear() - i}`,
          title: `${new Date().getFullYear() - i}`
        })),
        title: 'Рік народження'
      },
      {
        id: 'birth_month',
        value: { id: '', title: '' },
        options: new Array(12)
          .fill('')
          .map((_, i) => ({ id: `${i + 1}`, title: MONTHS[i] })),
        title: 'Місяць народження'
      },
      {
        id: 'birth_day',
        value: { id: '', title: '' },
        options: new Array(31)
          .fill('')
          .map((_, i) => ({ id: `${i + 1}`, title: `${i + 1}` })),
        title: 'День народження'
      }
    ],
    title: 'Дата народження',
    name: 'birth_date'
  },
  {
    type: 'sublist',
    title: 'Адреса (проживання)',
    list: [
      { type: 'text', title: 'Область', value: '', name: 'liv_region' },
      { type: 'text', title: 'Район / ОТГ', value: '', name: 'liv_district' },
      {
        type: 'text',
        title: 'Місто / СМТ / Село',
        value: '',
        name: 'liv_city'
      },
      { type: 'text', title: 'Вулиця', value: '', name: 'liv_street' },
      { type: 'text', title: 'Будинок', value: '', name: 'liv_building' },
      { type: 'text', title: 'Корпус', value: '', name: 'liv_unit' },
      { type: 'text', title: 'Квартира', value: '', name: 'liv_apartment' }
    ],
    name: 'liv_address'
  },
  {
    type: 'sublist',
    title: 'Адреса реєстрації',
    list: [
      { type: 'text', title: 'Область', value: '', name: 'reg_region' },
      { type: 'text', title: 'Район / ОТГ', value: '', name: 'reg_district' },
      {
        type: 'text',
        title: 'Місто / СМТ / Село',
        value: '',
        name: 'reg_city'
      },
      { type: 'text', title: 'Вулиця', value: '', name: 'reg_street' },
      { type: 'text', title: 'Будинок', value: '', name: 'reg_building' },
      { type: 'text', title: 'Корпус', value: '', name: 'reg_unit' },
      { type: 'text', title: 'Квартира', value: '', name: 'reg_apartment' }
    ],
    name: 'reg_address'
  },
  {
    type: 'checkbox',
    checkList: GENDER.map((item) => ({ ...item, checked: false })),
    title: 'Гендер',
    name: 'gender'
  },
  {
    type: 'checkbox',
    checkList: FAMILY_STATUS.map((item) => ({ ...item, checked: false })),
    title: 'Сімейний стан',
    name: 'family_status'
  },
  {
    type: 'sublist',
    title: 'Діти',
    list: [
      {
        type: 'checkbox',
        title: 'Кількість',
        checkList: [
          { id: '1', checked: false, title: '1-2' },
          { id: '2', checked: false, title: '3+' }
        ],
        name: 'children_num'
      },
      {
        type: 'select',
        title: 'Вік',
        selectList: [
          {
            id: 'children_age',
            placeholder: 'Оберіть зі списку',
            value: { id: '', title: '' },
            options: new Array(18).fill('').map((_, i) => ({
              id: `${i + 1}`,
              title: `${i + 1}`
            })),
            title: 'Вік'
          }
        ],
        name: 'children_ages'
      },
      {
        type: 'checkbox',
        title: 'Стать',
        checkList: [
          { id: '1', checked: false, title: 'Хлопчик' },
          { id: '2', checked: false, title: 'Дівчинка' }
        ],
        name: 'children_gen'
      }
    ],
    name: 'children'
  },
  {
    type: 'checkbox',
    checkList: EDUCATION.map((item) => ({ ...item, checked: false })),
    title: 'Освіта',
    name: 'education'
  },
  {
    type: 'checkbox',
    checkList: EMPLOYMENT.map((item) => ({ ...item, checked: false })),
    title: 'Діяльність',
    name: 'employment'
  },
  {
    type: 'checkbox',
    title: 'Проєкти',
    checkList: [],
    name: 'projects'
  },
  {
    type: 'checkbox',
    title: 'Пільгові групи',
    checkList: [],
    name: 'categories'
  },
  {
    type: 'checkbox',
    checkList: [],
    title: 'Канал приходу контакту',
    name: 'channels'
  },
  {
    type: 'checkbox',
    isSingly: true,
    checkList: [
      {
        id: 'no_docs',
        checked: false,
        title: 'Немає документів'
      }
    ],
    title: 'Документи',
    name: 'docs'
  }
];

export const managerInitialFilters: Filter[] = [
  {
    type: 'checkbox',
    isSingly: true,
    checkList: [
      {
        id: 'is_volunteer',
        checked: false,
        title: 'Волонтер'
      }
      // {
      //   id: 'is_partner',
      //   checked: false,
      //   title: 'Партнер'
      // }
    ],
    title: 'Вид контакту',
    name: 'type'
  },
  {
    type: 'checkbox',
    checkList: STATUS.map((item) => ({ ...item, checked: false })),
    title: 'Статус',
    name: 'status'
  },
  {
    type: 'checkbox',
    checkList: SATISFACTION.map((item) => ({ ...item, checked: false })),
    title: 'Рівень задоволеності',
    name: 'satisfaction'
  },
  {
    type: 'checkbox',
    checkList: QUALIFICATION.map((item) => ({ ...item, checked: false })),
    title: 'Кваліфікатор',
    name: 'qualification'
  },
  {
    type: 'select',
    selectList: [
      {
        id: 'birth_year',
        value: { id: '', title: '' },
        options: new Array(100).fill('').map((_, i) => ({
          id: `${new Date().getFullYear() - i}`,
          title: `${new Date().getFullYear() - i}`
        })),
        title: 'Рік народження'
      },
      {
        id: 'birth_month',
        value: { id: '', title: '' },
        options: new Array(12)
          .fill('')
          .map((_, i) => ({ id: `${i + 1}`, title: MONTHS[i] })),
        title: 'Місяць народження'
      },
      {
        id: 'birth_day',
        value: { id: '', title: '' },
        options: new Array(31)
          .fill('')
          .map((_, i) => ({ id: `${i + 1}`, title: `${i + 1}` })),
        title: 'День народження'
      }
    ],
    title: 'Дата народження',
    name: 'birth_date'
  },
  {
    type: 'sublist',
    title: 'Адреса (проживання)',
    list: [
      { type: 'text', title: 'Область', value: '', name: 'liv_region' },
      { type: 'text', title: 'Район / ОТГ', value: '', name: 'liv_district' },
      {
        type: 'text',
        title: 'Місто / СМТ / Село',
        value: '',
        name: 'liv_city'
      },
      { type: 'text', title: 'Вулиця', value: '', name: 'liv_street' },
      { type: 'text', title: 'Будинок', value: '', name: 'liv_building' },
      { type: 'text', title: 'Корпус', value: '', name: 'liv_unit' },
      { type: 'text', title: 'Квартира', value: '', name: 'liv_apartment' }
    ],
    name: 'liv_address'
  },
  {
    type: 'sublist',
    title: 'Адреса реєстрації',
    list: [
      { type: 'text', title: 'Область', value: '', name: 'reg_region' },
      { type: 'text', title: 'Район / ОТГ', value: '', name: 'reg_district' },
      {
        type: 'text',
        title: 'Місто / СМТ / Село',
        value: '',
        name: 'reg_city'
      },
      { type: 'text', title: 'Вулиця', value: '', name: 'reg_street' },
      { type: 'text', title: 'Будинок', value: '', name: 'reg_building' },
      { type: 'text', title: 'Корпус', value: '', name: 'reg_unit' },
      { type: 'text', title: 'Квартира', value: '', name: 'reg_apartment' }
    ],
    name: 'reg_address'
  },
  {
    type: 'checkbox',
    checkList: GENDER.map((item) => ({ ...item, checked: false })),
    title: 'Гендер',
    name: 'gender'
  },
  {
    type: 'checkbox',
    checkList: FAMILY_STATUS.map((item) => ({ ...item, checked: false })),
    title: 'Сімейний стан',
    name: 'family_status'
  },
  {
    type: 'sublist',
    title: 'Діти',
    list: [
      {
        type: 'checkbox',
        title: 'Кількість',
        checkList: [
          { id: '1', checked: false, title: '1-2' },
          { id: '2', checked: false, title: '3+' }
        ],
        name: 'children_num'
      },
      {
        type: 'select',
        title: 'Вік',
        selectList: [
          {
            id: 'children_age',
            placeholder: 'Оберіть зі списку',
            value: { id: '', title: '' },
            options: new Array(18).fill('').map((_, i) => ({
              id: `${i + 1}`,
              title: `${i + 1}`
            })),
            title: 'Вік'
          }
        ],
        name: 'children_ages'
      },
      {
        type: 'checkbox',
        title: 'Стать',
        checkList: [
          { id: '1', checked: false, title: 'Хлопчик' },
          { id: '2', checked: false, title: 'Дівчинка' }
        ],
        name: 'children_gen'
      }
    ],
    name: 'children'
  },
  {
    type: 'checkbox',
    checkList: BENEFIT.map((item) => ({ ...item, checked: false })),
    title: 'Пільгові групи',
    name: 'benefit'
  },
  {
    type: 'checkbox',
    checkList: EDUCATION.map((item) => ({ ...item, checked: false })),
    title: 'Освіта',
    name: 'education'
  },
  {
    type: 'checkbox',
    checkList: EMPLOYMENT.map((item) => ({ ...item, checked: false })),
    title: 'Діяльність',
    name: 'employment'
  },
  {
    type: 'checkbox',
    checkList: [],
    title: 'Канал приходу контакту',
    name: 'channels'
  }
];
