import { useLocation } from 'react-router-dom';
import {
  AppRoute,
  DashboardRoute
} from '../../../../../common/enums/app/app-route.enum';
import type { ITab } from '../types';
import { TabLink, TabStyled } from './style';

export const Tab = ({ title, path, icon: Icon }: ITab) => {
  const { pathname } = useLocation();

  return (
    <TabStyled>
      <TabLink
        $isActive={
          pathname === AppRoute.DASHBOARD &&
          path === `${AppRoute.DASHBOARD}/${DashboardRoute.PROJECTS}`
        }
        to={path}
      >
        <Icon />
        {title}
      </TabLink>
    </TabStyled>
  );
};
