import { useState } from 'react';
import { IconContainer } from '../icon-container';
import { StringCutter } from '../string-cutter';
import { DownloadSvg } from '../svg/download-svg';
import { FileDeleteSvg } from '../svg/file-delete-svg';
import { ConfirmPopup } from '../confirm-popup';
import { ImagePreview } from '../image-preview';
import {
  ActionContainer,
  ActionItem,
  FileData,
  FileItemHeader,
  FileItemHeaderDate,
  FileItemHeaderTitle,
  FileItemStyled,
  FileName
} from './style';

interface FileItemProps {
  date: string;
  fileName: string;
  fileLink: string;
  isDBFile?: boolean;
  onDelete: () => void;
  isDeleteLoading: boolean;
}

export const FileItem = ({
  date,
  fileName,
  onDelete,
  fileLink,
  isDBFile,
  isDeleteLoading
}: FileItemProps) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const isImage = !!fileName.trim().match(/\.(jpg|jpeg|png)$/i);

  return (
    <FileItemStyled>
      <FileItemHeader>
        <FileItemHeaderTitle>Додано новий файл </FileItemHeaderTitle>
        <FileItemHeaderDate>{date}</FileItemHeaderDate>
      </FileItemHeader>
      <FileData>
        <FileName
          onClick={isImage ? () => setShowPreview(true) : undefined}
          isImage={isImage}
        >
          <StringCutter lines="1">{fileName}</StringCutter>
        </FileName>
        <ActionContainer>
          <ActionItem>
            <a
              download={fileName}
              target="_blank"
              href={fileLink}
              rel="noreferrer"
            >
              <IconContainer onClick={() => {}}>
                <DownloadSvg />
              </IconContainer>
            </a>
          </ActionItem>
          {!isDBFile && (
            <ActionItem>
              <IconContainer onClick={() => setShowConfirm(true)}>
                <FileDeleteSvg />
              </IconContainer>
            </ActionItem>
          )}
        </ActionContainer>
      </FileData>
      <ConfirmPopup
        show={showConfirm}
        title="Ви дійсно бажаєте видалити даний файл?"
        setShow={setShowConfirm}
        onSubmit={onDelete}
        needTranslate
        disabled={isDeleteLoading}
      />
      <ImagePreview
        show={showPreview}
        setShow={setShowPreview}
        path={fileLink}
      />
    </FileItemStyled>
  );
};
