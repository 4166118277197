export const ListSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3563_224609)">
      <path
        d="M2.12064 7.44186L13.8794 7.44186C15.3067 7.44186 16 6.71584 16 5.22311L16 2.21875C16 0.726017 15.3067 -1.84674e-06 13.8794 -1.72196e-06L2.12064 -6.93977e-07C0.69329 -5.69194e-07 -3.8162e-07 0.726018 -2.51121e-07 2.21875L1.15283e-08 5.22311C1.42027e-07 6.71584 0.693314 7.44186 2.12064 7.44186ZM1.11628 2.21875C1.11628 1.27544 1.367 1.11628 2.12064 1.11628L13.8794 1.11628C14.633 1.11628 14.8837 1.27544 14.8837 2.21875L14.8837 5.22311C14.8837 6.16642 14.633 6.32558 13.8794 6.32558L2.12064 6.32558C1.367 6.32558 1.11628 6.16642 1.11628 5.22311L1.11628 2.21875ZM2.12064 16L13.8794 16C15.3067 16 16 15.274 16 13.7812L16 10.7769C16 9.28416 15.3067 8.55814 13.8794 8.55814L2.12064 8.55814C0.693291 8.55814 3.66557e-07 9.28416 4.97056e-07 10.7769L7.59705e-07 13.7812C8.90204e-07 15.274 0.693315 16 2.12064 16ZM1.11628 10.7769C1.11628 9.83358 1.367 9.67442 2.12064 9.67442L13.8794 9.67442C14.633 9.67442 14.8837 9.83358 14.8837 10.7769L14.8837 13.7812C14.8837 14.7246 14.633 14.8837 13.8794 14.8837L2.12064 14.8837C1.367 14.8837 1.11628 14.7246 1.11628 13.7812L1.11628 10.7769Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3563_224609">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(16 16) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);
