import styled from 'styled-components';

export const DoubleChartStyled = styled.div``;

export const LegendsContainer = styled.div`
  display: grid;
  grid-template-columns: 16px auto 1fr;
  gap: 20px 10px;
  align-items: center;
`;

export const LegendCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
`;

export const LegendTitle = styled.div`
  color: ${(p) => p.theme.colors.grey.light};
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
`;

export const LegendValue = styled.div`
  margin-left: 6px;
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
`;

export const TickStyled = styled.div`
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
`;

export const ChartStyled = styled.div`
  margin-top: 30px;
  svg {
    overflow: visible;
  }
  text {
    fill: ${(p) => p.theme.colors.blue.dark};
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    overflow: hidden;
    tspan {
      max-width: 80px;
      overflow: hidden;
    }
  }
`;
