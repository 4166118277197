export const SettingsSvg = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 20C8.59851 20 8.23048 19.9 7.86245 19.7L1.13755 15.8333C0.434944 15.4333 0 14.6667 0 13.8667V6.13333C0 5.33333 0.434944 4.56667 1.13755 4.16667L7.86245 0.3C8.56505 -0.1 9.43494 -0.1 10.1375 0.3L16.8625 4.16667C17.5651 4.56667 18 5.33333 18 6.13333V13.8667C18 14.6667 17.5651 15.4333 16.8625 15.8333L10.1375 19.7C9.76952 19.9 9.40149 20 9 20ZM9 1.33333C8.83271 1.33333 8.66543 1.36667 8.5316 1.46667L1.80669 5.33333C1.50558 5.5 1.33829 5.8 1.33829 6.13333V13.8667C1.33829 14.2 1.50558 14.5 1.80669 14.6667L8.5316 18.5333C8.83271 18.7 9.16729 18.7 9.4684 18.5333L16.1933 14.6667C16.4944 14.5 16.6617 14.2 16.6617 13.8667V6.13333C16.6617 5.8 16.4944 5.5 16.1933 5.33333L9.4684 1.46667C9.33457 1.36667 9.16728 1.33333 9 1.33333Z"
      fill="#9DABC0"
    />
    <path
      d="M8.99977 13.3041C7.15962 13.3041 5.6875 11.8374 5.6875 10.0041C5.6875 8.17077 7.15962 6.7041 8.99977 6.7041C10.8399 6.7041 12.312 8.17077 12.312 10.0041C12.312 11.8374 10.8399 13.3041 8.99977 13.3041ZM8.99977 8.03743C7.89568 8.03743 7.02579 8.9041 7.02579 10.0041C7.02579 11.1041 7.89568 11.9708 8.99977 11.9708C10.1039 11.9708 10.9737 11.1041 10.9737 10.0041C10.9737 8.9041 10.1039 8.03743 8.99977 8.03743Z"
      fill="#9DABC0"
    />
  </svg>
);
