import styled from 'styled-components';

export const Message = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.input};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const InputContainer = styled.div`
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`;

export const TextareaWrapper = styled.div`
  margin-top: 20px;
  padding: 20px 0;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.input};
  border-top: 1px solid ${(p) => p.theme.colors.grey.input};
  height: 120px;
`;

export const EditBtns = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 20px 0;
  border-top: 1px solid ${(p) => p.theme.colors.grey.input};
`;
