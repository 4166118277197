export const RolesSvg = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99468 2.07031C9.30892 2.07031 10.3761 3.13748 10.3761 4.45171C10.3761 5.16911 10.033 6.10084 9.49497 6.72893C9.07897 7.21489 8.54985 7.51703 7.99468 7.51703C7.43952 7.51703 6.9104 7.21489 6.4944 6.72893C5.95635 6.10084 5.61328 5.16911 5.61328 4.45171C5.61328 3.13748 6.68045 2.07031 7.99468 2.07031ZM7.99468 3.18659C7.29663 3.18659 6.72956 3.75367 6.72956 4.45171C6.72956 4.94213 6.9744 5.57395 7.34203 6.00335C7.52957 6.22214 7.74464 6.40074 7.99468 6.40074C8.24473 6.40074 8.4598 6.22214 8.64734 6.00335C9.01497 5.57395 9.2598 4.94213 9.2598 4.45171C9.2598 3.75367 8.69273 3.18659 7.99468 3.18659Z"
      fill="#9DABC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8409 0.0234375C13.8679 0.0234375 14.7014 0.856928 14.7014 1.88391C14.7014 2.42865 14.4424 3.13712 14.0339 3.61415C13.7042 3.99963 13.2808 4.23257 12.8409 4.23257C12.4011 4.23257 11.9777 3.99963 11.648 3.61415C11.2394 3.13712 10.9805 2.42865 10.9805 1.88391C10.9805 0.856928 11.814 0.0234375 12.8409 0.0234375ZM12.8409 1.13972C12.4301 1.13972 12.0968 1.47312 12.0968 1.88391C12.0968 2.20168 12.2582 2.61024 12.4964 2.88856C12.5976 3.00689 12.7055 3.11628 12.8409 3.11628C12.9764 3.11628 13.0843 3.00689 13.1855 2.88856C13.4236 2.61024 13.5851 2.20168 13.5851 1.88391C13.5851 1.47312 13.2517 1.13972 12.8409 1.13972Z"
      fill="#9DABC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.16125 0.0234375C4.18823 0.0234375 5.02172 0.856928 5.02172 1.88391C5.02172 2.42865 4.76274 3.13712 4.35419 3.61415C4.02451 3.99963 3.60107 4.23257 3.16125 4.23257C2.72144 4.23257 2.29799 3.99963 1.96832 3.61415C1.55976 3.13712 1.30078 2.42865 1.30078 1.88391C1.30078 0.856928 2.13427 0.0234375 3.16125 0.0234375ZM3.16125 1.13972C2.75046 1.13972 2.41706 1.47312 2.41706 1.88391C2.41706 2.20168 2.57855 2.61024 2.81669 2.88856C2.9179 3.00689 3.02581 3.11628 3.16125 3.11628C3.29669 3.11628 3.4046 3.00689 3.50581 2.88856C3.74395 2.61024 3.90544 2.20168 3.90544 1.88391C3.90544 1.47312 3.57204 1.13972 3.16125 1.13972Z"
      fill="#9DABC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00154 7.91211C10.3234 7.91211 12.2062 9.79491 12.2062 12.1168V12.8982C12.2062 13.4943 11.7232 13.9772 11.1271 13.9772H4.87595C4.27985 13.9772 3.79688 13.4943 3.79688 12.8982V12.1168C3.79688 9.79491 5.67967 7.91211 8.00154 7.91211ZM4.91316 12.861H11.0899V12.1168C11.0899 10.4111 9.70722 9.02839 8.00154 9.02839C6.29586 9.02839 4.91316 10.4111 4.91316 12.1168V12.861Z"
      fill="#9DABC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.883 8.20872V7.83663C14.883 6.70621 13.9669 5.79011 12.8365 5.79011C12.5224 5.79011 12.2255 5.86081 11.9598 5.98658C11.6815 6.11904 11.3481 6.00072 11.2164 5.72239C11.0839 5.44406 11.2022 5.11067 11.4806 4.9782C11.8914 4.78322 12.3513 4.67383 12.8365 4.67383C14.5831 4.67383 15.9993 6.09002 15.9993 7.83663V8.39477C15.9993 8.90826 15.5825 9.325 15.0691 9.325H12.0923C11.7842 9.325 11.5342 9.07496 11.5342 8.76686C11.5342 8.45877 11.7842 8.20872 12.0923 8.20872H14.883Z"
      fill="#9DABC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.11628 8.20872H3.90699C4.21508 8.20872 4.46513 8.45877 4.46513 8.76686C4.46513 9.07496 4.21508 9.325 3.90699 9.325H0.930235C0.416745 9.325 0 8.90826 0 8.39477V7.83663C0 6.09002 1.41619 4.67383 3.1628 4.67383C3.64801 4.67383 4.10792 4.78322 4.51871 4.9782C4.79704 5.11067 4.91536 5.44406 4.7829 5.72239C4.65118 6.00072 4.31778 6.11904 4.03945 5.98658C3.77378 5.86081 3.47685 5.79011 3.1628 5.79011C2.03238 5.79011 1.11628 6.70621 1.11628 7.83663V8.20872Z"
      fill="#9DABC0"
    />
  </svg>
);
