export const AngleAccordion = () => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.739574 12.2612C0.420142 11.9498 0.420142 11.4502 0.739574 11.1388L5.1301 6.858L5.49728 6.5L5.1301 6.142L0.739574 1.86124C0.420141 1.54979 0.420142 1.05021 0.739574 0.738762C1.06609 0.420413 1.60058 0.420414 1.92709 0.738763L7.26043 5.93876C7.57986 6.25021 7.57986 6.74979 7.26043 7.06124L1.92709 12.2612C1.60058 12.5796 1.06609 12.5796 0.739574 12.2612ZM0.739574 12.2612L0.392 12.6177L0.739574 12.2612Z"
      fill="#306FE3"
      stroke="#306FE3"
    />
  </svg>
);
