import type { Dispatch, FC, MouseEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import { Transition } from 'react-transition-group';
import { useSearchParams } from 'react-router-dom';
import { CheckBox } from '../checkbox';
import { IconContainer } from '../icon-container';
import { AngleTableSvg } from '../svg/angle-table-svg';
import { Pagination } from '../paginations';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { useGetContactsIdListQuery } from '../../api/contact-api';
import { CloseFileSvg } from '../svg/close-file-svg';
import { commonApi } from '../../api/common-api';
import { ApiTag } from '../../common/enums/http/api-tag.enum';
import { Loader } from '../loader';
import {
  Cell,
  CheckedAllPages,
  ClearCheckedBtn,
  Row,
  TableActions,
  TableActionsCount,
  TableActionsItem,
  TableActionsWrapper,
  TableStyled,
  TableWrapper,
  TBody,
  Th,
  THead
} from './style';
import {
  defaultStyle,
  duration,
  transitionAllPagesStyles,
  transitionStyles
} from './transition';

export interface HeaderItem<T> {
  id: keyof T;
  title: string;
  checked?: boolean;
  subFields?: {
    id: keyof T;
    title: string;
  }[];
}

interface TableProps<T> {
  headers: HeaderItem<T>[];
  data: T[];
  checkedList: number[];
  setCheckedList: Dispatch<React.SetStateAction<number[]>>;
  customHeight?: number;
  actions: {
    title: string;
    icon?: FC;
    handler?: () => void;
  }[];
  onRowClick?: (id: string | number) => void;
  withPagination?: boolean;
  count?: number;
  mt?: string;
  withOutCheck?: boolean;
  isFetching?: boolean;
  withCheckAllPages?: boolean;
  params?: string;
}

const nonBreakFields = ['phone', 'status'];

const coloredFields = [
  'name',
  'full_name',
  'organization',
  'email',
  'resp_manager'
];

export const DataTable = <T extends { id: number }>({
  data,
  checkedList,
  setCheckedList,
  headers,
  customHeight,
  actions,
  onRowClick,
  withPagination,
  count,
  mt,
  withOutCheck,
  isFetching,
  withCheckAllPages,
  params = ''
}: TableProps<T>) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get('page');
  const isCheckedAll = data.every((item) => checkedList.includes(item.id));
  const [expandedCols, setExpandedCols] = useState<(keyof T)[]>([]);
  const [showCheckAllPages, setShowCheckAllPages] = useState(false);
  const [needGetAllContacts, setNeedGetAllContacts] = useState(false);
  const {
    data: allIds,
    isLoading: isAllIdsLoading,
    isFetching: isAllIdsFetcing
  } = useGetContactsIdListQuery(
    { params },
    {
      skip: !withCheckAllPages || !needGetAllContacts,
      refetchOnMountOrArgChange: true
    }
  );

  const { pageSize } = useTypedSelector((state) => state['page-data']);

  const handleChackAll = (checked: boolean) => {
    if (checked) {
      setCheckedList((state) => {
        const updatedList = Array.from(
          new Set([...data.map((item) => item.id), ...state])
        );
        if (withCheckAllPages && count && updatedList.length < count) {
          setShowCheckAllPages(true);
        }

        return updatedList;
      });
    } else {
      setCheckedList((state) =>
        state.filter((item) => !data.map((item) => item.id).includes(item))
      );
      setShowCheckAllPages(false);
      setNeedGetAllContacts(false);
    }
  };

  const handleChecked = (checked: boolean, id: number) => {
    if (checked) {
      setCheckedList((state) => [...state, id]);
    } else {
      setCheckedList((state) => state.filter((item) => item !== id));
    }
    setShowCheckAllPages(false);
    setNeedGetAllContacts(false);
  };

  const handleClickCheckbox: MouseEventHandler<HTMLTableCellElement> = (e) => {
    e.stopPropagation();
  };

  const handleGetAllIds = () => {
    setNeedGetAllContacts(true);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
    setShowCheckAllPages(false);
    setNeedGetAllContacts(false);
  }, [currentPage]);

  useEffect(() => {
    setShowCheckAllPages(false);
    setNeedGetAllContacts(false);
  }, [pageSize]);

  useEffect(() => {
    if (allIds) {
      setCheckedList(allIds);
      setNeedGetAllContacts(false);
      setShowCheckAllPages(false);
    }
  }, [allIds, setCheckedList]);

  const handleClearChecked = () => {
    setCheckedList([]);
    setNeedGetAllContacts(false);
    setShowCheckAllPages(false);
    dispatch(commonApi.util.invalidateTags([ApiTag.CONTACTS_ID_LIST]));
  };

  return (
    <TableWrapper
      ref={ref}
      mt={mt}
      style={{
        maxHeight: customHeight
          ? `${customHeight}px`
          : withPagination
          ? 'calc(100% - 370px)'
          : 'calc(100% - 282px)'
      }}
    >
      {isFetching && <Loader />}
      <Transition
        in={!!checkedList.length}
        timeout={duration}
        mountOnEnter
        unmountOnExit
      >
        {(state) => (
          <TableActionsWrapper
            style={
              withCheckAllPages && showCheckAllPages
                ? {
                    ...defaultStyle,
                    ...transitionAllPagesStyles[state]
                  }
                : {
                    ...defaultStyle,
                    ...transitionStyles[state]
                  }
            }
          >
            <TableActions>
              <TableActionsCount>
                {checkedList.length} обрано{' '}
                <ClearCheckedBtn onClick={handleClearChecked}>
                  <CloseFileSvg />
                </ClearCheckedBtn>
              </TableActionsCount>
              {actions.map(({ title, icon: Icon, handler }, i) => (
                <TableActionsItem onClick={handler} key={i}>
                  {Icon ? (
                    <>
                      <IconContainer>
                        <Icon />
                      </IconContainer>
                      <span>{title}</span>
                    </>
                  ) : (
                    title
                  )}
                </TableActionsItem>
              ))}
            </TableActions>
            {withCheckAllPages && (
              <CheckedAllPages>
                Обрано{' '}
                {checkedList.length === pageSize &&
                JSON.stringify(checkedList) ===
                  JSON.stringify(data.map((item) => item.id))
                  ? `всі ${checkedList.length} комірок на цій сторінці.`
                  : `${checkedList.length} комірок`}
                <span onClick={handleGetAllIds}>
                  Обрати всі {count} записів.
                </span>
              </CheckedAllPages>
            )}
          </TableActionsWrapper>
        )}
      </Transition>
      <TableStyled
        isFetching={isFetching || isAllIdsFetcing || isAllIdsLoading}
      >
        <THead>
          <Row>
            {!withOutCheck && (
              <Th
                onClick={handleClickCheckbox}
                showActions={!!checkedList.length}
                showCheckAllPages={showCheckAllPages}
              >
                <CheckBox checked={isCheckedAll} setChecked={handleChackAll} />
              </Th>
            )}
            {headers.map((item) =>
              item.subFields ? (
                <Fragment key={item.id as string}>
                  <Th
                    withSublist
                    showList={expandedCols.includes(item.id)}
                    showActions={!!checkedList.length}
                    key={item.id as string}
                    onClick={() =>
                      setExpandedCols((state) =>
                        state.includes(item.id)
                          ? state.filter((el) => el !== item.id)
                          : [...state, item.id]
                      )
                    }
                    showCheckAllPages={showCheckAllPages}
                  >
                    {item.title}
                    <AngleTableSvg />
                  </Th>
                  {expandedCols.includes(item.id) &&
                    item.subFields.map((sub) => (
                      <Th
                        showCheckAllPages={showCheckAllPages}
                        key={sub.id as string}
                      >
                        {sub.title}
                      </Th>
                    ))}
                </Fragment>
              ) : (
                <Th
                  minWidth={
                    item.id === 'description' ||
                    item.id === 'categories' ||
                    item.id === 'comment'
                      ? '300px'
                      : 'unset'
                  }
                  showActions={!!checkedList.length}
                  key={item.id as string}
                  showCheckAllPages={showCheckAllPages}
                >
                  {item.title}
                </Th>
              )
            )}
          </Row>
        </THead>
        <TBody>
          {data.map((field) => (
            <Row
              onClick={() => (onRowClick ? onRowClick(field.id) : '')}
              key={field.id}
              checked={checkedList.includes(field.id)}
              canClick={!!onRowClick}
            >
              {!withOutCheck && (
                <Cell onClick={handleClickCheckbox}>
                  <CheckBox
                    checked={checkedList.includes(field.id)}
                    setChecked={(checked) => handleChecked(checked, field.id)}
                  />
                </Cell>
              )}
              {headers.map((item) =>
                item.subFields ? (
                  <Fragment key={item.id as string}>
                    <Cell
                      canBreack={!nonBreakFields.includes(item.id as string)}
                    >
                      {field[item.id] as string}
                    </Cell>
                    {expandedCols.includes(item.id) &&
                      item.subFields.map((sub) => (
                        <Cell
                          key={sub.id as string}
                          canBreack={
                            !nonBreakFields.includes(item.id as string)
                          }
                        >
                          {(field[sub.id] as string) || '-'}
                        </Cell>
                      ))}
                  </Fragment>
                ) : (
                  <Cell
                    canBreack={!nonBreakFields.includes(item.id as string)}
                    key={item.id as string}
                    isColored={coloredFields.includes(item.id as string)}
                  >
                    {field[item.id] as string}
                  </Cell>
                )
              )}
            </Row>
          ))}
        </TBody>
      </TableStyled>
      {count && count > 25 ? <Pagination count={count || 0} /> : ''}
    </TableWrapper>
  );
};
