import { useState } from 'react';
import { BtnPrimary } from '../btn-primary';
import {
  useGetBdLettersQuery,
  useGetLettersQuery
} from '../../api/send-puls-api';
import { splitByMonth } from '../../helpers/split-by-month';
import type { ILetter } from '../../models/letter';
import { LoaderSmall } from '../loader-small';
import type { LetterBd } from '../../models/letter-bd';
import { NewLetter } from '../new-letter';
import { Letter } from './letter';
import {
  LetterAdd,
  LettersByMonth,
  LettersByMonthTitle,
  LettersContainer,
  LettersStyled,
  Loader
} from './style';

interface LettersProps {
  contactId: string | number;
}

export const Letters = ({ contactId }: LettersProps) => {
  const [showNewLetter, setShowNewLetter] = useState(false);
  const { data: letters, isLoading } = useGetLettersQuery({
    contactId: contactId
  });
  const { data: dbLetters } = useGetBdLettersQuery({
    contactId: contactId
  });

  const isBdLetter = (letter: ILetter | LetterBd): letter is LetterBd =>
    'sendpulse_id' in letter;

  return (
    <LettersStyled>
      <LetterAdd>
        <BtnPrimary onClick={() => setShowNewLetter(true)}>
          Створити лист
        </BtnPrimary>
      </LetterAdd>
      {isLoading && (
        <Loader>
          <LoaderSmall />
        </Loader>
      )}
      <LettersContainer>
        {!isLoading
          ? splitByMonth<ILetter | LetterBd>(
              [...(letters || []), ...(dbLetters || [])].map((item) => ({
                ...item,
                created_at: item.send_date
              }))
            ).map((item, i) => (
              <LettersByMonth key={i}>
                <LettersByMonthTitle>{item.title}</LettersByMonthTitle>
                {item.list.map((letter, j) => {
                  if (isBdLetter(letter)) {
                    return (
                      <Letter
                        key={j}
                        body={letter.html}
                        date={letter.send_date}
                        letterName={letter.subject}
                        isDBLetter
                      />
                    );
                  } else {
                    return (
                      <Letter
                        key={j}
                        link={letter.permalink}
                        body={letter.message.body}
                        date={letter.send_date}
                        letterName={letter.name}
                      />
                    );
                  }
                })}
              </LettersByMonth>
            ))
          : ''}
      </LettersContainer>
      {showNewLetter && (
        <NewLetter
          show={showNewLetter}
          setShow={setShowNewLetter}
          contactId={contactId}
        />
      )}
    </LettersStyled>
  );
};
