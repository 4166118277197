import { useTheme } from 'styled-components';
import { Label, RangeInner, RangeStyled, RangeWrapper, Total } from './style';

interface RangeProps {
  value: number;
  total: number;
  totalTitle?: string;
  totalAlign?: 'left' | 'center' | 'right';
  label?: string;
  isHead?: boolean;
  mt?: string;
  color?: string;
}

export const Range = ({
  value,
  total,
  totalTitle,
  totalAlign,
  label,
  isHead,
  mt,
  color
}: RangeProps) => {
  const theme = useTheme();

  return (
    <RangeWrapper $isHead={isHead} $mt={mt}>
      {label && <Label>{label}</Label>}
      <RangeStyled>
        <RangeInner
          style={{
            width:
              total === 0
                ? 0
                : `${
                    (value / total) * 100 > 100 ? 100 : (value / total) * 100
                  }%`,
            background: color || theme.colors.primary
          }}
        />
      </RangeStyled>
      {!!totalTitle && <Total $textAlign={totalAlign}>{totalTitle}</Total>}
    </RangeWrapper>
  );
};
