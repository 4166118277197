import { Avatar, UserInfoStyled, UserName } from './style';

interface UserInfoProps {
  firstName: string;
  lastName: string;
  patronymic?: string;
  isBig?: boolean;
}

export const UserInfo = ({
  firstName,
  lastName,
  patronymic,
  isBig
}: UserInfoProps) => (
  <UserInfoStyled>
    <Avatar isBig={isBig}>{lastName[0] || firstName[0]}</Avatar>
    <UserName isBig={isBig}>
      {lastName ? (
        <>
          {lastName} <br></br>
        </>
      ) : (
        ''
      )}
      {firstName} {patronymic}
    </UserName>
  </UserInfoStyled>
);
