export const PlusSvg = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00195 16.5C7.79475 16.5 7.59604 16.4177 7.44953 16.2712C7.30301 16.1247 7.2207 15.926 7.2207 15.7188V1.28125C7.2207 1.07405 7.30301 0.875336 7.44953 0.728823C7.59604 0.58231 7.79475 0.5 8.00195 0.5C8.20915 0.5 8.40787 0.58231 8.55438 0.728823C8.70089 0.875336 8.7832 1.07405 8.7832 1.28125V15.7188C8.7832 15.926 8.70089 16.1247 8.55438 16.2712C8.40787 16.4177 8.20915 16.5 8.00195 16.5Z"
      fill="white"
    />
    <path
      d="M15.2188 9.28125H0.78125C0.57405 9.28125 0.375336 9.19894 0.228823 9.05243C0.08231 8.90591 0 8.7072 0 8.5C0 8.2928 0.08231 8.09409 0.228823 7.94757C0.375336 7.80106 0.57405 7.71875 0.78125 7.71875H15.2188C15.426 7.71875 15.6247 7.80106 15.7712 7.94757C15.9177 8.09409 16 8.2928 16 8.5C16 8.7072 15.9177 8.90591 15.7712 9.05243C15.6247 9.19894 15.426 9.28125 15.2188 9.28125Z"
      fill="white"
    />
  </svg>
);
