import styled from 'styled-components';
import { scrollBar } from '../../style/helpers';

export const CallHistoryStyled = styled.div`
  padding: 24px 0;
  margin-top: 20px;
  border-radius: 6px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05),
    4px 0px 20px 0px rgba(0, 0, 0, 0.05);
`;

export const Title = styled.div`
  padding: 0 24px;
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 18px;
  font-weight: 700;
`;

export const HistoryContainer = styled.div`
  padding: 0 24px;
  max-height: 280px;
  ${scrollBar}
`;

export const HistoryNoData = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Descr = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;
