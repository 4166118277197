export const ActionsMailSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_3649_1472"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <path d="M0 1.90735e-06H16V16H0V1.90735e-06Z" fill="white" />
    </mask>
    <g mask="url(#mask0_3649_1472)">
      <path
        d="M14.125 2.12499H1.875C1.18466 2.12499 0.625 2.68465 0.625 3.37499V4.60768L7.25397 9.53837C7.69678 9.86774 8.30322 9.86774 8.74603 9.53837L15.375 4.60768V3.37499C15.375 2.68465 14.8153 2.12499 14.125 2.12499Z"
        stroke="white"
        strokeWidth="1.5625"
        strokeMiterlimit="10"
      />
      <path
        d="M14.75 7.40934V12.625C14.75 12.9696 14.4696 13.25 14.125 13.25H1.875C1.53037 13.25 1.25 12.9696 1.25 12.625V7.40934L0 6.47959V12.625C0 13.6589 0.841125 14.5 1.875 14.5H14.125C15.1589 14.5 16 13.6589 16 12.625V6.47959L14.75 7.40934Z"
        fill="white"
      />
    </g>
  </svg>
);
