import { useState } from 'react';
import { dateWithTime } from '../../../helpers/date-helpers';
import { AngleSvg } from '../../svg/angle-svg';
import {
  HeaderDate,
  IframeWrapper,
  LetterHeader,
  LetterStyled,
  LetterTitle,
  TitleAngle
} from './style';

interface LetterProps {
  link?: string;
  body: string;
  date: string;
  letterName: string;
  isDBLetter?: boolean;
}

export const Letter = ({
  link,
  body,
  date,
  letterName,
  isDBLetter
}: LetterProps) => {
  const [expand, setExpand] = useState(false);

  return (
    <LetterStyled>
      <LetterHeader>
        <LetterTitle onClick={() => setExpand((state) => !state)}>
          <TitleAngle isOpen={expand}>
            <AngleSvg />
          </TitleAngle>
          <span>{letterName || 'Лист'}</span>
        </LetterTitle>
        <HeaderDate>{dateWithTime(date)}</HeaderDate>
      </LetterHeader>
      {expand ? (
        isDBLetter ? (
          <IframeWrapper dangerouslySetInnerHTML={{ __html: atob(body) }} />
        ) : (
          <IframeWrapper>
            <iframe title={letterName} src={link} srcDoc={body}></iframe>
          </IframeWrapper>
        )
      ) : (
        ''
      )}
    </LetterStyled>
  );
};
