import { useState } from 'react';
import { useTheme } from 'styled-components';
import { ChartsLayout } from '../components/charts-layout';
import { ChartContainer } from '../components/chart-container';
import { useGetAudienceQuery } from '../../../api/dashboard-api';
import { useTypedSelector } from '../../../api/store';
import { DoubleChart } from '../components/double-chart';
import { Range } from '../components/range';
import { DoughnutChart } from '../components/doughnut-chart';
import { Loader } from '../../../components/loader';
import { groupByRegion } from '../helpers/group-by-region';

export const DashboardChildrenPage = () => {
  const theme = useTheme();

  const [activeCity, setActiveCity] = useState({ id: '', name: '' });
  const [activeRegion, setActiveRegion] = useState({ id: '', name: '' });
  const { date, colors } = useTypedSelector((state) => state.dashboard);

  const { data, isFetching } = useGetAudienceQuery(
    {
      params: `?children_dashboard=true&liv_city=${activeCity.name}&liv_region=${activeRegion.name}&created_start=${date.start}&created_end=${date.end}`
    },
    { refetchOnMountOrArgChange: true }
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <ChartsLayout
      activeCity={activeCity}
      activeRegion={activeRegion}
      setActiveCity={setActiveCity}
      setActiveRegion={setActiveRegion}
      total={data.data.children}
      mt="0"
      isFetching={isFetching}
      dataByRegion={groupByRegion(data.data.children_map)}
    >
      <ChartContainer title="Загальна кількість дітей">
        <Range
          total={data.data.children}
          totalAlign="center"
          totalTitle={`${data.data.children}`}
          value={data.data.children}
          color={theme.colors.primary}
        />
      </ChartContainer>
      <ChartContainer title="Вік та стать дітей">
        <DoubleChart
          legends={[
            { name: 'Хлопчики', value: data.data.boys_count },
            { name: 'Дівчата', value: data.data.girls_count }
          ]}
          colors={['#306FE3', theme.colors.primary]}
          data={data.data.kids_age_and_gender.map((item) => ({
            name: item.age_gradation,
            firstValue: item.men,
            secondValue: item.women
          }))}
        />
      </ChartContainer>
      <DoughnutChart
        revrse
        title={'З яких проєктів їх батьки прийшли'}
        data={data.data.children_by_parents_projects.map((item) => ({
          name: item.name,
          value: item.count
        }))}
        colors={colors.map((item) => item.color)}
        totalValue={data.data.children_in_projects_all}
        totalLabel="загальна кількість"
      />
    </ChartsLayout>
  );
};
