import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { CloseSvg } from '../svg/close-svg';
import { useOutsideClick } from '../../hooks/use-outside-click';
import {
  PopupClose,
  PopupContent,
  PopupHeader,
  PopupStyled,
  PopupTitle
} from './style';
import { defaultStyle, duration, transitionStyles } from './transition';

interface PopopProps {
  children: ReactNode;
  title?: string;
  setShow: Dispatch<SetStateAction<boolean>>;
  maxContentWidth?: string;
  show: boolean;
  customPosition?: boolean;
  outsideOnlyEscape?: boolean;
}

export const Popup = ({
  children,
  title,
  setShow,
  maxContentWidth,
  show,
  customPosition,
  outsideOnlyEscape
}: PopopProps) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => setShow(false), { onlyEscape: outsideOnlyEscape });

  return (
    <Transition in={show} timeout={duration} unmountOnExit mountOnEnter>
      {(state) => (
        <PopupStyled
          customPosition={customPosition}
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          <PopupContent maxContentWidth={maxContentWidth} ref={ref}>
            {title && (
              <PopupHeader>
                <PopupTitle>{title}</PopupTitle>
                <PopupClose onClick={() => setShow(false)}>
                  <CloseSvg />
                </PopupClose>
              </PopupHeader>
            )}
            {children}
          </PopupContent>
        </PopupStyled>
      )}
    </Transition>
  );
};
