import { ChartContainer } from '../chart-container';
import { Range } from '../range';

interface CategoriesChartProps {
  categoriesCount: {
    id: number;
    [key: string]: number;
  }[];
  withAnyCategory: number;
  contactsCount: number;
}

export const CategoriesChart = ({
  categoriesCount,
  withAnyCategory,
  contactsCount
}: CategoriesChartProps) => (
  <ChartContainer title="Категорія пільг">
    <Range
      isHead
      label="Загальна кількість людей з пільгами"
      total={withAnyCategory}
      value={withAnyCategory}
      totalTitle={`${withAnyCategory}`}
    />
    {categoriesCount
      .filter(
        (item) =>
          Object.keys(item).length === 2 &&
          (Object.values(item)[1] ||
            (Object.values(item)[0] === 1 &&
              contactsCount - withAnyCategory > 0))
      )
      .map((item) => {
        const [_, [name, value]] = Object.entries(item);

        return (
          <Range
            key={item.id}
            total={withAnyCategory}
            label={name}
            value={item.id === 1 ? contactsCount - withAnyCategory : value}
            totalTitle={`${
              item.id === 1 ? contactsCount - withAnyCategory : value
            }`}
            mt="20px"
          />
        );
      })}
  </ChartContainer>
);
