import { NoWrap } from '../../components/no-wrap';
import { StringCutter } from '../../components/string-cutter';
import { dateTableFormat } from '../../helpers/date-helpers';
import type { IEvent } from '../../models/event';
import type { EventsTable } from './types';

export const transformEventsData = (data: IEvent[]): EventsTable[] =>
  data.map(
    ({
      managers,
      project_chief_manager,
      id,
      description,
      start_date,
      end_date,
      budget,
      region,
      format,
      contacts_count,
      name,
      meta_tag1,
      meta_tag2,
      meta_tag3,
      ...rest
    }) => ({
      ...rest,
      id: id as number,
      start_date: dateTableFormat(start_date || '') as string,
      end_date: dateTableFormat(end_date || '') as string,
      description: <StringCutter lines="2">{description}</StringCutter>,
      project_managers: (
        <>
          {managers.map((manager) => (
            <NoWrap key={manager.id}>
              {`${manager?.last_name || ''} ${manager?.first_name || ''} ${
                manager?.patronymic || ''
              }`}
            </NoWrap>
          ))}
        </>
      ),
      project_chief_manager: <NoWrap>{project_chief_manager.full_name}</NoWrap>,
      budget: `${budget || 0}`,
      region,
      contacts_count,
      format: format.title,
      name: name?.length < 30 ? <NoWrap>{name}</NoWrap> : name,
      meta_tag: `${meta_tag1} ${meta_tag2} ${meta_tag3}`
    })
  );
