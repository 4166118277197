import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Transition } from 'react-transition-group';
import { CloseEditSvg } from '../../../../components/svg/close-edit-svg';
import { UserInfo } from '../../../../components/user-info';
import { useChangeFields } from '../../../../hooks/use-change-fields';
import type { OptionItem } from '../../../../common/types/option-item';
import { getApiError } from '../../../../helpers/get-api-error';
import { useNotification } from '../../../../hooks/use-notification';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from '../transition';
import { useEditPartnerMutation } from '../../../../api/partner-api';
import type { Partner } from '../../../../models/partner';
import { Input } from '../../../../components/input';
import { Select } from '../../../../components/select';
import { ORG_TYPE, REGIONS } from '../../../../common/constants';
import { FormBtns } from '../../../../components/form-btns';
import {
  Close,
  EditHeader,
  EditHeaderRight,
  EditPartnerData,
  EditPartnerStyled,
  FormStyled,
  Group,
  GroupTitle,
  InputsContainer,
  PartnerId
} from './style';

interface EditUserProps {
  setEdit: Dispatch<SetStateAction<boolean>>;
  partner: Partner;
  edit?: boolean;
}

interface TextFields {
  name: string;
  edrpou: string;
  email: string;
  phone: string;
  contact_person: string;
  location: string;
  activity_field: string;
  employees_number: number;
  comment: string;
}

export const EditPartner = ({ setEdit, partner, edit }: EditUserProps) => {
  const notification = useNotification();
  const [editPartner, { isLoading }] = useEditPartnerMutation();
  const { fields, handleFieldsChange, updateFields } =
    useChangeFields<TextFields>({
      name: partner.name,
      edrpou: partner.edrpou,
      email: partner.email,
      phone: partner.phone,
      contact_person: partner.contact_person,
      location: partner.location,
      activity_field: partner.activity_field,
      employees_number: partner.employees_number,
      comment: partner.comment
    });

  const [orgType, setOrgType] = useState<OptionItem | null>(partner.org_type);
  const [region, setRegion] = useState<OptionItem | null>(partner.region);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const enteredDataWithRegion = region?.id
      ? { ...fields, region: region.id }
      : fields;
    const enteredData = orgType?.id
      ? { ...enteredDataWithRegion, org_type: orgType.id }
      : enteredDataWithRegion;

    editPartner({ id: partner.id, body: { ...enteredData } })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          message: 'Ви успішно відредагували партнра',
          title: 'Партнера відредаговано'
        });
        setEdit(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleCancel = () => {
    setEdit(false);
  };

  useEffect(() => {
    updateFields({
      name: partner.name,
      edrpou: partner.edrpou,
      email: partner.email,
      phone: partner.phone,
      contact_person: partner.contact_person,
      location: partner.location,
      activity_field: partner.activity_field,
      employees_number: partner.employees_number,
      comment: partner.comment
    });
    setOrgType(partner.org_type);
    setRegion(partner.region);
  }, [partner, updateFields, edit]);

  return (
    <Transition
      in={edit && !!partner}
      mountOnEnter
      unmountOnExit
      timeout={duration}
    >
      {(state) => (
        <EditPartnerStyled
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          <div
            style={{
              ...defaultContentStyle,
              ...transitionContentStyles[state],
              height: '100%'
            }}
          >
            <EditPartnerData>
              <Close onClick={() => setEdit(false)}>
                <CloseEditSvg />
              </Close>
              <EditHeader>
                <UserInfo
                  isBig
                  lastName={partner.name}
                  firstName=""
                  patronymic=""
                />
                <EditHeaderRight>
                  <PartnerId>ID Партнера: {partner.id}</PartnerId>
                </EditHeaderRight>
              </EditHeader>
              <FormStyled onSubmit={handleSubmit}>
                <div>
                  <GroupTitle>Дані організації</GroupTitle>
                  <InputsContainer>
                    <Input
                      label="Назва юридичної особи *"
                      value={fields.name}
                      name="name"
                      onChange={handleFieldsChange}
                      required
                    />
                    <Input
                      label="Напрям діяльності"
                      value={fields.activity_field}
                      name="activity_field"
                      onChange={handleFieldsChange}
                    />
                    <Input
                      label="Код ЄДРПО"
                      value={fields.edrpou}
                      name="edrpou"
                      onChange={handleFieldsChange}
                      mask="99999999"
                      maskChar=""
                    />
                    <Input
                      label="Кількість співробітників"
                      value={fields.employees_number?.toString() || ''}
                      name="employees_number"
                      onChange={handleFieldsChange}
                      mask="99999999"
                      maskChar=""
                    />
                    <Select
                      label="Тип організації *"
                      value={orgType || { id: '', title: '' }}
                      setValue={setOrgType}
                      options={ORG_TYPE}
                    />
                    <Input
                      label="Коментар"
                      value={fields.comment}
                      name="comment"
                      onChange={handleFieldsChange}
                    />
                  </InputsContainer>
                </div>
                <Group>
                  <GroupTitle>Контактні дані</GroupTitle>
                  <InputsContainer>
                    <Input
                      label="Місто"
                      value={fields.location}
                      name="location"
                      onChange={handleFieldsChange}
                    />
                    <Select
                      options={REGIONS.filter((_, i) => i).map((item, i) => ({
                        id: i + 1,
                        title: item
                      }))}
                      value={region || { id: '', title: '' }}
                      setValue={setRegion}
                      label="Область *"
                    />
                    <Input
                      label="Контактна особа *"
                      value={fields.contact_person}
                      name="contact_person"
                      onChange={handleFieldsChange}
                      required
                    />
                    <Input
                      label="Телефон *"
                      value={fields.phone}
                      onChange={handleFieldsChange}
                      name="phone"
                      required
                      mask="+38 999 999 99 99"
                      type="tel"
                    />
                    <Input
                      label="Email *"
                      value={fields.email}
                      name="email"
                      onChange={handleFieldsChange}
                      required
                      type="email"
                    />
                  </InputsContainer>
                </Group>
                <FormBtns onCancel={handleCancel} disabled={isLoading} />
              </FormStyled>
            </EditPartnerData>
          </div>
        </EditPartnerStyled>
      )}
    </Transition>
  );
};
