// import { PlusViewSvg } from '../svg/plus-view-svg';
import { Link } from 'react-router-dom';
import { HistoryItem } from '../history-item';
import { ActionsNoteSvg } from '../svg/actions/actions-note-svg';
import { Header, Title, ViewProjectsStyled, Descr } from './style';

interface ViewProjectsProps {
  title: string;
  items?: { title: string; link: string }[];
}

export const ViewProjects = ({ title, items }: ViewProjectsProps) => (
  <ViewProjectsStyled>
    <Header>
      <Title>{title}</Title>
    </Header>
    {items?.length ? (
      items.map((item, i) => (
        <HistoryItem
          key={i}
          title={
            <span>
              <Link to={item.link}>{item.title}</Link>
            </span>
          }
          icon={ActionsNoteSvg}
        />
      ))
    ) : (
      <Descr>Обєкти даного типу відсуні.</Descr>
    )}
  </ViewProjectsStyled>
);
