import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Transition } from 'react-transition-group';
import { CloseEditSvg } from '../../../../components/svg/close-edit-svg';
import { useChangeFields } from '../../../../hooks/use-change-fields';
import { getApiError } from '../../../../helpers/get-api-error';
import { useNotification } from '../../../../hooks/use-notification';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from '../transition';
import type { CommonContact, Contact } from '../../../../models/contact';
import {
  useEditContactMutation,
  useGetContactActivitiesQuery,
  useGetContactCategoriesQuery,
  useGetContactChannelsQuery,
  useGetContactTagsQuery
} from '../../../../api/contact-api';
import { ContactForm } from '../../contact-form';
import type { OptionItem } from '../../../../common/types/option-item';
import type { ServerFile } from '../../../../common/types/server-file';
import { dateToUIFormat } from '../../../../helpers/date-helpers';
import type { Project } from '../../../../models/project';
import { useGetProjectsQuery } from '../../../../api/projects-api';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { CheckBox } from '../../../../components/checkbox';
import {
  ContactSatisfaction,
  ContactSatisfactionHeader
} from '../current-contact-sidebar/style';
import { useOutsideClick } from '../../../../hooks/use-outside-click';
import { AngleSvg } from '../../../../components/svg/angle-svg';
import {
  OptionsScroolWrapper,
  SelectOptionItem,
  SelectOptions
} from '../../../../components/select/style';
import { SATISFACTION } from '../../../../common/constants';
import { isFieldAllowed } from '../../../../helpers/is-field-allowed';
import { checkIsManager } from '../../../../helpers/is-manager';
import { checkIsPhone } from '../../../../helpers/check-is-phone';
import {
  Close,
  ContactId,
  EditContactData,
  EditContactStyled,
  EditHeader,
  FormWrapper,
  HeaderRow,
  Title
} from './style';

interface EditContactProps {
  setEdit: Dispatch<SetStateAction<boolean>>;
  contact: Contact;
  edit?: boolean;
}

export const EditContact = ({ setEdit, contact, edit }: EditContactProps) => {
  const satisfactionRef = useRef(null);
  const { data: userMe } = useGetUserMeQuery('');
  const notification = useNotification();
  const [passportFiles, setPassportFiles] = useState<File[]>([]);
  const [innFiles, setInnFiles] = useState<File[]>([]);
  const [serverPassportFiles, setServerPassportFiles] = useState<ServerFile[]>(
    contact.passport_files || []
  );
  const [serverInnFiles, setServerInnFiles] = useState<ServerFile[]>(
    contact.inn_files || []
  );
  const [editContact, { isLoading }] = useEditContactMutation();
  const { fields, handleFieldsChange, updateFields } =
    useChangeFields<CommonContact>({
      last_name: contact.last_name,
      first_name: contact.first_name,
      patronymic: contact.patronymic,
      birth_date: contact.birth_date?.split('-').reverse().join('/') || '',
      phone: contact.phone,
      email: contact.email,
      passport: contact.passport,
      id_number: contact.id_number,
      liv_country: contact.liv_country,
      liv_region: contact.liv_region,
      liv_city: contact.liv_city,
      liv_district: contact.liv_district,
      liv_street: contact.liv_street,
      liv_building: contact.liv_building,
      liv_unit: contact.liv_unit,
      liv_apartment: contact.liv_apartment,
      reg_country: contact.reg_country,
      reg_region: contact.reg_region,
      reg_city: contact.reg_city,
      reg_district: contact.reg_district,
      reg_street: contact.reg_street,
      reg_building: contact.reg_building,
      reg_unit: contact.reg_unit,
      reg_apartment: contact.reg_apartment,
      children_num: contact.children_num,
      utm_source: '',
      utm_campaign: '',
      utm_content: '',
      utm_medium: '',
      utm_term: '',
      age: 0,
      comment: ''
    });
  const [showSatisfactionOption, setShowSatisfactionOption] = useState(false);
  useOutsideClick(satisfactionRef, () => setShowSatisfactionOption(false));

  const [gender, setGender] = useState<OptionItem | null>(contact.gender);
  const [familyStatus, setFamilyStatus] = useState<OptionItem | null>(
    contact.family_status
  );
  const [education, setEducation] = useState<OptionItem | null>(
    contact.education
  );
  const [qualification, setQualification] = useState<OptionItem | null>(
    contact.qualification
  );
  const [status, setStatus] = useState<OptionItem | null>(contact.status);
  const [employment, setEmployment] = useState<OptionItem | null>(
    contact.employment
  );
  const [respManagers, setRespManagers] = useState<OptionItem[] | null>(
    !contact.resp_managers?.length
      ? [{ id: '', title: '' }]
      : contact.resp_managers.map((item) => ({
          id: item.id,
          title: `${item.last_name || ''} ${item.first_name || ''}`
        }))
  );
  const [childrenCount, setChildrenCount] = useState<OptionItem | null>({
    id: contact.children_num || 0,
    title: `${contact.children_num || 0}`
  });
  const [childrenList, setChildrenList] = useState<
    { age: number; gender: OptionItem; birth_date: string | null }[] | null
  >(
    contact.children?.map((item) => ({
      ...item,
      birth_date: item.birth_date?.split('-').reverse().join('/') || null
    })) || null
  );
  const [additionalPhones, setAdditionalPhones] = useState(
    contact.additional_phones.map((item) => ({ phone: item.phone }))
  );
  const [termSpouse, setTermSpouse] = useState('');
  const [spouse, setSpouse] = useState<OptionItem>({
    id: contact.spouse?.id || '',
    title: `${contact.spouse?.last_name || ''} ${
      contact.spouse?.first_name || ''
    } ${contact.spouse?.patronymic || ''}`
  });
  const { data: allChannels } = useGetContactChannelsQuery('');
  const { data: allTags } = useGetContactTagsQuery('');
  const { data: allCategories } = useGetContactCategoriesQuery('');
  const [channels, setChannels] = useState<OptionItem[]>([]);
  const [tags, setTags] = useState<OptionItem[]>([]);
  const [categories, setCategories] = useState<OptionItem[]>([]);
  const [projects, setProjects] = useState<OptionItem<Project>[]>([]);

  const isManager = checkIsManager(userMe);
  const canViewProject = userMe?.role?.view_project || userMe?.is_superuser;
  const canViewEvent = userMe?.role?.view_event || userMe?.is_superuser;
  const canViewUser =
    (userMe?.role?.view_user || userMe?.is_superuser) && !isManager;

  const [events, setEvents] = useState<OptionItem[]>(
    contact.events.map((item) => ({
      id: item.id,
      title: item.name,
      checked: true
    }))
  );

  const { data: allProjects } = useGetProjectsQuery(
    { params: '?size=100' },
    {
      skip: !canViewProject
    }
  );

  const { data: activities } = useGetContactActivitiesQuery({ id: contact.id });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const fieldsHasBeenChanged = Object.entries(fields).filter(
      ([key, value]) => contact[key as keyof CommonContact] !== value
    );
    const formData = new FormData();
    fieldsHasBeenChanged.forEach(([key, value]) => {
      if (key === 'birth_date') {
        if (isFieldAllowed('birth_date', userMe)) {
          formData.append(key, value.split('/').reverse().join('-'));
        }

        return;
      }
      formData.append(key, value);
    });
    if (isFieldAllowed('passport_files', userMe)) {
      if (serverPassportFiles.length) {
        formData.append(
          'passport_save_ids',
          JSON.stringify(serverPassportFiles.map((item) => item.id))
        );
      } else {
        formData.append('passport_save_ids', JSON.stringify([]));
      }
      passportFiles.forEach((item) => {
        formData.append('passport_files_add', item);
      });
    }
    if (isFieldAllowed('inn_files', userMe)) {
      if (serverInnFiles.length) {
        formData.append(
          'inn_save_ids',
          JSON.stringify(serverInnFiles.map((item) => item.id))
        );
      } else {
        formData.append('inn_save_ids', JSON.stringify([]));
      }
      innFiles.forEach((item) => {
        formData.append('inn_files_add', item);
      });
    }

    if (canViewUser) {
      formData.append(
        'resp_managers',
        JSON.stringify(
          respManagers?.map((item) => item.id).filter((item) => item) || null
        )
      );
    }
    if (gender) {
      formData.append('gender', `${gender.id}`);
    }
    if (familyStatus) {
      formData.append('family_status', `${familyStatus.id}`);
    }
    if (education) {
      formData.append('education', `${education.id}`);
    }
    if (qualification) {
      formData.append('qualification', `${qualification.id}`);
    }
    if (status) {
      formData.append('status', `${status.id}`);
    }
    if (employment) {
      formData.append('employment', `${employment.id}`);
    }
    if (childrenCount && isFieldAllowed('children_num', userMe)) {
      formData.append('children_num', `${childrenCount?.title || 0}`);
    }
    if (childrenList?.length) {
      formData.append(
        'children',
        JSON.stringify(
          childrenList.map((item) => ({
            gender: item.gender.id,
            birth_date: item.birth_date?.split('/').reverse().join('-') || null
          }))
        )
      );
    }
    if (isFieldAllowed('spouse', userMe)) {
      if (spouse.id) {
        formData.append('spouse', `${spouse.id}`);
      } else {
        formData.append('spouse', '');
      }
    }
    if (isFieldAllowed('channels', userMe)) {
      if (channels.filter((item) => item.checked).length) {
        formData.append(
          'channels',
          JSON.stringify(
            channels.filter((item) => item.checked).map((item) => item.id)
          )
        );
      } else {
        formData.append('channels', JSON.stringify([]));
      }
    }

    if (tags) {
      if (tags.filter((item) => item.checked).length) {
        formData.append(
          'tags',
          JSON.stringify(
            tags.filter((item) => item.checked).map((item) => item.id)
          )
        );
      } else {
        formData.append('tags', JSON.stringify([]));
      }
    }

    if (categories) {
      if (categories.filter((item) => item.checked).length) {
        formData.append(
          'categories',
          JSON.stringify(
            categories.filter((item) => item.checked).map((item) => item.id)
          )
        );
      } else {
        formData.append('categories', JSON.stringify([]));
      }
    }

    if (canViewProject) {
      formData.append(
        'projects',
        JSON.stringify(
          projects.filter((item) => item.checked).map((item) => item.id)
        )
      );
    }

    if (canViewProject && canViewEvent && events) {
      formData.append(
        'events',
        JSON.stringify(
          events.filter((item) => item.checked).map((item) => item.id)
        )
      );
    }

    const phones = additionalPhones.filter((item) => checkIsPhone(item.phone));
    if (phones.length) {
      formData.append('additional_phones', JSON.stringify(phones));
    } else {
      formData.append('additional_phones', JSON.stringify([]));
    }

    editContact({ id: contact.id, body: formData })
      .unwrap()
      .then(() => {
        setEdit(false);
        notification({
          type: 'success',
          title: 'Контакт відредаговано!',
          message: 'Ви успішно відредагували контакт.'
        });
      })
      .catch((err) => {
        console.log(err);
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  useEffect(() => {
    updateFields({
      last_name: contact.last_name,
      first_name: contact.first_name,
      patronymic: contact.patronymic,
      birth_date: contact.birth_date?.split('-').reverse().join('/') || '',
      phone: contact.phone,
      email: contact.email,
      passport: contact.passport,
      id_number: contact.id_number,
      liv_country: contact.liv_country,
      liv_region: contact.liv_region,
      liv_city: contact.liv_city,
      liv_district: contact.liv_district,
      liv_street: contact.liv_street,
      liv_building: contact.liv_building,
      liv_unit: contact.liv_unit,
      liv_apartment: contact.liv_apartment,
      reg_country: contact.reg_country,
      reg_region: contact.reg_region,
      reg_city: contact.reg_city,
      reg_district: contact.reg_district,
      reg_street: contact.reg_street,
      reg_building: contact.reg_building,
      reg_unit: contact.reg_unit,
      reg_apartment: contact.reg_apartment,
      children_num: contact.children_num,
      utm_source: contact.utm_source,
      utm_campaign: contact.utm_campaign,
      utm_content: contact.utm_content,
      utm_medium: contact.utm_medium,
      utm_term: contact.utm_term,
      age: contact.age,
      comment: contact.comment
    });
    setPassportFiles([]);
    setInnFiles([]);
    setServerPassportFiles(contact.passport_files || []);
    setServerInnFiles(contact.inn_files || []);
    setGender(contact.gender);
    setFamilyStatus(contact.family_status);
    setEducation(contact.education);
    setQualification(contact.qualification);
    setStatus(contact.status);
    setEmployment(contact.employment);
    setRespManagers(
      !contact.resp_managers?.length
        ? [{ id: '', title: '' }]
        : contact.resp_managers.map((item) => ({
            id: item.id,
            title: `${item.last_name || ''} ${item.first_name || ''}`
          }))
    );
    setChildrenCount({
      id: contact.children_num || 0,
      title: `${contact.children_num || 0}`
    });
    setChildrenList(
      contact.children?.map((item) => ({
        ...item,
        birth_date: item.birth_date?.split('-').reverse().join('/') || null
      })) || []
    );
    setSpouse({
      id: contact.spouse?.id || '',
      title: `${contact.spouse?.last_name || ''} ${
        contact.spouse?.first_name || ''
      } ${contact.spouse?.patronymic || ''}`
    });
    setAdditionalPhones(
      contact.additional_phones.map((item) => ({ phone: item.phone }))
    );
  }, [contact, updateFields, edit]);

  useEffect(() => {
    if (allChannels && contact.channels !== null) {
      setChannels(
        allChannels.results.map((item) =>
          contact.channels?.some(
            (contactChannel) => contactChannel.id === item.id
          )
            ? { id: item.id, title: item.name, checked: true }
            : { id: item.id, title: item.name, checked: false }
        )
      );
    }
  }, [allChannels, contact.channels]);

  useEffect(() => {
    if (allTags && contact.tags !== null) {
      setTags(
        allTags.results.map((item) =>
          contact.tags?.some((contactTag) => contactTag.id === item.id)
            ? { id: item.id, title: item.name, checked: true }
            : { id: item.id, title: item.name, checked: false }
        )
      );
    }
  }, [allTags, contact.tags]);

  useEffect(() => {
    if (allCategories && contact.categories !== null) {
      setCategories(
        allCategories.results
          .filter((item) => item.is_active)
          .map((item) =>
            contact.categories?.some(
              (contactCategory) => contactCategory.id === item.id
            )
              ? { id: item.id, title: item.name, checked: true }
              : { id: item.id, title: item.name, checked: false }
          )
      );
    }
  }, [allCategories, contact.categories]);

  useEffect(() => {
    if (allProjects && contact.projects) {
      setProjects(() =>
        allProjects.results.map((item) =>
          contact.projects?.some(
            (contactProject) => contactProject.id === item.id
          )
            ? { id: item.id, title: item.name, checked: true, restFields: item }
            : {
                id: item.id,
                title: item.name,
                checked: false,
                restFields: item
              }
        )
      );
    }
  }, [allProjects, contact.projects]);

  const handleSatisfaction = (item: OptionItem) => {
    editContact({ id: contact?.id || '', body: { satisfaction: item.id } })
      .unwrap()
      .then(() => setShowSatisfactionOption(false))
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleChangeVolunteer = (checked: boolean) => {
    editContact({ id: contact?.id || '', body: { is_volunteer: checked } });
  };

  if (!userMe) {
    return null;
  }

  return (
    <Transition
      in={edit && !!contact}
      mountOnEnter
      unmountOnExit
      timeout={duration}
    >
      {(state) => (
        <EditContactStyled
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          <div
            style={{
              ...defaultContentStyle,
              ...transitionContentStyles[state],
              height: '100%'
            }}
          >
            <EditContactData>
              <Close onClick={() => setEdit(false)}>
                <CloseEditSvg />
              </Close>
              <EditHeader>
                <HeaderRow>
                  <Title>{contact.full_name}</Title>
                  <ContactId>ID Контакту: {contact.id}</ContactId>
                </HeaderRow>
                <HeaderRow>
                  {contact.satisfaction && (
                    <ContactSatisfaction ref={satisfactionRef}>
                      <ContactSatisfactionHeader
                        onClick={() =>
                          setShowSatisfactionOption((state) => !state)
                        }
                        isOpen={showSatisfactionOption}
                      >
                        {contact.satisfaction?.title}
                        <AngleSvg />
                      </ContactSatisfactionHeader>
                      {showSatisfactionOption && (
                        <SelectOptions customWidth="150px">
                          <OptionsScroolWrapper>
                            {SATISFACTION.map((item) => (
                              <SelectOptionItem
                                onClick={() => handleSatisfaction(item)}
                                key={item.id}
                              >
                                {item.title}
                              </SelectOptionItem>
                            ))}
                          </OptionsScroolWrapper>
                        </SelectOptions>
                      )}
                    </ContactSatisfaction>
                  )}
                  <CheckBox
                    fw="600"
                    fz="16px"
                    disabled={isLoading}
                    checked={contact.is_volunteer}
                    label="Волонтер"
                    setChecked={handleChangeVolunteer}
                  />
                </HeaderRow>
              </EditHeader>
              <FormWrapper>
                <ContactForm
                  fields={fields}
                  handleFieldsChange={handleFieldsChange}
                  gender={gender}
                  setGender={setGender}
                  familyStatus={familyStatus}
                  setFamilyStatus={setFamilyStatus}
                  education={education}
                  setEducation={setEducation}
                  employment={employment}
                  setEmployment={setEmployment}
                  childrenCount={childrenCount}
                  setChildrenCount={setChildrenCount}
                  childrenList={childrenList}
                  setChildrenList={setChildrenList}
                  isLoading={isLoading}
                  setShow={setEdit}
                  onSubmit={handleSubmit}
                  qualification={qualification}
                  setQualification={setQualification}
                  status={status}
                  setStatus={setStatus}
                  passportFiles={passportFiles}
                  setPassportFiles={setPassportFiles}
                  innFiles={innFiles}
                  setInnFiles={setInnFiles}
                  serverPassportFiles={serverPassportFiles}
                  setServerPassportFiles={setServerPassportFiles}
                  serverInnFiles={serverInnFiles}
                  setServerInnFiles={setServerInnFiles}
                  respManagers={respManagers}
                  setRespManagers={setRespManagers}
                  termSpouse={termSpouse}
                  setTermSpouse={setTermSpouse}
                  spouse={spouse}
                  setSpouse={setSpouse}
                  channels={channels}
                  setChannels={setChannels}
                  isEdit
                  activitiesCount={activities?.activities.length || 0}
                  createdAt={dateToUIFormat(
                    new Date(contact?.created_at || '')
                  )}
                  projects={projects}
                  setProjects={setProjects}
                  events={events}
                  setEvents={setEvents}
                  isManager={isManager}
                  discharged_user={contact.discharged_user}
                  rate_discharged={contact.rate_discharged}
                  updateFields={updateFields}
                  allowedFields={contact.allowed_fields}
                  tags={tags}
                  setTags={setTags}
                  categories={categories}
                  setCategories={setCategories}
                  additionalPhones={additionalPhones}
                  setAdditionalPhones={setAdditionalPhones}
                />
              </FormWrapper>
            </EditContactData>
          </div>
        </EditContactStyled>
      )}
    </Transition>
  );
};
