import { REGIONS } from '../../../common/constants';

export const groupByRegion = (
  data: { liv_region?: string; reg_region?: string; count: number }[]
) => {
  const transformedData = data.map((item) => ({
    region: item.liv_region || item.reg_region || ' ',
    count: item.count
  }));
  const groupedData: { region: string; count: number }[] = [];

  transformedData.forEach((item) => {
    const region = REGIONS.find(
      (el) => item.region.toLowerCase().indexOf(el.toLowerCase()) > -1
    );
    if (region) {
      const idx = groupedData.findIndex((el) => el.region === region);
      if (idx > -1) {
        groupedData[idx] = {
          region,
          count: groupedData[idx].count + item.count
        };
      } else {
        groupedData.push({ region, count: item.count });
      }
    }
  });

  return groupedData.sort((a, b) => a.count - b.count);
};
