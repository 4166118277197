import styled from 'styled-components';

export const DropFileStyled = styled.div`
  position: relative;
  margin-top: 20px;
  padding: 28px;
  border: 1px dashed ${(p) => p.theme.colors.grey.input};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 190.35px;
`;

export const Text = styled.div`
  margin-top: 14px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${(p) => p.theme.colors.grey.light};
`;

export const FileInputLink = styled.label`
  input {
    display: none;
  }
  cursor: pointer;
  color: ${(p) => p.theme.colors.blue.primary};
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  color: ${(p) => p.theme.colors.blue.dark};
`;
