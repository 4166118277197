import styled from 'styled-components';

export const RangeWrapper = styled.div<{ $isHead?: boolean; $mt?: string }>`
  margin-top: ${(p) => p.$mt || 0};
  ${(p) =>
    p.$isHead
      ? `
    padding-bottom: 30px;
    border-bottom: 1px solid ${p.theme.colors.border};
  `
      : ''}
`;

export const RangeStyled = styled.div`
  position: relative;
  height: 20px;
  border-radius: 50px;
  overflow: hidden;
  background: #f8f7f7;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    height: 18px;
  }
`;

export const Label = styled.div`
  margin-bottom: 14px;
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    font-size: 16px;
  }
`;

export const RangeInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0;
  transition: width 0.2s;
  background: ${(p) => p.theme.colors.primary};
  border-radius: 50px;
`;

export const Total = styled.div<{ $textAlign?: 'left' | 'center' | 'right' }>`
  margin-top: 14px;
  color: ${(p) => p.theme.colors.grey.light};
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  text-align: ${(p) => p.$textAlign || 'right'};
`;
