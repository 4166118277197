import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const IconContainerStyled = styled.div<{
  m?: string;
  mXl?: string;
  mXxl?: string;
  isActive?: boolean;
}>`
  margin: ${(p) => p.m || 0};
  width: 16px;
  min-width: 16px;
  height: 16px;
  ${centerFlex}
  svg {
    display: block;
    max-width: 100%;
  }
  ${(p) => (p.isActive ? 'cursor: pointer;' : '')}

  @media(max-width: ${(p) => p.theme.breakpoints.xxl}) {
    margin: ${(p) => p.mXxl || p.m || 0};
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    margin: ${(p) => p.mXl || p.m || 0};
  }
`;
