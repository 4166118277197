import styled from 'styled-components';

export const RangeHorisontalStyled = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px 12px;
`;

export const Label = styled.div`
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
  white-space: nowrap;
  margin-top: -3px;
`;
