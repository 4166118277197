import React, { useMemo, type Dispatch, type SetStateAction } from 'react';
import { useTheme } from 'styled-components';
import chroma from 'chroma-js';
import { paths } from './data';
import { MapPath } from './map-path';
import {
  MapCount,
  MapFooter,
  MapHeader,
  MapStyled,
  MapWrapper,
  Title,
  Total
} from './style';

interface UkraineMapProps {
  activeCity: { id: string; name: string };
  setActiveCity: Dispatch<SetStateAction<{ id: string; name: string }>>;
  activeRegion: { id: string; name: string };
  setActiveRegion: Dispatch<SetStateAction<{ id: string; name: string }>>;
  total: number;
  onlyCityClickable?: boolean;
  dataByRegion?: { region: string; count: number }[];
}

export const UkraineMap = ({
  activeCity,
  activeRegion,
  setActiveCity,
  setActiveRegion,
  total,
  onlyCityClickable,
  dataByRegion = []
}: UkraineMapProps) => {
  const theme = useTheme();

  const handleChangeCity = (name?: string, id?: string) => {
    if (activeRegion.id && id !== activeRegion.id) {
      setActiveRegion({ id: '', name: '' });
    }
    if (name === activeCity.name) {
      setActiveCity({ id: '', name: '' });
    } else {
      setActiveCity({ id: id || '', name: name || '' });
    }
  };

  const handleChangeRegion = (name?: string, id?: string) => {
    if (activeCity.id && id !== activeCity.id) {
      setActiveCity({ id: '', name: '' });
    }

    if (name === activeRegion.name) {
      setActiveRegion({ id: '', name: '' });
      setActiveCity({ id: '', name: '' });
    } else {
      if (!onlyCityClickable) {
        setActiveRegion({ id: id || '', name: name || '' });
      }
      const city = paths.find(
        (item) => item.alias === id && item.type === 'city'
      );
      if (city?.alias && city.name) {
        setActiveCity({ id: city.alias, name: city.name });
      }
    }
  };

  const choosedText = useMemo(() => {
    if (activeCity.name && activeRegion.name) {
      return `Обрано ${activeCity.name} та ${activeRegion.name} ${
        activeRegion.name === 'Крим' ? '' : 'область'
      }`;
    }

    if (activeCity.name) {
      return `Обрано ${activeCity.name}`;
    }

    if (activeRegion.name) {
      return `Обрано ${activeRegion.name} ${
        activeRegion.name === 'Крим' ? '' : 'область'
      }`;
    }

    return 'Статистика відображається по всій країні, якщо потрібно місто або область, то оберіть на карті його';
  }, [activeCity, activeRegion]);

  const renderPath = () => {
    const filteredData = dataByRegion.filter((item) => item.count);
    const colorsLength = filteredData.length;
    const colors = chroma
      .scale(['rgb(238,190,174)', 'rgb(239,92,43)'])
      .colors(colorsLength);

    return paths.map((item, i) => {
      let idx = 0;
      const count = filteredData.find((mapCounts, i) => {
        idx = i;

        return (
          mapCounts.region === item.name || mapCounts.region === item.region
        );
      })?.count;

      const fill =
        item.name === activeCity.name || item.name === activeRegion.name
          ? theme.colors.primary
          : count && !activeCity.id && !activeRegion.id
          ? colors[idx]
          : item.fill;

      return item.name ? (
        <React.Fragment key={i}>
          <MapPath
            onClick={
              item.type === 'city' && item.name && item.alias
                ? () => handleChangeCity(item.name, item.alias)
                : item.type === 'region'
                ? () => handleChangeRegion(item.name, item.alias)
                : undefined
            }
            d={item.d}
            fill={fill}
          />
          {!!count && item.type === 'city' && (
            <MapCount
              x={item.countX}
              y={item.countY}
              fill="black"
              textAnchor="middle"
            >
              <tspan>{count}</tspan>
            </MapCount>
          )}
        </React.Fragment>
      ) : (
        <MapPath key={i} d={item.d} fill={item.fill} />
      );
    });
  };

  return (
    <MapStyled>
      <MapHeader>
        <Title>Теплова карта України</Title>
        <Total>Загальна кількість людей {total}</Total>
      </MapHeader>
      <MapWrapper>
        <svg
          width="941"
          height="555"
          viewBox="0 0 941 555"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>{renderPath()}</g>
          <defs>
            <clipPath id="clip0_5538_355578">
              <rect width="941" height="555" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </MapWrapper>
      <MapFooter $isActive={!!(activeCity.name || activeRegion.name)}>
        {choosedText}
      </MapFooter>
    </MapStyled>
  );
};
