import { useEffect, useRef, useState } from 'react';
import { useGetProjectsQuery } from '../../../../api/projects-api';
import { Select } from '../../../../components/select';
import { RoleSelect, RoleTitle } from '../style';
import type { OptionItem } from '../../../../common/types/option-item';
import {
  useEditRoleMutation,
  useGetUserRolesQuery
} from '../../../../api/user-api';
import { DataTable } from '../../../../components/data-table';
import type { DynamicContact } from '../../../../models/contact';
import { CheckBox } from '../../../../components/checkbox';
import { ConfirmPanel } from '../../../../components/confirm-panel';
import { useScrollHeight } from '../../../../hooks/use-scroll-height';
import { tableHeaders } from './data';
import { transformRole, transformToTaleFormat } from './helpers';

export const FieldsAccess = () => {
  const tableRef = useRef(null);
  const { scrollHeight } = useScrollHeight(tableRef);
  const { data: projects } = useGetProjectsQuery({ params: '?size=100' });
  const { data: roles, isFetching } = useGetUserRolesQuery('');
  const [editRole, { isLoading }] = useEditRoleMutation();
  const [tableData, setTableData] = useState<DynamicContact[]>([]);
  const [project, setProject] = useState<OptionItem>({
    id: '',
    title: ''
  });

  useEffect(() => {
    if (projects?.results.length && !project?.id) {
      setProject({
        id: projects.results[0].id,
        title: projects.results[0].name
      });
    }
    if (roles && project.id) {
      setTableData(transformToTaleFormat(roles, tableHeaders, project.id));
    }
  }, [projects, project, roles]);

  const handleSubmit = () => {
    tableData.forEach((item) => {
      if (roles) {
        const initialRole = roles.results.find(
          (role) => role.name === item.role
        );
        if (
          initialRole &&
          JSON.stringify(item) !==
            JSON.stringify(transformRole(initialRole, tableHeaders, project.id))
        ) {
          const fieldPermissions = [
            ...initialRole.field_permissions.filter(
              (item) => item.project !== project.id
            ),
            ...Object.entries(item)
              .filter(([key, value]) => value && key !== 'id' && key !== 'role')
              .map(([key]) =>
                key === 'files'
                  ? [
                      { project: project.id, name: key },
                      { project: project.id, name: 'db_files' }
                    ]
                  : { project: project.id, name: key }
              )
          ].flat();
          editRole({
            id: initialRole.id,
            body: {
              field_permissions: fieldPermissions
            }
          }).unwrap();
        }
      }
    });
  };

  const dataHasBeenChanged =
    roles &&
    project &&
    tableData.length &&
    JSON.stringify(transformToTaleFormat(roles, tableHeaders, project.id)) !==
      JSON.stringify(tableData);

  return (
    <>
      <RoleSelect>
        <RoleTitle>Проєкти:</RoleTitle>
        <Select
          value={project}
          setValue={(value) => setProject(value)}
          size={{ width: '183px' }}
          options={
            projects?.results.map((item) => ({
              id: item.id,
              title: item.name
            })) || []
          }
        />
      </RoleSelect>
      <div ref={tableRef}>
        <DataTable<DynamicContact>
          actions={[]}
          customHeight={dataHasBeenChanged ? scrollHeight - 64 : scrollHeight}
          headers={tableHeaders}
          data={tableData.map(({ role, id, ...rest }, i) => ({
            role,
            id,
            ...Object.assign(
              {},
              ...Object.entries(rest).map((field) => ({
                [field[0]]: (
                  <CheckBox
                    disabled={isLoading}
                    key={field[0]}
                    checked={field[1] as boolean}
                    setChecked={(checked) =>
                      setTableData((state) =>
                        state.map((item, j) =>
                          i !== j ? item : { ...item, [field[0]]: checked }
                        )
                      )
                    }
                  />
                )
              }))
            )
          }))}
          checkedList={[]}
          setCheckedList={() => {}}
          withOutCheck
        />
      </div>
      {!!dataHasBeenChanged && (
        <ConfirmPanel
          onCancel={() =>
            setTableData(transformToTaleFormat(roles, tableHeaders, project.id))
          }
          onSubmit={handleSubmit}
          disabled={isLoading || isFetching}
          cancelDisabled={isLoading || isFetching}
        />
      )}
    </>
  );
};
