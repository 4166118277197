import styled from 'styled-components';

export const BadgeStyled = styled.div`
  max-width: 320px;
  padding: 4px 12px;
  background: ${(p) => p.theme.colors.blue.bg};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.blue.dark};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  margin-left: 10px;
`;
