import { type ReactNode } from 'react';
import { ProjectTabStyled } from './style';

export interface ProjectTabProps {
  children: ReactNode;
  path: string;
  isSmall?: boolean;
  color: string;
}

export const ProjectTab = ({
  children,
  path,
  isSmall,
  color
}: ProjectTabProps) => (
  <ProjectTabStyled $color={color} $isSmall={isSmall} to={path}>
    {children}
  </ProjectTabStyled>
);
