import { useEffect } from 'react';
import { useAppDispatch } from '../../../api/store';
import { setDashboardDate } from '../../../api/dashboard-slice';

export const useClearDate = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setDashboardDate({
        start: '',
        end: ''
      })
    );

    return () => {
      dispatch(
        setDashboardDate({
          start: '',
          end: ''
        })
      );
    };
  }, [dispatch]);
};
