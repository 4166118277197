import type { Dispatch, SetStateAction } from 'react';
import type { ServerFile } from '../../common/types/server-file';
import { StringCutter } from '../string-cutter';
import { CloseFileSvg } from '../svg/close-file-svg';
import { setFileNameFromUrl } from '../../helpers/file-helpers';
import { CloseIcon, FileName } from './style';

interface FilesFromServerProps {
  files: ServerFile[];
  setFiles: Dispatch<SetStateAction<ServerFile[]>>;
}

export const FilesFromServer = ({ files, setFiles }: FilesFromServerProps) => (
  <>
    {files.map((item) => (
      <FileName key={item.id}>
        <StringCutter lines="1">{setFileNameFromUrl(item.file)}</StringCutter>
        <CloseIcon
          onClick={() =>
            setFiles((state) => state.filter((file) => file.id !== item.id))
          }
        >
          <CloseFileSvg />
        </CloseIcon>
      </FileName>
    ))}
  </>
);
