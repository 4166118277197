import { useCallback, useEffect, useState } from 'react';
import type { Call as ICall } from '../../../models/call';
import { ActivityItem } from '../../activity-item';
import { ConfirmPopup } from '../../confirm-popup';
import { Input } from '../../input';
import { Select } from '../../select';
import { InputDate } from '../../input-date';
import type { OptionItem } from '../../../common/types/option-item';
import { dateToUIFormat } from '../../../helpers/date-helpers';
import { CALL_RESULT, SATISFACTION } from '../../../common/constants';
import { BtnSecondary } from '../../btn-secondary';
import { BtnPrimary } from '../../btn-primary';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { ActivityTextArea } from '../../activity-textarea';
import type {
  DeleteCall,
  EditCall
} from '../../../common/types/rtk-triggers/call';
import { EditBtns, InputContainer, Message, TextareaWrapper } from './style';

interface CallProps {
  call: ICall;
  fullName: string;
  editCall: EditCall;
  deleteCall: DeleteCall;
  isPartner?: boolean;
}

export const Call = ({
  call,
  fullName,
  editCall,
  deleteCall,
  isPartner
}: CallProps) => {
  const notification = useNotification();
  const [result, setResult] = useState<OptionItem>(call.result);
  const [satisfaction, setSatisfaction] = useState<OptionItem>(
    call.satisfaction
  );
  const [message, setMessage] = useState(call.text);
  const [date, setDate] = useState(dateToUIFormat(new Date(call.date)));
  const [edit, setEdit] = useState(false);
  const [expand, setExpand] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleCancel = useCallback(() => {
    setEdit(false);
    setResult(call.result);
    setSatisfaction(call.satisfaction);
    setMessage(call.text);
    setDate(dateToUIFormat(new Date(call.date)));
  }, [call]);

  const handleEdit = () => {
    editCall({
      id: call.id,
      body: {
        result: result.id,
        satisfaction: satisfaction.id,
        text: message,
        date: date.split('/').reverse().join('-')
      }
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Дзінок відредаговано',
          message: 'Ви успішно змінили дзвінок'
        });
        setEdit(false);
        setShowConfirm(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleDelete = () => {
    deleteCall({ id: call.id })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Дзінок видалено',
          message: 'Ви успішно видалили дзвінок'
        });
        setEdit(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  useEffect(() => {
    if (!edit) {
      handleCancel();
    }
  }, [edit, handleCancel]);

  return (
    <>
      <ActivityItem
        expand={expand}
        setExpand={setExpand}
        setEdit={setEdit}
        setShowConfirm={setShowConfirm}
        ownerId={call.contact_call_owner?.id || call.partner_call_owner?.id}
        createdAt={call.created_at}
        title={
          <>
            <span>Дзвінок</span> від{' '}
            {call.contact_call_owner?.full_name ||
              call.contact_call_owner?.username ||
              call.partner_call_owner?.full_name ||
              call.partner_call_owner?.username}{' '}
            до {fullName}
          </>
        }
      >
        {expand && (
          <>
            {!edit ? (
              <Message>{call.text}</Message>
            ) : (
              <TextareaWrapper>
                <ActivityTextArea
                  value={message}
                  setValue={setMessage}
                  placeholder="Опишіть дзвінок..."
                  required
                />
              </TextareaWrapper>
            )}
            <InputContainer>
              <Input
                label={isPartner ? 'Партнер' : 'Контакт'}
                disabled
                value={fullName}
                onChange={() => {}}
              />
              <Select
                label="Результат"
                value={result}
                options={CALL_RESULT}
                setValue={setResult}
                disabled={!edit}
              />
              <div></div>
              <InputDate
                disabled={!edit}
                value={date}
                setValue={setDate}
                required
                openUp
              />
              <Select
                value={satisfaction}
                setValue={setSatisfaction}
                label="Рівень задоволеності/настрій"
                options={SATISFACTION}
                disabled={!edit}
              />
            </InputContainer>
            {edit && (
              <EditBtns>
                <BtnSecondary onClick={handleCancel}>Відмінити</BtnSecondary>
                <BtnPrimary disabled={!message} onClick={handleEdit}>
                  Зберегти
                </BtnPrimary>
              </EditBtns>
            )}
          </>
        )}
      </ActivityItem>
      <ConfirmPopup
        show={showConfirm}
        setShow={setShowConfirm}
        onSubmit={handleDelete}
        title="Ви дійсно бажаєте видалити дзвінок?"
        needTranslate
      />
    </>
  );
};
