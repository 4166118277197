export const DashboardSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66732 7.16732H3.33398C1.72065 7.16732 0.833984 6.28065 0.833984 4.66732V3.33398C0.833984 1.72065 1.72065 0.833984 3.33398 0.833984H4.66732C6.28065 0.833984 7.16732 1.72065 7.16732 3.33398V4.66732C7.16732 6.28065 6.28065 7.16732 4.66732 7.16732ZM3.33398 1.83398C2.28065 1.83398 1.83398 2.28065 1.83398 3.33398V4.66732C1.83398 5.72065 2.28065 6.16732 3.33398 6.16732H4.66732C5.72065 6.16732 6.16732 5.72065 6.16732 4.66732V3.33398C6.16732 2.28065 5.72065 1.83398 4.66732 1.83398H3.33398Z"
      fill="#9DABC0"
    />
    <path
      d="M12.6673 7.16732H11.334C9.72065 7.16732 8.83398 6.28065 8.83398 4.66732V3.33398C8.83398 1.72065 9.72065 0.833984 11.334 0.833984H12.6673C14.2807 0.833984 15.1673 1.72065 15.1673 3.33398V4.66732C15.1673 6.28065 14.2807 7.16732 12.6673 7.16732ZM11.334 1.83398C10.2807 1.83398 9.83398 2.28065 9.83398 3.33398V4.66732C9.83398 5.72065 10.2807 6.16732 11.334 6.16732H12.6673C13.7207 6.16732 14.1673 5.72065 14.1673 4.66732V3.33398C14.1673 2.28065 13.7207 1.83398 12.6673 1.83398H11.334Z"
      fill="#9DABC0"
    />
    <path
      d="M12.6673 15.1673H11.334C9.72065 15.1673 8.83398 14.2807 8.83398 12.6673V11.334C8.83398 9.72065 9.72065 8.83398 11.334 8.83398H12.6673C14.2807 8.83398 15.1673 9.72065 15.1673 11.334V12.6673C15.1673 14.2807 14.2807 15.1673 12.6673 15.1673ZM11.334 9.83398C10.2807 9.83398 9.83398 10.2807 9.83398 11.334V12.6673C9.83398 13.7207 10.2807 14.1673 11.334 14.1673H12.6673C13.7207 14.1673 14.1673 13.7207 14.1673 12.6673V11.334C14.1673 10.2807 13.7207 9.83398 12.6673 9.83398H11.334Z"
      fill="#9DABC0"
    />
    <path
      d="M4.66732 15.1673H3.33398C1.72065 15.1673 0.833984 14.2807 0.833984 12.6673V11.334C0.833984 9.72065 1.72065 8.83398 3.33398 8.83398H4.66732C6.28065 8.83398 7.16732 9.72065 7.16732 11.334V12.6673C7.16732 14.2807 6.28065 15.1673 4.66732 15.1673ZM3.33398 9.83398C2.28065 9.83398 1.83398 10.2807 1.83398 11.334V12.6673C1.83398 13.7207 2.28065 14.1673 3.33398 14.1673H4.66732C5.72065 14.1673 6.16732 13.7207 6.16732 12.6673V11.334C6.16732 10.2807 5.72065 9.83398 4.66732 9.83398H3.33398Z"
      fill="#9DABC0"
    />
  </svg>
);
