import styled from 'styled-components';
import { scrollBar } from '../../style/helpers';

export const PageFilesStyled = styled.div`
  padding: 0 20px;
  height: calc(100vh - 150px);
  ${scrollBar}
`;

export const FileList = styled.div`
  margin-top: 40px;
`;

export const FileGroup = styled.div`
  margin-top: 20px;
  :first-child {
    margin-top: 0;
  }
`;

export const FileGroupTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: ${(p) => p.theme.colors.blue.dark};
`;
