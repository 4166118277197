import styled from 'styled-components';
import { centerFlex, scrollBar } from '../../style/helpers';

export const Wrapper = styled.div`
  position: relative;
`;

export const ActionBtnStyled = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.primary};
  cursor: pointer;
  ${centerFlex}
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  padding-top: 6px;
  z-index: 1;
`;

export const Options = styled.div`
  padding: 4px 0;
  min-width: 100%;
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  box-shadow: -3px -3px 9px rgba(63, 81, 126, 0.06),
    4px 5px 9px rgba(63, 81, 126, 0.08);
  border-radius: 6px;
  max-height: 250px;
  ${scrollBar}
`;

export const AcyionOptionItem = styled.div<{ openUp?: boolean }>`
  padding: 8px 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  background-color: ${(p) => p.theme.colors.white};
  transition: background-color 0.2s;
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.blue.bg};
    transition: background-color 0.2s;
  }
`;
