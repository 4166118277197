import styled from 'styled-components';

export const ActivityTabStyled = styled.div<{ isActive?: boolean }>`
  position: relative;
  padding: 0 20px 20px;
  font-weight: ${(p) => (p.isActive ? '600' : '400')};
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.blue.dark};
  cursor: pointer;
  :after {
    content: '';
    display: ${(p) => (p.isActive ? 'block' : 'none')};
    position: absolute;
    left: 0;
    bottom: -1.5px;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background: ${(p) => p.theme.colors.blue.dark};
  }
`;
