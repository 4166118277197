import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const FilterItemStyled = styled.div``;

export const FilterItemHead = styled.div<{ isActive?: boolean }>`
  padding: 8.5px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) =>
    p.isActive ? p.theme.colors.primary : p.theme.colors.blue.dark};
`;

export const FilterItemBody = styled.div`
  padding: 0 16px;
`;

export const AngleIcon = styled.div<{ isOpen: boolean; isActive?: boolean }>`
  ${centerFlex}
  margin-right: 8px;
  svg {
    display: block;
    transform: ${(p) => (p.isOpen ? 'rotate(0)' : 'rotate(-90deg)')};
    path {
      fill: ${(p) =>
        p.isActive
          ? p.theme.colors.primary
          : p.isOpen
          ? p.theme.colors.blue.primary
          : p.theme.colors.grey.light};
      stroke: ${(p) =>
        p.isActive
          ? p.theme.colors.primary
          : p.isOpen
          ? p.theme.colors.blue.primary
          : p.theme.colors.grey.light};
    }
  }
`;
