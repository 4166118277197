import styled from 'styled-components';

export const BarChartStyled = styled.div`
  flex: 1;
  svg {
    overflow: visible;
  }
  text {
    fill: ${(p) => p.theme.colors.blue.dark};
    font-size: 14px;
    font-weight: 600;
    line-height: 140%;
    overflow: hidden;
    tspan {
      max-width: 80px;
      overflow: hidden;
    }
  }
`;

export const TickStyled = styled.div<{ $textAlign?: 'left' | 'center' }>`
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  text-align: ${(p) => p.$textAlign};
`;

export const CustomTooltip = styled.div`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 12px;
  font-weight: 500;
  line-height: 140%;
  background: rgba(255, 255, 255, 0.8);
`;

export const NoData = styled.div`
  text-align: center;
  color: ${(p) => p.theme.colors.grey.light};
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
`;
