import type { ReactNode } from 'react';
import { AccordionItemStyled, Data, Title } from './style';

interface AccordionItemProps {
  title: string;
  data: ReactNode;
}

export const AccordionItem = ({ title, data }: AccordionItemProps) => (
  <AccordionItemStyled>
    <Title>{title}</Title>
    <Data>{data}</Data>
  </AccordionItemStyled>
);
