export const ActionsNoteSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00195 4.3125C5.69445 4.3125 5.43945 4.0575 5.43945 3.75V1.5C5.43945 1.1925 5.69445 0.9375 6.00195 0.9375C6.30945 0.9375 6.56445 1.1925 6.56445 1.5V3.75C6.56445 4.0575 6.30945 4.3125 6.00195 4.3125Z"
      fill="white"
    />
    <path
      d="M11.998 4.3125C11.6905 4.3125 11.4355 4.0575 11.4355 3.75V1.5C11.4355 1.1925 11.6905 0.9375 11.998 0.9375C12.3055 0.9375 12.5605 1.1925 12.5605 1.5V3.75C12.5605 4.0575 12.3055 4.3125 11.998 4.3125Z"
      fill="white"
    />
    <path
      d="M11.248 8.8125H5.24805C4.94055 8.8125 4.68555 8.5575 4.68555 8.25C4.68555 7.9425 4.94055 7.6875 5.24805 7.6875H11.248C11.5555 7.6875 11.8105 7.9425 11.8105 8.25C11.8105 8.5575 11.5555 8.8125 11.248 8.8125Z"
      fill="white"
    />
    <path
      d="M8.99805 11.8125H5.24805C4.94055 11.8125 4.68555 11.5575 4.68555 11.25C4.68555 10.9425 4.94055 10.6875 5.24805 10.6875H8.99805C9.30555 10.6875 9.56055 10.9425 9.56055 11.25C9.56055 11.5575 9.30555 11.8125 8.99805 11.8125Z"
      fill="white"
    />
    <path
      d="M11.25 17.0625H6.75C2.535 17.0625 1.6875 15.075 1.6875 11.865V7.2375C1.6875 3.6825 2.8875 2.235 5.97 2.0625H12C15.1125 2.235 16.3125 3.6825 16.3125 7.2375V12C16.3125 12.3075 16.0575 12.5625 15.75 12.5625C15.4425 12.5625 15.1875 12.3075 15.1875 12V7.2375C15.1875 3.9675 14.1 3.3075 11.97 3.1875H6C3.9 3.3075 2.8125 3.9675 2.8125 7.2375V11.865C2.8125 14.7375 3.36 15.9375 6.75 15.9375H11.25C11.5575 15.9375 11.8125 16.1925 11.8125 16.5C11.8125 16.8075 11.5575 17.0625 11.25 17.0625Z"
      fill="white"
    />
    <path
      d="M11.25 17.0625C11.175 17.0625 11.1075 17.0475 11.0325 17.0175C10.8225 16.9275 10.6875 16.725 10.6875 16.5V14.25C10.6875 12.435 11.685 11.4375 13.5 11.4375H15.75C15.975 11.4375 16.185 11.5725 16.2675 11.7825C16.3575 11.9925 16.305 12.2325 16.1475 12.3975L11.6475 16.8975C11.5425 17.0025 11.4 17.0625 11.25 17.0625ZM13.5 12.5625C12.315 12.5625 11.8125 13.065 11.8125 14.25V15.1425L14.3925 12.5625H13.5Z"
      fill="white"
    />
  </svg>
);
