import styled from 'styled-components';

export const Content = styled.form`
  margin: 0 auto;
  max-width: 400px;
  padding: 40px 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  min-height: 180px;
  align-items: start;
`;

export const BtnsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px 0;
  border-top: 1px solid ${(p) => p.theme.colors.popup.border};
`;
