export const FileDeleteSvg = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5592 15.3337H5.43924C4.92699 15.3263 4.43641 15.1258 4.06561 14.7723C3.6948 14.4188 3.47111 13.9383 3.43924 13.427L2.83258 4.20033C2.82947 4.13343 2.83965 4.06658 2.86254 4.00364C2.88542 3.94071 2.92056 3.88293 2.96591 3.83366C3.01264 3.78259 3.0692 3.74151 3.13222 3.71286C3.19523 3.68422 3.26338 3.66862 3.33258 3.66699H12.6659C12.7345 3.66684 12.8023 3.68079 12.8653 3.70798C12.9282 3.73516 12.9849 3.775 13.0318 3.82502C13.0787 3.87504 13.1148 3.93417 13.1379 3.99873C13.1609 4.0633 13.1705 4.13192 13.1659 4.20033L12.5859 13.427C12.5537 13.9429 12.3263 14.4271 11.9498 14.7813C11.5733 15.1355 11.0761 15.333 10.5592 15.3337ZM3.89258 4.66699L4.40591 13.367C4.42287 13.6293 4.53918 13.8753 4.73116 14.0549C4.92314 14.2345 5.17636 14.3342 5.43924 14.3337H10.5592C10.8217 14.3326 11.074 14.2324 11.2656 14.0532C11.4573 13.8739 11.5741 13.6288 11.5926 13.367L12.1326 4.70033L3.89258 4.66699Z"
      fill="#306FE3"
    />
    <path
      d="M14 4.66699H2C1.86739 4.66699 1.74021 4.61431 1.64645 4.52055C1.55268 4.42678 1.5 4.2996 1.5 4.16699C1.5 4.03438 1.55268 3.90721 1.64645 3.81344C1.74021 3.71967 1.86739 3.66699 2 3.66699H14C14.1326 3.66699 14.2598 3.71967 14.3536 3.81344C14.4473 3.90721 14.5 4.03438 14.5 4.16699C14.5 4.2996 14.4473 4.42678 14.3536 4.52055C14.2598 4.61431 14.1326 4.66699 14 4.66699Z"
      fill="#306FE3"
    />
    <path
      d="M10 4.66699H6C5.86793 4.66527 5.74175 4.61203 5.64836 4.51864C5.55496 4.42524 5.50173 4.29906 5.5 4.16699V2.96699C5.50845 2.62487 5.64813 2.2991 5.89012 2.05711C6.13211 1.81512 6.45788 1.67544 6.8 1.66699H9.2C9.54781 1.67569 9.87845 1.82 10.1213 2.06911C10.3642 2.31822 10.5001 2.6524 10.5 3.00033V4.16699C10.4983 4.29906 10.445 4.42524 10.3516 4.51864C10.2582 4.61203 10.1321 4.66527 10 4.66699ZM6.5 3.66699H9.5V3.00033C9.5 2.92076 9.46839 2.84445 9.41213 2.78819C9.35587 2.73193 9.27957 2.70033 9.2 2.70033H6.8C6.72043 2.70033 6.64413 2.73193 6.58787 2.78819C6.53161 2.84445 6.5 2.92076 6.5 3.00033V3.66699Z"
      fill="#306FE3"
    />
    <path
      d="M10 12.6663C9.86793 12.6646 9.74175 12.6114 9.64836 12.518C9.55496 12.4246 9.50173 12.2984 9.5 12.1663V6.83301C9.5 6.7004 9.55268 6.57322 9.64645 6.47945C9.74022 6.38569 9.86739 6.33301 10 6.33301C10.1326 6.33301 10.2598 6.38569 10.3536 6.47945C10.4473 6.57322 10.5 6.7004 10.5 6.83301V12.1663C10.4983 12.2984 10.445 12.4246 10.3516 12.518C10.2582 12.6114 10.1321 12.6646 10 12.6663Z"
      fill="#306FE3"
    />
    <path
      d="M6 12.6663C5.86793 12.6646 5.74175 12.6114 5.64836 12.518C5.55496 12.4246 5.50173 12.2984 5.5 12.1663V6.83301C5.5 6.7004 5.55268 6.57322 5.64645 6.47945C5.74022 6.38569 5.86739 6.33301 6 6.33301C6.13261 6.33301 6.25978 6.38569 6.35355 6.47945C6.44732 6.57322 6.5 6.7004 6.5 6.83301V12.1663C6.49827 12.2984 6.44504 12.4246 6.35164 12.518C6.25825 12.6114 6.13207 12.6646 6 12.6663Z"
      fill="#306FE3"
    />
    <path
      d="M8 12.6663C7.86793 12.6646 7.74175 12.6114 7.64836 12.518C7.55496 12.4246 7.50173 12.2984 7.5 12.1663V6.83301C7.5 6.7004 7.55268 6.57322 7.64645 6.47945C7.74022 6.38569 7.86739 6.33301 8 6.33301C8.13261 6.33301 8.25978 6.38569 8.35355 6.47945C8.44732 6.57322 8.5 6.7004 8.5 6.83301V12.1663C8.49827 12.2984 8.44504 12.4246 8.35164 12.518C8.25825 12.6114 8.13207 12.6646 8 12.6663Z"
      fill="#306FE3"
    />
  </svg>
);
