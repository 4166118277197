export const dateToApiFormat = (date: Date | null) =>
  date
    ? date
        .toLocaleDateString('uk', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
        .split('.')
        .reverse()
        .join('-')
    : '';

export const dateToUIFormat = (date: Date | null) =>
  date
    ? date
        .toLocaleDateString('uk', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
        .split('.')
        .join('/')
    : '';

export const isValidNewDate = (date: string | null) => {
  if (!date) {
    return false;
  }

  return !isNaN(Date.parse(date));
};

export const withLeadingZero = (date: number) =>
  date < 10 ? `0${date}` : date;

export const dateWithTime = (date: string | number) => {
  const dateObj = new Date(date);
  const dayMonth = dateObj.toLocaleDateString('uk', {
    day: 'numeric',
    month: 'long'
  });
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = withLeadingZero(dateObj.getMinutes());

  return `${dayMonth}, ${year} | ${hours}:${minutes}`;
};

export const dateTableFormat = (date: string, withTime?: boolean) => {
  if (!isValidNewDate(date) || !date) {
    return '';
  }
  const dateObj = new Date(date);
  const formatedDate = dateObj.toLocaleDateString('uk', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  });
  const hours = dateObj.getHours();
  const minutes = withLeadingZero(dateObj.getMinutes());

  if (withTime) {
    return (
      <>
        {formatedDate}
        <br />
        {hours}:{minutes}
      </>
    );
  }

  return formatedDate;
};

export const activityDate = (date: string | number) =>
  `${new Date(date).toLocaleDateString('uk', {
    day: 'numeric',
    month: 'long'
  })}, ${new Date(date).getFullYear()}`;

export const kanbanDate = (date: string) =>
  `${new Date(date).toLocaleDateString('uk', {
    month: 'long',
    day: 'numeric'
  })}, ${new Date(date).getFullYear()}`;

export const dateToApiFromInput = (date: string) =>
  date.split('/').reverse().join('-');
