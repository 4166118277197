export const DashboardArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_5543_424828)">
      <path
        d="M10.0001 4.29866C10.3585 4.29866 10.7169 4.43552 10.9902 4.70866L19.5897 13.3083C20.1368 13.8553 20.1368 14.7423 19.5897 15.2891C19.0429 15.8359 18.1561 15.8359 17.609 15.2891L10.0001 7.67967L2.39102 15.2888C1.84398 15.8357 0.957304 15.8357 0.410524 15.2888C-0.136784 14.742 -0.136784 13.8551 0.410524 13.308L9.00994 4.7084C9.28335 4.43521 9.64175 4.29866 10.0001 4.29866Z"
        fill="#171C3D"
      />
    </g>
    <defs>
      <clipPath id="clip0_5543_424828">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(20 20) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);
