export const AngleSvg = () => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 3.57574L3.64645 3.22218L1.01213 0.587868C0.894975 0.470711 0.705026 0.470711 0.587868 0.587867L4 3.57574ZM4 3.57574L4.35355 3.22218M4 3.57574L4.35355 3.22218M4.35355 3.22218L6.98787 0.587868C7.10503 0.470711 7.29497 0.47071 7.41213 0.587868M4.35355 3.22218L7.41213 0.587868M7.41213 0.587868C7.52929 0.705025 7.52929 0.894975 7.41213 1.01213M7.41213 0.587868L7.41213 1.01213M7.41213 1.01213L4.21213 4.21213M7.41213 1.01213L4.21213 4.21213M4.21213 4.21213C4.09497 4.32929 3.90503 4.32929 3.78787 4.21213M4.21213 4.21213L3.78787 4.21213M3.78787 4.21213L0.587868 1.01213M3.78787 4.21213L0.587868 1.01213M0.587868 1.01213C0.470711 0.894975 0.470711 0.705026 0.587867 0.587868L0.587868 1.01213Z"
      fill="#9DABC0"
      stroke="#9DABC0"
    />
  </svg>
);
