import { useState } from 'react';
import { useTheme } from 'styled-components';
import { ChartsLayout } from '../components/charts-layout';
import { ChartContainer } from '../components/chart-container';
import { useGetAudienceQuery } from '../../../api/dashboard-api';
import { useTypedSelector } from '../../../api/store';
import { DoubleChart } from '../components/double-chart';
import { BarChart } from '../components/bar-chart';
import { CheckBox } from '../../../components/checkbox';
import { ChartsSlider } from '../components/charts-slider';
import { Loader } from '../../../components/loader';
import { groupByRegion } from '../helpers/group-by-region';
import { CheckboxContainer } from './style';

interface DashboardLivLocationPageProps {
  isReg?: boolean;
}

export const DashboardLivLocationPage = ({
  isReg
}: DashboardLivLocationPageProps) => {
  const theme = useTheme();

  const [activeCity, setActiveCity] = useState({ id: '', name: '' });
  const [activeRegion, setActiveRegion] = useState({ id: '', name: '' });
  const { date } = useTypedSelector((state) => state.dashboard);
  const [contactToShow, setContactToShow] = useState({
    children: true,
    men: true,
    women: true
  });

  const cityQueryKey = isReg ? 'reg_city' : 'liv_city';
  const regionQueryKey = isReg ? 'reg_region' : 'liv_region';

  const { data, isFetching } = useGetAudienceQuery(
    {
      params: `?${
        isReg ? 'reg_dashboard=true' : 'liv_dashboard=true'
      }&${cityQueryKey}=${activeCity.name}&${regionQueryKey}=${
        activeRegion.name
      }&created_start=${date.start}&created_end=${date.end}`
    },
    { refetchOnMountOrArgChange: true }
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <ChartsLayout
      activeCity={activeCity}
      activeRegion={activeRegion}
      setActiveCity={setActiveCity}
      setActiveRegion={setActiveRegion}
      total={data.data.adults}
      mt="0"
      isFetching={isFetching}
      dataByRegion={groupByRegion(
        isReg ? data.data.reg_address_map || [] : data.data.map || []
      )}
    >
      <ChartContainer title="Загальна кількість контактів">
        <CheckboxContainer>
          <CheckBox
            label="Діти"
            checked={contactToShow.children}
            setChecked={(checked) =>
              setContactToShow((state) => ({ ...state, children: checked }))
            }
          />
          <CheckBox
            label="Жінки"
            checked={contactToShow.women}
            setChecked={(checked) =>
              setContactToShow((state) => ({ ...state, women: checked }))
            }
          />
          <CheckBox
            label="Чоловіки"
            checked={contactToShow.men}
            setChecked={(checked) =>
              setContactToShow((state) => ({ ...state, men: checked }))
            }
          />
        </CheckboxContainer>
        <BarChart
          tickAlign="center"
          tickMaxWidth={66}
          aspect={1}
          barCount={2}
          color={theme.colors.primary}
          data={[
            { name: 'Діти', value: data.data.children },
            { name: 'Жінки', value: data.data.women_count },
            { name: 'Чоловіки', value: data.data.men_count }
          ].filter(
            (item) =>
              (item.name === 'Діти' && contactToShow.children) ||
              (item.name === 'Жінки' && contactToShow.women) ||
              (item.name === 'Чоловіки' && contactToShow.men)
          )}
        />
      </ChartContainer>
      {(contactToShow.men || contactToShow.women) && (
        <ChartContainer title="Вік та гендер відвідувачів">
          <DoubleChart
            legends={[
              { name: 'Чоловіки', value: data.data.men_count },
              { name: 'Жінки', value: data.data.women_count }
            ].filter(
              (item) =>
                (item.name === 'Жінки' && contactToShow.women) ||
                (item.name === 'Чоловіки' && contactToShow.men)
            )}
            colors={
              contactToShow.women && contactToShow.men
                ? ['#306FE3', theme.colors.primary]
                : contactToShow.women
                ? [theme.colors.primary, theme.colors.primary]
                : ['#306FE3', theme.colors.primary]
            }
            data={data.data.age_and_gender.map((item) => ({
              name: item.age_gradation,
              firstValue: contactToShow.men ? item.men : '',
              secondValue: contactToShow.women ? item.women : ''
            }))}
          />
        </ChartContainer>
      )}
      {contactToShow.children && (
        <ChartContainer title="Вік та стать дітей">
          <DoubleChart
            legends={[
              { name: 'Хлопчики', value: data.data.boys_count },
              { name: 'Дівчата', value: data.data.girls_count }
            ]}
            colors={['#306FE3', theme.colors.primary]}
            data={data.data.kids_age_and_gender.map((item) => ({
              name: item.age_gradation,
              firstValue: item.men,
              secondValue: item.women
            }))}
          />
        </ChartContainer>
      )}
      <ChartsSlider
        title="Статус (П Н Н)"
        data={[
          {
            title: 'Загальна кількість людей ',
            chart: (
              <BarChart
                aspect={2}
                color={theme.colors.primary}
                data={[
                  ...data.data.satisfaction_count
                    .filter((item) => item.id)
                    .map((item) => {
                      const [_, [name, value]] = Object.entries(item);

                      return { name, value };
                    }),
                  {
                    name: 'Всі статуси',
                    value: data.data.satisfaction_count
                      .filter((item) => item.id)
                      .map((item) => Object.values(item)[1])
                      .reduce((a, b) => a + b, 0)
                  }
                ]}
              />
            )
          }
        ]}
      />
    </ChartsLayout>
  );
};
