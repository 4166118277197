import { useTheme } from 'styled-components';

interface EyeSvgProps {
  $focus?: boolean;
}

export const EyeSvg = ({ $focus }: EyeSvgProps) => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.375C3.75 1.375 1.25 7.00061 1.25 7.00061C1.25 7.00061 3.75 12.625 10 12.625C16.25 12.625 18.75 7.00061 18.75 7.00061C18.75 7.00061 16.25 1.375 10 1.375Z"
        stroke={$focus ? theme?.colors.blue.dark : theme?.colors.grey.light}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10.1257C11.7259 10.1257 13.125 8.72655 13.125 7.00066C13.125 5.27477 11.7259 3.87566 10 3.87566C8.27411 3.87566 6.875 5.27477 6.875 7.00066C6.875 8.72655 8.27411 10.1257 10 10.1257Z"
        stroke={$focus ? theme?.colors.blue.dark : theme?.colors.grey.light}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
