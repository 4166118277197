export const ImportSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.62651 11.1671C9.49984 11.1671 9.37318 11.2137 9.27318 11.3137L7.91984 12.6671L6.56651 11.3137C6.37318 11.1204 6.05318 11.1204 5.85984 11.3137C5.66651 11.5071 5.66651 11.8271 5.85984 12.0204L7.56651 13.7271C7.75984 13.9204 8.07984 13.9204 8.27318 13.7271L9.97984 12.0204C10.1732 11.8271 10.1732 11.5071 9.97984 11.3137C9.87984 11.2137 9.75318 11.1671 9.62651 11.1671Z"
      fill="#292D32"
    />
    <path
      d="M7.92188 6.04715C7.64854 6.04715 7.42188 6.27381 7.42188 6.54715V13.3271C7.42188 13.6005 7.64854 13.8271 7.92188 13.8271C8.19521 13.8271 8.42188 13.6005 8.42188 13.3271V6.54715C8.42188 6.26715 8.19521 6.04715 7.92188 6.04715Z"
      fill="#292D32"
    />
    <path
      d="M8.0013 2.16667C4.56797 2.16667 2.16797 4.56667 2.16797 8C2.16797 8.27333 2.39464 8.5 2.66797 8.5C2.9413 8.5 3.16797 8.27333 3.16797 8C3.16797 5.15333 5.15464 3.16667 8.0013 3.16667C10.848 3.16667 12.8346 5.15333 12.8346 8C12.8346 8.27333 13.0613 8.5 13.3346 8.5C13.608 8.5 13.8346 8.27333 13.8346 8C13.8346 4.56667 11.4346 2.16667 8.0013 2.16667Z"
      fill="#292D32"
    />
  </svg>
);
