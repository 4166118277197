import type { FormEventHandler } from 'react';
import { useState, type Dispatch, type SetStateAction, useEffect } from 'react';
import { Popup } from '../../../components/popup';
import { Select } from '../../../components/select';
import type { OptionItem } from '../../../common/types/option-item';
import { BtnSecondary } from '../../../components/btn-secondary';
import { BtnPrimary } from '../../../components/btn-primary';
import { BtnsContainer, FormStyled } from '../delegate/style';
import { SATISFACTION } from '../../../common/constants';

interface DelegateProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: { satisfaction: string | number }) => void;
}

export const ChangeSatisfaction = ({
  show,
  setShow,
  onSubmit
}: DelegateProps) => {
  const [satisfaction, setSatisfaction] = useState<OptionItem>({
    id: '',
    title: ''
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit({ satisfaction: satisfaction.id });
  };

  useEffect(() => {
    setSatisfaction({ id: '', title: '' });
  }, [show]);

  return (
    <Popup
      maxContentWidth="448px"
      title="Додати рівень задоволеності"
      show={show}
      setShow={setShow}
    >
      <FormStyled onSubmit={handleSubmit}>
        <Select
          placeholder="Оберіть тут"
          label="Рівень задоволеності"
          value={satisfaction}
          setValue={setSatisfaction}
          options={SATISFACTION}
        />
        <BtnsContainer>
          <BtnSecondary onClick={() => setShow(false)}>Відмінити</BtnSecondary>
          <BtnPrimary disabled={!satisfaction.id && satisfaction.id !== 0}>
            Зберегти
          </BtnPrimary>
        </BtnsContainer>
      </FormStyled>
    </Popup>
  );
};
