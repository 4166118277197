import styled from 'styled-components';
import { scrollBar } from '../../../../style/helpers';

export const TabsStyled = styled.div`
  padding: 0 20px;
  width: 100%;
  max-width: 795px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const TabContent = styled.div`
  margin-top: 40px;
`;

export const ScrollContainer = styled.div`
  padding: 0 20px;
  height: calc(100vh - 130px);
  ${scrollBar}
`;
