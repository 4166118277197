import styled from 'styled-components';
import { scrollBar } from '../../style/helpers';

export const CallsStyled = styled.div`
  padding: 20px 0;
`;

export const CreateCall = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

export const CallsContainer = styled.div`
  padding: 0 20px;
  height: calc(100vh - 250px);
  ${scrollBar}
`;

export const CallsByMonth = styled.div`
  margin-top: 20px;
  :first-child {
    margin-top: 0;
  }
`;

export const CallsByMonthTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: ${(p) => p.theme.colors.blue.dark};
`;
