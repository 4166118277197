import { useTypedSelector } from '../../api/store';
import { BtnPrimary } from '../btn-primary';
import { BtnSecondary } from '../btn-secondary';
import { ConfirmPanelStyled } from './style';

interface ConfirmPanelProps {
  onCancel: () => void;
  onSubmit: () => void;
  disabled?: boolean;
  cancelDisabled?: boolean;
}

export const ConfirmPanel = ({
  onCancel,
  onSubmit,
  disabled,
  cancelDisabled
}: ConfirmPanelProps) => {
  const { menuIsOpen } = useTypedSelector((state) => state['page-data']);

  return (
    <ConfirmPanelStyled menuIsOpen={menuIsOpen}>
      <BtnSecondary disabled={cancelDisabled} onClick={onCancel}>
        Відмінити
      </BtnSecondary>
      <BtnPrimary onClick={onSubmit} disabled={disabled}>
        Зберегти
      </BtnPrimary>
    </ConfirmPanelStyled>
  );
};
