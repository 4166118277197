import type { FormEventHandler } from 'react';
import { useState, type Dispatch, type SetStateAction, useEffect } from 'react';
import { Popup } from '../../../components/popup';
import { Select } from '../../../components/select';
import type { OptionItem } from '../../../common/types/option-item';
import { BtnSecondary } from '../../../components/btn-secondary';
import { BtnPrimary } from '../../../components/btn-primary';
import type { IEvent } from '../../../models/event';
import { BtnsContainer, FormStyled, Subtitle } from './style';

interface DelegateProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: { manager: string | number }) => void;
  event: IEvent;
}

export const Delegate = ({ show, setShow, onSubmit, event }: DelegateProps) => {
  const [manager, setManager] = useState<OptionItem>({ id: '', title: '' });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit({ manager: manager.id });
  };

  useEffect(() => {
    setManager({ id: '', title: '' });
  }, [show]);

  return (
    <Popup
      maxContentWidth="448px"
      title="Делегувати"
      show={show}
      setShow={setShow}
    >
      <FormStyled onSubmit={handleSubmit}>
        <Subtitle>
          Оберіть менеджера на якого Ви бажаєте делегувати обрані контакти.
        </Subtitle>
        <Select
          mt="20px"
          placeholder="Делегувати на"
          label="Менеджер"
          value={manager}
          setValue={setManager}
          options={event.managers.map((item) => ({
            id: item.id,
            title: `${item?.last_name || ''} ${item?.first_name || ''} ${
              item?.patronymic || ''
            }`
          }))}
          disabled={
            !event.managers.map((item) => ({
              id: item.id,
              title: `${item?.last_name || ''} ${item?.first_name || ''} ${
                item?.patronymic || ''
              }`
            })).length
          }
        />
        <BtnsContainer>
          <BtnSecondary onClick={() => setShow(false)}>Відмінити</BtnSecondary>
          <BtnPrimary disabled={!manager.id}>Зберегти</BtnPrimary>
        </BtnsContainer>
      </FormStyled>
    </Popup>
  );
};
