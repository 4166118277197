import styled from 'styled-components';
import { scrollBar } from '../../../../style/helpers';

export const DoughnutStyled = styled.div`
  padding: 20px;
  border-radius: 9px;
  border: 1px solid ${(p) => p.theme.colors.grey.table};
`;

export const Title = styled.div`
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    font-size: 18px;
  }
`;

export const ChartWrapper = styled.div<{ $reverse?: boolean }>`
  margin-top: 18px;
  display: flex;
  flex-direction: ${(p) => (p.$reverse ? 'row-reverse' : 'row')};
  align-items: center;
  gap: 40px;
  svg {
    path {
      outline: none;
    }
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    gap: 20px;
  }
`;

export const DoughnutWrapper = styled.div`
  width: 270px;
  height: 270px;
  position: relative;

  @media (max-width: 1700px) {
    width: 200px;
    height: 200px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    width: 170px;
    height: 170px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    width: 140px;
    height: 140px;
  }
`;

export const Labels = styled.div`
  max-height: 100%;
  flex: 1;
  padding-right: 10px;
  ${scrollBar}
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
  gap: 10px;
  &:first-child {
    margin-top: 0;
  }
  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    margin-top: 10px;
  }
`;

export const Label = styled.div<{ $clicable?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(p) => p.theme.colors.grey.light};
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  cursor: ${(p) => (p.$clicable ? 'pointer' : 'default')};

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    font-size: 14px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    font-size: 12px;
  }
`;

export const LabelCircle = styled.div`
  min-width: 16px;
  height: 16px;
  border-radius: 100%;
`;

export const Value = styled.div`
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    font-size: 14px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    font-size: 12px;
  }
`;

export const Total = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const TotalValue = styled.div`
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 30px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    font-size: 20px;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    font-size: 16px;
  }
`;

export const TotalLabel = styled.div`
  margin-top: 6px;
  color: ${(p) => p.theme.colors.grey.light};
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  text-align: center;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    margin-top: 0;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    font-size: 12px;
  }
`;
