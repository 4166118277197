import styled from 'styled-components';

export const FlexContainerStyled = styled.div<{
  gap?: string;
  wrappMd?: boolean;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${(p) => p.gap || 0};

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    flex-wrap: ${(p) => (p.wrappMd ? 'wrap' : 'unset')};
  }
`;
