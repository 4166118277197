import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ButtonAdd } from '../../components/button-add';
// import { NoData } from '../../components/no-data';
import { PageTitle } from '../../components/page-title';
import { SubHeader } from '../../components/subheader';
import { Popup } from '../../components/popup';
import type { HeaderItem } from '../../components/data-table';
import { DataTable } from '../../components/data-table';
import {
  useDeleteProjectMutation,
  useGetProjectsQuery
} from '../../api/projects-api';
import { ConfirmPopup } from '../../components/confirm-popup';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { FlexContainer } from '../../components/flex-container';
import { Counter } from '../../components/counter';
import { SubheaderSelect } from '../../components/subheader-select';
import { CalendarSvg } from '../../components/svg/calendar-svg';
import { DateRange } from '../../components/date-range';
import { dateToApiFormat, isValidNewDate } from '../../helpers/date-helpers';
import { EditColumns } from '../../components/edit-colums';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { setProjectColumns } from '../../api/page-data-slice';
import { useGetUserMeQuery } from '../../api/user-api';
import { ProjectsSvg } from '../../components/svg/sidebar/projects-svg';
import { AddProject } from './add-project';
import type { ProjectsTable } from './types';
import { transformProjectsData } from './helpers';
import { tableHeaders } from './data';
import { Delegate } from './delegate';

export const ProjectsPage = () => {
  const tableRef = useRef(null);
  const notification = useNotification();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: userMe } = useGetUserMeQuery('');
  const { projectColumns, pageSize } = useTypedSelector(
    (state) => state['page-data']
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { scrollHeight, manuallySetScrollHeight } = useScrollHeight(tableRef);
  const [showAddProject, setShowAddProject] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showConfirmDelete, setShowConfimDelete] = useState(false);
  const [showDelegate, setShowDelegate] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { data, isFetching, isLoading, isSuccess } = useGetProjectsQuery(
    { params: `?size=${pageSize}&${searchParams.toString()}` },
    { refetchOnMountOrArgChange: true }
  );
  const [deleteProject] = useDeleteProjectMutation();

  useEffect(() => {
    if (isSuccess) {
      manuallySetScrollHeight();
    }
  }, [isSuccess, manuallySetScrollHeight]);

  const handleConfirmDelete = useCallback(() => {
    setShowConfimDelete(true);
  }, []);

  const handleDelete = () => {
    deleteProject({ ids: checkedList })
      .unwrap()
      .then(() => {
        notification({
          title: 'Проєкт/-и видалено!',
          message: 'Ви успішно видалили обрані проєкти.',
          type: 'success'
        });
        setShowConfimDelete(false);
        setCheckedList([]);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  const applyDate = () => {
    if (startDate) {
      searchParams.set('start_date', dateToApiFormat(startDate));
    }
    if (endDate) {
      searchParams.set('end_date', dateToApiFormat(endDate));
    }
    setSearchParams(searchParams);
    setShowCalendar(false);
  };

  const resetDate = () => {
    setStartDate(null);
    setEndDate(null);
    searchParams.delete('start_date');
    searchParams.delete('end_date');
    setSearchParams(searchParams);
    setShowCalendar(false);
  };

  const setCustomColumns = useCallback(
    (columns: HeaderItem<ProjectsTable>[] | null) => {
      dispatch(setProjectColumns(columns));
    },
    [dispatch]
  );

  const resetCustomColumns = useCallback(() => {
    dispatch(setProjectColumns(null));
  }, [dispatch]);

  useEffect(() => {
    if (isValidNewDate(searchParams.get('start_date'))) {
      setStartDate(new Date(searchParams.get('start_date') as string));
    } else {
      setStartDate(null);
    }
    if (isValidNewDate(searchParams.get('end_date'))) {
      setEndDate(new Date(searchParams.get('end_date') as string));
    } else {
      setEndDate(null);
    }
  }, [searchParams]);

  const columns =
    projectColumns?.filter((item) => item.checked) || tableHeaders;

  if (!userMe) {
    return null;
  }

  const canView = userMe.role?.view_project || userMe?.is_superuser;
  const canAdd = userMe.role?.add_project || userMe?.is_superuser;
  const canDelete = userMe.role?.delete_project || userMe?.is_superuser;

  if (!canView) {
    return null;
  }

  return (
    <>
      <SubHeader>
        <FlexContainer>
          <PageTitle>Проєкти</PageTitle>
          <Counter icon={ProjectsSvg}>{data?.count}</Counter>
          <SubheaderSelect
            show={showCalendar}
            setShow={setShowCalendar}
            icon={CalendarSvg}
            title="Дата"
            isActive={!!(startDate || endDate)}
          >
            <DateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onSubmit={applyDate}
              onReset={resetDate}
            />
          </SubheaderSelect>
          <EditColumns<ProjectsTable>
            onSubmit={setCustomColumns}
            initialHeaders={tableHeaders}
            columns={projectColumns}
            onReset={resetCustomColumns}
          />
        </FlexContainer>
        {canAdd && (
          <ButtonAdd onClick={() => setShowAddProject(true)}>
            Новий проєкт
          </ButtonAdd>
        )}
      </SubHeader>
      <div ref={tableRef}>
        <DataTable<ProjectsTable>
          onRowClick={(id) => navigate(`/projects/${id}`)}
          customHeight={scrollHeight}
          actions={
            canDelete
              ? [
                  { title: 'Делегувати', handler: () => setShowDelegate(true) },
                  { title: 'Видалити', handler: handleConfirmDelete }
                ]
              : [{ title: 'Делегувати', handler: () => setShowDelegate(true) }]
          }
          count={data?.count || 0}
          headers={columns}
          data={transformProjectsData(data?.results || [])}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          isFetching={isFetching || isLoading}
        />
      </div>
      {/* <NoData subTitle="Будь ласка, додайте новий контакт або підвантажте файли" /> */}
      <Popup
        show={showAddProject}
        maxContentWidth="866px"
        setShow={setShowAddProject}
        title="Новий проєкт"
        outsideOnlyEscape
      >
        <AddProject setShow={setShowAddProject} />
      </Popup>
      <ConfirmPopup
        show={showConfirmDelete}
        title={
          checkedList.length === 1
            ? `Чи дійсно бажаєте видалити проєкт ${
                data?.results.find((item) => item.id === checkedList[0])
                  ?.name || ''
              }?`
            : `Чи дійсно бажаєте видалити ${checkedList.length} проєктів`
        }
        setShow={setShowConfimDelete}
        onSubmit={handleDelete}
      />
      <Delegate
        show={showDelegate}
        setShow={setShowDelegate}
        projectIds={checkedList}
      />
    </>
  );
};
