import styled from 'styled-components';

export const InputContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.input};
`;

export const TextareaWrapper = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.input};
  height: calc(100% - 260px);
`;
