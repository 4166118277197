export const concatenateAddress = (data: {
  country: string;
  region: string;
  city: string;
  street: string;
  building: string;
  unit: string;
  apartment: string;
}) =>
  `${data.country ? `${data.country}, ` : ''}${
    data.region ? `${data.region} обл., ` : ''
  }${data.city ? `м/с. ${data.city}, ` : ''}${
    data.street ? `вул. ${data.street},` : ''
  } ${data.building ? `${data.building}, ` : ''}${
    data.unit ? ' корпус ' + data.unit + ',' : ''
  } ${data.apartment ? 'кв. ' + data.apartment : ''}`;
