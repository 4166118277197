import styled from 'styled-components';

export const HeaderStyled = styled.header`
  height: 72px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.table};
`;
