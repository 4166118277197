import { Cell, PieChart, Pie, ResponsiveContainer } from 'recharts';
import {
  ChartWrapper,
  DoughnutStyled,
  DoughnutWrapper,
  Label,
  LabelCircle,
  LabelContainer,
  Labels,
  Title,
  Total,
  TotalLabel,
  TotalValue,
  Value
} from './style';

interface DoughnutChartProps {
  title: string;
  data: { name: string; value: number; onClick?: () => void }[];
  colors: string[];
  totalValue?: string | number;
  totalLabel?: string;
  revrse?: boolean;
}

export const DoughnutChart = ({
  title,
  data,
  colors,
  totalLabel,
  totalValue,
  revrse
}: DoughnutChartProps) => (
  <DoughnutStyled>
    <Title>{title}</Title>
    <ChartWrapper $reverse={revrse}>
      <DoughnutWrapper>
        <ResponsiveContainer aspect={1}>
          <PieChart>
            <Pie
              data={data.map((item) =>
                item.value === 0 ? { ...item, value: 0.01 } : item
              )}
              innerRadius={'70%'}
              outerRadius={'100%'}
              dataKey="value"
            >
              {data.map((_, i) => (
                <Cell key={`cell-${i}`} fill={colors[i] || colors[0]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {(totalLabel || totalValue) && (
          <Total>
            <TotalValue>{totalValue}</TotalValue>
            <TotalLabel>{totalLabel}</TotalLabel>
          </Total>
        )}
      </DoughnutWrapper>
      <Labels>
        {data.map((item, i) => (
          <LabelContainer key={i}>
            <Label $clicable={!!item.onClick} onClick={item.onClick}>
              <LabelCircle style={{ backgroundColor: colors[i] }} />
              <span>{item.name}</span>
            </Label>
            <Value>{item.value}</Value>
          </LabelContainer>
        ))}
      </Labels>
    </ChartWrapper>
  </DoughnutStyled>
);
