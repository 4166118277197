import type { Dispatch, SetStateAction } from 'react';
import { useState, type ReactNode } from 'react';
import { DashboardArrowSvg } from '../../../../components/svg/dashboard/tabs/dashboard-arrow-svg';
import { Arrow, ChartContainerStyled, Title } from './style';

interface ChartContainerProps {
  children: ReactNode;
  title: string;
  withNav?: boolean;
  autoHeightChart?: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
  };
}

export const ChartContainer = ({
  children,
  title,
  autoHeightChart,
  withNav
}: ChartContainerProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleOpen = () => {
    setIsOpen((state) => !state);
  };

  return (
    <ChartContainerStyled
      $isOpen={autoHeightChart ? autoHeightChart.isOpen : isOpen}
      $withNav={withNav}
    >
      <Title
        $isOpen={autoHeightChart ? autoHeightChart.isOpen : isOpen}
        onClick={
          autoHeightChart
            ? () => autoHeightChart.setIsOpen((state) => !state)
            : handleOpen
        }
      >
        {title}
        <Arrow $isOpen={autoHeightChart ? autoHeightChart.isOpen : isOpen}>
          <DashboardArrowSvg />
        </Arrow>
      </Title>
      {(autoHeightChart ? autoHeightChart.isOpen : isOpen) && children}
    </ChartContainerStyled>
  );
};
