import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const ImagePreviewStyled = styled.div`
  position: fixed;
  top: 0;
  left: calc(100vw * -0.17);
  width: 100vw;
  height: 100vh;
  background: rgba(23, 28, 61, 0.6);
  padding: 50px;
  ${centerFlex}
  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    left: calc(100vw * -0.1);
  }
`;
