import type { ReactNode } from 'react';
import { AngleTableSvg } from '../../components/svg/angle-table-svg';
import {
  CardContent,
  CardInfo,
  CardLayoutStyled,
  CardSidebar,
  Close
} from './style';

interface CardLayoutProps {
  sidebar: ReactNode;
  children: ReactNode;
  onClose: () => void;
  transitionStyle?: Record<string, unknown>;
  transitionContentStyle?: Record<string, unknown>;
}

export const CardLayout = ({
  sidebar,
  children,
  onClose,
  transitionStyle,
  transitionContentStyle
}: CardLayoutProps) => (
  <CardLayoutStyled style={transitionStyle}>
    <div style={transitionContentStyle}>
      <CardContent>
        <CardSidebar>{sidebar}</CardSidebar>
        <CardInfo>{children}</CardInfo>
        <Close onClick={onClose}>
          <AngleTableSvg />
        </Close>
      </CardContent>
    </div>
  </CardLayoutStyled>
);
