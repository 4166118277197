import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EventsForm } from '../events-form';
import type { OptionItem } from '../../../common/types/option-item';
import {
  useCreateEventMutation,
  useGetTEmplateQuery
} from '../../../api/events-api';
import { EventTemplate } from '../event-template';
import { dateToApiFromInput } from '../../../helpers/date-helpers';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { ChooseTemplate } from '../choose-template';
import { AddEventStyled, TabItem, TabNum, Tabs } from './style';

interface AddEventProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const AddEvent = ({ setShow }: AddEventProps) => {
  const { projectId } = useParams();
  const notification = useNotification();
  const [step, setStep] = useState<'form' | 'choose' | 'template'>('form');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [format, setFormat] = useState<OptionItem>({ id: '', title: '' });
  const [region, setRegion] = useState('');
  const [budget, setBudget] = useState('');
  const [managers, setManagers] = useState<OptionItem[]>([
    { id: '', title: '' }
  ]);
  const [metaTags, setMetaTags] = useState(['', '', '']);
  const [withMainTemplate, setWithMainTemplate] = useState(false);

  const { data: template } = useGetTEmplateQuery('');
  const [createEvent] = useCreateEventMutation();

  const hasMainTemplate = !!template?.results.length;

  const handleNext = useCallback(() => {
    if (!format.id) {
      notification({
        type: 'error',
        title: 'Помилка',
        message: 'Вкажіть формат події'
      });

      return;
    }
    if (!managers.filter((item) => item.id).length) {
      notification({
        type: 'error',
        title: 'Помилка',
        message: 'Вкажіть хоча б одного менедджера'
      });

      return;
    }
    if (hasMainTemplate) {
      setStep('choose');

      return;
    }

    setStep('template');
  }, [hasMainTemplate, format.id, managers, notification]);

  const handleCreateEvent = (
    steps: { number: number; name: string }[],
    saveTemplate?: boolean
  ) => {
    if (steps.filter((item) => item.name).length < 2) {
      notification({
        type: 'error',
        title: 'Помилка',
        message: 'Вкажіть хоча б один крок, крім опрацьовано'
      });

      return;
    }
    if (!format.id) {
      notification({
        type: 'error',
        title: 'Помилка',
        message: 'Вкажіть формат події'
      });

      return;
    }
    if (!managers.filter((item) => item.id).length) {
      notification({
        type: 'error',
        title: 'Помилка',
        message: 'Вкажіть хоча б одного менедджера'
      });

      return;
    }
    const commonData = {
      project: projectId || '',
      choose_main_template: withMainTemplate,
      save_template: saveTemplate,
      name,
      description,
      format: format.id,
      region,
      start_date: dateToApiFromInput(startDate),
      end_date: endDate ? dateToApiFromInput(endDate) : null,
      budget: budget || 0,
      managers: managers.map((item) => item.id),
      meta_tag1: metaTags[0],
      meta_tag2: metaTags[1],
      meta_tag3: metaTags[2]
    };
    const data =
      withMainTemplate || saveTemplate
        ? { ...commonData, template_steps: steps }
        : { ...commonData, event_steps: steps };
    createEvent(data)
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Подію створено!',
          message: 'Ви успішно створили нову подію. '
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  if (!template) {
    return null;
  }

  const renderContent = () => {
    switch (step) {
      case 'form':
        return (
          <EventsForm
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            managers={managers}
            setManagers={setManagers}
            region={region}
            setRegion={setRegion}
            format={format}
            setFormat={setFormat}
            budget={budget}
            setBudget={setBudget}
            formTitle="Нова подія"
            setShow={setShow}
            submitTitle="Далі"
            cancelTitle="Відмінити"
            onSubmit={handleNext}
            metaTags={metaTags}
            setMetaTags={setMetaTags}
            projectId={projectId || ''}
          />
        );
      case 'choose':
        return (
          <ChooseTemplate
            setStep={setStep}
            setWithMainTemplate={setWithMainTemplate}
          />
        );
      case 'template':
        return (
          <EventTemplate
            title={withMainTemplate ? 'Існуючий шаблон' : 'Новий шаблон'}
            initialSteps={
              withMainTemplate
                ? template.results[0].template_steps
                    .slice(0, template.results[0].template_steps.length - 1)
                    .map((item) => ({
                      name: item.name,
                      number: item.number
                    }))
                : [{ number: 1, name: '' }]
            }
            initialLastStep={
              withMainTemplate
                ? template.results[0].template_steps
                    .map((item) => ({
                      name: item.name,
                      number: item.number
                    }))
                    .pop() || { number: 2, name: 'Опрацьовано' }
                : { number: 2, name: 'Опрацьовано' }
            }
            onCancel={() =>
              hasMainTemplate ? setStep('choose') : setStep('form')
            }
            onSubmit={handleCreateEvent}
            canSaveTemplate={!hasMainTemplate}
          />
        );
    }
  };

  return (
    <AddEventStyled>
      {template && (
        <Tabs>
          <TabItem isActive>
            <TabNum isActive>1</TabNum>
            Характеристики події
          </TabItem>
          {hasMainTemplate && (
            <TabItem
              withTemplate
              isActive={step === 'choose' || step === 'template'}
            >
              <TabNum isActive={step === 'choose' || step === 'template'}>
                2
              </TabNum>
              Вибір конфігурації
            </TabItem>
          )}
          <TabItem
            withTemplate={hasMainTemplate}
            isActive={step === 'template'}
          >
            <TabNum isActive={step === 'template'}>
              {hasMainTemplate ? 3 : 2}
            </TabNum>
            Конфігурація події
          </TabItem>
        </Tabs>
      )}
      {renderContent()}
    </AddEventStyled>
  );
};
