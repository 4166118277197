import { BtnPrimary } from '../btn-primary';
import { BtnSecondary } from '../btn-secondary';
import { FormBtnsStyled } from './style';

interface FormBtnsProps {
  primaryTitle?: string;
  secondaryTitle?: string;
  onCancel: () => void;
  onSubmit?: () => void;
  disabled?: boolean;
  onlySecondaryBtn?: boolean;
}

export const FormBtns = ({
  primaryTitle = 'Зберегти',
  secondaryTitle = 'Відмінити',
  onCancel,
  onSubmit,
  disabled,
  onlySecondaryBtn
}: FormBtnsProps) => (
  <FormBtnsStyled>
    <BtnSecondary onClick={onCancel}>{secondaryTitle}</BtnSecondary>
    {!onlySecondaryBtn && (
      <BtnPrimary disabled={disabled} onClick={onSubmit}>
        {primaryTitle}
      </BtnPrimary>
    )}
  </FormBtnsStyled>
);
