export const TrashSvg = () => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.81966 1.66732C3.81966 0.930938 4.41662 0.333984 5.153 0.333984H7.81966C8.55604 0.333984 9.153 0.930938 9.153 1.66732V3.00065H11.1461C11.1502 3.00061 11.1544 3.00061 11.1585 3.00065H11.8197C12.1879 3.00065 12.4863 3.29913 12.4863 3.66732C12.4863 4.03551 12.1879 4.33398 11.8197 4.33398H11.7737L11.2398 11.8098C11.165 12.8564 10.2941 13.6673 9.24484 13.6673H3.72782C2.67854 13.6673 1.80766 12.8564 1.7329 11.8098L1.19892 4.33398H1.15299C0.784805 4.33398 0.486328 4.03551 0.486328 3.66732C0.486328 3.29913 0.784805 3.00065 1.15299 3.00065H1.81416C1.81829 3.00061 1.82242 3.00061 1.82654 3.00065H3.81966V1.66732ZM2.53565 4.33398L3.06285 11.7148C3.08777 12.0637 3.37806 12.334 3.72782 12.334H9.24484C9.5946 12.334 9.88489 12.0637 9.90981 11.7148L10.437 4.33398H2.53565ZM7.81966 3.00065H5.153V1.66732H7.81966V3.00065ZM5.153 5.66732C5.52118 5.66732 5.81966 5.96579 5.81966 6.33398V10.334C5.81966 10.7022 5.52118 11.0007 5.153 11.0007C4.78481 11.0007 4.48633 10.7022 4.48633 10.334V6.33398C4.48633 5.96579 4.78481 5.66732 5.153 5.66732ZM7.81966 5.66732C8.18785 5.66732 8.48633 5.96579 8.48633 6.33398V10.334C8.48633 10.7022 8.18785 11.0007 7.81966 11.0007C7.45147 11.0007 7.153 10.7022 7.153 10.334V6.33398C7.153 5.96579 7.45147 5.66732 7.81966 5.66732Z"
      fill="#306FE3"
    />
  </svg>
);
