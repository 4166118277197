import type { ReactNode } from 'react';
import { BtnPrimaryStyled } from './style';

interface BtnPrimaryProps {
  children: ReactNode;
  type?: 'submit' | 'button';
  disabled?: boolean;
  onClick?: () => void;
  isActivity?: boolean;
  fz?: string;
  p?: string;
}

export const BtnPrimary = ({
  children,
  type = 'submit',
  disabled,
  onClick,
  isActivity,
  fz,
  p
}: BtnPrimaryProps) => (
  <BtnPrimaryStyled
    isActivity={isActivity}
    onClick={onClick}
    disabled={disabled}
    type={type}
    fz={fz}
    p={p}
  >
    {children}
  </BtnPrimaryStyled>
);
