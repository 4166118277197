export const TelegramSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <g clipPath="url(#clip0_5210_353687)">
      <path
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
        fill="#039BE5"
      />
      <path
        d="M9.15308 19.566L28.4364 12.131C29.3314 11.8077 30.1131 12.3494 29.8231 13.7027L29.8247 13.701L26.5414 29.1694C26.2981 30.266 25.6464 30.5327 24.7347 30.016L19.7347 26.331L17.3231 28.6544C17.0564 28.921 16.8314 29.146 16.3147 29.146L16.6697 24.0577L25.9364 15.686C26.3397 15.331 25.8464 15.131 25.3147 15.4844L13.8631 22.6944L8.92641 21.1544C7.85475 20.8144 7.83141 20.0827 9.15308 19.566Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5210_353687">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
