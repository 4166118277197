export const DashboardVolunteerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M12.2515 8.10375C12.0305 8.10375 11.8515 7.92469 11.8515 7.70375V2.75687C11.8515 2.3025 11.4826 1.93281 11.0291 1.93281C10.8088 1.93281 10.6019 2.01844 10.4464 2.17406C10.4419 2.17859 10.4373 2.18297 10.4326 2.18719L10.1584 1.89625L9.875 1.61406C9.8768 1.61219 9.87922 1.60984 9.8818 1.60734C10.1883 1.30125 10.5957 1.13281 11.0291 1.13281C11.9237 1.13281 12.6515 1.86141 12.6515 2.75687V7.70375C12.6515 7.92469 12.4724 8.10375 12.2515 8.10375Z"
      fill="#9DABC0"
    />
    <path
      d="M10.1695 7.36937C9.94852 7.36937 9.76945 7.19031 9.76945 6.96937V2.0225C9.76945 1.99687 9.76883 1.97141 9.76578 1.94703C9.72195 1.51625 9.36977 1.19844 8.94539 1.19844C8.72633 1.19844 8.51898 1.28453 8.36156 1.44078C8.22953 1.57266 8.14578 1.74734 8.12648 1.93187C8.1082 2.13594 7.9368 2.29641 7.72812 2.29641C7.50719 2.29641 7.32812 2.11828 7.32812 1.89734V1.89562C7.32812 1.88234 7.32883 1.86906 7.33008 1.85578C7.36703 1.48672 7.53281 1.13797 7.79695 0.873906C8.10625 0.567031 8.51375 0.398438 8.94539 0.398438C9.78187 0.398438 10.4762 1.02547 10.5606 1.85703C10.5662 1.90094 10.5695 1.95812 10.5695 2.0225V6.96937C10.5695 7.19031 10.3904 7.36937 10.1695 7.36937Z"
      fill="#9DABC0"
    />
    <path
      d="M9.96039 15.5983H9.24805C7.72961 15.5983 6.30188 15.0069 5.22797 13.9332L1.74125 10.4463C1.43453 10.1398 1.26562 9.73194 1.26562 9.29788C1.26562 8.86397 1.43453 8.45616 1.74125 8.1496C2.04688 7.84272 2.45469 7.6735 2.88883 7.6735C3.32305 7.6735 3.73086 7.84272 4.03703 8.15007L5.23531 9.34835V2.75679C5.23531 2.32304 5.40422 1.91507 5.71086 1.60835C6.04367 1.27554 6.50391 1.10397 6.97594 1.13679C7.82125 1.19632 8.48336 1.92772 8.48336 2.80194V7.70366C8.48336 7.9246 8.3043 8.10366 8.08336 8.10366C7.86242 8.10366 7.68336 7.9246 7.68336 7.70366V2.80194C7.68336 2.34585 7.34797 1.96507 6.91984 1.93491C6.67898 1.91741 6.44547 2.00507 6.27656 2.17397C6.12102 2.3296 6.03531 2.53663 6.03531 2.75679V10.314C6.03531 10.4757 5.93789 10.6216 5.78836 10.6835C5.63898 10.7452 5.46688 10.7113 5.3525 10.5968L3.47078 8.71522C3.31523 8.55913 3.10883 8.4735 2.88891 8.4735C2.66898 8.4735 2.46242 8.55913 2.30742 8.71475C2.15133 8.87085 2.06562 9.07772 2.06562 9.29788C2.06562 9.51819 2.15133 9.72507 2.30687 9.88069L5.79359 13.3676C6.71641 14.2902 7.94328 14.7983 9.24805 14.7983H9.96039C12.1569 14.7983 13.9439 13.0113 13.9439 10.8149V4.25054C13.9439 3.79257 13.6068 3.41179 13.1765 3.38366C12.9351 3.36647 12.7088 3.45257 12.5377 3.62225C12.3809 3.77804 12.1276 3.7771 11.972 3.62007C11.8164 3.46319 11.8174 3.21007 11.9742 3.05444C12.3055 2.72569 12.7635 2.5546 13.2289 2.58522C14.0784 2.641 14.7439 3.37241 14.7439 4.25054V10.8149C14.7439 13.4526 12.598 15.5983 9.96039 15.5983Z"
      fill="#9DABC0"
    />
  </svg>
);
