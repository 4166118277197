import type { MouseEventHandler } from 'react';
import { useRef, type ReactNode, useState } from 'react';
import { Transition } from 'react-transition-group';
import {
  defaultStyle,
  duration,
  transitionStyles
} from '../../../common/transition/fade-transition';
import { TooltipFixedStyled, TooltipStyled } from './style';

interface TooltipFixedProps {
  children: ReactNode;
  title: string;
  canBreak?: boolean;
  offset?: {
    top: number;
    left: number;
  };
  position?: 'center' | 'left' | 'right' | 'topRight';
  disabled?: boolean;
}

export const TooltipFixed = ({
  children,
  title,
  canBreak,
  offset,
  position = 'center',
  disabled
}: TooltipFixedProps) => {
  const [pos, setPos] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);

  const handleShowTooltip: MouseEventHandler<HTMLDivElement> = () => {
    if (ref.current) {
      const rect = ref.current?.getBoundingClientRect();
      const titleRect = titleRef.current?.getBoundingClientRect();
      if (rect && titleRect) {
        setShow(true);
        switch (position) {
          case 'center':
            setPos({
              left: rect.left + rect.width / 2 - titleRect.width / 2,
              top: rect.top - titleRect.height - 6
            });
            break;
          case 'topRight':
            setPos({
              left: rect.right - titleRect.width,
              top: rect.top - titleRect.height - 6
            });
            break;
          case 'left':
            setPos({
              left: rect.left,
              top: rect.top
            });
            break;
          case 'right':
            setPos({
              left: rect.right + 6,
              top: rect.top + rect.height / 2 - titleRect.height / 2
            });
            break;
        }
      }
    }
  };

  return (
    <TooltipFixedStyled
      onMouseEnter={handleShowTooltip}
      onMouseLeave={() => setShow(false)}
      ref={ref}
    >
      {children}
      <Transition in={show && !disabled} timeout={duration}>
        {(state) => (
          <TooltipStyled
            ref={titleRef}
            canBreak={canBreak}
            style={{
              left: pos ? `${pos.left + (offset?.left || 0)}px` : 0,
              top: pos ? `${pos.top + (offset?.top || 0)}px` : 0,
              ...defaultStyle,
              ...transitionStyles[state]
            }}
          >
            {title}
          </TooltipStyled>
        )}
      </Transition>
    </TooltipFixedStyled>
  );
};
