import type { ReactNode } from 'react';
import { SubHeaderStyled } from './style';

interface SubHeaderProps {
  children: ReactNode;
  mt?: string;
}

export const SubHeader = ({ children, mt }: SubHeaderProps) => (
  <SubHeaderStyled mt={mt}>{children}</SubHeaderStyled>
);
