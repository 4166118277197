import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCookie, removeCookie } from 'typescript-cookie';
import { HttpHeader } from '../common/enums/http/http-header.enum';
import { StorageKey } from '../common/enums/storage/storage-key.enum';
import { ApiPath, AuthApiPath } from '../common/enums/http/api-path.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import { AppRoute } from '../common/enums/app/app-route.enum';
import { ApiTag } from '../common/enums/http/api-tag.enum';
import type { RootState } from './store';
import { logOut, setCredential } from './auth-slice';

const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;
    if (token) {
      headers.set(HttpHeader.AUTHORIZATION, `Bearer ${token}`);
    }

    return headers;
  }
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (
    result.error &&
    result.error.status === 401 &&
    getCookie(StorageKey.REFRESH_TOKEN)
  ) {
    const refreshResult = await baseQuery(
      {
        url: `${apiUrl}${ApiPath.AUTH}${AuthApiPath.REFRESH}`,
        method: HttpMethod.POST,
        body: { refresh: getCookie(StorageKey.REFRESH_TOKEN) }
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      const accessToken = (refreshResult.data as { access: string }).access;
      api.dispatch(
        setCredential({
          accessToken
        })
      );
      localStorage.setItem(StorageKey.ACCESS_TOKEN, accessToken);
      if ((args as FetchArgs).url === `${ApiPath.AUTH}${AuthApiPath.VERIFY}`) {
        result = await baseQuery(
          {
            ...(args as FetchArgs),
            body: { token: accessToken }
          },
          api,
          extraOptions
        );
      } else {
        result = await baseQuery(args, api, extraOptions);
      }
    } else {
      api.dispatch(logOut());
      localStorage.removeItem(StorageKey.ACCESS_TOKEN);
      removeCookie(StorageKey.REFRESH_TOKEN, { path: AppRoute.ROOT });
    }
  }

  return result;
};

export const commonApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    ApiTag.USERS,
    ApiTag.USER,
    ApiTag.ROLES,
    ApiTag.ROLE,
    ApiTag.CONTACTS,
    ApiTag.CONTACTS_ID_LIST,
    ApiTag.CONTACT,
    ApiTag.PROJECTS,
    ApiTag.PROJECT,
    ApiTag.EVENTS,
    ApiTag.EVENT,
    ApiTag.PARTNERS,
    ApiTag.PARTNER,
    ApiTag.ADDRESS_BOOKS,
    ApiTag.LETTER
  ],
  endpoints: () => ({})
});
