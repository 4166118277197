import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const NotificationStyled = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0px 6px 20px rgba(61, 52, 78, 0.2);
  display: flex;
  z-index: 100;
  border-radius: 6px;
  min-width: 268px;
  max-width: 400px;
`;

export const Status = styled.div<{ type: 'success' | 'error' }>`
  border-radius: 6px 0 0 6px;
  width: 38px;
  background: ${(p) =>
    p.type === 'success' ? p.theme.colors.success : p.theme.colors.error};
  ${centerFlex}
  svg {
    display: block;
  }
`;

export const Content = styled.div`
  border-radius: 0 6px 6px 0;
  padding: 15px 10px;
  background: ${(p) => p.theme.colors.white};
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.violet.dark};
`;

export const Message = styled.div`
  margin-top: 7px;
  font-weight: 400;
  font-size: 10px;
  line-height: 160%;
  color: ${(p) => p.theme.colors.violet.dark};
`;
