import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../../../components/page-title';
import { SubHeader } from '../../../components/subheader';
import { Popup } from '../../../components/popup';
import { DataTable } from '../../../components/data-table';
import {
  useDeleteProjectMutation,
  useGetArchivedProjectsQuery
} from '../../../api/projects-api';
import { ConfirmPopup } from '../../../components/confirm-popup';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { useScrollHeight } from '../../../hooks/use-scroll-height';
import { FlexContainer } from '../../../components/flex-container';
import { Counter } from '../../../components/counter';
import { useTypedSelector } from '../../../api/store';
import { AddProject } from '../add-project';
import type { ProjectsTable } from '../types';
import { transformProjectsData } from '../helpers';
import { tableHeaders } from '../data';
import { Delegate } from '../delegate';
import { AppRoute } from '../../../common/enums/app/app-route.enum';

export const ProjectsArchPage = () => {
  const tableRef = useRef(null);
  const notification = useNotification();
  const navigate = useNavigate();
  const { pageSize } = useTypedSelector((state) => state['page-data']);
  const { scrollHeight } = useScrollHeight(tableRef);
  const [showAddProject, setShowAddProject] = useState(false);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showConfirmDelete, setShowConfimDelete] = useState(false);
  const [showDelegate, setShowDelegate] = useState(false);

  const { data } = useGetArchivedProjectsQuery(
    { params: `?size=${pageSize}` },
    { refetchOnMountOrArgChange: true }
  );
  const [deleteProject] = useDeleteProjectMutation();

  const handleConfirmDelete = useCallback(() => {
    setShowConfimDelete(true);
  }, []);

  const handleDelete = () => {
    deleteProject({ ids: checkedList })
      .unwrap()
      .then(() => {
        notification({
          title: 'Проєкт/-и видалено!',
          message: 'Ви успішно видалили обрані проєкти.',
          type: 'success'
        });
        setShowConfimDelete(false);
        setCheckedList([]);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  return (
    <>
      <SubHeader>
        <FlexContainer>
          <PageTitle>Архів проєктів</PageTitle>
          <Counter>{data?.count}</Counter>
        </FlexContainer>
      </SubHeader>
      <div ref={tableRef}>
        <DataTable<ProjectsTable>
          onRowClick={(id) => navigate(`${AppRoute.PROJECTS_ARCH}/${id}`)}
          customHeight={scrollHeight}
          actions={[{ title: 'Видалити', handler: handleConfirmDelete }]}
          count={0}
          headers={tableHeaders}
          data={transformProjectsData(data?.results || [])}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
        />
      </div>
      <Popup
        show={showAddProject}
        maxContentWidth="866px"
        setShow={setShowAddProject}
        title="Новий проєкт"
        outsideOnlyEscape
      >
        <AddProject setShow={setShowAddProject} />
      </Popup>
      <ConfirmPopup
        show={showConfirmDelete}
        title={
          checkedList.length === 1
            ? `Чи дійсно бажаєте видалити проєкт ${
                data?.results.find((item) => item.id === checkedList[0])
                  ?.name || ''
              }?`
            : `Чи дійсно бажаєте видалити ${checkedList.length} проєктів`
        }
        setShow={setShowConfimDelete}
        onSubmit={handleDelete}
      />
      <Delegate
        show={showDelegate}
        setShow={setShowDelegate}
        projectIds={checkedList}
      />
    </>
  );
};
