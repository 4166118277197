import type { ReactNode } from 'react';
import { useState } from 'react';
import { AngleSvg } from '../svg/angle-svg';
import {
  AngleIcon,
  FilterItemBody,
  FilterItemHead,
  FilterItemStyled
} from './style';

interface FilterItemProps {
  title: string;
  children: ReactNode;
  isActive?: boolean;
}

export const FilterItem = ({ title, children, isActive }: FilterItemProps) => {
  const [showBody, setShowBody] = useState(false);

  return (
    <FilterItemStyled>
      <FilterItemHead
        isActive={isActive}
        onClick={() => setShowBody((state) => !state)}
      >
        <AngleIcon isActive={isActive} isOpen={showBody}>
          <AngleSvg />
        </AngleIcon>
        {title}
      </FilterItemHead>
      {showBody && <FilterItemBody>{children}</FilterItemBody>}
    </FilterItemStyled>
  );
};
