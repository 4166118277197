import DatePicker from 'react-datepicker';
import { FlexContainer } from '../flex-container';
import { Label, SelectDateContainer } from './style';

import 'react-datepicker/dist/react-datepicker.css';
import './custom-style.css';
import { DateSelect } from './date-select';
import { days, months, years } from './data';

interface CustomDatePickerProps {
  isRange?: boolean;
  selected: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  selectsRange?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  onChange: (date: Date | [Date | null, Date | null] | null) => void;
  startEndLabel?: string;
  shouldCloseOnSelect?: boolean;
  maxDate?: Date;
}

export const CustomDatePicker = ({
  selected,
  startDate,
  endDate,
  selectsRange,
  selectsStart,
  selectsEnd,
  onChange,
  isRange,
  startEndLabel,
  shouldCloseOnSelect,
  maxDate
}: CustomDatePickerProps) => {
  const customLocale = {
    localize: {
      day: (n: number) => days[n],
      month: (n: number) => months[n]
    },
    formatLong: {
      date: () => 'dd/mm/yyyy'
    }
  };

  return (
    <div>
      <DatePicker
        shouldCloseOnSelect={shouldCloseOnSelect}
        onChange={(date) => onChange(date)}
        selected={selected}
        startDate={startDate}
        endDate={endDate}
        selectsRange={selectsRange}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        disabledKeyboardNavigation
        locale={customLocale as any}
        calendarStartDay={1}
        maxDate={maxDate}
        showYearDropdown
        inline
        calendarClassName={`my-date-picker ${
          isRange ? 'my-date-picker-range' : ''
        }`}
        dayClassName={() => 'my-day'}
        renderCustomHeader={({ changeMonth, changeYear, date }) => (
          <SelectDateContainer>
            <FlexContainer>
              {isRange && <Label>{startEndLabel}</Label>}
              <DateSelect
                onChoose={(i) => {
                  changeMonth(i);
                }}
                options={months}
                value={months[date.getMonth()]}
              />
            </FlexContainer>
            <DateSelect
              onChoose={(i) => {
                changeYear(+years[i]);
              }}
              options={years}
              value={`${date.getFullYear()}`}
              isYear
            />
          </SelectDateContainer>
        )}
      />
    </div>
  );
};
