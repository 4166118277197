import type { Dispatch, SetStateAction } from 'react';
import type { OptionItem } from '../../../common/types/option-item';
import { ActivityTab } from '../activity-tab';
import { ActivityTabsStyled } from './style';

interface ActivityTabsProps {
  tabs: OptionItem[];
  currentTab: OptionItem;
  setCurrentTab: Dispatch<SetStateAction<OptionItem>>;
}

export const ActivityTabs = ({
  tabs,
  currentTab,
  setCurrentTab
}: ActivityTabsProps) => (
  <ActivityTabsStyled>
    {tabs.map((item) => (
      <ActivityTab
        key={item.id}
        id={item.id}
        title={item.title}
        setTab={setCurrentTab}
        isActive={currentTab.id === item.id}
      />
    ))}
  </ActivityTabsStyled>
);
