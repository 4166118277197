export const SidebarAngleSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.42426 8L6.77782 8.35355L9.41213 10.9879C9.52929 11.105 9.52929 11.295 9.41213 11.4121L6.42426 8ZM6.42426 8L6.77782 7.64645M6.42426 8L6.77782 7.64645M6.77782 7.64645L9.41213 5.01213C9.52929 4.89497 9.52929 4.70503 9.41213 4.58787M6.77782 7.64645L9.41213 4.58787M9.41213 4.58787C9.29497 4.47071 9.10503 4.47071 8.98787 4.58787M9.41213 4.58787L8.98787 4.58787M8.98787 4.58787L5.78787 7.78787M8.98787 4.58787L5.78787 7.78787M5.78787 7.78787C5.67071 7.90502 5.67071 8.09497 5.78787 8.21213M5.78787 7.78787L5.78787 8.21213M5.78787 8.21213L8.98787 11.4121M5.78787 8.21213L8.98787 11.4121M8.98787 11.4121C9.10503 11.5293 9.29497 11.5293 9.41213 11.4121L8.98787 11.4121Z"
      fill="#171C3D"
      stroke="#171C3D"
    />
  </svg>
);
