import {
  AppRoute,
  DashboardRoute
} from '../../../../common/enums/app/app-route.enum';
import { AudienceSvg } from '../../../../components/svg/dashboard/tabs/audience-svg';
import { DasboardChildrenSvg } from '../../../../components/svg/dashboard/tabs/dasboard-children-svg';
import { DashboardLoacationSvg } from '../../../../components/svg/dashboard/tabs/dashboard-lacation-svg';
import { DashboardPartnerSvg } from '../../../../components/svg/dashboard/tabs/dashboard-partner-svg';
import { DashboardVolunteerSvg } from '../../../../components/svg/dashboard/tabs/dashboard-volunteer-svg';
import { ProjectDashboardSvg } from '../../../../components/svg/dashboard/tabs/progect-dashboard-svg';
import type { ITab } from './types';

export const tabs: ITab[] = [
  {
    path: `${AppRoute.DASHBOARD}/${DashboardRoute.PROJECTS}`,
    icon: ProjectDashboardSvg,
    title: 'Проєкти'
  },
  {
    path: `${AppRoute.DASHBOARD}/${DashboardRoute.AUDIENCE}`,
    icon: AudienceSvg,
    title: 'Аудиторія'
  },
  {
    path: `${AppRoute.DASHBOARD}/${DashboardRoute.CHILDREN}`,
    icon: DasboardChildrenSvg,
    title: 'Діти'
  },
  {
    path: `${AppRoute.DASHBOARD}/${DashboardRoute.LOCATION}`,
    icon: DashboardLoacationSvg,
    title: 'Фактичне місце знаходження'
  },
  {
    path: `${AppRoute.DASHBOARD}/${DashboardRoute.LOCATION_REG}`,
    icon: DashboardLoacationSvg,
    title: 'Фактичне місце реєстрації'
  },
  {
    path: `${AppRoute.DASHBOARD}/${DashboardRoute.VOLUNTEER}`,
    icon: DashboardVolunteerSvg,
    title: 'Волонтери'
  },
  {
    path: `${AppRoute.DASHBOARD}/${DashboardRoute.PARTNERS}`,
    icon: DashboardPartnerSvg,
    title: 'Партнери'
  }
];
