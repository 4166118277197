import type { ReactNode } from 'react';
import { StatusStyled } from './style';

interface StatusProps {
  isActive: boolean;
  children: ReactNode;
}

export const Status = ({ isActive, children }: StatusProps) => (
  <StatusStyled isActive={isActive}>{children}</StatusStyled>
);
