import type { ReactNode } from 'react';
import { IconContainer } from '../icon-container';
import { PlusSvg } from '../svg/plus-svg';
import { ButtonAddStyled } from './style';

interface ButtonAddProps {
  children: ReactNode;
  onClick: () => void;
}

export const ButtonAdd = ({ children, onClick }: ButtonAddProps) => (
  <ButtonAddStyled onClick={onClick}>
    <IconContainer>
      <PlusSvg />
    </IconContainer>
    <span>{children}</span>
  </ButtonAddStyled>
);
