import React from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis } from 'recharts';
import { StringCutter } from '../../../../components/string-cutter';
// import { NoData } from '../bar-chart/style';
import {
  ChartStyled,
  DoubleChartStyled,
  LegendCircle,
  LegendTitle,
  LegendValue,
  LegendsContainer,
  TickStyled
} from './style';

interface DoubleChartProps {
  legends: { name: string; value: number }[];
  colors: string[];
  data: {
    name: string;
    firstValue: number | string;
    secondValue: number | string;
  }[];
}

export const DoubleChart = ({ legends, colors, data }: DoubleChartProps) => (
  // if (!data.filter((item) => item.firstValue || item.secondValue).length) {
  //   return <NoData>Недестатньо даних</NoData>;
  // }

  <DoubleChartStyled>
    <LegendsContainer>
      {legends.map((item, i) => (
        <React.Fragment key={i}>
          <LegendCircle style={{ background: colors[i] }} />
          <LegendTitle>{item.name}</LegendTitle>
          <LegendValue>{item.value}</LegendValue>
        </React.Fragment>
      ))}
    </LegendsContainer>
    <ChartStyled>
      <ResponsiveContainer aspect={2}>
        <BarChart data={data}>
          <XAxis
            dataKey="name"
            strokeWidth={0}
            interval={0}
            tickLine={false}
            tick={({ payload, x, y }) => (
              <foreignObject width="70" height="50" x={x - 35} y={y}>
                <TickStyled>
                  <StringCutter lines="2">{payload.value}</StringCutter>
                </TickStyled>
              </foreignObject>
            )}
          />
          <Bar
            label={{ position: 'top' }}
            barSize={30}
            dataKey="firstValue"
            fill={colors[0]}
          />
          <Bar
            barSize={30}
            dataKey="secondValue"
            fill={colors[1]}
            label={{ position: 'top' }}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartStyled>
  </DoubleChartStyled>
);
