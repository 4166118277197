import type { ReactNode } from 'react';
import { NoWrapStyled } from './style';

interface NoWrapProps {
  children: ReactNode;
}

export const NoWrap = ({ children }: NoWrapProps) => (
  <NoWrapStyled>{children}</NoWrapStyled>
);
