import type { ReactNode } from 'react';
import { AuthBtnStyled } from './style';

interface AuthBtnProps {
  children: ReactNode;
  type: 'submit' | 'reset' | 'button';
  disabled?: boolean;
}

export const AuthBtn = ({ children, type, disabled }: AuthBtnProps) => (
  <AuthBtnStyled disabled={disabled} type={type}>
    {children}
  </AuthBtnStyled>
);
