import type { ChangeEventHandler } from 'react';
import { ColorPicker } from '../../../../components/color-picker';
import { Input } from '../../../../components/input';
import { CloseSvg } from '../../../../components/svg/close-svg';
import { CloseIcon, UserRoleStyled } from './style';

interface UserRoleProps {
  id?: string | number;
  name: string;
  color?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  setColor: (color: string) => void;
  disabledEdit?: boolean;
  disabledDelete?: boolean;
  onDeleteRole: (arg: { id?: string | number; name: string }) => void;
  onClearInput: () => void;
  disabledColorEdit?: boolean;
}

export const UserRole = ({
  name,
  color,
  disabledEdit,
  disabledDelete,
  disabledColorEdit,
  setColor,
  onDeleteRole,
  onChange,
  id,
  onClearInput
}: UserRoleProps) => (
  <UserRoleStyled>
    <Input
      disabled={disabledEdit}
      value={name}
      onChange={onChange}
      withBtns={!disabledEdit}
      placeholder="Введіть назву ролі "
      onClearInput={onClearInput}
    />
    <ColorPicker
      disabled={disabledColorEdit}
      color={color}
      setColor={setColor}
    />
    {!disabledDelete && (
      <CloseIcon onClick={() => onDeleteRole({ id, name })}>
        <CloseSvg />
      </CloseIcon>
    )}
  </UserRoleStyled>
);
