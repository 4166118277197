import type { ChangeEventHandler, HTMLInputTypeAttribute } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { Label } from '../label';
import { CloseSvg } from '../svg/close-svg';
import { EditSvg } from '../svg/edit-svg';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { InputBtn, InputDateStyled, InputStyled, InputWrapper } from './style';

interface InputProps {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type?: HTMLInputTypeAttribute;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  withBtns?: boolean;
  onClearInput?: () => void;
  mask?: string;
  maskChar?: string;
  mt?: string;
  mb?: string;
  asPlaceholder?: boolean;
}

export const Input = ({
  label,
  onChange,
  value,
  type,
  name,
  required,
  disabled,
  withBtns,
  placeholder,
  mask,
  mt,
  mb,
  maskChar,
  onClearInput,
  asPlaceholder
}: InputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const refWrapper = useRef<HTMLInputElement>(null);
  const [editable, setEditable] = useState(false);
  useOutsideClick(refWrapper, () => {
    setEditable(false);
  });

  useEffect(() => {
    if (editable) {
      ref.current?.focus();
    }
  }, [editable]);

  const toggleEditable = () => {
    setEditable((state) => !state);
  };

  const handleClear = () => {
    if (onClearInput && ref.current) {
      onClearInput();
      ref.current.focus();
    }
  };

  return (
    <InputWrapper ref={refWrapper} mt={mt} mb={mb} withBtns={withBtns}>
      {label && <Label>{label}</Label>}
      {mask ? (
        <InputDateStyled
          mask={mask}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          name={name}
          required={required}
          maskChar={maskChar ?? '_'}
          disabled={disabled}
        />
      ) : (
        <InputStyled
          ref={ref}
          type={type}
          onChange={onChange}
          value={value}
          name={name}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={() => setEditable(true)}
          asPlaceholder={asPlaceholder}
        />
      )}

      {withBtns &&
        (editable ? (
          <InputBtn onClick={handleClear}>
            <CloseSvg width="11.43" height="11.43" />
          </InputBtn>
        ) : (
          <InputBtn onClick={toggleEditable}>
            <EditSvg />
          </InputBtn>
        ))}
    </InputWrapper>
  );
};
