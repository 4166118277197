import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { HeaderItem } from '../components/data-table';
import type { DynamicContact } from '../models/contact';
import type { ProjectsTable } from '../pages/projects/types';
import type { EventsTable } from '../pages/events/types';
import type { CurrentEventsTable } from '../pages/events/current-event/types';

type PageDataState = {
  contactColumns: HeaderItem<DynamicContact>[] | null;
  projectColumns: HeaderItem<ProjectsTable>[] | null;
  eventsColumns: HeaderItem<EventsTable>[] | null;
  contactInEventColumns: HeaderItem<CurrentEventsTable>[] | null;
  pageSize: number;
  menuIsOpen: boolean;
  phoneSearchTerm: string;
  isKanbanPage: boolean;
};

const initialState: PageDataState = {
  contactColumns: null,
  projectColumns: null,
  eventsColumns: null,
  contactInEventColumns: null,
  pageSize: 25,
  menuIsOpen: true,
  phoneSearchTerm: '',
  isKanbanPage: false
};

const pageDataSlice = createSlice({
  name: 'page-data',
  initialState,
  reducers: {
    setContactColumns: (
      state,
      { payload }: PayloadAction<HeaderItem<DynamicContact>[] | null>
    ) => {
      state.contactColumns = payload;
    },
    setProjectColumns: (
      state,
      { payload }: PayloadAction<HeaderItem<ProjectsTable>[] | null>
    ) => {
      state.projectColumns = payload;
    },
    setEventsColumns: (
      state,
      { payload }: PayloadAction<HeaderItem<EventsTable>[] | null>
    ) => {
      state.eventsColumns = payload;
    },
    setContactInEventColumns: (
      state,
      { payload }: PayloadAction<HeaderItem<CurrentEventsTable>[] | null>
    ) => {
      state.contactInEventColumns = payload;
    },
    deleteContactColumns: (state) => {
      state.contactColumns = null;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    toggleMenu: (state) => {
      state.menuIsOpen = !state.menuIsOpen;
    },
    closeMenu: (state) => {
      state.menuIsOpen = false;
    },
    setPhoneSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.phoneSearchTerm = payload;
    },
    setIsKanbanPage: (state, { payload }: PayloadAction<boolean>) => {
      state.isKanbanPage = payload;
    }
  }
});

export const {
  setContactColumns,
  setPageSize,
  setProjectColumns,
  setEventsColumns,
  setContactInEventColumns,
  toggleMenu,
  closeMenu,
  setPhoneSearchTerm,
  setIsKanbanPage
} = pageDataSlice.actions;

export default pageDataSlice.reducer;
