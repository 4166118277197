export const ClipboardSvg = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 0.833496H2.33333C1.32081 0.833496 0.5 1.65431 0.5 2.66683V9.90483C0.5 10.9174 1.32081 11.7382 2.33333 11.7382H8.33333C9.34586 11.7382 10.1667 10.9174 10.1667 9.90483V2.66683C10.1667 1.65431 9.34586 0.833496 8.33333 0.833496Z"
      fill="#306FE3"
    />
    <path
      d="M13.4994 6.00033V13.3337C13.4996 13.5745 13.4523 13.8129 13.3602 14.0355C13.2682 14.258 13.1331 14.4601 12.9628 14.6304C12.7926 14.8007 12.5904 14.9357 12.3679 15.0278C12.1454 15.1199 11.9069 15.1672 11.6661 15.167H5.58609C5.09909 15.1675 4.63178 14.9748 4.28679 14.631C3.9418 14.2873 3.74733 13.8207 3.74609 13.3337V12.407H8.33276C8.99537 12.4056 9.63043 12.1417 10.099 11.6732C10.5675 11.2047 10.8314 10.5696 10.8328 9.90699V4.16699H11.6661C11.9069 4.16682 12.1454 4.21412 12.3679 4.30619C12.5904 4.39826 12.7926 4.5333 12.9628 4.70357C13.1331 4.87385 13.2682 5.07602 13.3602 5.29853C13.4523 5.52104 13.4996 5.75952 13.4994 6.00033Z"
      fill="#306FE3"
    />
  </svg>
);
