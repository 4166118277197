import styled from 'styled-components';

export const SwitcherKanbanStyled = styled.div<{ isKanban: boolean }>`
  margin-right: 20px;
  position: relative;
  height: 36px;
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  border-radius: 20px;
  width: 196px;
  display: flex;
  :after {
    content: '';
    position: absolute;
    left: ${(p) => (p.isKanban ? '98px' : '-1px')};
    top: -1px;
    transition: left 0.2s;
    background: ${(p) => p.theme.colors.primary};
    width: 98px;
    height: 36px;
    border-radius: 20px;
    z-index: 0;
  }
`;

export const SwitcherItem = styled.div<{
  isActive?: boolean;
  isRight?: boolean;
}>`
  position: relative;
  flex: 1;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  color: ${(p) => (p.isActive ? p.theme.colors.white : p.theme.colors.primary)};
  svg {
    path {
      fill: ${(p) =>
        p.isActive ? p.theme.colors.white : p.theme.colors.primary};
      fill: stroke 0.2s;
    }
    rect {
      stroke: ${(p) =>
        p.isActive ? p.theme.colors.white : p.theme.colors.primary};
      transition: stroke 0.2s;
    }
  }
  cursor: pointer;
  z-index: 1;
  transition: color 0.2s;
`;
