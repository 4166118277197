import { useNavigate } from 'react-router-dom';
import { BtnPrimary } from '../btn-primary';
import { EditBtn } from '../edit-btn';
import { FlexContainer } from '../flex-container';
import { AngleSvg } from '../svg/angle-svg';
import { useGetUserMeQuery } from '../../api/user-api';
import { checkIsManager } from '../../helpers/is-manager';
import { useExportReportMutation } from '../../api/events-api';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { BackBtn, ProjectHeaderStyled, ProjectId, Title } from './style';

interface ProjectHeaderProps {
  title: string;
  idTitle?: string;
  onEdit: () => void;
  onFinish: () => void;
  finishTitle?: string;
  backLink: string;
  canFinish?: boolean;
  withOutEdit?: boolean;
  onlyTitle?: boolean;
  eventId?: string | number;
  eventFinished?: boolean;
}

export const ProjectHeader = ({
  title,
  idTitle,
  onEdit,
  onFinish,
  finishTitle = 'Завершити проєкт',
  backLink,
  canFinish,
  withOutEdit,
  onlyTitle,
  eventId,
  eventFinished
}: ProjectHeaderProps) => {
  const navigate = useNavigate();
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [exportReport] = useExportReportMutation();

  const handleReport = () => {
    if (eventId) {
      exportReport(eventId)
        .unwrap()
        .then(() => {
          notification({
            type: 'success',
            title: 'Звіт експортовано',
            message: ''
          });
        })
        .catch((err) =>
          notification({
            type: 'error',
            title: 'Помилка',
            message: getApiError(err)
          })
        );
    }
  };

  return (
    <ProjectHeaderStyled>
      <FlexContainer>
        <BackBtn onClick={() => navigate(backLink)}>
          <AngleSvg />
        </BackBtn>
        <Title>{title}</Title>
        {!onlyTitle && (
          <>
            <ProjectId>{idTitle}</ProjectId>
            {!withOutEdit && <EditBtn onClick={onEdit} />}
          </>
        )}
      </FlexContainer>
      <FlexContainer gap="20px">
        {!checkIsManager(userMe) && eventId && eventFinished && (
          <BtnPrimary onClick={handleReport} type="button">
            Звіт
          </BtnPrimary>
        )}
        {canFinish && (
          <BtnPrimary fz="14px" p="11px 12px" onClick={onFinish}>
            {finishTitle}
          </BtnPrimary>
        )}
      </FlexContainer>
    </ProjectHeaderStyled>
  );
};
