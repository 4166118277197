import { useState } from 'react';
import { Input } from '../../../components/input';
import { IconContainer } from '../../../components/icon-container';
import { CloseEditSvg } from '../../../components/svg/close-edit-svg';
import { AddItem } from '../../../components/add-item/indes';
import { FormBtns } from '../../../components/form-btns';
import { CheckBox } from '../../../components/checkbox';
import {
  EventTemplateStyled,
  InputsContainer,
  Step,
  StepsWrapper,
  TemplateTitle
} from './style';

interface EventTemplateProps {
  initialSteps: { number: number; name: string }[];
  initialLastStep: { number: number; name: string };
  title: string;
  onCancel: () => void;
  onSubmit: (
    steps: { number: number; name: string }[],
    saveTemplate?: boolean
  ) => void;
  canSaveTemplate?: boolean;
}

export const EventTemplate = ({
  initialSteps,
  title,
  onCancel,
  onSubmit,
  canSaveTemplate,
  initialLastStep
}: EventTemplateProps) => {
  const [steps, setSteps] = useState(initialSteps);
  const [lastStep, setLastStep] = useState(initialLastStep);
  const [saveTemplate, setSaveTemplate] = useState(false);

  const handleDeleteStep = (i: number) => {
    if (steps.length === 1) {
      setSteps([{ number: 1, name: '' }]);

      return;
    }

    setSteps((state) =>
      state
        .filter((_, j) => j !== i)
        .map((item, j) => ({ number: j + 1, name: item.name }))
    );
  };

  console.log(steps);

  return (
    <EventTemplateStyled>
      <StepsWrapper>
        <div style={{ marginBottom: '20px' }}>
          <TemplateTitle>{title}</TemplateTitle>
          <InputsContainer>
            <div>
              {steps.map((step, i) => (
                <Step key={i}>
                  <Input
                    value={step.name}
                    label={`Крок ${i + 1}`}
                    placeholder="Введіть назву"
                    onChange={(e) =>
                      setSteps((state) =>
                        state.map((item, j) =>
                          i === j
                            ? { number: i + 1, name: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                  <IconContainer
                    m="24px 0 0 0"
                    onClick={() => handleDeleteStep(i)}
                  >
                    <CloseEditSvg />
                  </IconContainer>
                </Step>
              ))}
            </div>
            <Input
              value={lastStep.name}
              onClearInput={() =>
                setLastStep((state) => ({ ...state, name: '' }))
              }
              withBtns
              label={'Останній крок'}
              placeholder="Введіть назву"
              onChange={(e) =>
                setLastStep({ number: steps.length + 1, name: e.target.value })
              }
            />
          </InputsContainer>
          <AddItem
            mt="16px"
            title="+ Додати крок"
            onClick={() =>
              setSteps((state) => [
                ...state,
                { number: state.length + 1, name: '' }
              ])
            }
          />
        </div>
        {canSaveTemplate && (
          <CheckBox
            label="Зберегти шаблон"
            checked={saveTemplate}
            setChecked={setSaveTemplate}
          />
        )}
      </StepsWrapper>
      <FormBtns
        onCancel={onCancel}
        secondaryTitle="Назад"
        primaryTitle="Зберегти"
        onSubmit={() =>
          onSubmit(
            [...steps, { number: steps.length + 1, name: lastStep.name }],
            saveTemplate
          )
        }
      />
    </EventTemplateStyled>
  );
};
