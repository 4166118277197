import { ApiPath } from '../common/enums/http/api-path.enum';
import { ApiTag } from '../common/enums/http/api-tag.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import type { ReponseWithPagination } from '../common/types/response-with-pagination';
import type { Project } from '../models/project';
import { commonApi } from './common-api';

export const projectsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<
      ReponseWithPagination<Project>,
      { params: string }
    >({
      query: ({ params }) => `${ApiPath.PROJECTS}${ApiPath.ROOT}${params}`,
      providesTags: [ApiTag.PROJECTS]
    }),
    getArchivedProjects: builder.query<
      ReponseWithPagination<Project>,
      { params: string }
    >({
      query: ({ params }) =>
        `${ApiPath.PROJECTS_ARCHIVED}${ApiPath.ROOT}${params}`,
      providesTags: [ApiTag.PROJECTS]
    }),
    getCurrentProject: builder.query<Project, { id: string | undefined }>({
      query: ({ id }) =>
        `${ApiPath.PROJECTS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.PROJECT]
    }),
    getCurrentArchProject: builder.query<Project, { id: string | undefined }>({
      query: ({ id }) =>
        `${ApiPath.PROJECTS_ARCHIVED}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.PROJECT]
    }),
    createProject: builder.mutation<Project, Record<string, unknown>>({
      query: (data) => ({
        url: `${ApiPath.PROJECTS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.PROJECTS]
    }),
    editProject: builder.mutation<
      Project,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.PROJECTS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.PROJECTS, ApiTag.PROJECT, ApiTag.EVENTS]
    }),
    editArchProject: builder.mutation<
      Project,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.PROJECTS_ARCHIVED}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.PROJECTS, ApiTag.PROJECT, ApiTag.EVENTS]
    }),
    delegateProject: builder.mutation<Project, Record<string, unknown>>({
      query: (data) => ({
        url: `${ApiPath.PROJECTS_DELEGATE}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.PROJECTS]
    }),
    deleteProject: builder.mutation<Project, { ids: (string | number)[] }>({
      query: ({ ids }) => ({
        url: `${ApiPath.PROJECTS}${ApiPath.ROOT}?ids=${ids.join(',')}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.PROJECTS]
    })
  }),
  overrideExisting: false
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useGetCurrentProjectQuery,
  useDeleteProjectMutation,
  useDelegateProjectMutation,
  useEditProjectMutation,
  useGetArchivedProjectsQuery,
  useGetCurrentArchProjectQuery,
  useEditArchProjectMutation
} = projectsApi;
