import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface NotificationOptions {
  type: 'success' | 'error';
  title: string;
  message: string;
}

type NotificationState = {
  show: boolean;
  options: NotificationOptions;
};

const initialState: NotificationState = {
  show: false,
  options: {
    type: 'success',
    title: '',
    message: ''
  }
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (
      state,
      { payload }: PayloadAction<NotificationOptions>
    ) => {
      state.show = true;
      state.options = payload;
    },
    closeNotification: (state) => {
      state.show = false;
      state.options = {
        type: 'success',
        title: '',
        message: ''
      };
    }
  }
});

export const { showNotification, closeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
