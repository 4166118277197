import styled from 'styled-components';

export const InputDateStyled = styled.div`
  position: relative;
`;

export const CalendarIcon = styled.div<{ disabled?: boolean }>`
  position: absolute;
  width: 16px;
  height: 16px;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
`;

export const DatePickerWrapper = styled.div<{ openUp?: boolean }>`
  position: absolute;
  right: 0;
  top: ${(p) => (p.openUp ? 'unset' : '64px')};
  bottom: ${(p) => (p.openUp ? 'calc(100% - 20px)' : 'unset')};
  z-index: 2;
`;
