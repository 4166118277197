import styled, { css } from 'styled-components';
import ReactInputMask from 'react-input-mask';
import { centerFlex } from '../../style/helpers';

interface InputStyledProps {
  withBtns?: boolean;
  mt?: string;
  mb?: string;
  asPlaceholder?: boolean;
}

const inputCss = css<InputStyledProps>`
  display: block;
  width: 100%;
  padding: 8px 12px;
  height: 36px;
  padding-right: ${(p) => (p.withBtns ? '30px' : '12px')};
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) =>
    p.asPlaceholder ? p.theme.colors.grey.light : p.theme.colors.blue.dark};
  ::placeholder {
    color: ${(p) => p.theme.colors.grey.light};
  }
  :focus {
    border: 1px solid ${(p) => p.theme.colors.blue.primary};
  }
  :disabled {
    background: ${(p) => p.theme.colors.white};
  }
`;

export const InputWrapper = styled.div<InputStyledProps>`
  margin-top: ${(p) => p.mt || 0};
  margin-bottom: ${(p) => p.mb || 0};
  position: relative;
`;

export const InputStyled = styled.input<InputStyledProps>`
  ${inputCss}
`;

export const InputDateStyled = styled(ReactInputMask)<InputStyledProps>`
  ${inputCss}
`;

export const InputBtn = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  ${centerFlex}
  cursor: pointer;
  svg {
    display: block;
    max-width: 100%;
  }
`;
