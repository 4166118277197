import type { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { useRef, useState } from 'react';
import { EmailSvg } from '../../svg/auth/email-svg';
import { PasswordSvg } from '../../svg/auth/password-svg';
import { EyeSvg } from '../../svg/auth/eye-svg';
import { EyeCloseSvg } from '../../svg/auth/eye-close-svg';
import {
  AuthInputEyeIcon,
  AuthInputMainIcon,
  AuthInputStyled,
  AuthInputWrapper,
  HelpText
} from './style';

interface AuthInputProps {
  type: 'email' | 'password' | 'text';
  placeholder?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  error?: string;
  serverError?: string;
  errorFullWidth?: boolean;
}

export const AuthInput = ({
  type,
  placeholder,
  value,
  setValue,
  error,
  serverError,
  errorFullWidth
}: AuthInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [needToValidate, setNeedToValidate] = useState(false);

  const handleShowPassword: MouseEventHandler<HTMLDivElement> = () => {
    setShowPassword((state) => !state);
    inputRef.current?.focus();
  };

  const handleBlur = () => {
    setFocus(false);
    setNeedToValidate(true);
  };

  return (
    <div>
      <AuthInputWrapper $focus={focus} error={!!(needToValidate && error)}>
        <AuthInputMainIcon>
          {type === 'text' ? (
            <EmailSvg $focus={focus} />
          ) : (
            <PasswordSvg $focus={focus} />
          )}
        </AuthInputMainIcon>
        <AuthInputStyled
          $focus={focus}
          ref={inputRef}
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          onFocus={() => setFocus(true)}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {type === 'password' && (
          <AuthInputEyeIcon onClick={handleShowPassword}>
            {showPassword ? (
              <EyeSvg $focus={focus} />
            ) : (
              <EyeCloseSvg $focus={focus} />
            )}
          </AuthInputEyeIcon>
        )}
      </AuthInputWrapper>
      <HelpText fullWidth={errorFullWidth}>
        {serverError ? serverError : needToValidate && error}
      </HelpText>
    </div>
  );
};
