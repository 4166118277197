import type { Dispatch, SetStateAction } from 'react';
import { BtnPrimary } from '../btn-primary';
import { splitByMonth } from '../../helpers/split-by-month';
import type { Call as ICall } from '../../models/call';
import { BtnSecondary } from '../btn-secondary';
import type {
  DeleteCall,
  EditCall
} from '../../common/types/rtk-triggers/call';
import { useMakeCallMutation } from '../../api/binotel-api';
import { useGetUserMeQuery } from '../../api/user-api';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import {
  CreateCall,
  CallsByMonth,
  CallsByMonthTitle,
  CallsContainer,
  CallsStyled
} from './style';
import { Call } from './call';

interface CallsProps {
  setShowNewCall: Dispatch<SetStateAction<boolean>>;
  calls: ICall[];
  contactFullName: string;
  phone?: string;
  editCall: EditCall;
  deleteCall: DeleteCall;
  isPartner?: boolean;
  contactId?: string | number;
}

export const Calls = ({
  setShowNewCall,
  calls,
  contactFullName,
  phone,
  editCall,
  deleteCall,
  isPartner,
  contactId
}: CallsProps) => {
  const notification = useNotification();
  const [makeCall] = useMakeCallMutation();
  const { data: userMe } = useGetUserMeQuery('');

  const handleCall = () => {
    if (contactId) {
      makeCall({
        contact_id: contactId,
        internal_number: userMe?.binotel || 901
      })
        .unwrap()
        .then((res) => {
          if (res.status === 'error') {
            notification({
              type: 'error',
              title: 'Помилка',
              message: res.message
            });
          }
        })
        .catch((err) => {
          notification({
            type: 'error',
            title: 'Помилка',
            message: getApiError(err)
          });
        });
    }
  };

  return (
    <CallsStyled>
      <CreateCall>
        <BtnSecondary onClick={() => setShowNewCall(true)}>
          Внести дзвінок
        </BtnSecondary>
        {isPartner ? (
          <a href={`tel:${phone}`}>
            {' '}
            <BtnPrimary onClick={() => {}}>Зателефонувати</BtnPrimary>
          </a>
        ) : (
          <BtnPrimary onClick={handleCall}>Зателефонувати</BtnPrimary>
        )}
      </CreateCall>
      <CallsContainer>
        {splitByMonth<ICall>(calls).map((item, i) => (
          <CallsByMonth key={i}>
            <CallsByMonthTitle>{item.title}</CallsByMonthTitle>
            {item.list.map((call) => (
              <Call
                key={call.id}
                call={call}
                fullName={contactFullName}
                editCall={editCall}
                deleteCall={deleteCall}
                isPartner={isPartner}
              />
            ))}
          </CallsByMonth>
        ))}
      </CallsContainer>
    </CallsStyled>
  );
};
