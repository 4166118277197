import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { DoughnutChart } from '../../components/doughnut-chart';
import { useTypedSelector } from '../../../../api/store';
import { useGetCurrentProjectStatistcsQuery } from '../../../../api/dashboard-api';
import { ChartsLayout } from '../../components/charts-layout';
import { ChartContainer } from '../../components/chart-container';
import { DoubleChart } from '../../components/double-chart';
import { BarChart } from '../../components/bar-chart';
import { Range } from '../../components/range';
import { RangeHorisontalContainer } from '../../components/range-horisontal-container';
import { ChartsSlider } from '../../components/charts-slider';
import { splitArr } from '../../../../helpers/split-arr';
import type { DashboardEvents } from '../../../../models/dashboard';
import {
  AppRoute,
  DashboardRoute
} from '../../../../common/enums/app/app-route.enum';
import { useClearDate } from '../../hooks/use-clear-date';
import { Loader } from '../../../../components/loader';
import { groupByRegion } from '../../helpers/group-by-region';
import { CategoriesChart } from '../../components/categories-chart';

export const CurrentDashboardProject = () => {
  useClearDate();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const { colors, date } = useTypedSelector((state) => state.dashboard);
  const projectColorObj = colors.find(
    (item) => item.projectId.toString() === id
  );

  const [activeCity, setActiveCity] = useState({ id: '', name: '' });
  const [activeRegion, setActiveRegion] = useState({ id: '', name: '' });

  const [secondChartIsOpen, setSecondChartIsOpen] = useState(true);

  const { data, isFetching } = useGetCurrentProjectStatistcsQuery(
    {
      id: id || '',
      params: `?liv_city=${activeCity.name}&liv_region=${activeRegion.name}&created_start=${date.start}&created_end=${date.end}`
    },
    { refetchOnMountOrArgChange: true, skip: !id }
  );

  const doughnutTitle = useMemo(() => {
    if (activeCity.name && activeRegion.name) {
      return `${activeCity.name} та ${activeRegion.name} область`;
    }

    if (activeCity.name) {
      return `${activeCity.name}`;
    }

    if (activeRegion.name) {
      return `${activeRegion.name} область`;
    }

    return `Події (${data?.events.length || 0})`;
  }, [activeCity, activeRegion, data?.events]);

  if (!data) {
    return <Loader />;
  }

  return (
    <ChartsLayout
      activeCity={activeCity}
      activeRegion={activeRegion}
      setActiveCity={setActiveCity}
      setActiveRegion={setActiveRegion}
      total={data.contacts_count}
      secondChartIsOpen={secondChartIsOpen}
      isProject
      isFetching={isFetching}
      dataByRegion={groupByRegion(data.map)}
    >
      <DoughnutChart
        title={doughnutTitle}
        data={data.events.map((item) => ({
          name: item.name,
          value: item.contacts_count,
          onClick: () => {
            navigate(
              `${AppRoute.DASHBOARD}/${DashboardRoute.PROJECTS}/${id}/${item.id}/`
            );
          }
        }))}
        colors={projectColorObj?.events.map((item) => item.color) || []}
        totalValue={data.contacts_count}
        totalLabel="загальна кількість"
      />
      <ChartsSlider
        title="Вартість подій"
        autoHeightChart={{
          isOpen: secondChartIsOpen,
          setIsOpen: setSecondChartIsOpen
        }}
        data={splitArr<DashboardEvents>(data.events, 4).map((item, i) => ({
          title: '',
          chart: (
            <BarChart
              tickAlign="center"
              yMax={Math.max(
                ...data.events.map((item) => Math.floor(item.budget))
              )}
              data={item.map((subItem) => ({
                name: subItem.name,
                value: Math.floor(subItem.budget)
              }))}
              color={
                splitArr(
                  projectColorObj?.events.map((item) => item.color) || [],
                  4
                )[i]
              }
              tickMaxWidth={100}
            />
          )
        }))}
      />
      <ChartsSlider
        title="Вартість проєкта"
        data={splitArr<DashboardEvents>(data.events, 3).map((item, i) => ({
          title: '',
          chart: (
            <BarChart
              tickAlign="center"
              aspect={2}
              yMax={Math.max(
                ...data.events.map((item) => Math.floor(item.budget))
              )}
              data={[
                ...item.map((subItem) => ({
                  name: subItem.name,
                  value: Math.floor(subItem.budget)
                })),
                { name: 'Загальна кількість балів', value: data.budget }
              ]}
              color={[
                ...(splitArr(
                  projectColorObj?.events.map((item) => item.color) || [],
                  3
                )[i] || []),
                projectColorObj?.color || ''
              ]}
              tickMaxWidth={80}
            />
          )
        }))}
      />
      <ChartContainer title="Кількість зібраних контактів">
        <RangeHorisontalContainer
          data={[
            {
              label: 'Всі контакти',
              total: data.contacts_count,
              value: data.contacts_count
            },
            {
              label: 'Відвідали проєкт',
              total: data.contacts_count,
              value: data.visitors
            }
          ]}
        />
      </ChartContainer>
      <ChartContainer title="Кількість дітей">
        <RangeHorisontalContainer
          data={[
            {
              label: 'Всіх дітей',
              total: data.kids_count,
              value: data.kids_count
            },
            {
              label: 'Отримав послугу',
              total: data.kids_count,
              value: data.visitors_kids_count
            }
          ]}
        />
      </ChartContainer>
      <ChartContainer title="Вартість одного контакту проєкту">
        <Range
          total={data.contact_budget}
          totalAlign="center"
          totalTitle={`${data.contact_budget} Балів`}
          value={data.contact_budget}
        />
      </ChartContainer>
      <ChartContainer title="Вік та гендер відвідувачів">
        <DoubleChart
          legends={[
            { name: 'Чоловіки', value: data.men_count },
            { name: 'Жінки', value: data.women_count }
          ]}
          colors={[
            projectColorObj?.color || '',
            theme.colors.primary === projectColorObj?.color
              ? '#306FE3'
              : theme.colors.primary
          ]}
          data={data.age_and_gender.map((item) => ({
            name: item.age_gradation,
            firstValue: item.men,
            secondValue: item.women
          }))}
        />
      </ChartContainer>
      <ChartContainer title="Вік та стать дітей">
        <DoubleChart
          legends={[
            { name: 'Хлопчики', value: data.boys_count },
            { name: 'Дівчата', value: data.girls_count }
          ]}
          colors={[
            projectColorObj?.color || '',
            theme.colors.primary === projectColorObj?.color
              ? '#306FE3'
              : theme.colors.primary
          ]}
          data={data.kids_age_and_gender.map((item) => ({
            name: item.age_gradation,
            firstValue: item.men,
            secondValue: item.women
          }))}
        />
      </ChartContainer>
      <CategoriesChart
        withAnyCategory={data.with_any_category}
        categoriesCount={data.categories_count}
        contactsCount={data.contacts_count}
      />
      <ChartsSlider
        title="Статус (П Н Н)"
        data={[
          {
            title: 'Загальна кількість людей ',
            chart: (
              <BarChart
                aspect={2}
                tickAlign="center"
                color={projectColorObj?.color || ''}
                data={[
                  ...data.satisfaction_count
                    .filter((item) => item.id)
                    .map((item) => {
                      const [_, [name, value]] = Object.entries(item);

                      return { name, value };
                    }),
                  {
                    name: 'Всі статуси',
                    value: data.satisfaction_count
                      .filter((item) => item.id)
                      .map((item) => Object.values(item)[1])
                      .reduce((a, b) => a + b, 0)
                  }
                ]}
              />
            )
          },
          {
            title: 'Загальна кількість людей, які отримали послугу',
            chart: (
              <BarChart
                tickAlign="center"
                aspect={2}
                color={projectColorObj?.color || ''}
                data={[
                  ...data.visitors_satisfaction_count
                    .filter((item) => item.id)
                    .map((item) => {
                      const [_, [name, value]] = Object.entries(item);

                      return { name, value };
                    }),
                  {
                    name: 'Всі статуси',
                    value: data.visitors_satisfaction_count
                      .filter((item) => item.id)
                      .map((item) => Object.values(item)[1])
                      .reduce((a, b) => a + b, 0)
                  }
                ]}
              />
            )
          }
        ]}
      />
    </ChartsLayout>
  );
};
