import type { ReactNode } from 'react';
import { PageTitleStyled } from './style';

interface PageTitleProps {
  children: ReactNode;
}

export const PageTitle = ({ children }: PageTitleProps) => (
  <PageTitleStyled>{children}</PageTitleStyled>
);
