import type { Dispatch, SetStateAction } from 'react';
import { AccordionBlock } from '../../../../components/accordion-block';
import { AccordionItem } from '../../../../components/accordion-item';
import { EditBtn } from '../../../../components/edit-btn';
import { UserInfo } from '../../../../components/user-info';
import { useGetUserMeQuery } from '../../../../api/user-api';
import type { Partner } from '../../../../models/partner';
import { SidebarScrollBlock } from '../../../users/current-user/current-user-sidebar/sidebar-scroll-block';
import {
  HeaderItem,
  UserId,
  PartnerSidebarHeader,
  PartnerSidebarStyled
} from './style';

interface CurrenPartnerSidebarProps {
  partner?: Partner;
  setEdit: Dispatch<SetStateAction<boolean>>;
}

export const CurrentPartnerSidebar = ({
  partner,
  setEdit
}: CurrenPartnerSidebarProps) => {
  const { data: userMe } = useGetUserMeQuery('');

  if (!userMe) {
    return null;
  }

  const canEdit = userMe.role?.change_partner || userMe.is_superuser;

  return (
    <PartnerSidebarStyled>
      {!!partner && (
        <>
          {' '}
          <PartnerSidebarHeader>
            <HeaderItem>
              <UserInfo lastName={partner.name} firstName="" patronymic="" />
              {canEdit && <EditBtn onClick={() => setEdit(true)} />}
            </HeaderItem>
            <HeaderItem>
              <UserId>ID Партнера: {partner.id}</UserId>
            </HeaderItem>
          </PartnerSidebarHeader>
          <SidebarScrollBlock>
            <AccordionBlock maxHeight={900} title="Дані організації">
              <AccordionItem
                title="Назва юридичної особи"
                data={partner.name}
              />
              <AccordionItem title="Код ЄДРПО" data={partner.edrpou} />
              <AccordionItem
                title="Тип організації"
                data={partner.org_type?.title}
              />
              <AccordionItem
                title="Напрям діяльності"
                data={partner.activity_field}
              />
              <AccordionItem
                title="Кількість співробітників"
                data={partner.employees_number}
              />
              <AccordionItem title="Коментар" data={partner.comment} />
            </AccordionBlock>
            <AccordionBlock maxHeight={130} title="Контактні дані">
              <AccordionItem title="Місто" data={partner.location} />
              <AccordionItem title="Область" data={partner.region.title} />
              <AccordionItem
                title="Контактна особа"
                data={partner.contact_person}
              />
              <AccordionItem title="Телефон" data={partner.phone} />
              <AccordionItem title="Email" data={partner.email} />
            </AccordionBlock>
          </SidebarScrollBlock>
        </>
      )}
    </PartnerSidebarStyled>
  );
};
