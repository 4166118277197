import type { ReactNode } from 'react';
import { FlexContainerStyled } from './style';

interface FlexContaineProps {
  children?: ReactNode;
  gap?: string;
  wrappMd?: boolean;
}

export const FlexContainer = ({
  children,
  gap,
  wrappMd
}: FlexContaineProps) => (
  <FlexContainerStyled gap={gap} wrappMd={wrappMd}>
    {children}
  </FlexContainerStyled>
);
