import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
  accessToken: string | null;
  isAuthorized: 'yes' | 'no' | 'needToCheck';
};

const initialState: AuthState = {
  accessToken: null,
  isAuthorized: 'needToCheck'
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredential: (
      state,
      {
        payload: { accessToken }
      }: PayloadAction<{
        accessToken: string;
      }>
    ) => {
      state.accessToken = accessToken;
      state.isAuthorized = 'yes';
    },
    logOut: (state) => {
      state.accessToken = null;
      state.isAuthorized = 'no';
    }
  }
});

export const { setCredential, logOut } = authSlice.actions;

export default authSlice.reducer;
