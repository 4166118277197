import styled from 'styled-components';
import { centerFlex, scrollBar } from '../../style/helpers';

export const PopupStyled = styled.div<{ customPosition?: boolean }>`
  padding: 20px;
  position: fixed;
  left: ${(p) => (p.customPosition ? 'calc(100vw * -0.17)' : 0)};
  width: 100vw;
  top: 0;
  height: 100vh;
  background: ${(p) => p.theme.colors.popup.bg};
  ${centerFlex}
  flex-wrap: wrap;
  ${scrollBar}
  z-index: 2;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    left: ${(p) => (p.customPosition ? 'calc(100vw * -0.1)' : 0)};
  }
`;

export const PopupContent = styled.div<{ maxContentWidth?: string }>`
  padding: 0 30px;
  width: 100%;
  max-width: ${(p) => p.maxContentWidth || '866px'};
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

export const PopupHeader = styled.div`
  padding: 30px 0 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.popup.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PopupTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const PopupClose = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;
  svg {
    max-width: 100%;
  }
`;

export const PopupFooter = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${(p) => p.theme.colors.popup.border};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
