import type { ChangeEvent } from 'react';
import { useState } from 'react';

export const useChangeFields = <T>(initialData: T) => {
  const [fields, setFields] = useState(initialData);

  const handleFieldsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFields((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  return { fields, handleFieldsChange, updateFields: setFields };
};
