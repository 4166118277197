import { MapPathStyled } from './style';

interface MapPathProps {
  d: string;
  fill: string;
  onClick?: () => void;
}
export const MapPath = ({ d, fill, onClick }: MapPathProps) => (
  <MapPathStyled onClick={onClick} d={d} fill={fill} />
);
