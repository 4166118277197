import { NoWrap } from '../../../components/no-wrap';
import { dateTableFormat } from '../../../helpers/date-helpers';
import type { Contact } from '../../../models/contact';
import type { CurrentEventsTable } from './types';

export const transformEventsData = (
  data: Contact[],
  eventId: string | number
): CurrentEventsTable[] =>
  data.map((item) => ({
    id: item.id,
    last_name: item.last_name,
    first_name: item.first_name,
    patronymic: item.patronymic,
    managers: (
      <>
        {item.resp_managers?.map((item) => (
          <NoWrap key={item.id}>{item.full_name}</NoWrap>
        ))}
      </>
    ),
    created_at: dateTableFormat(item.created_at || '', true),
    channels: item.imported_from
      ? `Завантажено з ${item.imported_from}`
      : item.channels?.map((item) => item.name).join(', ') || '',
    current_steps:
      item.current_steps?.find(
        (item) => item.event.id.toString() === eventId.toString()
      )?.name || '',
    satisfaction: item.satisfaction?.title || '',
    events_date: dateTableFormat(
      item.events_added_dates.find(
        (item) => item.event_id.toString() === eventId.toLocaleString()
      )?.date || '',
      true
    )
  }));
