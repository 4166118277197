export const ExpandSvg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.39922 6.59897C7.46019 6.66015 7.53263 6.7087 7.6124 6.74182C7.69217 6.77494 7.77769 6.79199 7.86406 6.79199C7.95043 6.79199 8.03596 6.77494 8.11572 6.74182C8.19549 6.7087 8.26794 6.66015 8.32891 6.59897L12.6875 2.24038L12.6875 5.15741C12.6875 5.33146 12.7566 5.49838 12.8797 5.62145C13.0028 5.74452 13.1697 5.81366 13.3438 5.81366C13.5178 5.81366 13.6847 5.74452 13.8078 5.62145C13.9309 5.49838 14 5.33146 14 5.15741L14 0.673038C14 0.494058 13.9289 0.322409 13.8023 0.195851C13.6758 0.069293 13.5041 -0.0018063 13.3252 -0.00180632L8.84379 -0.00180671C8.6719 -0.0018382 8.50686 0.0655732 8.38414 0.185936C8.26143 0.3063 8.19083 0.470006 8.18754 0.641865C8.18043 1.00964 8.49051 1.31069 8.85856 1.31069L11.757 1.31069L7.39922 5.66929C7.33804 5.73025 7.2895 5.8027 7.25637 5.88247C7.22325 5.96224 7.2062 6.04776 7.2062 6.13413C7.2062 6.2205 7.22325 6.30602 7.25637 6.38579C7.2895 6.46556 7.33804 6.53801 7.39922 6.59897Z"
      fill="#9DABC0"
    />
    <path
      d="M0.674562 13.9932L5.15593 13.9932C5.32782 13.9932 5.49286 13.9258 5.61558 13.8054C5.73829 13.6851 5.80888 13.5214 5.81218 13.3495C5.81929 12.9817 5.50921 12.6807 5.14116 12.6807L2.24136 12.6807L6.60077 8.32125C6.72383 8.19818 6.79297 8.03127 6.79297 7.85723C6.79297 7.68319 6.72383 7.51627 6.60077 7.3932C6.4777 7.27014 6.31079 7.20101 6.13675 7.20101C5.96271 7.20101 5.7958 7.27014 5.67273 7.3932L1.31413 11.7518L1.31413 8.83367C1.31413 8.65962 1.24499 8.4927 1.12192 8.36963C0.99885 8.24656 0.831931 8.17742 0.657882 8.17742C0.483834 8.17742 0.316914 8.24656 0.193843 8.36963C0.0707726 8.4927 0.00163222 8.65962 0.00163224 8.83367L0.00163263 13.318C0.00148721 13.4968 0.072268 13.6682 0.198436 13.7948C0.324603 13.9214 0.495845 13.9927 0.674562 13.9932Z"
      fill="#9DABC0"
    />
  </svg>
);
