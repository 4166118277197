import styled from 'styled-components';

export const AddItemStyled = styled.div<{ mt?: string }>`
  margin-top: ${(p) => p.mt || '0px'};
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.primary};
  cursor: pointer;
  width: fit-content;
`;
