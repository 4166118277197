export const SatisfactionNeutralSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99996 0C3.58874 0 0 3.58881 0 7.99997C0 12.4112 3.5888 16 7.99996 16C12.4111 16 16 12.4113 16 8.00003C16 3.58881 12.4112 0 7.99996 0ZM7.99996 14.936C4.17548 14.936 1.06402 11.8245 1.06402 7.99997C1.06402 4.17548 4.17548 1.06402 7.99996 1.06402C11.8245 1.06402 14.936 4.17548 14.936 8.00003C14.936 11.8245 11.8245 14.936 7.99996 14.936ZM6.62213 6.1777C6.62213 5.32462 5.92812 4.63061 5.07511 4.63061C4.22203 4.63061 3.52802 5.32462 3.52802 6.1777C3.52802 7.03071 4.22203 7.72472 5.07511 7.72472C5.92812 7.72472 6.62213 7.03071 6.62213 6.1777ZM5.07511 6.6607C4.80877 6.6607 4.59204 6.44404 4.59204 6.1777C4.59204 5.91136 4.8087 5.69463 5.07511 5.69463C5.34144 5.69463 5.55811 5.9113 5.55811 6.1777C5.55811 6.44404 5.34138 6.6607 5.07511 6.6607ZM10.9248 4.63068C10.0717 4.63068 9.37774 5.32469 9.37774 6.17777C9.37774 7.03078 10.0717 7.72478 10.9248 7.72478C11.7778 7.72478 12.4718 7.03078 12.4718 6.17777C12.4718 5.32469 11.7778 4.63068 10.9248 4.63068ZM10.9248 6.6607C10.6584 6.6607 10.4418 6.44404 10.4418 6.1777C10.4418 5.91136 10.6584 5.69463 10.9248 5.69463C11.1911 5.69463 11.4078 5.9113 11.4078 6.1777C11.4078 6.44404 11.1911 6.6607 10.9248 6.6607ZM10.923 10.9289C10.923 11.2227 10.6848 11.4609 10.391 11.4609H5.60891C5.31511 11.4609 5.0769 11.2227 5.0769 10.9289C5.0769 10.6351 5.31511 10.3969 5.60891 10.3969H10.391C10.6848 10.3969 10.923 10.6351 10.923 10.9289Z"
      fill="#171C3D"
    />
  </svg>
);
