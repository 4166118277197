export const ActionsRemoveSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5592 14.8334H5.43924C4.92699 14.826 4.43641 14.6255 4.06561 14.272C3.6948 13.9185 3.47111 13.4381 3.43924 12.9267L2.83258 3.70008C2.82947 3.63319 2.83965 3.56634 2.86254 3.5034C2.88542 3.44046 2.92056 3.38269 2.96591 3.33342C3.01264 3.28235 3.0692 3.24126 3.13222 3.21262C3.19523 3.18398 3.26338 3.16837 3.33258 3.16675H12.6659C12.7345 3.1666 12.8023 3.18055 12.8653 3.20773C12.9282 3.23492 12.9849 3.27476 13.0318 3.32478C13.0787 3.3748 13.1148 3.43393 13.1379 3.49849C13.1609 3.56305 13.1705 3.63167 13.1659 3.70008L12.5859 12.9267C12.5537 13.4426 12.3263 13.9269 11.9498 14.281C11.5733 14.6352 11.0761 14.8327 10.5592 14.8334ZM3.89258 4.16675L4.40591 12.8667C4.42287 13.1291 4.53918 13.3751 4.73116 13.5547C4.92314 13.7343 5.17636 13.834 5.43924 13.8334H10.5592C10.8217 13.8324 11.074 13.7322 11.2656 13.5529C11.4573 13.3736 11.5741 13.1285 11.5926 12.8667L12.1326 4.20008L3.89258 4.16675Z"
      fill="white"
    />
    <path
      d="M14 4.16675H2C1.86739 4.16675 1.74021 4.11407 1.64645 4.0203C1.55268 3.92653 1.5 3.79936 1.5 3.66675C1.5 3.53414 1.55268 3.40696 1.64645 3.31319C1.74021 3.21943 1.86739 3.16675 2 3.16675H14C14.1326 3.16675 14.2598 3.21943 14.3536 3.31319C14.4473 3.40696 14.5 3.53414 14.5 3.66675C14.5 3.79936 14.4473 3.92653 14.3536 4.0203C14.2598 4.11407 14.1326 4.16675 14 4.16675Z"
      fill="white"
    />
    <path
      d="M10 4.16675H6C5.86793 4.16502 5.74175 4.11179 5.64836 4.01839C5.55496 3.925 5.50173 3.79882 5.5 3.66675V2.46675C5.50845 2.12463 5.64813 1.79886 5.89012 1.55687C6.13211 1.31488 6.45788 1.1752 6.8 1.16675H9.2C9.54781 1.17545 9.87845 1.31975 10.1213 1.56886C10.3642 1.81798 10.5001 2.15216 10.5 2.50008V3.66675C10.4983 3.79882 10.445 3.925 10.3516 4.01839C10.2582 4.11179 10.1321 4.16502 10 4.16675ZM6.5 3.16675H9.5V2.50008C9.5 2.42052 9.46839 2.34421 9.41213 2.28795C9.35587 2.23169 9.27957 2.20008 9.2 2.20008H6.8C6.72043 2.20008 6.64413 2.23169 6.58787 2.28795C6.53161 2.34421 6.5 2.42052 6.5 2.50008V3.16675Z"
      fill="white"
    />
    <path
      d="M10 12.1666C9.86793 12.1649 9.74175 12.1116 9.64836 12.0182C9.55496 11.9248 9.50173 11.7987 9.5 11.6666V6.33325C9.5 6.20064 9.55268 6.07347 9.64645 5.9797C9.74022 5.88593 9.86739 5.83325 10 5.83325C10.1326 5.83325 10.2598 5.88593 10.3536 5.9797C10.4473 6.07347 10.5 6.20064 10.5 6.33325V11.6666C10.4983 11.7987 10.445 11.9248 10.3516 12.0182C10.2582 12.1116 10.1321 12.1649 10 12.1666Z"
      fill="white"
    />
    <path
      d="M6 12.1666C5.86793 12.1649 5.74175 12.1116 5.64836 12.0182C5.55496 11.9248 5.50173 11.7987 5.5 11.6666V6.33325C5.5 6.20064 5.55268 6.07347 5.64645 5.9797C5.74022 5.88593 5.86739 5.83325 6 5.83325C6.13261 5.83325 6.25978 5.88593 6.35355 5.9797C6.44732 6.07347 6.5 6.20064 6.5 6.33325V11.6666C6.49827 11.7987 6.44504 11.9248 6.35164 12.0182C6.25825 12.1116 6.13207 12.1649 6 12.1666Z"
      fill="white"
    />
    <path
      d="M8 12.1666C7.86793 12.1649 7.74175 12.1116 7.64836 12.0182C7.55496 11.9248 7.50173 11.7987 7.5 11.6666V6.33325C7.5 6.20064 7.55268 6.07347 7.64645 5.9797C7.74022 5.88593 7.86739 5.83325 8 5.83325C8.13261 5.83325 8.25978 5.88593 8.35355 5.9797C8.44732 6.07347 8.5 6.20064 8.5 6.33325V11.6666C8.49827 11.7987 8.44504 11.9248 8.35164 12.0182C8.25825 12.1116 8.13207 12.1649 8 12.1666Z"
      fill="white"
    />
  </svg>
);
