import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import { ProjectForm } from '../project-form';
import type { OptionItem } from '../../../common/types/option-item';
import { useCreateProjectMutation } from '../../../api/projects-api';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { dateToApiFromInput } from '../../../helpers/date-helpers';

interface AddProjectProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const AddProject = ({ setShow }: AddProjectProps) => {
  const notification = useNotification();
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [respManager, setRespManager] = useState<OptionItem>({
    id: '',
    title: ''
  });
  const [managers, setManagers] = useState<OptionItem[]>([
    { id: '', title: '' }
  ]);

  const [createProject] = useCreateProjectMutation();

  const handleCreateProject = useCallback(() => {
    if (!respManager.id) {
      notification({
        title: ' Помилка',
        type: 'error',
        message: 'Вкажіть керівника проєкту'
      });

      return;
    }
    if (!managers.filter((item) => item.id).length) {
      notification({
        title: ' Помилка',
        type: 'error',
        message: 'Вкажіть хоча б одного менеджера'
      });

      return;
    }
    const data = {
      name: projectName,
      description,
      start_date: dateToApiFromInput(startDate),
      chief_manager: respManager.id,
      managers: managers.map((item) => item.id).filter((item) => item)
    };

    createProject(
      endDate ? { ...data, end_date: dateToApiFromInput(endDate) } : data
    )
      .unwrap()
      .then(() => {
        notification({
          title: 'Проєкт створено!',
          type: 'success',
          message: 'Ви успішно створили проєкт. '
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          title: ' Помилка',
          type: 'error',
          message: getApiError(err)
        });
      });
  }, [
    createProject,
    notification,
    setShow,
    description,
    endDate,
    managers,
    projectName,
    respManager,
    startDate
  ]);

  return (
    <ProjectForm
      projectName={projectName}
      setProjectName={setProjectName}
      setShow={setShow}
      description={description}
      setDescription={setDescription}
      startDate={startDate}
      setStartDate={setStartDate}
      respManager={respManager}
      setRespManager={setRespManager}
      endDate={endDate}
      setEndDate={setEndDate}
      managers={managers}
      setManagers={setManagers}
      onSubmit={handleCreateProject}
    />
  );
};
