import { useTheme } from 'styled-components';

interface EyeCloseSvgProps {
  $focus?: boolean;
}

export const EyeCloseSvg = ({ $focus }: EyeCloseSvgProps) => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 1.125L16.25 14.875M12.1021 10.3123C11.4888 10.8698 10.6792 11.1607 9.85143 11.1213C9.02361 11.0818 8.24536 10.7152 7.68788 10.1019C7.1304 9.4887 6.83933 8.67913 6.87872 7.85131C6.9181 7.02348 7.2847 6.24521 7.89788 5.68767M5.78082 3.35887C2.59572 4.97181 1.25 8 1.25 8C1.25 8 3.75 13.6244 10 13.6244C11.4644 13.6361 12.9105 13.2988 14.2186 12.6404M16.2976 11.2109C18.0009 9.68533 18.75 8 18.75 8C18.75 8 16.25 2.37439 10 2.37439C9.45869 2.3735 8.91824 2.41751 8.3842 2.50596M10.5881 4.9303C11.2523 5.05784 11.8572 5.39729 12.3122 5.89774C12.7671 6.3982 13.0475 7.03266 13.1113 7.70598"
        stroke={$focus ? theme?.colors.blue.dark : theme?.colors.grey.light}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
