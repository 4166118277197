import { useSearchParams } from 'react-router-dom';
import { Fragment, type Dispatch, type SetStateAction } from 'react';
import { Badge } from '../badge';
import { AngleSvg } from '../svg/angle-svg';
import type { OptionItem } from '../../common/types/option-item';
import { ExpandBtn, FilterBadgesStyled } from './style';
import type { IBadge } from './helpers';

interface FilterBadgesProps {
  badges: IBadge[];
  showAll: boolean;
  setShowAll: Dispatch<SetStateAction<boolean>>;
}

export const FilterBadges = ({
  badges,
  showAll,
  setShowAll
}: FilterBadgesProps) => {
  const [searchParams, setSearhParams] = useSearchParams();

  const handleRemoveBadge = (id?: string | number) => {
    searchParams.delete(`${id}` || '');
    searchParams.delete('page');
    setSearhParams(searchParams);
  };

  const renderBadges = (badge: IBadge, i: number) => {
    switch (badge.type) {
      case 'text':
        return (
          <Badge onRemove={() => handleRemoveBadge(badge.name)} key={i}>
            {typeof badge.value === 'string'
              ? `${badge?.parent ? badge?.parent : ''} ${badge.title}: ${
                  badge?.value
                }`
              : null}
          </Badge>
        );
      case 'checkbox':
        return (
          <Fragment key={i}>
            <Badge
              onRemove={() => {
                if (!badge.parentId) {
                  handleRemoveBadge(badge.id);
                } else {
                  searchParams.delete(
                    badge.sub?.length ? badge.sub[0].name : ''
                  );
                  if (
                    searchParams.get(badge.parentId)?.toString().split(',')
                      .length === 1
                  ) {
                    handleRemoveBadge(badge.parentId);
                  } else {
                    searchParams.set(
                      badge.parentId,
                      searchParams
                        .get(badge.parentId)
                        ?.toString()
                        .split(',')
                        .filter((item) => `${item}` !== `${badge.id}`)
                        .join(',') || ''
                    );
                    setSearhParams(searchParams);
                  }
                }
              }}
            >
              {badge?.parent ? badge.parent + ':' : ''} {badge.title}
            </Badge>
            {badge.sub
              ?.filter((item) => item.checked)
              .map((item, i) => (
                <Badge
                  key={i}
                  onRemove={() => {
                    const ids = searchParams.get(badge.name || '');
                    searchParams.set(
                      badge.name || '',
                      ids
                        ?.split(',')
                        .filter((id) => id !== `${item.id}`)
                        .join(',') || ''
                    );
                    setSearhParams(searchParams);
                  }}
                >
                  {item.label}: {item.title}
                </Badge>
              ))}
          </Fragment>
        );

      case 'select':
        return (
          <Badge onRemove={() => handleRemoveBadge(badge.id)} key={i}>
            {badge.parent} {badge.title}: {(badge.value as OptionItem)?.title}
          </Badge>
        );
      default:
        return null;
    }
  };

  const toggleExpand = () => {
    setShowAll((state) => !state);
  };

  const badgesToShow = showAll ? badges : badges.slice(0, 6);

  return (
    <FilterBadgesStyled>
      {badgesToShow.map(renderBadges)}
      {badges.length > 6 && (
        <ExpandBtn isOpen={showAll} onClick={toggleExpand}>
          {showAll ? 'Згорнути' : 'Розгорнути'} <AngleSvg />
        </ExpandBtn>
      )}
    </FilterBadgesStyled>
  );
};
