import styled from 'styled-components';

export const ChooseTemplateStyled = styled.div`
  padding: 30px 0 0;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const BtnsContainer = styled.div`
  margin-top: 94px;
  width: 100%;
  max-width: 406px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 275px;
  gap: 20px;
`;
