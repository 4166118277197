import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Transition } from 'react-transition-group';
import { CloseEditSvg } from '../../../../components/svg/close-edit-svg';
import { UserInfo } from '../../../../components/user-info';
import type { CreateUser, User } from '../../../../models/user';
import { CheckBox } from '../../../../components/checkbox';
import { useChangeFields } from '../../../../hooks/use-change-fields';
import type { OptionItem } from '../../../../common/types/option-item';
import { useEditUserMutation } from '../../../../api/user-api';
import { getApiError } from '../../../../helpers/get-api-error';
import { useNotification } from '../../../../hooks/use-notification';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from '../transition';
import { UserForm } from '../../user-form';
import type { ServerFile } from '../../../../common/types/server-file';
import {
  Close,
  EditHeader,
  EditHeaderRight,
  EditUserData,
  EditUserStyled,
  FormWrapper,
  UserId
} from './style';

interface EditUserProps {
  setEdit: Dispatch<SetStateAction<boolean>>;
  user: User;
  edit?: boolean;
  setShowUserRait: Dispatch<SetStateAction<boolean>>;
}

export const EditUser = ({
  setEdit,
  user,
  edit,
  setShowUserRait
}: EditUserProps) => {
  const notification = useNotification();
  const [editUser, { isLoading }] = useEditUserMutation();
  const [passportFiles, setPassportFiles] = useState<File[]>([]);
  const [innFiles, setInnFiles] = useState<File[]>([]);
  const [serverPassportFiles, setServerPassportFiles] = useState<ServerFile[]>(
    user.passport_files
  );
  const [serverInnFiles, setServerInnFiles] = useState<ServerFile[]>(
    user.inn_files
  );
  const { fields, handleFieldsChange, updateFields } =
    useChangeFields<CreateUser>({
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      patronymic: user.patronymic,
      email: user.email,
      phone: user.phone,
      liv_country: user.liv_country,
      liv_region: user.liv_region,
      liv_district: user.liv_district,
      liv_city: user.liv_city,
      liv_street: user.liv_street,
      liv_building: user.liv_building,
      liv_unit: user.liv_unit,
      liv_apartment: user.liv_apartment,
      reg_country: user.reg_country,
      reg_region: user.reg_region,
      reg_district: user.reg_district,
      reg_city: user.reg_city,
      reg_street: user.reg_street,
      reg_building: user.reg_building,
      reg_unit: user.reg_building,
      reg_apartment: user.reg_apartment,
      organization: user.organization,
      passport: user.passport,
      id_number: user.id_number,
      password: user.password,
      binotel: user.binotel
    });

  const [role, setRole] = useState<OptionItem>({
    id: user.role?.id || '',
    title: user.role?.name || ''
  });
  const [gender, setGender] = useState<OptionItem>(user.gender);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const fieldsHasBeenChanged = Object.entries(fields).filter(
      ([key, value]) => user[key as keyof User] !== value
    );

    const formData = new FormData();
    fieldsHasBeenChanged.forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (serverPassportFiles.length) {
      formData.append(
        'passport_save_ids',
        JSON.stringify(serverPassportFiles.map((item) => item.id))
      );
    } else {
      formData.append('passport_save_ids', JSON.stringify([]));
    }
    passportFiles.forEach((item) => {
      formData.append('passport_files_add', item);
    });
    if (serverInnFiles.length) {
      formData.append(
        'inn_save_ids',
        JSON.stringify(serverInnFiles.map((item) => item.id))
      );
    } else {
      formData.append('inn_save_ids', JSON.stringify([]));
    }
    innFiles.forEach((item) => {
      formData.append('inn_files_add', item);
    });
    formData.append('gender', `${gender.id}`);
    formData.append('role', `${role?.id}`);
    editUser({ id: user.id, body: formData })
      .unwrap()
      .then(() => {
        setEdit(false);
        notification({
          type: 'success',
          title: 'Співробітника відредаговано!',
          message: 'Ви успішно відредагували співробітника.'
        });
      })
      .catch((err) => {
        console.log(err);
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleFire = (fired: boolean) => {
    if (fired) {
      setShowUserRait(true);
    } else {
      editUser({
        id: user.id,
        body: {
          discharged: false,
          rate_discharged: null
        }
      });
    }
  };

  const handleCancel = () => {
    setEdit(false);
  };

  useEffect(() => {
    updateFields({
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      patronymic: user.patronymic,
      email: user.email,
      phone: user.phone,
      liv_country: user.liv_country,
      liv_region: user.liv_region,
      liv_district: user.liv_district,
      liv_city: user.liv_city,
      liv_street: user.liv_street,
      liv_building: user.liv_building,
      liv_unit: user.liv_unit,
      liv_apartment: user.liv_apartment,
      reg_country: user.reg_country,
      reg_region: user.reg_region,
      reg_district: user.reg_district,
      reg_city: user.reg_city,
      reg_street: user.reg_street,
      reg_building: user.reg_building,
      reg_unit: user.reg_building,
      reg_apartment: user.reg_apartment,
      organization: user.organization,
      passport: user.passport,
      id_number: user.id_number,
      password: user.password,
      binotel: user.binotel
    });
    setPassportFiles([]);
    setInnFiles([]);
    setRole({
      id: user.role?.id || '',
      title: user.role?.name || ''
    });
    setGender(user.gender);
    setServerPassportFiles(user.passport_files);
    setServerInnFiles(user.inn_files);
  }, [user, updateFields, edit]);

  return (
    <Transition
      in={edit && !!user}
      mountOnEnter
      unmountOnExit
      timeout={duration}
    >
      {(state) => (
        <EditUserStyled
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          <div
            style={{
              ...defaultContentStyle,
              ...transitionContentStyles[state],
              height: '100%'
            }}
          >
            <EditUserData>
              <Close onClick={() => setEdit(false)}>
                <CloseEditSvg />
              </Close>
              <EditHeader>
                <UserInfo
                  isBig
                  lastName={user.last_name}
                  firstName={user.first_name}
                  patronymic={user.patronymic}
                />
                <EditHeaderRight>
                  <UserId>ID Співробітника: {user.id}</UserId>
                  <CheckBox
                    fw={'600'}
                    fz="16px"
                    label="Звільнено"
                    checked={user.discharged}
                    setChecked={handleFire}
                  />
                </EditHeaderRight>
              </EditHeader>
              <FormWrapper>
                <UserForm
                  fields={fields}
                  role={role}
                  setRole={setRole}
                  passportFiles={passportFiles}
                  setPassportFiles={setPassportFiles}
                  innFiles={innFiles}
                  setInnFiles={setInnFiles}
                  onSubmit={handleSubmit}
                  handleFieldsChange={handleFieldsChange}
                  onCancel={handleCancel}
                  isLoading={isLoading}
                  gender={gender}
                  setGender={setGender}
                  isEdit
                  serverPassportFiles={serverPassportFiles}
                  setServerPassportFiles={setServerPassportFiles}
                  serverInnFiles={serverInnFiles}
                  setServerInnFiles={setServerInnFiles}
                />
              </FormWrapper>
            </EditUserData>
          </div>
        </EditUserStyled>
      )}
    </Transition>
  );
};
