import type { ReactNode } from 'react';
import { BtnSecondaryStyled } from './style';

interface BtnSecondaryProps {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

export const BtnSecondary = ({
  children,
  onClick,
  disabled
}: BtnSecondaryProps) => (
  <BtnSecondaryStyled disabled={disabled} type="button" onClick={onClick}>
    {children}
  </BtnSecondaryStyled>
);
