import { LogoSvg } from '../svg/logo-svg';
import { LoaderWrapper } from './style';

interface LoaderProps {
  withoutOverlay?: boolean;
}

export const Loader = ({ withoutOverlay }: LoaderProps) => (
  <LoaderWrapper $withoutOverlay={withoutOverlay}>
    <LogoSvg />
  </LoaderWrapper>
);
