import styled from 'styled-components';
import { centerFlex, scrollBar } from '../../style/helpers';

export const HistoryStyled = styled.div`
  padding: 24px 0;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

export const Title = styled.div`
  padding: 0 24px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${(p) => p.theme.colors.blue.dark};
  display: flex;
  align-items: center;
`;

export const HistoryContainer = styled.div`
  padding: 0 24px;
  max-height: 280px;
  ${scrollBar}
`;

export const HistoryNoData = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Descr = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const Info = styled.div`
  position: relative;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  ${centerFlex}
  :hover {
    svg {
      path {
        fill: ${(p) => p.theme.colors.primary};
      }
    }
  }
`;
