import type { ReactNode } from 'react';
import { useRef } from 'react';
import { useScrollHeight } from '../../../../../hooks/use-scroll-height';
import { SidebarScrollBlockStyled } from './style';

interface SidebarMainProps {
  children: ReactNode;
}

export const SidebarScrollBlock = ({ children }: SidebarMainProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { scrollHeight } = useScrollHeight(scrollRef);

  return (
    <SidebarScrollBlockStyled
      ref={scrollRef}
      style={{ height: `${scrollHeight}px` }}
    >
      {children}
    </SidebarScrollBlockStyled>
  );
};
