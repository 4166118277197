import styled, { keyframes } from 'styled-components';
import { scrollBar } from '../../style/helpers';

export const KanbanColStyled = styled.div`
  width: 324px;
  min-width: 324px;
`;

export const ColHeader = styled.div<{ idx: number; isProcessed?: boolean }>`
  display: flex;
  gap: 5px;
  width: calc(100% - 10px);
  padding: 12px 20px;
  height: 44px;
  border-radius: 6px;
  box-shadow: 2px 2px 10px 0px rgba(27, 36, 58, 0.06),
    -2px -2px 7px 0px rgba(27, 36, 58, 0.06);
  background: ${(p) =>
    p.isProcessed
      ? p.theme.colors.processed
      : p.theme.colors.steps[p.idx] || p.theme.colors.steps[0]};
  color: ${(p) => p.theme.colors.white};
  font-size: 16px;
  font-weight: 600;
  span {
    min-width: fit-content;
  }
`;

export const TitleWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

export const ColStyled = styled.div`
  padding-top: 10px;
  margin-top: 10px;
  padding-right: 10px;
  ${scrollBar}
  ::-webkit-scrollbar-track {
    margin-top: 10px;
  }
  padding-bottom: 30px;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderStyled = styled.span`
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  width: 30px;
  height: 30px;
  border: 2px dotted ${(p) => p.theme.colors.blue.primary};
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: ${rotate} 2s linear infinite;
`;

export const DragPlaceholder = styled.div`
  height: 218px;
  border-radius: 5px 10px 10px 5px;
  background: ${(p) => p.theme.colors.grey.bg};
`;
