import { css } from 'styled-components';

export const centerFlex = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const scrollBar = css`
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(23, 28, 61, 0.35);
    border-radius: 10px;
    opacity: 0.35;
  }
`;
