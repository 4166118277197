export const setYearsLabel = (year: number) => {
  if (year === 1) {
    return 'рік';
  }
  if (year > 1 && year < 5) {
    return 'роки';
  }

  return 'років';
};
