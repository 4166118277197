import styled from 'styled-components';

export const NewLetterStyled = styled.div`
  height: calc(100% - 80px);
  .quill {
    height: calc(100% - 140px);
  }
`;

export const Subject = styled.div`
  padding: 20px;
`;
