import { isValidNewDate } from './date-helpers';

export const calculateAge = (date: string | null) => {
  if (date && /^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
    const dateApiFormat = date.split('/').reverse().join('-');
    if (isValidNewDate(dateApiFormat)) {
      const currentDate = new Date();
      const birthDate = new Date(dateApiFormat);
      const birthYear = birthDate.getFullYear();
      const birthMonth = birthDate.getMonth();
      const birthDay = birthDate.getDate();

      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();

      let age = currentYear - birthYear;

      if (
        currentMonth < birthMonth ||
        (currentMonth === birthMonth && currentDay < birthDay)
      ) {
        age--;
      }

      if (age < 0) {
        return 0;
      }

      return age;
    }

    return 0;
  }

  return 0;
};
