import styled from 'styled-components';

export const InputFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const InputFileStyled = styled.label`
  margin-top: 12px;
  input {
    display: none;
  }
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.primary};
  transition: color 0.2s;
  :hover {
    color: ${(p) => p.theme.colors.blue.hoverPrimary};
    transition: color 0.2s;
  }
  cursor: pointer;
`;

export const FileName = styled.div`
  margin-top: 8px;
  width: 100%;
  padding: 4px 12px;
  background: ${(p) => p.theme.colors.blue.bg};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${(p) => p.theme.colors.blue.dark};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :first-child {
    margin-top: 12px;
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;
