export const SatisfactionPositiveSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0001 8C16.0001 3.58875 12.4113 0 8.00007 0C3.58882 0 0 3.58882 0 8C0 12.4112 3.58882 16 8.00007 16C12.4113 16 16.0001 12.4112 16.0001 8ZM8.00007 14.936C4.1755 14.936 1.06402 11.8245 1.06402 8C1.06402 4.1755 4.1755 1.06402 8.00007 1.06402C11.8246 1.06402 14.9361 4.1755 14.9361 8C14.9361 11.8245 11.8246 14.936 8.00007 14.936ZM5.07513 7.72475C5.92814 7.72475 6.62215 7.03074 6.62215 6.17772C6.62215 5.32464 5.92814 4.63063 5.07513 4.63063C4.22211 4.63063 3.5281 5.32464 3.5281 6.17772C3.5281 7.03074 4.22211 7.72475 5.07513 7.72475ZM5.07513 5.69466C5.34147 5.69466 5.55813 5.91132 5.55813 6.17772C5.55813 6.44406 5.34147 6.66073 5.07513 6.66073C4.80879 6.66073 4.59213 6.44406 4.59213 6.17772C4.59213 5.91132 4.80879 5.69466 5.07513 5.69466ZM10.9249 4.63063C10.0718 4.63063 9.37778 5.32464 9.37778 6.17772C9.37778 7.03074 10.0718 7.72475 10.9249 7.72475C11.7779 7.72475 12.4719 7.03074 12.4719 6.17772C12.4719 5.32464 11.7779 4.63063 10.9249 4.63063ZM10.9249 6.66073C10.6585 6.66073 10.4418 6.44406 10.4418 6.17772C10.4418 5.91139 10.6585 5.69466 10.9249 5.69466C11.1912 5.69466 11.4079 5.91132 11.4079 6.17772C11.4079 6.44406 11.1912 6.66073 10.9249 6.66073ZM11.7635 10.0877H4.23661C4.03844 10.0877 3.85662 10.1979 3.76492 10.3737C3.67328 10.5494 3.68684 10.7615 3.80023 10.9241C4.75812 12.2975 6.32815 13.1174 8.00007 13.1174C9.67192 13.1174 11.2419 12.2975 12.1998 10.9241C12.3132 10.7615 12.3268 10.5494 12.2351 10.3737C12.1434 10.1979 11.9616 10.0877 11.7635 10.0877ZM8.00013 12.0533C7.06273 12.0533 6.16582 11.7279 5.45246 11.1518H10.5477C9.83438 11.7279 8.93747 12.0533 8.00013 12.0533Z"
      fill="#171C3D"
    />
  </svg>
);
