import type { ChangeEventHandler, DragEventHandler } from 'react';
import { FileBigSvg } from '../svg/file-big-svg';
import { LoaderSmall } from '../loader-small';
import { DropFileStyled, FileInputLink, Text, Title } from './style';

interface DropFileProps {
  onUpload: (files: File[]) => void;
  isLoading?: boolean;
}

export const DropFile = ({ onUpload, isLoading }: DropFileProps) => {
  const handleDropFile: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files[0]?.type) {
      onUpload(Array.from(e.dataTransfer.files));
    }
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
  };

  const handleChangeFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const currentFiles = e.target.files;
    if (currentFiles?.length) {
      onUpload(Array.from(currentFiles));
    }
  };

  return (
    <div>
      <Title>Оберіть файл</Title>
      <DropFileStyled onDragOver={handleDragOver} onDrop={handleDropFile}>
        {isLoading ? (
          <LoaderSmall />
        ) : (
          <>
            <FileBigSvg />
            <Text>
              Перетягніть або{' '}
              <FileInputLink>
                <input
                  accept=".jpg, .pdf, .tif, .txt, .doc, .docx, .xls, .xlsx, .bmp, .png, .heif, .heic"
                  onChange={handleChangeFile}
                  type="file"
                  multiple
                />
                оберіть файл
              </FileInputLink>{' '}
              для завантаження
            </Text>
            <Text>
              Формати JPG, PNG, PDF, XLSX, DOC, HEIC, HEIF доступні для
              завантаження
            </Text>
            <Text>Максимальний розмір завантажуваного файлу: 30 МБ</Text>
          </>
        )}
      </DropFileStyled>
    </div>
  );
};
