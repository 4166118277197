import { useNavigate } from 'react-router-dom';
import { AuthBtn } from '../../components/auth/auth-btn';
import { AuthLayout } from '../../layouts/auth-layout';
import { AppRoute } from '../../common/enums/app/app-route.enum';
import { FormStyled } from './style';

export const ForgotPasswordRequest = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout
      title="Запит відправлено"
      subtitle="Зачекайте, будь ласка, поки адмінстратор системи дасть вам новий доступ. Це займе декілька хвилин."
    >
      <FormStyled onSubmit={() => navigate(AppRoute.LOG_IN)}>
        <AuthBtn type="submit">Увійти повторно</AuthBtn>
      </FormStyled>
    </AuthLayout>
  );
};
