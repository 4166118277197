import styled from 'styled-components';

export const TextAreaStyled = styled.textarea<{
  customHeight?: string;
  areaHeight?: string;
  autoHeight?: boolean;
}>`
  display: block;
  width: 100%;
  padding: 8px 12px;
  height: ${(p) => (p.customHeight || p.autoHeight ? p.areaHeight : '117px')};
  max-height: 150px;
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  resize: none;
  outline: none;
  ::placeholder {
    color: ${(p) => p.theme.colors.grey.light};
  }
  :focus {
    border: 1px solid ${(p) => p.theme.colors.blue.primary};
  }
  :disabled {
    background: ${(p) => p.theme.colors.white};
  }
`;
