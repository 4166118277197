import styled from 'styled-components';

export const FormStyled = styled.form`
  margin: 0 auto;
  margin-top: 24px;
  max-width: 406px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 7px;
`;
