import styled from 'styled-components';
import { scrollBar } from '../../../../style/helpers';

export const EditContactStyled = styled.div`
  padding-left: 31%;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: calc(100vw * -0.17);
  top: 0;
  background: ${(p) => p.theme.colors.popup.bg};
  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    padding-left: 20%;
    left: calc(100vw * -0.1);
  }
  z-index: 11;
`;

export const EditContactData = styled.div`
  padding-top: 20px;
  position: relative;
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  height: 100%;
  box-shadow: -4px 4px 21px rgba(0, 0, 0, 0.12);
`;

export const FormWrapper = styled.div`
  padding: 0 40px;
  height: calc(100vh - 115px);
  ${scrollBar}

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    height: calc(100vh - 140px);
  }
`;

export const Close = styled.div`
  position: absolute;
  left: -32px;
  top: 80px;
  width: 58px;
  height: 58px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.card.bg};
  padding: 19px;
  padding-left: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: -1;
`;

export const EditHeader = styled.div`
  padding: 20px 0 36px;
  width: calc(100% - 80px);
  margin: 0 auto;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.input};
`;

export const HeaderRow = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :first-child {
    margin-top: 0;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const EditHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContactId = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const Form = styled.form`
  padding: 40px 40px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  align-items: end;
  height: calc(100vh - 115px);
  ${scrollBar}

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    height: calc(100vh - 140px);
  }
`;

export const GroupTitle = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const GroupTitleSmall = styled(GroupTitle)`
  font-size: 18px;
`;

export const GroupTitleSmallDouble = styled(GroupTitleSmall)`
  grid-column: span 2;
`;

export const Groupe = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

export const GroupeDouble = styled(Groupe)`
  grid-template-columns: repeat(2, 1fr);
`;

export const BtnsWrapper = styled.div`
  grid-column: span 2;
`;
