import type { NotificationOptions } from '../api/notification-slice';
import { showNotification } from '../api/notification-slice';
import { useAppDispatch } from '../api/store';

export const useNotification = () => {
  const dispatch = useAppDispatch();

  const handleNotification = (options: NotificationOptions) => {
    dispatch(showNotification(options));
  };

  return handleNotification;
};
