import styled from 'styled-components';

export const AccordionBlockStyled = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.table};
`;

export const AccordionHeader = styled.div<{ isOpen?: boolean }>`
  padding: 20px 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  transition: color 0.2s;
  svg {
    display: block;
    transform: ${(p) => (p.isOpen ? 'rotate(90deg)' : 'rotate(0)')};
    transition: transform 300ms;
  }
  :hover {
    transition: color 0.2s;
    color: ${(p) => p.theme.colors.blue.primary};
  }
`;
