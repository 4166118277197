import { useRef, type ChangeEventHandler, useEffect, useState } from 'react';
import { Label } from '../label';
import { TextAreaStyled } from './style';

interface TextAreaProps {
  label?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  customHeight?: string;
  required?: boolean;
  autoHeight?: boolean;
  name?: string;
  placeholder?: string;
}

export const TextArea = ({
  label,
  value,
  onChange,
  customHeight,
  required,
  autoHeight,
  name,
  placeholder
}: TextAreaProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [areaHeight, setAreaHeight] = useState('36px');

  useEffect(() => {
    if (ref.current?.scrollHeight && autoHeight) {
      setAreaHeight(`${ref.current?.scrollHeight}px`);
    }
  }, [autoHeight]);

  return (
    <div>
      {label && <Label>{label}</Label>}
      <TextAreaStyled
        areaHeight={areaHeight}
        autoHeight={autoHeight}
        customHeight={customHeight}
        onChange={onChange}
        value={value}
        required={required}
        ref={ref}
        name={name}
        placeholder={placeholder}
      ></TextAreaStyled>
    </div>
  );
};
