import styled from 'styled-components';

export const UsersAccessStyled = styled.div`
  position: relative;
`;

export const RoleSelect = styled.div`
  padding: 16px 20px 20px;
  display: flex;
  align-items: center;
`;

export const RoleTitle = styled.div`
  margin-right: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.colors.slate};
`;

export const Switcher = styled.div`
  position: absolute;
  right: 0;
  top: 15px;
`;
