import { useCallback, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageTitle } from '../../components/page-title';
import { SubHeader } from '../../components/subheader';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import {
  useDeleteEventMutation,
  useGetEventsQuery
} from '../../api/events-api';
import type { HeaderItem } from '../../components/data-table';
import { DataTable } from '../../components/data-table';
import { Popup } from '../../components/popup';
import { ButtonAdd } from '../../components/button-add';
import { AppRoute } from '../../common/enums/app/app-route.enum';
import { FlexContainer } from '../../components/flex-container';
import { CalendarSvg } from '../../components/svg/calendar-svg';
import { SubheaderSelect } from '../../components/subheader-select';
import { DateRange } from '../../components/date-range';
import { EditColumns } from '../../components/edit-colums';
import { setEventsColumns } from '../../api/page-data-slice';
import { Filters } from '../../components/filters';
import { FilterSvg } from '../../components/svg/filter-svg';
import { Counter } from '../../components/counter';
import { useFilter } from '../../hooks/use-filter';
import { useDateFilter } from '../../hooks/use-date-filter';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { ConfirmPopup } from '../../components/confirm-popup';
import { useGetUserMeQuery } from '../../api/user-api';
import { ProjectsSvg } from '../../components/svg/sidebar/projects-svg';
import { NoData } from '../../components/no-data';
import { AddEvent } from './add-event';
import { transformEventsData } from './helpers';
import { initialFilters, tableHeaders } from './data';
import type { EventsTable } from './types';

interface EventsPageProps {
  projectId?: string;
  isArchived?: boolean;
}

export const EventsPage = ({ projectId, isArchived }: EventsPageProps) => {
  const notification = useNotification();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: userMe } = useGetUserMeQuery('');
  const [searchParams] = useSearchParams();
  const {
    filters,
    filtersLength,
    showFilter,
    setShowFilter,
    setFilters,
    badges,
    resetFilters,
    aplyFilter
  } = useFilter({
    initialFilters,
    usersFilterName: 'managers'
  });
  const {
    showCalendar,
    setShowCalendar,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    applyDate,
    resetDate
  } = useDateFilter({
    startDateName: 'start_date',
    endDateName: 'end_date'
  });
  const { scrollHeight } = useScrollHeight(tableRef);
  const { eventsColumns, pageSize } = useTypedSelector(
    (state) => state['page-data']
  );
  const { data, isLoading, isFetching } = useGetEventsQuery(
    {
      params: `?size=${pageSize}&projects=${
        projectId || ''
      }&${searchParams.toString()}`
    },
    { refetchOnMountOrArgChange: true }
  );
  const [deleteEvent] = useDeleteEventMutation();
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showAddEvent, setShowEvent] = useState(false);
  const [showConfirmDelete, setShowConfimDelete] = useState(false);

  const setCustomColumns = useCallback(
    (columns: HeaderItem<EventsTable>[] | null) => {
      dispatch(setEventsColumns(columns));
    },
    [dispatch]
  );

  const resetCustomColumns = useCallback(() => {
    dispatch(setEventsColumns(null));
  }, [dispatch]);

  const handleDelete = () => {
    deleteEvent({ ids: checkedList })
      .unwrap()
      .then(() => {
        notification({
          title: 'Подію/ї видалено!',
          message: 'Ви успішно видалили обрані події.',
          type: 'success'
        });
        setShowConfimDelete(false);
        setCheckedList([]);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  const columns = eventsColumns?.filter((item) => item.checked) || tableHeaders;

  if (!userMe) {
    return null;
  }

  const canView = userMe.role?.view_event || userMe.is_superuser;
  const canAdd = userMe.role?.add_event || userMe.is_superuser;
  const canDelete = userMe.role?.delete_event || userMe.is_superuser;

  if (!canView) {
    return null;
  }

  return (
    <>
      <SubHeader mt="20px">
        <FlexContainer>
          <PageTitle>Події</PageTitle>
          <Counter icon={ProjectsSvg}>{data?.count || 0}</Counter>
          {!isArchived && (
            <>
              <SubheaderSelect
                show={showCalendar}
                setShow={setShowCalendar}
                icon={CalendarSvg}
                title="Дата"
                isActive={!!(startDate || endDate)}
              >
                <DateRange
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  onSubmit={applyDate}
                  onReset={resetDate}
                />
              </SubheaderSelect>
              <SubheaderSelect
                show={showFilter}
                setShow={setShowFilter}
                icon={FilterSvg}
                title={`Розширені фільтри (${filtersLength})`}
                isActive={!!filtersLength}
              >
                <Filters
                  setFilters={setFilters}
                  filters={filters}
                  setShow={setShowFilter}
                  onCancel={resetFilters}
                  onSubmit={aplyFilter}
                  disabled={!filtersLength && !badges.length}
                />
              </SubheaderSelect>
              <EditColumns<EventsTable>
                onSubmit={setCustomColumns}
                initialHeaders={tableHeaders}
                columns={eventsColumns}
                onReset={resetCustomColumns}
              />
            </>
          )}
        </FlexContainer>
        {!isArchived && canAdd && (
          <ButtonAdd onClick={() => setShowEvent(true)}>
            Створити подію
          </ButtonAdd>
        )}
      </SubHeader>
      {data && !data.results.length ? (
        <NoData subTitle="" />
      ) : (
        <div ref={tableRef}>
          <DataTable<EventsTable>
            mt="20px"
            onRowClick={(id) =>
              isArchived
                ? ''
                : navigate(`${AppRoute.PROJECTS}${AppRoute.EVENTS}/${id}`)
            }
            customHeight={
              (data?.count || 0) >= pageSize ? scrollHeight - 64 : scrollHeight
            }
            actions={[
              { title: 'Видалити', handler: () => setShowConfimDelete(true) }
            ]}
            count={data?.count || 0}
            headers={columns}
            data={transformEventsData(data?.results || [])}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            withOutCheck={isArchived || !canDelete}
            isFetching={isFetching || isLoading}
          />
        </div>
      )}
      <Popup show={showAddEvent} setShow={setShowEvent}>
        <AddEvent setShow={setShowEvent} />
      </Popup>
      <ConfirmPopup
        show={showConfirmDelete}
        title={
          checkedList.length === 1
            ? `Чи дійсно бажаєте видалити подію ${
                data?.results.find((item) => item.id === checkedList[0])
                  ?.name || ''
              }?`
            : `Чи дійсно бажаєте видалити вибрані події`
        }
        setShow={setShowConfimDelete}
        onSubmit={handleDelete}
      />
    </>
  );
};
