export const duration = 300;

export const defaultStyle = {
  transition: `max-height ${duration}ms ease-in-out`,
  maxHeight: '0px',
  overflow: 'hidden'
};

export const transitionStyles = {
  entering: { maxHeight: '46px' },
  entered: { maxHeight: '46px' },
  exiting: { maxHeight: '0' },
  exited: { maxHeight: 0 },
  unmounted: { maxHeight: 0 }
};

export const transitionAllPagesStyles = {
  entering: { maxHeight: '106px' },
  entered: { maxHeight: '106px' },
  exiting: { maxHeight: '0' },
  exited: { maxHeight: 0 },
  unmounted: { maxHeight: 0 }
};
