import styled from 'styled-components';

interface StatusStyledProps {
  isActive: boolean;
}

export const StatusStyled = styled.div<StatusStyledProps>`
  padding: 2px 8px;
  display: flex;
  align-items: center;
  background: ${(p) =>
    p.isActive
      ? p.theme.colors.status.activeLight
      : p.theme.colors.status.noActiveLight};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  border-radius: 6px;
  color: ${(p) =>
    p.isActive ? p.theme.colors.status.active : p.theme.colors.status.noActive};
  :before {
    content: '';
    display: block;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${(p) =>
      p.isActive
        ? p.theme.colors.status.active
        : p.theme.colors.status.noActive};
  }
`;
