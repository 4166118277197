import type { HeaderItem } from '../../../../components/data-table';
import type { DynamicContact } from '../../../../models/contact';

export const tableHeaders: HeaderItem<DynamicContact>[] = [
  { id: 'id', title: 'ID' },
  { id: 'role', title: 'Роль' },
  { id: 'is_volunteer', title: 'ВИД контакту' },
  { id: 'satisfaction', title: 'Рівень задоволеності' },
  { id: 'status', title: 'Статус' },
  { id: 'qualification', title: 'Кваліфікатор' },
  { id: 'channels', title: 'Канал приходу контакту' },
  { id: 'resp_managers', title: 'Власник' },
  { id: 'birth_date', title: 'ДАТА НАРОДЖЕННЯ' },
  { id: 'age', title: 'Вік' },
  {
    id: 'address',
    title: 'Адреса Проживання',
    subFields: [
      { id: 'liv_country', title: 'Країна' },
      { id: 'liv_region', title: 'Область' },
      { id: 'liv_city', title: 'Місто / СМТ / СЕЛО' },
      { id: 'liv_district', title: 'Район / ОТГ' },
      { id: 'liv_street', title: 'Вулиця' },
      { id: 'liv_building', title: 'Будинок' },
      { id: 'liv_unit', title: 'Корпус' },
      { id: 'liv_apartment', title: 'Квартира' }
    ]
  },
  {
    id: 'reg_address',
    title: 'Адреса Реєстрації',
    subFields: [
      { id: 'reg_country', title: 'Країна' },
      { id: 'reg_region', title: 'Область' },
      { id: 'reg_city', title: 'Місто / СМТ / СЕЛО' },
      { id: 'reg_district', title: 'Район / ОТГ' },
      { id: 'reg_street', title: 'Вулиця' },
      { id: 'reg_building', title: 'Будинок' },
      { id: 'reg_unit', title: 'Корпус' },
      { id: 'reg_apartment', title: 'Квартира' }
    ]
  },
  { id: 'gender', title: 'Гендер' },
  { id: 'family_status', title: 'Сімейний статус' },
  { id: 'children_num', title: 'Кількість дітей' },
  { id: 'children', title: 'Діти' },
  { id: 'categories', title: 'Пільгові групи' },
  { id: 'employment', title: 'Діяльність' },
  { id: 'education', title: 'Освіта' },
  { id: 'email', title: 'EMAIL' },
  { id: 'project', title: 'ПРОЄКТ' },
  { id: 'comment', title: 'Коментар' },
  { id: 'files', title: 'Файли' },
  { id: 'passport', title: 'Паспорт' },
  { id: 'passport_files', title: 'Паспорт Файли' },
  { id: 'id_number', title: 'ІПН' },
  { id: 'inn_files', title: 'ІПН Файли' },
  { id: 'utm', title: 'UTM мітка' }
];
