import type { FormEventHandler } from 'react';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { FormBtns } from '../../../components/form-btns';
import { useChangeFields } from '../../../hooks/use-change-fields';
import { Input } from '../../../components/input';
import type { OptionItem } from '../../../common/types/option-item';
import { Select } from '../../../components/select';
import { ORG_TYPE, REGIONS } from '../../../common/constants';
import { checkIsPhone } from '../../../helpers/check-is-phone';
import { useCreatePartnerMutation } from '../../../api/partner-api';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { AddPartnerStyled, Col, ColTitle, InputsContainer } from './style';

interface AddPartnerProps {
  setShow: Dispatch<SetStateAction<boolean>>;
}

interface TextFields {
  name: string;
  edrpou: string;
  email: string;
  phone: string;
  contact_person: string;
  location: string;
  activity_field: string;
  employees_number: string;
  comment: string;
}

export const AddPartner = ({ setShow }: AddPartnerProps) => {
  const notification = useNotification();
  const { fields, handleFieldsChange } = useChangeFields<TextFields>({
    name: '',
    edrpou: '',
    email: '',
    phone: '',
    contact_person: '',
    location: '',
    activity_field: '',
    employees_number: '',
    comment: ''
  });
  const [orgType, setOrgType] = useState<OptionItem>({ id: '', title: '' });
  const [region, setRegion] = useState<OptionItem>({ id: '', title: '' });

  const [createPartner, { isLoading }] = useCreatePartnerMutation();

  const notAllFieldsAreFilled =
    !fields.name ||
    !fields.contact_person ||
    !checkIsPhone(fields.phone) ||
    !fields.email ||
    !orgType.id ||
    !region.id;

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const eneredData = Object.entries(fields).filter((item) => item[1]);
    createPartner({
      ...Object.fromEntries(eneredData),
      org_type: orgType.id || 0,
      region: region.id || 0
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          message: 'Партнера додано',
          title: 'Ви успішно створили партнера'
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          message: 'Помилка',
          title: getApiError(err)
        });
      });
  };

  return (
    <AddPartnerStyled onSubmit={handleSubmit}>
      <InputsContainer>
        <Col>
          <ColTitle>Дані організації</ColTitle>
          <Input
            label="Назва юридичної особи *"
            value={fields.name}
            name="name"
            onChange={handleFieldsChange}
            required
          />
          <Input
            label="Код ЄДРПО"
            value={fields.edrpou}
            name="edrpou"
            onChange={handleFieldsChange}
            mask="99999999"
            maskChar=""
          />
          <Select
            label="Тип організації *"
            value={orgType}
            setValue={setOrgType}
            options={ORG_TYPE}
          />
          <Input
            label="Напрям діяльності"
            value={fields.activity_field}
            name="activity_field"
            onChange={handleFieldsChange}
          />
          <Input
            label="Кількість співробітників"
            value={fields.employees_number}
            name="employees_number"
            onChange={handleFieldsChange}
            mask="99999999"
            maskChar=""
          />
          <Input
            label="Коментар"
            value={fields.comment}
            name="comment"
            onChange={handleFieldsChange}
          />
        </Col>
        <Col>
          <ColTitle>Контактні дані</ColTitle>
          <Input
            label="Місто"
            value={fields.location}
            name="location"
            onChange={handleFieldsChange}
          />
          <Select
            options={REGIONS.filter((_, i) => i).map((item, i) => ({
              id: i + 1,
              title: item
            }))}
            value={region}
            setValue={setRegion}
            label="Область *"
          />
          <Input
            label="Контактна особа *"
            value={fields.contact_person}
            name="contact_person"
            onChange={handleFieldsChange}
            required
          />
          <Input
            label="Телефон *"
            value={fields.phone}
            onChange={handleFieldsChange}
            name="phone"
            required
            mask="+38 999 999 99 99"
            type="tel"
          />
          <Input
            label="Email *"
            value={fields.email}
            name="email"
            onChange={handleFieldsChange}
            required
            type="email"
          />
        </Col>
      </InputsContainer>
      <FormBtns
        disabled={notAllFieldsAreFilled || isLoading}
        onCancel={() => setShow(false)}
      />
    </AddPartnerStyled>
  );
};
