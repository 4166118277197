export const DashboardLoacationSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_5547_436699)">
      <path
        d="M12.7363 1.96406C11.476 0.698337 9.79698 0.00125985 8.00848 0.00125985H8.00076C4.39926 -0.0724538 1.23963 3.09927 1.31378 6.69946C1.40805 9.24163 3.85367 12.4701 5.75816 14.9505C6.31747 15.6186 7.13241 16 8.00792 16C8.9141 16 9.75548 15.5917 10.3163 14.8797C11.7291 13.124 14.7799 8.90121 14.6878 6.6994C14.6878 4.90961 13.9948 3.22791 12.7363 1.96406ZM9.33439 14.1063C8.68852 14.9582 7.3299 14.9598 6.68379 14.1078C4.10137 10.8195 2.56363 8.08099 2.56363 6.69943C2.56363 3.69526 5.00271 1.25121 8.00076 1.25121H8.00848C11.0023 1.25121 13.4379 3.69529 13.4379 6.69943C13.4379 8.09602 11.9422 10.7957 9.33439 14.1063ZM8.03588 9.34428C6.57133 9.34428 5.37981 8.15276 5.37981 6.68821C5.52571 3.16457 10.5466 3.16561 10.6919 6.68824C10.6919 8.1528 9.50044 9.34428 8.03588 9.34428ZM8.03588 5.28205C7.26053 5.28205 6.62973 5.91286 6.62973 6.68821C6.70697 8.55367 9.36507 8.55311 9.44204 6.68821C9.44204 5.91286 8.81127 5.28205 8.03588 5.28205Z"
        fill="#9DABC0"
      />
    </g>
    <defs>
      <clipPath id="clip0_5547_436699">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
