import { useRef, useState } from 'react';
import { PlaySvg } from '../svg/play-svg';
import { useGetCallRecordQuery } from '../../api/binotel-api';
import { PauseSvg } from '../svg/pause-svg';
import {
  AudioPlayerStyled,
  PlayBtn,
  ProgressBarContainer,
  SeekSlider,
  TimeStyled
} from './style';

interface AudioPlayerProps {
  callId: string | number;
}

export const AudioPlayer = ({ callId }: AudioPlayerProps) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const seekSliderRef = useRef<HTMLInputElement>(null);
  const [playState, setPlayState] = useState<'play' | 'pause'>('pause');
  const [currentTime, setCurrentTime] = useState(0);
  let raf: number | null = null;

  const { data } = useGetCallRecordQuery({ id: callId });

  const calculateTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${minutes}:${returnedSeconds}`;
  };

  const whilePlaying = () => {
    const seekSlider = seekSliderRef.current;
    if (seekSlider && audioRef.current) {
      setCurrentTime(Math.floor(audioRef.current.currentTime));
      seekSlider.style.setProperty(
        '--seek-before-width',
        `${(+seekSlider.value / +seekSlider.max) * 100}%`
      );
      raf = requestAnimationFrame(whilePlaying);
    }
  };

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (playState === 'pause') {
        audio.play();
        requestAnimationFrame(whilePlaying);
        setPlayState('play');
      } else {
        audio.pause();
        cancelAnimationFrame(raf || 0);
        setPlayState('pause');
      }
    }
  };

  if (!data) {
    return null;
  }

  return (
    <AudioPlayerStyled>
      <PlayBtn onClick={handlePlayPause}>
        {playState === 'play' ? <PauseSvg /> : <PlaySvg />}
      </PlayBtn>
      {playState === 'play' && (
        <ProgressBarContainer>
          <TimeStyled isStart>{calculateTime(currentTime)}</TimeStyled>
          <SeekSlider
            value={currentTime}
            ref={seekSliderRef}
            type="range"
            max={Math.floor(audioRef.current?.duration || 0)}
            onChange={(e) => {
              setCurrentTime(+e.target.value);
              if (
                audioRef.current?.currentTime ||
                audioRef.current?.currentTime === 0
              ) {
                audioRef.current.currentTime = +e.target.value;
              }

              // showRangeProgress(e.target);
            }}
          />
          <TimeStyled>
            {calculateTime(Math.floor(audioRef.current?.duration || 0))}
          </TimeStyled>
        </ProgressBarContainer>
      )}
      <audio
        onPause={() => setPlayState('pause')}
        ref={audioRef}
        src={data.url}
      >
        <track kind="captions" />
      </audio>
    </AudioPlayerStyled>
  );
};
