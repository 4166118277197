import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CheckBox } from '../../checkbox';
import { DragSvg } from '../../svg/drag-svg';
import { DragIcon, DragItemStyled } from './style';

interface DragItemProps {
  label: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  id: string;
}

export const DragItem = ({ checked, setChecked, label, id }: DragItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      transition: {
        duration: 0,
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
      }
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <DragItemStyled {...attributes} ref={setNodeRef} style={style}>
      <CheckBox setChecked={setChecked} checked={checked} label={label} />
      {id !== 'id' && (
        <DragIcon {...listeners}>
          <DragSvg />
        </DragIcon>
      )}
    </DragItemStyled>
  );
};
