import styled from 'styled-components';
import { centerFlex, scrollBar } from '../../style/helpers';

export const ConfirmPopupStyled = styled.div<{ needTranslate?: boolean }>`
  position: fixed;
  left: ${(p) => (p.needTranslate ? 'calc(-17vw)' : 0)};
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 99;
  background: ${(p) => p.theme.colors.popup.bg};
  ${centerFlex}
  flex-wrap: wrap;
  ${scrollBar}

  @media(max-width: ${(p) => p.theme.breakpoints.xxl}) {
    left: ${(p) => (p.needTranslate ? 'calc(-10vw)' : 0)};
  }
`;

export const PopupContent = styled.div<{ customWidth?: string }>`
  padding: 60px;
  width: 100%;
  max-width: ${(p) => p.customWidth || '436px'};
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const ButtonsContainer = styled.form`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;
