import type { FormEventHandler } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthBtn } from '../../components/auth/auth-btn';
import { AuthInput } from '../../components/auth/auth-input';
import { AuthLayout } from '../../layouts/auth-layout';
import { useForgotPasswordMutation } from '../../api/user-api';
import { getApiError } from '../../helpers/get-api-error';
import { AppRoute } from '../../common/enums/app/app-route.enum';
import { FormStyled } from './style';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [apiError, setApiError] = useState('');

  const [forgorPassword, { isLoading }] = useForgotPasswordMutation();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setApiError('');

    forgorPassword({ username })
      .unwrap()
      .then(() => navigate(AppRoute.FORGOT_PASSWORD_REQUEST))
      .catch((err) => {
        console.log(err);
        setApiError(getApiError(err));
      });
  };

  return (
    <AuthLayout
      title="Відновити пароль"
      subtitle="Будь ласка, надайте свою адресу електронної пошти, і ми надішлемо вам інструкції щодо зміни вашого пароля."
    >
      <FormStyled onSubmit={handleSubmit}>
        <AuthInput
          value={username}
          setValue={setUsername}
          type="text"
          placeholder="Логін"
          error={!username ? 'Обовʼязкове поле' : ''}
          serverError={apiError}
          errorFullWidth
        />
        <AuthBtn disabled={isLoading || !username} type="submit">
          ПРОДОВЖИТИ
        </AuthBtn>
      </FormStyled>
    </AuthLayout>
  );
};
