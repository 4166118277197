import { useState } from 'react';
import { ConfirmPopup } from '../confirm-popup';
import { useDeactivateMutation } from '../../api/user-api';
import { DeactivationBtnStyled } from './style';

export const DeactivationBtn = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [deactivate] = useDeactivateMutation();

  const handleDeactivate = () => {
    deactivate({ deactivate: 'yes' })
      .unwrap()
      .then(() => {
        location.href = 'https://www.google.com/';
      })
      .catch(() => {
        location.href = 'https://www.google.com/';
      });
  };

  return (
    <>
      <DeactivationBtnStyled onClick={() => setShowConfirm(true)}>
        Деактивація CRM
      </DeactivationBtnStyled>
      <ConfirmPopup
        show={showConfirm}
        setShow={setShowConfirm}
        title="Деактивувати CRM?"
        onSubmit={handleDeactivate}
      />
    </>
  );
};
