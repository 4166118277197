import { useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Transition } from 'react-transition-group';
import { CardLayout } from '../../../layouts/card-layout';
import { getApiError } from '../../../helpers/get-api-error';
import {
  useEditContactMutation,
  useGetCurrentContactQuery
} from '../../../api/contact-api';
import { useGetUserMeQuery } from '../../../api/user-api';
import { NewNote } from '../../../components/new-note';
import { useNotification } from '../../../hooks/use-notification';
import { NewCall } from '../../../components/new-call';
import { NewMeet } from '../../../components/new-meet';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from './transition';
import { CurrentContactTabs } from './current-contact-tabs';
import { CurrentConatctSidebar } from './current-contact-sidebar';
import { EditContact } from './edit-contact';

interface CurrentContactProps {
  id: string | number | null;
}

export const Currentcontact = ({ id }: CurrentContactProps) => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [newNote, setNewNote] = useState('');
  const [showNewNote, setShowNewNote] = useState(false);
  const [showNewCall, setShowNewCall] = useState(false);
  const [showNewMeet, setShowNewMeet] = useState(false);
  const [edit, setEdit] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, error, isLoading } = useGetCurrentContactQuery(
    { id },
    { refetchOnMountOrArgChange: true, skip: !id }
  );
  const [editContact] = useEditContactMutation();

  const handleClose = useCallback(() => {
    setShowNewNote(false);
    setNewNote('');
    searchParams.delete('id');
    searchParams.delete('contact_id');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleAddNote = () => {
    editContact({ id: id || '', body: { notes: [{ text: newNote }] } })
      .unwrap()
      .then(() => {
        setNewNote('');
        setShowNewNote(false);
        notification({
          type: 'success',
          title: 'Нотатку додано!',
          message: 'Ви успішно створили нотатку. '
        });
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const canView = userMe?.role?.view_contact || userMe?.is_superuser;

  if (!canView) {
    return null;
  }

  return (
    <Transition
      in={!!id && !isLoading && data && data.id.toString() === id.toString()}
      timeout={duration}
      unmountOnExit
      mountOnEnter
    >
      {(state) => (
        <CardLayout
          onClose={handleClose}
          sidebar={
            <CurrentConatctSidebar
              setShowNewNote={setShowNewNote}
              contact={data}
              setEdit={setEdit}
              setShowNewCall={setShowNewCall}
              setShowNewMeet={setShowNewMeet}
              checkedList={id ? [+id] : []}
            />
          }
          transitionStyle={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          transitionContentStyle={{
            ...defaultContentStyle,
            ...transitionContentStyles[state]
          }}
        >
          {error ? (
            getApiError(error)
          ) : (
            <CurrentContactTabs
              contactId={id || ''}
              files={data?.files}
              passportFiles={data?.passport_files}
              innFiles={data?.inn_files}
              setShowNewNote={setShowNewNote}
              notes={data?.notes || []}
              setShowNewCall={setShowNewCall}
              calls={data?.calls || []}
              contactFullName={data?.full_name || ''}
              meets={data?.meets || []}
              setShowNewMeet={setShowNewMeet}
              phone={data?.phone}
              projects={data?.projects}
              events={data?.events}
              dbFiles={data?.db_files}
              allowedFields={data?.allowed_fields}
            />
          )}
          {!!data && id && id === `${data.id}` && (
            <EditContact contact={data} edit={edit} setEdit={setEdit} />
          )}
          <NewNote
            show={showNewNote}
            setShow={setShowNewNote}
            value={newNote}
            setValue={setNewNote}
            onSubmit={handleAddNote}
          />
          <NewCall
            show={showNewCall}
            setShow={setShowNewCall}
            id={data?.id || ''}
            fullName={data?.full_name || ''}
          />
          <NewMeet
            show={showNewMeet}
            setShow={setShowNewMeet}
            id={data?.id || ''}
            fullName={data?.full_name || ''}
          />
        </CardLayout>
      )}
    </Transition>
  );
};
