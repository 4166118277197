import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import { NewActivity } from '../new-activity';
import { ActivityTextArea } from '../activity-textarea';
import { TextareaWrapper } from './style';

interface NewNoteProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: () => void;
}

export const NewNote = ({
  value,
  setValue,
  show,
  setShow,
  onSubmit
}: NewNoteProps) => {
  const handleClose = useCallback(() => {
    setShow(false);
    setValue('');
  }, [setShow, setValue]);

  return (
    <NewActivity
      show={show}
      onSubmit={onSubmit}
      onClose={handleClose}
      btnTitle="Зберегти нотатку"
      title="Нотатка"
      disabled={!value}
    >
      <TextareaWrapper>
        <ActivityTextArea
          value={value}
          setValue={setValue}
          placeholder="Пишіть тут щоб залишити нотатку ..."
          required
        />
      </TextareaWrapper>
    </NewActivity>
  );
};
