export const ActionsEditSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3649_1467)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1048 0.260347C11.4519 -0.0867822 12.0148 -0.0867822 12.3619 0.260347L14.8508 2.74923C15.0174 2.91593 15.1111 3.14202 15.1111 3.37777C15.1111 3.61351 15.0174 3.83961 14.8508 4.0063L6.67298 12.1841C6.50631 12.3508 6.28018 12.4444 6.04444 12.4444H3.55556C3.06464 12.4444 2.66667 12.0465 2.66667 11.5556V9.06667C2.66667 8.83093 2.76032 8.6048 2.92701 8.43813L11.1048 0.260347ZM11.7333 2.14596L4.44444 9.43484V10.6667H5.67626L12.9652 3.37777L11.7333 2.14596ZM0 15.1111C0 14.6202 0.397973 14.2222 0.888889 14.2222H15.1111C15.602 14.2222 16 14.6202 16 15.1111C16 15.602 15.602 16 15.1111 16H0.888889C0.397973 16 0 15.602 0 15.1111Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3649_1467">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
