import styled from 'styled-components';
import { scrollBar } from '../../../style/helpers';

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  gap: 21px;
  padding-bottom: 10px;
  ${scrollBar}
  ::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
  }
  &:first-child {
    margin-top: 0;
  }

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    padding-bottom: 6px;
  }
`;
