import styled from 'styled-components';

export const CardLayoutStyled = styled.div`
  padding-left: 17%;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: ${(p) => p.theme.colors.popup.bg};
  z-index: 10;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    padding-left: 10%;
  }
`;

export const CardContent = styled.div`
  position: relative;
  background: ${(p) => p.theme.colors.white};
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: -4px 4px 21px rgba(0, 0, 0, 0.12);
`;

export const Close = styled.div`
  position: absolute;
  left: -32px;
  top: 30px;
  width: 58px;
  height: 58px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.card.bg};
  padding: 19px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: -1;
`;

export const CardSidebar = styled.div`
  width: 32%;
`;

export const CardInfo = styled.div`
  padding: 40px 0;
  width: 68%;
  background: ${(p) => p.theme.colors.card.bg};
  height: 100vh;
`;
