export const SearchSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.66732 14.4997C3.90065 14.4997 0.833984 11.433 0.833984 7.66634C0.833984 3.89967 3.90065 0.833008 7.66732 0.833008C11.434 0.833008 14.5007 3.89967 14.5007 7.66634C14.5007 11.433 11.434 14.4997 7.66732 14.4997ZM7.66732 1.83301C4.44732 1.83301 1.83398 4.45301 1.83398 7.66634C1.83398 10.8797 4.44732 13.4997 7.66732 13.4997C10.8873 13.4997 13.5007 10.8797 13.5007 7.66634C13.5007 4.45301 10.8873 1.83301 7.66732 1.83301Z"
      fill="#9DABC0"
    />
    <path
      d="M14.6673 15.1663C14.5407 15.1663 14.414 15.1197 14.314 15.0197L12.9807 13.6863C12.7873 13.493 12.7873 13.173 12.9807 12.9797C13.174 12.7863 13.494 12.7863 13.6873 12.9797L15.0207 14.313C15.214 14.5063 15.214 14.8263 15.0207 15.0197C14.9207 15.1197 14.794 15.1663 14.6673 15.1663Z"
      fill="#9DABC0"
    />
  </svg>
);
