import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import {
  useEditProjectMutation,
  useGetCurrentProjectQuery
} from '../../../api/projects-api';
import { ProjectHeader } from '../../../components/project-header';
import { EventsPage } from '../../events';
import { AppRoute } from '../../../common/enums/app/app-route.enum';
import { ConfirmPopup } from '../../../components/confirm-popup';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { dateToApiFormat } from '../../../helpers/date-helpers';
import { useGetUserMeQuery } from '../../../api/user-api';
import { CurrentProjectStyled } from './style';
import { EditProject } from './edit-project';

export const CurrentProject = () => {
  const notification = useNotification();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { data: userMe } = useGetUserMeQuery('');
  const { data: project } = useGetCurrentProjectQuery(
    { id: projectId },
    { refetchOnMountOrArgChange: true, skip: !projectId }
  );
  const [editProject] = useEditProjectMutation();
  const [edit, setEdit] = useState(false);
  const [archProject, setArchProject] = useState(false);

  const handleArchProject = () => {
    editProject({
      id: project?.id || '',
      body: { finished: true, end_date: dateToApiFormat(new Date()) }
    })
      .unwrap()
      .then(() => {
        notification({
          title: 'Проєкт завершено!',
          message: 'Ви успішно завершили проєкт.',
          type: 'success'
        });
        navigate(AppRoute.PROJECTS);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  if (!userMe) {
    return null;
  }

  const canView = userMe.role?.view_project || userMe?.is_superuser;
  const canEdit = userMe.role?.change_project || userMe?.is_superuser;
  const canArch = userMe.role?.archive_project || userMe?.is_superuser;

  if (!canView) {
    return null;
  }

  return (
    <CurrentProjectStyled>
      <ProjectHeader
        title={`Проєкт “${project?.name || ''}”`}
        idTitle={`ID Проєкту: ${project?.id || ''}`}
        onEdit={() => setEdit(true)}
        onFinish={() => setArchProject(true)}
        backLink={AppRoute.PROJECTS}
        canFinish={project && !project?.end_date && canArch}
        withOutEdit={!canEdit}
      />
      <EventsPage projectId={projectId || ''} />
      {project && (
        <EditProject show={edit} setShow={setEdit} project={project} />
      )}
      <ConfirmPopup
        title={`Чи дійсно бажаєте завершити проєкт ${project?.name || ''}?`}
        show={archProject}
        setShow={setArchProject}
        onSubmit={handleArchProject}
      />
    </CurrentProjectStyled>
  );
};
