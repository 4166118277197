import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ProjectTabStyled = styled(NavLink)<{
  $isSmall?: boolean;
  $color: string;
}>`
  display: block;
  padding: ${(p) => (p.$isSmall ? '10px' : '20px')};
  border-radius: 9px;
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  background: ${(p) => p.theme.colors.primaryLight};
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: ${(p) => (p.$isSmall ? '14px' : '20px')};
  font-weight: 600;
  line-height: 140%;
  width: clamp(200px, 100%, 100%);
  min-width: fit-content;
  white-space: nowrap;
  &.active,
  &:hover {
    color: ${(p) => p.theme.colors.white};
    background: ${(p) => p.$color};
    border: 1px solid ${(p) => p.$color};
  }
  transition: all 0.2s;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    br,
    span {
      display: none;
    }

    padding: ${(p) => (p.$isSmall ? '8px' : '14px')};
    font-size: ${(p) => (p.$isSmall ? '14px' : '18px')};
  }
`;
