import { useState, type Dispatch, type SetStateAction } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { NewActivity } from '../new-activity';
import { Input } from '../input';
import { useCreateLetterMutation } from '../../api/send-puls-api';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { NewLetterStyled, Subject } from './style';
import 'react-quill/dist/quill.snow.css';

Quill.register(Quill.import('attributors/style/align'));

interface NewLetterProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  contactId: string | number;
}

export const NewLetter = ({ show, setShow, contactId }: NewLetterProps) => {
  const notification = useNotification();
  const [letter, setLetter] = useState('');
  const [subject, setSubject] = useState('');
  const [createLetter, { isLoading }] = useCreateLetterMutation();

  const handleSubmit = () => {
    let email = '';
    try {
      email = btoa(letter);
    } catch {
      notification({
        type: 'error',
        title: 'Помилка',
        message: 'Спробуйте пізніше'
      });
    }
    createLetter({ contact_id: contactId, subject, html: email })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Лист відправлено',
          message: 'Ви успішно відправили лист'
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  return (
    <NewActivity
      show={show}
      onClose={() => setShow(false)}
      onSubmit={handleSubmit}
      title="Новий лист"
      btnTitle="Надіслати"
      expanded
      disabled={!subject || isLoading}
    >
      <NewLetterStyled>
        <Subject>
          <Input
            label="Тема*"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </Subject>
        <ReactQuill
          theme="snow"
          value={letter}
          onChange={setLetter}
          modules={{
            toolbar: [
              ['bold', 'italic', 'underline'],
              [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' }
              ],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link']
            ]
          }}
        />
      </NewLetterStyled>
    </NewActivity>
  );
};
