import { useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { useSearchParams } from 'react-router-dom';
import type { Contact } from '../../models/contact';
import { DragKanbanSvg } from '../svg/drrag-kanban-svg';
import { SatisfactionNegativeSvg } from '../svg/satisfactions/satisfaction-negative-svg';
import { SatisfactionNeutralSvg } from '../svg/satisfactions/satisfaction-neutral-svg';
import { SatisfactionPositiveSvg } from '../svg/satisfactions/satisfaction-pisitive-svg';
import { dateWithTime } from '../../helpers/date-helpers';
import { TooltipFixed } from '../tooltip/tooltip-fixed';
import {
  BtnWrapper,
  CardDate,
  CardHeader,
  ContactName,
  DragTrigger,
  KanbanCardStyled,
  PhoneAndEmail,
  PhoneAndEmailItem,
  RadioBtn,
  RespManager,
  SatisfactionIcon
} from './style';

interface KanbanCardProps {
  contact: Contact;
  colIdx: number;
  isProcessed?: boolean;
  isFetcing?: boolean;
  onSetProcessed: (contact: Contact) => void;
  onCancelProcessed: (contact: Contact) => void;
  eventId: string | number;
}

const satisfactionIcons = [
  <SatisfactionPositiveSvg key={1} />,
  <SatisfactionNeutralSvg key={2} />,
  <SatisfactionNegativeSvg key={3} />
];

export const KanbanCard = ({
  contact,
  colIdx,
  isProcessed,
  isFetcing,
  onSetProcessed,
  onCancelProcessed,
  eventId
}: KanbanCardProps) => {
  const [isHover, setIsHover] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { setNodeRef, attributes, listeners, isDragging } = useDraggable({
    id: contact.id || '',
    data: contact
  });

  const handleSetProcessed = () => {
    if (!isFetcing) {
      onSetProcessed(contact);
    }
  };

  const handleCancelProcessed = () => {
    if (!isFetcing) {
      onCancelProcessed(contact);
    }
  };

  const handleShowContact = () => {
    searchParams.set('contact_id', contact.id.toString());
    setSearchParams(searchParams);
  };

  const addEventDate = contact.events_added_dates.find(
    (item) => `${item.event_id}` === `${eventId}`
  )?.date;

  return isDragging ? (
    <div style={{ height: '220px' }}></div>
  ) : (
    <KanbanCardStyled
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      colIdx={colIdx}
      ref={setNodeRef}
      isProcessed={isProcessed}
    >
      <CardHeader isProcessed={isProcessed}>
        {!isProcessed && (
          <DragTrigger isProcessed={isProcessed} {...attributes} {...listeners}>
            {isHover && !isFetcing && <DragKanbanSvg />}
          </DragTrigger>
        )}
        <BtnWrapper>
          <TooltipFixed title={contact.satisfaction?.title || ''}>
            <SatisfactionIcon isProcessed={isProcessed} colIdx={colIdx}>
              {
                satisfactionIcons[
                  contact.satisfaction?.id ? +contact.satisfaction.id - 1 : 1
                ]
              }
            </SatisfactionIcon>
          </TooltipFixed>
          {isProcessed ? (
            <TooltipFixed title="Відмінити">
              <RadioBtn
                onClick={handleCancelProcessed}
                isActive={true}
                colIdx={colIdx}
              />
            </TooltipFixed>
          ) : (
            <TooltipFixed title="Відмітити як опрацюваний">
              <RadioBtn
                onClick={handleSetProcessed}
                isActive={false}
                colIdx={colIdx}
              />
            </TooltipFixed>
          )}
        </BtnWrapper>
      </CardHeader>
      <CardDate isProcessed={isProcessed}>
        {addEventDate ? (
          dateWithTime(addEventDate)
        ) : (
          <>{dateWithTime(contact.created_at)} (Дата створення)</>
        )}
      </CardDate>
      <ContactName onClick={handleShowContact} isProcessed={isProcessed}>
        {contact.full_name}
      </ContactName>
      <PhoneAndEmail>
        <PhoneAndEmailItem isProcessed={isProcessed}>
          {contact.phone}
        </PhoneAndEmailItem>
        <PhoneAndEmailItem isProcessed={isProcessed}>
          {contact.email}
        </PhoneAndEmailItem>
      </PhoneAndEmail>
      <RespManager colIdx={colIdx} isProcessed={isProcessed}>
        {contact.resp_managers?.map((item) => (
          <p key={item.id}>
            {item.last_name} {item.first_name} {item.patronymic}
          </p>
        ))}
      </RespManager>
    </KanbanCardStyled>
  );
};
