import styled from 'styled-components';

export const UserRatingStyled = styled.form``;

export const Descr = styled.div`
  margin: 20px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;
