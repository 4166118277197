import type { Dispatch, SetStateAction } from 'react';
import { IconContainer } from '../icon-container';
import { KanbanSvg } from '../svg/kanban-svg';
import { ListSvg } from '../svg/list-svg';
import { useAppDispatch } from '../../api/store';
import { setIsKanbanPage } from '../../api/page-data-slice';
import { SwitcherItem, SwitcherKanbanStyled } from './style';

interface SwitcherKanbanProps {
  isRightOption: boolean;
  setIsRightOptins: Dispatch<SetStateAction<boolean>>;
  leftTitle?: string;
  rightTitle?: string;
}

export const SwitcherKanban = ({
  isRightOption,
  setIsRightOptins,
  leftTitle = 'Список',
  rightTitle = 'Канбан'
}: SwitcherKanbanProps) => {
  const dispatch = useAppDispatch();

  const handleSwitch = (option: boolean) => {
    setIsRightOptins(option);
    dispatch(setIsKanbanPage(option));
  };

  return (
    <SwitcherKanbanStyled isKanban={isRightOption}>
      <SwitcherItem
        onClick={() => handleSwitch(false)}
        isActive={!isRightOption}
      >
        <IconContainer onClick={() => {}}>
          <ListSvg />
        </IconContainer>
        {leftTitle}
      </SwitcherItem>
      <SwitcherItem
        onClick={() => handleSwitch(true)}
        isActive={isRightOption}
        isRight
      >
        <IconContainer onClick={() => {}}>
          <KanbanSvg />
        </IconContainer>
        {rightTitle}
      </SwitcherItem>
    </SwitcherKanbanStyled>
  );
};
