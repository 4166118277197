import type { FormEventHandler } from 'react';
import { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { setCookie } from 'typescript-cookie';
import { AuthInput } from '../../components/auth/auth-input';
import { AuthLayout } from '../../layouts/auth-layout';
import { AuthBtn } from '../../components/auth/auth-btn';
import { useAppDispatch } from '../../api/store';
import { useLoginMutation } from '../../api/user-api';
import { setCredential } from '../../api/auth-slice';
import { StorageKey } from '../../common/enums/storage/storage-key.enum';
import { getApiError } from '../../helpers/get-api-error';
import { ApiPath } from '../../common/enums/http/api-path.enum';
import { ForgotPassword, FormStyled, InputsContainer } from './style';

export const LogIn = () => {
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [apiError, setApiError] = useState('');
  const [login, { isLoading }] = useLoginMutation();

  const handleLogIn: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setApiError('');
    try {
      const data = await login({
        username,
        password
      }).unwrap();
      const { exp: refreshExp } = jwt_decode<{ exp: string }>(data.refresh);
      dispatch(setCredential({ accessToken: data.access }));
      localStorage.setItem(StorageKey.ACCESS_TOKEN, data.access);
      setCookie(StorageKey.REFRESH_TOKEN, data.refresh, {
        expires: new Date(refreshExp + Date.now()),
        path: ApiPath.ROOT
      });
    } catch (err) {
      console.log(err);
      setApiError(getApiError(err));
    }
  };

  return (
    <AuthLayout title="Увійти">
      <FormStyled onSubmit={handleLogIn}>
        <InputsContainer>
          <AuthInput
            value={username}
            setValue={setUsername}
            type="text"
            placeholder="Логін"
            error={!username ? 'Обовʼязкове поле' : ''}
          />
          <AuthInput
            value={password}
            setValue={setPassword}
            type="password"
            placeholder="Пароль"
            error={apiError}
            serverError={apiError}
          />
        </InputsContainer>
        <ForgotPassword to="/forgot-password">
          <span>Забули пароль?</span>
        </ForgotPassword>
        <AuthBtn disabled={isLoading || !username || !password} type="submit">
          ПРОДОВЖИТИ
        </AuthBtn>
      </FormStyled>
    </AuthLayout>
  );
};
