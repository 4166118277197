import { ApiPath } from '../common/enums/http/api-path.enum';
import { ApiTag } from '../common/enums/http/api-tag.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import type { ReponseWithPagination } from '../common/types/response-with-pagination';
import type { IEvent, Template } from '../models/event';
import { commonApi } from './common-api';
import { exportData } from './helpers';

export const eventsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<ReponseWithPagination<IEvent>, { params: string }>(
      {
        query: ({ params }) => `${ApiPath.EVENTS}${ApiPath.ROOT}${params}`,
        providesTags: [ApiTag.EVENTS]
      }
    ),
    getCurrentEvent: builder.query<IEvent, { id: string | undefined }>({
      query: ({ id }) => `${ApiPath.EVENTS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.EVENT]
    }),
    createEvent: builder.mutation<Event, Record<string, unknown>>({
      query: (data) => ({
        url: `${ApiPath.EVENTS}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.EVENTS, ApiTag.EVENT]
    }),
    editEvent: builder.mutation<
      Event,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.EVENTS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.EVENTS, ApiTag.EVENT]
    }),
    editStep: builder.mutation<
      Event,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.EVENT_STEPS}${ApiPath.ROOT}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.EVENT, ApiTag.CONTACTS]
    }),
    deleteEvent: builder.mutation<IEvent, { ids: (string | number)[] }>({
      query: ({ ids }) => ({
        url: `${ApiPath.EVENTS}${ApiPath.ROOT}?ids=${ids.join(',')}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.EVENTS]
    }),
    getTEmplate: builder.query<ReponseWithPagination<Template>, ''>({
      query: () => `${ApiPath.TAMPLATES}${ApiPath.ROOT}`,
      providesTags: [ApiTag.EVENTS]
    }),
    exportReport: builder.mutation({
      query: (id: string | number) => ({
        url: `${ApiPath.EVENT_REPORT_PDF}?event_id=${id}`,
        method: HttpMethod.POST,
        body: {},
        responseHandler: (response) => exportData(response, true),
        cache: 'no-cache'
      })
    })
  }),
  overrideExisting: false
});

export const {
  useGetEventsQuery,
  useCreateEventMutation,
  useGetCurrentEventQuery,
  useDeleteEventMutation,
  useGetTEmplateQuery,
  useEditEventMutation,
  useExportReportMutation,
  useEditStepMutation
} = eventsApi;
