import type { ReactNode } from 'react';
import { LogoSvg } from '../../components/svg/logo-svg';
import { AuthContent, AuthLayoutStyled, Logo, SubTitle, Title } from './style';

interface AuthLayoutProps {
  children: ReactNode;
  title: string;
  subtitle?: string;
}

export const AuthLayout = ({ children, title, subtitle }: AuthLayoutProps) => (
  <AuthLayoutStyled>
    <AuthContent>
      <Logo>
        <LogoSvg />
      </Logo>
      <Title>{title}</Title>
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
      {children}
    </AuthContent>
  </AuthLayoutStyled>
);
