import type { MouseEventHandler, ReactNode } from 'react';
import { StringCutter } from '../string-cutter';
import { CloseFileSvg } from '../svg/close-file-svg';
import { BadgeStyled, CloseIcon } from './style';

interface BadgeProps {
  children: ReactNode;
  onRemove(): void;
  fullWidth?: boolean;
}

export const Badge = ({ children, onRemove, fullWidth }: BadgeProps) => {
  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <BadgeStyled>
      {fullWidth ? children : <StringCutter lines="1">{children}</StringCutter>}
      <CloseIcon onClick={handleClick}>
        <CloseFileSvg />
      </CloseIcon>
    </BadgeStyled>
  );
};
