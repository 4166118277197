import {
  ApiPath,
  AuthApiPath,
  UsersApiPath
} from '../common/enums/http/api-path.enum';
import { ApiTag } from '../common/enums/http/api-tag.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import type { ReponseWithPagination } from '../common/types/response-with-pagination';
import type { Activity } from '../models/activity';
import type { Role, RoleCreate } from '../models/role';
import type { User, UserMe } from '../models/user';
import { commonApi } from './common-api';

export const userApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<
      { access: string; refresh: string },
      { username: string; password: string }
    >({
      query: (body) => ({
        url: `${ApiPath.AUTH}${AuthApiPath.CREATE}`,
        method: HttpMethod.POST,
        body
      })
    }),
    deactivate: builder.mutation<unknown, { deactivate: 'yes' | 'no' }>({
      query: (body) => ({
        url: `${ApiPath.DEACTIVATE}${ApiPath.ROOT}`,
        method: HttpMethod.POST,
        body
      })
    }),
    forgotPassword: builder.mutation<null, { username: string }>({
      query: (body) => ({
        url: `${ApiPath.USERS}${UsersApiPath.FORGOT_PASSWORD}`,
        method: HttpMethod.POST,
        body
      })
    }),
    getUsers: builder.query<ReponseWithPagination<User>, { params: string }>({
      query: ({ params }) =>
        `${ApiPath.USERS}${UsersApiPath.ALL_USERS}${params}`,
      providesTags: [ApiTag.USERS]
    }),
    getUserMe: builder.query<UserMe, ''>({
      query: () => ApiPath.USER_ME,
      providesTags: [ApiTag.ROLE]
    }),
    getCurrentUser: builder.query<User, { id: string | number | null }>({
      query: ({ id }) => `${ApiPath.AUTH_USER}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.USER]
    }),
    getUserRoles: builder.query<ReponseWithPagination<Role>, ''>({
      query: () => `${ApiPath.USERS}${UsersApiPath.ROLES}`,
      providesTags: [ApiTag.ROLES]
    }),
    getCurrentRole: builder.query<Role, { id: string | number }>({
      query: ({ id }) =>
        `${ApiPath.USERS}${UsersApiPath.ROLES}${id}${ApiPath.ROOT}`,
      providesTags: [ApiTag.ROLE]
    }),
    getUserActiviti: builder.query<Activity, { id: number | string | null }>({
      query: ({ id }) => `${ApiPath.USER_ACTIVITI}/${id}`,
      providesTags: [ApiTag.USER]
    }),
    createUser: builder.mutation<User, FormData>({
      query: (data) => ({
        url: ApiPath.AUTH_USER,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.USERS]
    }),
    editUser: builder.mutation<
      User,
      { id: string | number; body: Record<string, unknown> | FormData }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.AUTH_USER}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.USERS, ApiTag.USER]
    }),
    createRole: builder.mutation<Role, RoleCreate>({
      query: (data) => ({
        url: `${ApiPath.USERS}${UsersApiPath.ROLES}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.ROLES]
    }),
    editRole: builder.mutation<
      Role,
      { id: string | number; body: Record<string, unknown> }
    >({
      query: ({ id, body }) => ({
        url: `${ApiPath.USERS}${UsersApiPath.ROLES}${id}${ApiPath.ROOT}`,
        method: HttpMethod.PATCH,
        body
      }),
      invalidatesTags: [ApiTag.ROLES, ApiTag.ROLE]
    }),
    deleteRole: builder.mutation<null, { id: string | number }>({
      query: ({ id }) => ({
        url: `${ApiPath.USERS}${UsersApiPath.ROLES}${id}${ApiPath.ROOT}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.ROLES]
    }),
    deleteUser: builder.mutation<null, { ids: number[] }>({
      query: ({ ids }) => ({
        url: `${ApiPath.USERS}${UsersApiPath.ALL_USERS}?ids=${ids.join(',')}`,
        method: HttpMethod.DELETE
      }),
      invalidatesTags: [ApiTag.USERS]
    }),
    fireUser: builder.mutation<
      User,
      {
        discharged_user: boolean;
        discharge_rate: number;
        user_id: string | number;
      }
    >({
      query: (data) => ({
        url: `${ApiPath.DISCHARGE_USER}`,
        method: HttpMethod.POST,
        body: data
      }),
      invalidatesTags: [ApiTag.USERS, ApiTag.USER]
    })
  }),
  overrideExisting: false
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useGetUsersQuery,
  useCreateUserMutation,
  useGetUserRolesQuery,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useGetUserMeQuery,
  useDeleteUserMutation,
  useGetCurrentRoleQuery,
  useGetCurrentUserQuery,
  useEditUserMutation,
  useFireUserMutation,
  useGetUserActivitiQuery,
  useDeactivateMutation
} = userApi;
