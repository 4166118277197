import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import type { OptionItem } from '../../common/types/option-item';
import { Notes } from '../notes';
import type { Note } from '../../models/note';
import { Calls } from '../calls';
import type { Call } from '../../models/call';
import { Meets } from '../meets';
import type { Meet } from '../../models/meet';
import { ActivityList } from '../activity-list';
import type {
  DeleteCall,
  EditCall
} from '../../common/types/rtk-triggers/call';
import type {
  DeleteMeet,
  EditMeet
} from '../../common/types/rtk-triggers/meet';
import type {
  DeleteNote,
  EditNote
} from '../../common/types/rtk-triggers/note';
import { Letters } from '../letters';
import { ActivityTabs } from './activity-tabs';
import { ActivityStyled } from './style';

interface ActivityProps {
  tabs: OptionItem[];
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  setShowNewCall: Dispatch<SetStateAction<boolean>>;
  setShowNewMeet: Dispatch<SetStateAction<boolean>>;
  notes: Note[];
  calls: Call[];
  meets: Meet[];
  contactFullName: string;
  contactId: string | number;
  phone?: string;
  editCall: EditCall;
  deleteCall: DeleteCall;
  editMeet: EditMeet;
  deleteMeet: DeleteMeet;
  editNote: EditNote;
  deleteNote: DeleteNote;
  isPartner?: boolean;
}

export const Activity = ({
  tabs,
  setShowNewNote,
  setShowNewCall,
  setShowNewMeet,
  notes,
  calls,
  meets,
  contactFullName,
  contactId,
  phone,
  editCall,
  deleteCall,
  editMeet,
  deleteMeet,
  editNote,
  deleteNote,
  isPartner
}: ActivityProps) => {
  const [currentTab, setCurrentTab] = useState<OptionItem>(tabs[0]);

  const renderContent = () => {
    switch (currentTab.id) {
      case 'note':
        return (
          <Notes
            notes={notes}
            editNote={editNote}
            deleteNote={deleteNote}
            setShowNewNote={setShowNewNote}
          />
        );
      case 'activity':
        return (
          <ActivityList
            contactId={contactId}
            contactFullName={contactFullName}
            editCall={editCall}
            deleteCall={deleteCall}
            editMeet={editMeet}
            deleteMeet={deleteMeet}
            editNote={editNote}
            deleteNote={deleteNote}
            isPartner={isPartner}
          />
        );
      case 'calls':
        return (
          <Calls
            contactFullName={contactFullName}
            calls={calls}
            setShowNewCall={setShowNewCall}
            phone={phone}
            editCall={editCall}
            deleteCall={deleteCall}
            isPartner={isPartner}
            contactId={contactId}
          />
        );
      case 'meet':
        return (
          <Meets
            contactFullName={contactFullName}
            meets={meets}
            setShowNewMeet={setShowNewMeet}
            editMeet={editMeet}
            deleteMeet={deleteMeet}
            isPartner={isPartner}
          />
        );
      case 'letter':
        return <Letters contactId={contactId} />;
      default:
        return null;
    }
  };

  return (
    <ActivityStyled>
      <ActivityTabs
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {renderContent()}
    </ActivityStyled>
  );
};
