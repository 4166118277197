import { useState } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ChartsLayout } from '../components/charts-layout';
import { ChartContainer } from '../components/chart-container';
import { BarChart } from '../components/bar-chart';
import { useGetAudienceQuery } from '../../../api/dashboard-api';
import { useTypedSelector } from '../../../api/store';
import { DoubleChart } from '../components/double-chart';
import { ChartsSlider } from '../components/charts-slider';
import { AppRoute } from '../../../common/enums/app/app-route.enum';
import { DoughnutChart } from '../components/doughnut-chart';
import { Loader } from '../../../components/loader';
import { groupByRegion } from '../helpers/group-by-region';
import { CategoriesChart } from '../components/categories-chart';

export const AudiencePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeCity, setActiveCity] = useState({ id: '', name: '' });
  const [activeRegion, setActiveRegion] = useState({ id: '', name: '' });
  const { date, colors } = useTypedSelector((state) => state.dashboard);

  const { data, isFetching } = useGetAudienceQuery(
    {
      params: `?audience_dashboard=true&liv_city=${activeCity.name}&liv_region=${activeRegion.name}&created_start=${date.start}&created_end=${date.end}`
    },
    { refetchOnMountOrArgChange: true }
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <ChartsLayout
      activeCity={activeCity}
      activeRegion={activeRegion}
      setActiveCity={setActiveCity}
      setActiveRegion={setActiveRegion}
      total={data.data.adults}
      mt="0"
      isFetching={isFetching}
      dataByRegion={groupByRegion(data.data.map)}
    >
      <ChartContainer title="Загальна кількість людей">
        <BarChart
          aspect={1.5}
          tickMaxWidth={80}
          barCount={3}
          color={theme.colors.primary}
          data={[
            { name: 'Діти', value: data.data.children },
            { name: 'Дорослі', value: data.data.adults },
            { name: 'Всі', value: data.data.all }
          ]}
        />
      </ChartContainer>
      <ChartContainer title="Вік та гендер відвідувачів">
        <DoubleChart
          legends={[
            { name: 'Чоловіки', value: data.data.men_count },
            { name: 'Жінки', value: data.data.women_count }
          ]}
          colors={['#306FE3', theme.colors.primary]}
          data={data.data.age_and_gender.map((item) => ({
            name: item.age_gradation,
            firstValue: item.men,
            secondValue: item.women
          }))}
        />
      </ChartContainer>
      <ChartContainer title="Вік та стать дітей">
        <DoubleChart
          legends={[
            { name: 'Хлопчики', value: data.data.boys_count },
            { name: 'Дівчата', value: data.data.girls_count }
          ]}
          colors={['#306FE3', theme.colors.primary]}
          data={data.data.kids_age_and_gender.map((item) => ({
            name: item.age_gradation,
            firstValue: item.men,
            secondValue: item.women
          }))}
        />
      </ChartContainer>
      <ChartsSlider
        title="Статус (П Н Н)"
        data={[
          {
            title: 'Загальна кількість людей ',
            chart: (
              <BarChart
                aspect={2}
                color={theme.colors.primary}
                data={[
                  ...data.data.satisfaction_count
                    .filter((item) => item.id)
                    .map((item) => {
                      const [_, [name, value]] = Object.entries(item);

                      return { name, value };
                    }),
                  {
                    name: 'Всі статуси',
                    value: data.data.satisfaction_count
                      .filter((item) => item.id)
                      .map((item) => Object.values(item)[1])
                      .reduce((a, b) => a + b, 0)
                  }
                ]}
              />
            )
          }
        ]}
      />
      <ChartsSlider
        title="Освіта"
        data={[
          {
            title: '',
            chart: (
              <BarChart
                aspect={2}
                tickAlign="center"
                tickMaxWidth={80}
                color={theme.colors.primary}
                data={data.data.education_count.slice(1, 5).map((item) => {
                  const [_, [name, value]] = Object.entries(item);
                  if (typeof value === 'number') {
                    return { name, value };
                  }

                  return { name: '', value: 0 };
                })}
              />
            )
          },
          {
            title: '',
            chart: (
              <BarChart
                aspect={2}
                tickAlign="center"
                tickMaxWidth={80}
                color={theme.colors.primary}
                data={[
                  ...data.data.education_count.slice(5).map((item) => {
                    const [_, [name, value]] = Object.entries(item);
                    if (typeof value === 'number') {
                      return { name, value };
                    }

                    return { name: '', value: 0 };
                  }),
                  {
                    name: 'Інше',
                    value: data.data.education_count[0]['Інше'] as number
                  }
                ]}
              />
            )
          }
        ]}
      />
      <ChartContainer title="Сімейний стан">
        <DoubleChart
          legends={[
            { name: 'Чоловіки', value: data.data.men_count },
            { name: 'Жінки', value: data.data.women_count }
          ]}
          colors={['#306FE3', theme.colors.primary]}
          data={[
            ...data.data.family_status_count.slice(1).map((item) => {
              const dataArr = Object.entries(item);

              return {
                name: dataArr[1][0],
                firstValue: Array.isArray(dataArr[2][1])
                  ? dataArr[2][1][1]['Чоловік']
                  : 0,
                secondValue: Array.isArray(dataArr[2][1])
                  ? dataArr[2][1][2]['Жінка']
                  : 66
              };
            }),
            {
              name: 'Інше',
              firstValue:
                data.data.family_status_count[0].by_gender[1]['Чоловік'],
              secondValue:
                data.data.family_status_count[0].by_gender[2]['Жінка']
            }
          ]}
        />
      </ChartContainer>
      <ChartContainer title="Діяльність">
        <DoubleChart
          legends={[
            { name: 'Чоловіки', value: data.data.men_count },
            { name: 'Жінки', value: data.data.women_count }
          ]}
          colors={['#306FE3', theme.colors.primary]}
          data={[
            ...data.data.employment_count.slice(1).map((item) => {
              const dataArr = Object.entries(item);

              return {
                name: dataArr[1][0],
                firstValue: Array.isArray(dataArr[2][1])
                  ? dataArr[2][1][1]['Чоловік']
                  : 0,
                secondValue: Array.isArray(dataArr[2][1])
                  ? dataArr[2][1][2]['Жінка']
                  : 66
              };
            }),
            {
              name: 'Інше',
              firstValue: data.data.employment_count[0].by_gender[1]['Чоловік'],
              secondValue: data.data.employment_count[0].by_gender[2]['Жінка']
            }
          ]}
        />
      </ChartContainer>
      <CategoriesChart
        withAnyCategory={data.data.with_any_category}
        categoriesCount={data.data.categories_count}
        contactsCount={data.data.adults}
      />
      <ChartContainer title="Документи">
        <BarChart
          aspect={1.5}
          tickMaxWidth={80}
          barCount={2}
          color={theme.colors.primary}
          data={[
            { name: 'Є', value: data.data.docs_count.with_docs },
            {
              name: 'Немає',
              value: data.data.docs_count.no_docs,
              onClick: () => navigate(`${AppRoute.CONTACTS}/?no_docs=true`)
            }
          ]}
        />
      </ChartContainer>
      <DoughnutChart
        revrse
        title={'З яких проєктів прийшли'}
        data={data.data.by_projects.map((item) => ({
          name: item.name,
          value: item.count
        }))}
        colors={colors.map((item) => item.color)}
        totalValue={data.data.adults}
        totalLabel="загальна кількість"
      />
    </ChartsLayout>
  );
};
