import type { FormEventHandler } from 'react';
import { useState, type Dispatch, type SetStateAction, useEffect } from 'react';
import { Popup } from '../../../components/popup';
import { Select } from '../../../components/select';
import type { OptionItem } from '../../../common/types/option-item';
import { BtnSecondary } from '../../../components/btn-secondary';
import { BtnPrimary } from '../../../components/btn-primary';
import { useGetProjectsQuery } from '../../../api/projects-api';
import type { Project } from '../../../models/project';
import type { IEvent } from '../../../models/event';
import { useGetUserMeQuery } from '../../../api/user-api';
import { BtnsContainer, FormStyled, Subtitle } from './style';

interface DelegateProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: {
    manager: string | number;
    project: string | number;
    event: string | number;
  }) => void;
}

export const Delegate = ({ show, setShow, onSubmit }: DelegateProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const [manager, setManager] = useState<OptionItem>({ id: '', title: '' });
  const [project, setProject] = useState<OptionItem<Project>>({
    id: '',
    title: ''
  });
  const [event, setEvent] = useState<OptionItem<IEvent>>({ id: '', title: '' });
  const { data: projects } = useGetProjectsQuery(
    { params: `?size=100` },
    {
      refetchOnMountOrArgChange: true,
      skip: !userMe
        ? true
        : userMe.is_superuser
        ? false
        : !userMe.role?.view_project
    }
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit({ manager: manager.id, project: project.id, event: event.id });
  };

  const handleChangeProject = (item: OptionItem<Project>) => {
    const currentProject = projects?.results.find(
      (project) => item.id === project.id
    );
    if (
      currentProject &&
      !currentProject.events.some((eventItem) => eventItem.id === event.id)
    ) {
      setEvent({ id: '', title: '' });
      setManager({ id: '', title: '' });
    }
    setProject(item);
  };

  const handleChangeEvent = (item: OptionItem<IEvent>) => {
    if (
      !item.restFields?.managers.some(
        (eventManager) => eventManager.id === manager.id
      )
    ) {
      setManager({ id: '', title: '' });
    }
    setEvent(item);
  };

  useEffect(() => {
    setProject({ id: '', title: '' });
    setManager({ id: '', title: '' });
    setEvent({ id: '', title: '' });
  }, [show]);

  return (
    <Popup
      maxContentWidth="448px"
      title="Додати в проєкт"
      show={show}
      setShow={setShow}
    >
      <FormStyled onSubmit={handleSubmit}>
        <Subtitle>
          Оберіть менеджера на якого Ви бажаєте делегувати обрані контакти.
        </Subtitle>
        <Select
          mt="20px"
          placeholder="Оберіть зі списку"
          label="Проєкт"
          value={project}
          setValue={handleChangeProject}
          options={
            projects?.results.map((item) => ({
              id: item.id,
              title: item.name,
              restFields: item
            })) || []
          }
        />
        <Select<IEvent>
          mt="20px"
          placeholder="Оберіть зі списку"
          label="Подія"
          value={event}
          setValue={handleChangeEvent}
          options={
            projects?.results
              .find((item) => item.id === project.id)
              ?.events.map((item) => ({
                id: item.id,
                title: item.name,
                restFields: item
              })) || []
          }
          disabled={
            !(
              projects?.results
                .find((item) => item.id === project.id)
                ?.events.map((item) => ({
                  id: item.id,
                  title: item.name,
                  restFields: item
                })) || []
            ).length
          }
        />
        <Select
          mt="20px"
          placeholder="Делегувати на"
          label="Менеджер"
          value={manager}
          setValue={setManager}
          options={event.restFields?.managers.map((item) => ({
            id: item.id,
            title: `${item?.last_name || ''} ${item?.first_name || ''} ${
              item?.patronymic || ''
            }`
          }))}
          disabled={
            !event.restFields?.managers.map((item) => ({
              id: item.id,
              title: `${item?.last_name || ''} ${item?.first_name || ''} ${
                item?.patronymic || ''
              }`
            })).length
          }
        />
        <BtnsContainer>
          <BtnSecondary onClick={() => setShow(false)}>Відмінити</BtnSecondary>
          <BtnPrimary disabled={!manager.id || !project.id || !event.id}>
            Зберегти
          </BtnPrimary>
        </BtnsContainer>
      </FormStyled>
    </Popup>
  );
};
