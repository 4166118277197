export const ExitSvg = () => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2944 12.413L19.648 12.0595L19.2944 11.7059L17.2644 9.6759C17.1697 9.58116 17.1697 9.41774 17.2644 9.32301C17.3592 9.22827 17.5226 9.22827 17.6173 9.32301L17.9709 8.96945L17.6173 9.32301L20.1773 11.883C20.272 11.9777 20.272 12.1412 20.1773 12.2359L17.6173 14.7959C17.566 14.8472 17.5054 14.8695 17.4409 14.8695C17.3763 14.8695 17.3157 14.8472 17.2644 14.7959C17.1697 14.7012 17.1697 14.5377 17.2644 14.443L19.2944 12.413Z"
      fill="#9DABC0"
      stroke="#9DABC0"
    />
    <path
      d="M19.9298 12.3105H9.75977C9.62591 12.3105 9.50977 12.1944 9.50977 12.0605C9.50977 11.9267 9.62591 11.8105 9.75977 11.8105H19.9298C20.0636 11.8105 20.1798 11.9267 20.1798 12.0605C20.1798 12.1944 20.0636 12.3105 19.9298 12.3105Z"
      fill="#9DABC0"
      stroke="#9DABC0"
    />
    <path
      d="M11.7598 20.25C9.30436 20.25 7.24823 19.3939 5.80707 17.9527C4.36591 16.5115 3.50977 14.4554 3.50977 12C3.50977 9.54459 4.36591 7.48847 5.80707 6.0473C7.24823 4.60614 9.30436 3.75 11.7598 3.75C11.8936 3.75 12.0098 3.86614 12.0098 4C12.0098 4.13386 11.8936 4.25 11.7598 4.25C9.50538 4.25 7.56143 5.03873 6.17996 6.4202C4.79849 7.80167 4.00977 9.74561 4.00977 12C4.00977 14.2544 4.79849 16.1983 6.17996 17.5798C7.56143 18.9613 9.50538 19.75 11.7598 19.75C11.8936 19.75 12.0098 19.8661 12.0098 20C12.0098 20.1339 11.8936 20.25 11.7598 20.25Z"
      fill="#9DABC0"
      stroke="#9DABC0"
    />
  </svg>
);
