import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { Popup } from '../../../../components/popup';
import { EventsForm } from '../../events-form';
import type { OptionItem } from '../../../../common/types/option-item';
import type { IEvent } from '../../../../models/event';
import {
  useEditEventMutation,
  useEditStepMutation
} from '../../../../api/events-api';
import { dateToApiFromInput } from '../../../../helpers/date-helpers';
import { useNotification } from '../../../../hooks/use-notification';
import { getApiError } from '../../../../helpers/get-api-error';

interface EditEventProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  event: IEvent;
}

export const EditEvent = ({ show, setShow, event }: EditEventProps) => {
  const notification = useNotification();
  const [name, setName] = useState(event.name);
  const [description, setDescription] = useState(event.description);
  const [startDate, setStartDate] = useState(
    event.start_date?.split('-').reverse().join('/') || ''
  );
  const [endDate, setEndDate] = useState(
    event.end_date?.split('-').reverse().join('/') || ''
  );
  const [format, setFormat] = useState<OptionItem>(event.format);
  const [region, setRegion] = useState(event.region);
  const [budget, setBudget] = useState(event.budget || '');
  const [managers, setManagers] = useState<OptionItem[]>(
    event.managers.map((item) => ({
      id: item.id,
      title: `${item.last_name || ''} ${item.first_name || ''} ${
        item.patronymic || ''
      }`
    }))
  );
  const [metaTags, setMetaTags] = useState([
    event.meta_tag1,
    event.meta_tag2,
    event.meta_tag3
  ]);
  const [steps, setSteps] = useState(event.steps);
  const [editEvent] = useEditEventMutation();
  const [editStep] = useEditStepMutation();

  const hanndleEdit = () => {
    if (!managers.filter((item) => item.id).length) {
      notification({
        type: 'error',
        title: 'Помилка',
        message: 'Вкажіть хоча б одного менедджера'
      });

      return;
    }

    editEvent({
      id: event.id,
      body: {
        name,
        description,
        format: format.id,
        region,
        start_date: dateToApiFromInput(startDate),
        end_date: endDate ? dateToApiFromInput(endDate) : null,
        budget: budget || 0,
        managers: managers.map((item) => item.id),
        meta_tag1: metaTags[0],
        meta_tag2: metaTags[1],
        meta_tag3: metaTags[2]
      }
    })
      .unwrap()
      .then(() => {
        notification({
          title: 'Подію відредаговано',
          type: 'success',
          message: 'Ви успішно змінили подію'
        });
        setShow(false);
        event.steps.forEach((item, i) => {
          if (item.name !== steps[i].name) {
            editStep({ id: item.id, body: { name: steps[i].name } });
          }
        });
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          type: 'error',
          message: getApiError(err)
        });
      });
  };

  return (
    <Popup show={show} setShow={setShow} title="Редагувати подію">
      <EventsForm
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        managers={managers}
        setManagers={setManagers}
        region={region}
        setRegion={setRegion}
        format={format}
        setFormat={setFormat}
        budget={budget}
        setBudget={setBudget}
        formTitle="Нова подія"
        setShow={setShow}
        submitTitle="Зберегти"
        cancelTitle="Відмінити"
        onSubmit={hanndleEdit}
        isEdit
        metaTags={metaTags}
        setMetaTags={setMetaTags}
        projectId={event.project.id}
        steps={steps}
        setSteps={setSteps}
      />
    </Popup>
  );
};
