import styled from 'styled-components';
import { centerFlex, scrollBar } from '../../../../style/helpers';

export const UsersAccessTableStyled = styled.div`
  height: calc(100vh - 340px);
  width: 100%;
  ${scrollBar}
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
`;

export const TableHeaderItem = styled.div`
  height: 46px;
  padding: 14.5px 12px;
  background: ${(p) => p.theme.colors.grey.table};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.dark};
  text-align: center;
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  border-left: none;
  :first-child {
    text-align: left;
    /* background: ${(p) => p.theme.colors.grey.bg}; */
  }
`;

export const TableRowItem = styled.div`
  padding: 14.5px 16px;
  min-height: 46px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.dark};
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.table};
  border-right: 1px solid ${(p) => p.theme.colors.grey.table};
  ${centerFlex}
  :first-child {
    justify-content: flex-start;
  }
`;
