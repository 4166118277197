export const ProjectsSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0007 8.63281H5.33398C5.06065 8.63281 4.83398 8.40615 4.83398 8.13281C4.83398 7.85948 5.06065 7.63281 5.33398 7.63281H10.0007C10.274 7.63281 10.5007 7.85948 10.5007 8.13281C10.5007 8.40615 10.274 8.63281 10.0007 8.63281Z"
      fill="#9DABC0"
    />
    <path
      d="M8.25398 11.3008H5.33398C5.06065 11.3008 4.83398 11.0741 4.83398 10.8008C4.83398 10.5274 5.06065 10.3008 5.33398 10.3008H8.25398C8.52732 10.3008 8.75398 10.5274 8.75398 10.8008C8.75398 11.0741 8.52732 11.3008 8.25398 11.3008Z"
      fill="#9DABC0"
    />
    <path
      d="M9.33398 4.50065H6.66732C6.02732 4.50065 4.83398 4.50065 4.83398 2.66732C4.83398 0.833984 6.02732 0.833984 6.66732 0.833984H9.33398C9.97398 0.833984 11.1673 0.833984 11.1673 2.66732C11.1673 3.30732 11.1673 4.50065 9.33398 4.50065ZM6.66732 1.83398C6.00732 1.83398 5.83398 1.83398 5.83398 2.66732C5.83398 3.50065 6.00732 3.50065 6.66732 3.50065H9.33398C10.1673 3.50065 10.1673 3.32732 10.1673 2.66732C10.1673 1.83398 9.99398 1.83398 9.33398 1.83398H6.66732Z"
      fill="#9DABC0"
    />
    <path
      d="M10 15.1669H6C2.25333 15.1669 1.5 13.4469 1.5 10.6669V6.66695C1.5 3.62695 2.6 2.32695 5.30667 2.18695C5.57333 2.17361 5.82 2.38028 5.83333 2.66028C5.84667 2.94028 5.63333 3.16695 5.36 3.18028C3.46667 3.28695 2.5 3.85361 2.5 6.66695V10.6669C2.5 13.1336 2.98667 14.1669 6 14.1669H10C13.0133 14.1669 13.5 13.1336 13.5 10.6669V6.66695C13.5 3.85361 12.5333 3.28695 10.64 3.18028C10.3667 3.16695 10.1533 2.92695 10.1667 2.65361C10.18 2.38028 10.42 2.16695 10.6933 2.18028C13.4 2.32695 14.5 3.62695 14.5 6.66028V10.6603C14.5 13.4469 13.7467 15.1669 10 15.1669Z"
      fill="#9DABC0"
    />
  </svg>
);
