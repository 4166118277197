import { LoaderSmallStyled, LoaderWrapper } from './style';

interface LoaderSmallProps {
  ac?: boolean;
}

const LoaderSmall = ({ ac }: LoaderSmallProps) => (
  <LoaderWrapper ac={ac}>
    <LoaderSmallStyled />
  </LoaderWrapper>
);

export { LoaderSmall };
