import type { Dispatch, SetStateAction } from 'react';
import { BtnPrimary } from '../btn-primary';
import type { Note as INote } from '../../models/note';
import { splitByMonth } from '../../helpers/split-by-month';
import type {
  DeleteNote,
  EditNote
} from '../../common/types/rtk-triggers/note';
import {
  CreateNote,
  NotesByMonth,
  NotesByMonthTitle,
  NotesContainer,
  NotesStyled
} from './style';
import { Note } from './note';

interface NotesProps {
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  notes: INote[];
  editNote: EditNote;
  deleteNote: DeleteNote;
}

export const Notes = ({
  setShowNewNote,
  notes,
  editNote,
  deleteNote
}: NotesProps) => (
  <NotesStyled>
    <CreateNote>
      <BtnPrimary onClick={() => setShowNewNote(true)}>
        Створити нотатку
      </BtnPrimary>
    </CreateNote>
    <NotesContainer>
      {splitByMonth<INote>(notes).map((item, i) => (
        <NotesByMonth key={i}>
          <NotesByMonthTitle>{item.title}</NotesByMonthTitle>
          {item.list.map((note) => (
            <Note
              key={note.id}
              note={note}
              editNote={editNote}
              deleteNote={deleteNote}
            />
          ))}
        </NotesByMonth>
      ))}
    </NotesContainer>
  </NotesStyled>
);
