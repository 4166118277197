import type { ReponseWithPagination } from '../../../../common/types/response-with-pagination';
import type { HeaderItem } from '../../../../components/data-table';
import type { DynamicContact } from '../../../../models/contact';
import type { Role } from '../../../../models/role';

export const transformRole = (
  role: Role,
  tableHeaders: HeaderItem<DynamicContact>[],
  projectId: string | number
) => ({
  ...Object.assign(
    {},
    ...tableHeaders.map((field) => ({
      [field.id]: role.field_permissions
        .filter((item) => item.project === projectId)
        .some((item) => item.name === field.id)
    })),
    { role: role.name },
    { id: role.id }
  )
});

export const transformToTaleFormat = (
  roles: ReponseWithPagination<Role>,
  tableHeaders: HeaderItem<DynamicContact>[],
  projectId: string | number
) =>
  roles.results
    .filter((item) => item.name.toLowerCase().indexOf('менеджер') > -1)
    .map((role) =>
      transformRole(
        role,
        tableHeaders
          .map((item) => (item.subFields ? item.subFields : item))
          .flat(),
        projectId
      )
    );
