import styled from 'styled-components';

export const TextAreaStyled = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  resize: none;
  ::placeholder {
    color: ${(p) => p.theme.colors.grey.light};
  }
`;
