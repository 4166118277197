import styled from 'styled-components';
import { centerFlex } from '../../../style/helpers';

export const HeaderUserStyled = styled.div`
  margin-left: 12px;
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 6px;
  background: ${(p) => p.theme.colors.avatar};
  ${centerFlex}
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: ${(p) => p.theme.colors.blue.dark};
  text-transform: uppercase;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const UserName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const UserEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(p) => p.theme.colors.grey.seventy};
`;

export const UserColor = styled.div<{ color?: string }>`
  margin-left: 16px;
  height: 100%;
  width: 20px;
  background: ${(p) => p.color || p.theme.colors.blue.dark};
`;
