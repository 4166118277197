import styled from 'styled-components';

export const ButtonAddStyled = styled.button`
  height: 40px;
  padding: 12px;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.colors.primary};
  border-radius: 6px;
  transition: background-color 0.2s;
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.primaryHover};
    transition: background-color 0.2s;
  }
  span {
    margin-left: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${(p) => p.theme.colors.white};
  }
`;
