import styled from 'styled-components';

export const AddContactStyled = styled.form`
  padding-top: 20px;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3 - 40px));
  gap: 40px;
  row-gap: 20px;
  padding-bottom: 30px;
  align-items: start;
`;

export const InputsGroupSingle = styled.div<{ space?: string }>`
  display: grid;
  grid-template-columns: 100%;
  gap: ${(p) => p.space || '20px'};
`;

export const InputsGroupDouble = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

export const InputsGroupTitle = styled.div<{ fz?: string }>`
  font-weight: 600;
  font-size: ${(p) => p.fz || '16px'};
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const InputsGroupDoubleTitle = styled(InputsGroupTitle)`
  grid-column: span 2;
`;

export const InputsGroupTripleTitle = styled(InputsGroupTitle)`
  grid-column: span 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SpanTwoCol = styled.div`
  grid-column: span 2;
`;

export const FiredUser = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  gap: 12px;
`;
