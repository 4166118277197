export const SliderArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path d="M11.25 22.5L18.75 15L11.25 7.5" stroke="#D2D6ED" strokeWidth="4" />
  </svg>
);
