import type { Dispatch, SetStateAction } from 'react';
import { CustomDatePicker } from '../custom-date-picker';
import { BtnSecondary } from '../btn-secondary';
import { BtnPrimary } from '../btn-primary';
import { CalendarContainer, DateRangeStyled, Footer } from './style';

interface DateRangeProps {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
  onSubmit: () => void;
  onReset: () => void;
}

export const DateRange = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onSubmit,
  onReset
}: DateRangeProps) => (
  <DateRangeStyled>
    <CalendarContainer>
      <CustomDatePicker
        isRange
        onChange={(date) => setStartDate(date as Date | null)}
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        startEndLabel="Від"
      />
      <CustomDatePicker
        isRange
        selected={endDate}
        onChange={(date) => setEndDate(date as Date | null)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        startEndLabel="До"
      />
    </CalendarContainer>
    <Footer>
      <BtnSecondary onClick={onReset}>Скинути</BtnSecondary>
      <BtnPrimary onClick={onSubmit}>Зберегти</BtnPrimary>
    </Footer>
  </DateRangeStyled>
);
