import styled, { css, keyframes } from 'styled-components';

export const rotate = keyframes`
  0% {
    transform: rotate(0) scale(1);
    opacity: 1;
  }
  50% {
    opacity: 0.8;
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
`;

const withoutOverlayStyle = css`
  width: 60px;
  height: 60px;
  display: block;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
`;

export const LoaderWrapper = styled.div<{ $withoutOverlay?: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  ${(p) => (p.$withoutOverlay ? withoutOverlayStyle : '')}
  svg {
    animation: ${rotate} 2s linear infinite;
  }
  z-index: 10;
`;
