export const days = ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

export const months = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень'
];

export const years = new Array(110)
  .fill('')
  .map((_, i) => new Date().getFullYear() + 7 - i);
