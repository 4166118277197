import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const AuthLayoutStyled = styled.div`
  background: ${(p) => p.theme.colors.grey.bg};
  height: 100vh;
  overflow: auto;
  ${centerFlex}
`;

export const AuthContent = styled.div`
  padding: 120px 20px;
  width: 100%;
  max-width: 844px;
  background: ${(p) => p.theme.colors.white};
  border-radius: 15px;
`;

export const Logo = styled.div`
  text-align: center;
  svg {
    width: 100px;
    height: 100px;
  }
`;

export const Title = styled.h1`
  margin-top: 40px;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.5px;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const SubTitle = styled.p`
  margin: 0 auto;
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.2px;
  color: ${(p) => p.theme.colors.blue.dark};
  max-width: 406px;
  text-align: center;
`;
