export const exportData = async (response: Response, isPdf?: boolean) => {
  if (response.ok) {
    const url = window.URL.createObjectURL(await response.blob());
    const a = document.createElement('a');
    a.href = url;
    a.download = isPdf ? 'report.pdf' : 'exported_data.xls';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  return null;
};
