import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useTypedSelector } from '../../../../api/store';
import { DateRange } from '../../../../components/date-range';
import { FlexContainer } from '../../../../components/flex-container';
import { HeaderUser } from '../../../../components/header/header-user';
import { HeaderStyled } from '../../../../components/header/style';
import { SubheaderSelect } from '../../../../components/subheader-select';
import { CalendarSvg } from '../../../../components/svg/calendar-svg';
import { setDashboardDate } from '../../../../api/dashboard-slice';
import { dateToApiFormat } from '../../../../helpers/date-helpers';
import { tabs } from './data';
import { Calendar, Tabs } from './style';
import { Tab } from './tab';

export const Header = () => {
  const dispatch = useAppDispatch();
  const { menuIsOpen } = useTypedSelector((state) => state['page-data']);
  const { date } = useTypedSelector((state) => state.dashboard);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const applyDate = () => {
    setShowCalendar(false);
    dispatch(
      setDashboardDate({
        start: startDate ? dateToApiFormat(startDate) : '',
        end: endDate ? dateToApiFormat(endDate) : ''
      })
    );
  };

  const resetDate = useCallback(() => {
    dispatch(
      setDashboardDate({
        start: '',
        end: ''
      })
    );
    setShowCalendar(false);
  }, [dispatch]);

  useEffect(() => {
    if (!date.start && !date.end) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [date]);

  useEffect(() => resetDate, [resetDate]);

  return (
    <HeaderStyled>
      <FlexContainer>
        <Tabs $menuIsOpen={menuIsOpen}>
          {tabs.map((item) => (
            <Tab key={item.path} {...item} />
          ))}
        </Tabs>
        <Calendar>
          <SubheaderSelect
            show={showCalendar}
            setShow={setShowCalendar}
            icon={CalendarSvg}
            title="Дата"
            isActive={!!(startDate || endDate)}
            showChildrenLeft
          >
            <DateRange
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              onSubmit={applyDate}
              onReset={resetDate}
            />
          </SubheaderSelect>
        </Calendar>
      </FlexContainer>
      <HeaderUser />
    </HeaderStyled>
  );
};
