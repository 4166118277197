import type { Dispatch, SetStateAction } from 'react';
import { useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { AccordionBlock } from '../../../../components/accordion-block';
import { AccordionItem } from '../../../../components/accordion-item';
import { EditBtn } from '../../../../components/edit-btn';
import type { Contact } from '../../../../models/contact';
import { FlexContainer } from '../../../../components/flex-container';
import { CheckBox } from '../../../../components/checkbox';
import {
  useDeleteContactMutation,
  useEditContactMutation
} from '../../../../api/contact-api';
import { ActionBtn } from '../../../../components/action-btn';
import { ActionsEditSvg } from '../../../../components/svg/actions/actons-edit-svg';
import { ActionsMailSvg } from '../../../../components/svg/actions/actions-mail-svg';
import { ActionsCallSvg } from '../../../../components/svg/actions/actions-call-svg';
// import { ActionsArchSvg } from '../../../../components/svg/actions/actions-arch-svg';
import { ActionsRemoveSvg } from '../../../../components/svg/actions/actions-remove-svg';
import { ActionsNoteSvg } from '../../../../components/svg/actions/actions-note-svg';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { ConfirmPopup } from '../../../../components/confirm-popup';
import { useNotification } from '../../../../hooks/use-notification';
import { getApiError } from '../../../../helpers/get-api-error';
import type { OptionItem } from '../../../../common/types/option-item';
import { concatenateAddress } from '../../../../helpers/concatenate-address';
import { IconContainer } from '../../../../components/icon-container';
import { ClipboardSvg } from '../../../../components/svg/clipboard-svg';
import {
  OptionsScroolWrapper,
  SelectOptionItem,
  SelectOptions
} from '../../../../components/select/style';
import { useOutsideClick } from '../../../../hooks/use-outside-click';
import { SATISFACTION } from '../../../../common/constants';
import { AngleSvg } from '../../../../components/svg/angle-svg';
import { MailingListPopup } from '../../../../components/mailing-list-popup';
import { dateToUIFormat } from '../../../../helpers/date-helpers';
import { useMakeCallMutation } from '../../../../api/binotel-api';
import { isFieldInProject } from '../../../../helpers/is-field-allowed';
import { TelegramSvg } from '../../../../components/svg/telegram-svg';
import { SidebarScrollBlock } from './sidebar-scroll-block';
import {
  AccordionSubTitle,
  ContactId,
  ContactSatisfaction,
  ContactSatisfactionHeader,
  ContactSidebarHeader,
  ContactSidebarStyled,
  FlexWrapper,
  FullName,
  HeaderItem
} from './style';

interface CurrentConatctSidebarProps {
  contact?: Contact;
  setEdit: Dispatch<SetStateAction<boolean>>;
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  setShowNewCall: Dispatch<SetStateAction<boolean>>;
  setShowNewMeet: Dispatch<SetStateAction<boolean>>;
  checkedList: number[];
}

export const CurrentConatctSidebar = ({
  contact,
  setEdit,
  setShowNewNote,
  setShowNewCall,
  setShowNewMeet,
  checkedList
}: CurrentConatctSidebarProps) => {
  const satisfactionRef = useRef(null);
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: userMe } = useGetUserMeQuery('');
  const [editContact, { isLoading }] = useEditContactMutation();
  const [showMailingList, setShowMailingList] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showArchContact, setShowArchContact] = useState(false);
  const [showSatisfactionOption, setShowSatisfactionOption] = useState(false);
  useOutsideClick(satisfactionRef, () => setShowSatisfactionOption(false));
  const [deleteContact, { isLoading: deleteIsLoading }] =
    useDeleteContactMutation();
  const [makeCall] = useMakeCallMutation();

  // const handleChangePartner = (checked: boolean) => {
  //   editContact({ id: contact?.id || '', body: { is_partner: checked } });
  // };

  const handleChangeVolunteer = (checked: boolean) => {
    editContact({ id: contact?.id || '', body: { is_volunteer: checked } });
  };

  const handleDelete = () => {
    deleteContact({ ids: [contact?.id || ''] })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Контакт видалено',
          message: 'Ви успішно видалили контакт'
        });
        searchParams.delete('id');
        searchParams.delete('contact_id');
        setSearchParams(searchParams);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleCall = (item: OptionItem) => {
    if (item.id === 'enter-call') {
      setShowNewCall(true);

      return;
    }
    makeCall({
      contact_id: contact?.id || '',
      internal_number: userMe?.binotel || 901
    })
      .unwrap()
      .then((res) => {
        if (res.status === 'error') {
          notification({
            type: 'error',
            title: 'Помилка',
            message: res.message
          });
        }
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleSatisfaction = (item: OptionItem) => {
    editContact({ id: contact?.id || '', body: { satisfaction: item.id } })
      .unwrap()
      .then(() => setShowSatisfactionOption(false))
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  if (!userMe) {
    return null;
  }

  const canEdit = userMe?.role?.change_contact || userMe?.is_superuser;
  const canRemove = userMe.role?.delete_contact || userMe.is_superuser;

  return (
    <ContactSidebarStyled>
      {!!contact && (
        <>
          <ContactSidebarHeader>
            <HeaderItem>
              <ContactSatisfaction ref={satisfactionRef}>
                {isFieldInProject('satisfaction', contact.allowed_fields) && (
                  <ContactSatisfactionHeader
                    onClick={() => setShowSatisfactionOption((state) => !state)}
                    isOpen={showSatisfactionOption}
                  >
                    {contact.satisfaction?.title}
                    <AngleSvg />
                  </ContactSatisfactionHeader>
                )}
                {showSatisfactionOption && (
                  <SelectOptions customWidth="150px">
                    <OptionsScroolWrapper>
                      {SATISFACTION.map((item) => (
                        <SelectOptionItem
                          onClick={() => handleSatisfaction(item)}
                          key={item.id}
                        >
                          {item.title}
                        </SelectOptionItem>
                      ))}
                    </OptionsScroolWrapper>
                  </SelectOptions>
                )}
              </ContactSatisfaction>
              {canEdit && <EditBtn onClick={() => setEdit(true)} />}
            </HeaderItem>
            <FullName>{contact.full_name}</FullName>
            <HeaderItem mt="14px">
              <ContactId>ID Контакту: {contact.id}</ContactId>
              <FlexContainer gap="20px">
                {/* <CheckBox
                  disabled={isLoading || !canEdit}
                  checked={contact.is_partner}
                  label="Партнер"
                  setChecked={handleChangePartner}
                /> */}
                {canEdit &&
                  isFieldInProject('is_volunteer', contact.allowed_fields) && (
                    <CheckBox
                      disabled={isLoading || !canEdit}
                      checked={contact.is_volunteer}
                      label="Волонтер"
                      setChecked={handleChangeVolunteer}
                    />
                  )}
              </FlexContainer>
            </HeaderItem>
            <HeaderItem mt="40px">
              <FlexContainer gap="10px" wrappMd>
                {canEdit && (
                  <ActionBtn
                    onClick={() => setShowNewNote(true)}
                    tooltip="Нотатка"
                  >
                    <ActionsEditSvg />
                  </ActionBtn>
                )}
                <ActionBtn
                  onClick={() => setShowMailingList(true)}
                  tooltip="Надіслати лист"
                >
                  <ActionsMailSvg />
                </ActionBtn>
                <ActionBtn
                  onClick={() => {}}
                  onOptionClick={handleCall}
                  options={[
                    { id: 'call', title: 'Зателефонувати' },
                    { id: 'enter-call', title: 'Внести дзвінок' }
                  ]}
                  tooltip="Зателефонувати"
                >
                  <ActionsCallSvg />
                </ActionBtn>
                <ActionBtn
                  onClick={() => setShowNewMeet(true)}
                  tooltip="Внести зутріч"
                >
                  <ActionsNoteSvg />
                </ActionBtn>
                {contact.has_telegram && (
                  <a
                    target="_blank"
                    href={`https://t.me/${contact.phone}`}
                    rel="noreferrer noopener"
                  >
                    {' '}
                    <ActionBtn onClick={() => {}} tooltip="Написати в телеграм">
                      <TelegramSvg />
                    </ActionBtn>
                  </a>
                )}
              </FlexContainer>
              <FlexContainer gap="10px">
                {/* <ActionBtn
                  onClick={() => setShowArchContact(true)}
                  tooltip="Архівувати"
                >
                  <ActionsArchSvg />
                </ActionBtn> */}
                {canRemove && (
                  <ActionBtn
                    onClick={() => setShowConfirmDelete(true)}
                    tooltip="Видалити"
                  >
                    <ActionsRemoveSvg />
                  </ActionBtn>
                )}
              </FlexContainer>
            </HeaderItem>
          </ContactSidebarHeader>
          <SidebarScrollBlock>
            <AccordionBlock maxHeight={650} title="Інформація про контакт">
              <AccordionItem
                title="Кваліфікатор"
                data={contact.qualification?.title}
              />
              <AccordionItem
                title="Власники контакту"
                data={contact.resp_managers
                  ?.map(
                    (item) =>
                      `${item.last_name || ''} ${item.first_name || ''} ${
                        item.patronymic || ''
                      }`
                  )
                  .join(', ')}
              />
              <AccordionItem
                title="Канал приходу контакту"
                data={contact.channels?.map((item) => item.name).join(', ')}
              />
              <AccordionItem
                title="Тег"
                data={contact.tags?.map((item) => item.name).join(', ')}
              />
            </AccordionBlock>
            <AccordionBlock maxHeight={700} title="Персональні дані">
              <AccordionItem title="Прізвище" data={contact.last_name} />
              <AccordionItem title="Ім’я" data={contact.first_name} />
              <AccordionItem title="По батькові" data={contact.patronymic} />
              <AccordionItem
                title="Дата народження"
                data={contact.birth_date?.split('-').reverse().join('/') || ''}
              />
              <AccordionItem title="Гендер" data={contact.gender?.title} />
              <AccordionItem
                title="Сімейний статус"
                data={contact.family_status?.title}
              />
              {contact.spouse && (
                <AccordionItem
                  title="Чоловік/дружина"
                  data={
                    <Link to={`/contacts?contact_id=${contact.spouse.id}`}>
                      {contact.spouse.last_name} {contact.spouse.first_name}{' '}
                      {contact.spouse.patronymic}
                    </Link>
                  }
                />
              )}
              <AccordionItem
                title="Діяльність"
                data={contact.employment?.title}
              />
              <AccordionItem title="Освіта " data={contact.education?.title} />
              <AccordionItem
                title="Пільгові групи"
                data={contact.categories?.map((item) => item.name).join(', ')}
              />
            </AccordionBlock>
            {contact.children?.length ? (
              <AccordionBlock
                maxHeight={contact.children.length * 250}
                title="Діти"
              >
                {contact.children.map((item, i) => (
                  <div key={i}>
                    <AccordionSubTitle>Дитина {i + 1}</AccordionSubTitle>
                    <AccordionItem title="Стать" data={item.gender.title} />
                    <AccordionItem
                      title="День народження"
                      data={dateToUIFormat(
                        item.birth_date ? new Date(item.birth_date) : null
                      )}
                    />
                    <AccordionItem title="Вік" data={item.age} />
                  </div>
                ))}
              </AccordionBlock>
            ) : (
              ''
            )}
            <AccordionBlock title="Контактні та паспортні дані">
              <AccordionItem
                title="Телефон"
                data={
                  <a style={{ color: '#171C3D' }} href={`tel:${contact.phone}`}>
                    {contact.phone}
                  </a>
                }
              />
              {contact.additional_phones.map((item, i) => (
                <AccordionItem
                  key={item.id}
                  title={`Додатковий телефон ${i ? i + 1 : ''}`}
                  data={
                    <a style={{ color: '#171C3D' }} href={`tel:${item.phone}`}>
                      {item.phone}
                    </a>
                  }
                />
              ))}
              <AccordionItem
                title="Email"
                data={
                  <FlexWrapper>
                    {
                      <a
                        style={{ color: '#171C3D' }}
                        href={`mailto:${contact.email}`}
                      >
                        {contact.email}
                      </a>
                    }
                    {contact.email ? (
                      <IconContainer
                        m="0 0 0 8px"
                        onClick={() =>
                          navigator.clipboard.writeText(contact.email)
                        }
                      >
                        <ClipboardSvg />
                      </IconContainer>
                    ) : (
                      ''
                    )}
                  </FlexWrapper>
                }
              />
              <AccordionItem
                title="Адреса проживання"
                data={concatenateAddress({
                  country: contact.liv_country,
                  city: contact.liv_city,
                  region: contact.liv_region,
                  street: contact.liv_street,
                  building: contact.liv_building,
                  unit: contact.liv_unit,
                  apartment: contact.liv_apartment
                })}
              />
              <AccordionItem
                title="Адреса реєстрації"
                data={concatenateAddress({
                  country: contact.reg_country,
                  city: contact.reg_city,
                  region: contact.reg_region,
                  street: contact.reg_street,
                  building: contact.reg_building,
                  unit: contact.reg_unit,
                  apartment: contact.reg_apartment
                })}
              />
              <AccordionItem title="Паспорт" data={contact.passport} />
              <AccordionItem title="ІПН" data={contact.id_number} />
            </AccordionBlock>
          </SidebarScrollBlock>
        </>
      )}
      <ConfirmPopup
        show={showConfirmDelete}
        setShow={setShowConfirmDelete}
        title={`Чи дійсно бажаєте видалити контакт ${contact?.full_name}`}
        onSubmit={handleDelete}
        disabled={deleteIsLoading}
        needTranslate
      />
      <ConfirmPopup
        show={showArchContact}
        setShow={setShowArchContact}
        onSubmit={() => setShowArchContact(false)}
        title="Архівувати цей контакт?"
        needTranslate
      ></ConfirmPopup>
      <MailingListPopup
        show={showMailingList}
        setShow={setShowMailingList}
        contactIds={checkedList}
        needTranslate
      />
    </ContactSidebarStyled>
  );
};
