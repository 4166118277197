export const TabDataSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.84615C0 0.826552 0.826552 0 1.84615 0H14.1538C15.1735 0 16 0.82656 16 1.84615V8C16 8.33986 15.7245 8.61539 15.3846 8.61539C15.0448 8.61539 14.7692 8.33986 14.7692 8V1.84615C14.7692 1.50628 14.4937 1.23077 14.1538 1.23077H1.84615C1.50629 1.23077 1.23077 1.50629 1.23077 1.84615V14.1538C1.23077 14.4937 1.50628 14.7692 1.84615 14.7692H8C8.33986 14.7692 8.61539 15.0448 8.61539 15.3846C8.61539 15.7245 8.33986 16 8 16H1.84615C0.82656 16 0 15.1735 0 14.1538V1.84615Z"
      fill="#9DABC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.284 9.43585C10.2644 9.43585 9.4378 10.2624 9.4378 11.282C9.4378 12.3016 10.2644 13.1282 11.284 13.1282C12.3035 13.1282 13.1301 12.3016 13.1301 11.282C13.1301 10.2624 12.3035 9.43585 11.284 9.43585ZM8.20703 11.282C8.20703 9.58264 9.58459 8.20508 11.284 8.20508C12.9833 8.20508 14.3609 9.58264 14.3609 11.282C14.3609 12.9814 12.9833 14.3589 11.284 14.3589C9.58459 14.3589 8.20703 12.9814 8.20703 11.282Z"
      fill="#9DABC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8536 12.5396C13.0659 12.2742 13.4532 12.2312 13.7185 12.4434L15.7698 14.0845C16.0353 14.2968 16.0782 14.6841 15.866 14.9494C15.6536 15.2149 15.2664 15.2579 15.001 15.0455L12.9497 13.4045C12.6844 13.1922 12.6413 12.805 12.8536 12.5396Z"
      fill="#9DABC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.28125 4.71695C3.28125 4.37708 3.55677 4.10156 3.89663 4.10156H12.1018C12.4416 4.10156 12.7171 4.37708 12.7171 4.71695C12.7171 5.05681 12.4416 5.33233 12.1018 5.33233H3.89663C3.55677 5.33233 3.28125 5.05681 3.28125 4.71695Z"
      fill="#9DABC0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.28125 8.00015C3.28125 7.66029 3.55677 7.38477 3.89663 7.38477H7.17869C7.51854 7.38477 7.79407 7.66029 7.79407 8.00015C7.79407 8.34001 7.51854 8.61553 7.17869 8.61553H3.89663C3.55677 8.61553 3.28125 8.34001 3.28125 8.00015Z"
      fill="#9DABC0"
    />
  </svg>
);
