import type { Dispatch, SetStateAction } from 'react';
import { BtnPrimary } from '../btn-primary';
import { splitByMonth } from '../../helpers/split-by-month';
import type { Meet as IMeet } from '../../models/meet';
import type {
  DeleteMeet,
  EditMeet
} from '../../common/types/rtk-triggers/meet';
import {
  CreateMeet,
  MeetsByMonth,
  MeetsByMonthTitle,
  MeetsContainer,
  MeetsStyled
} from './style';
import { Meet } from './meet';

interface MeetsProps {
  setShowNewMeet: Dispatch<SetStateAction<boolean>>;
  meets: IMeet[];
  contactFullName: string;
  editMeet: EditMeet;
  deleteMeet: DeleteMeet;
  isPartner?: boolean;
}

export const Meets = ({
  setShowNewMeet,
  meets,
  contactFullName,
  editMeet,
  deleteMeet,
  isPartner
}: MeetsProps) => (
  <MeetsStyled>
    <CreateMeet>
      <BtnPrimary onClick={() => setShowNewMeet(true)}>
        Внести зустріч
      </BtnPrimary>
    </CreateMeet>
    <MeetsContainer>
      {splitByMonth<IMeet>(meets).map((item, i) => (
        <MeetsByMonth key={i}>
          <MeetsByMonthTitle>{item.title}</MeetsByMonthTitle>
          {item.list.map((meet) => (
            <Meet
              editMeet={editMeet}
              deleteMeet={deleteMeet}
              key={meet.id}
              meet={meet}
              contactFullName={contactFullName}
              isPartner={isPartner}
            />
          ))}
        </MeetsByMonth>
      ))}
    </MeetsContainer>
  </MeetsStyled>
);
