import type { OptionItem } from '../types/option-item';

export const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const GENDER: OptionItem[] = [
  {
    id: 0,
    title: 'Інше'
  },
  {
    id: 1,
    title: 'Чоловік'
  },
  {
    id: 2,
    title: 'Жінка'
  }
];

export const CHILD_GENDER: OptionItem[] = [
  {
    id: 1,
    title: 'Хлопчик'
  },
  {
    id: 2,
    title: 'Дівчинка'
  }
];

export const FAMILY_STATUS: OptionItem[] = [
  {
    id: 0,
    title: 'Інше'
  },
  {
    id: 1,
    title: 'Неодружений/a'
  },
  {
    id: 2,
    title: 'Одружений/a'
  },
  {
    id: 3,
    title: 'Вдівець/Вдова'
  }
];

export const EDUCATION: OptionItem[] = [
  {
    id: 0,
    title: 'Інше'
  },
  {
    id: 1,
    title: 'Початкова загальна'
  },
  {
    id: 2,
    title: 'Базова загальна середня'
  },
  {
    id: 3,
    title: 'Повна загальна середня'
  },
  {
    id: 4,
    title: 'Професійно-технічна'
  },
  {
    id: 5,
    title: 'Неповна вища'
  },
  {
    id: 6,
    title: 'Базова вища'
  },
  {
    id: 7,
    title: 'Повна вища'
  }
];

export const EMPLOYMENT: OptionItem[] = [
  {
    id: 0,
    title: 'Інше'
  },
  {
    id: 1,
    title: 'Найманий працівник'
  },
  {
    id: 2,
    title: 'ФОП'
  },
  {
    id: 3,
    title: 'Самозайнятий'
  },
  {
    id: 4,
    title: 'Безробітний'
  }
];

export const SATISFACTION: OptionItem[] = [
  {
    id: 0,
    title: 'Інше'
  },
  {
    id: 1,
    title: 'Позитивний'
  },
  {
    id: 2,
    title: 'Нейтральний'
  },
  {
    id: 3,
    title: 'Негативний'
  }
];

export const STATUS: OptionItem[] = [
  {
    id: 0,
    title: 'Інше'
  },
  {
    id: 1,
    title: 'Активний'
  },
  {
    id: 2,
    title: 'Неактивний'
  }
];

export const QUALIFICATION: OptionItem[] = [
  {
    id: 0,
    title: 'Інше'
  },
  {
    id: 1,
    title: 'Кваліфікований лід'
  },
  {
    id: 2,
    title: 'Некваліфікований лід'
  }
];

export const BENEFIT: OptionItem[] = [
  {
    id: 1,
    title: 'Відсутні'
  },
  {
    id: 2,
    title:
      'Учасники бойових дій - військовослужбовці АТО, члени їх сімей у випадку смерті бійця'
  },
  {
    id: 3,
    title:
      'Учасники бойових дій - військовослужбовці в період дії воєнного стану, члени їх сімей'
  },
  {
    id: 4,
    title:
      'Особи, які мешкають на території населенних пунктів, в яких проводяться бойові дії'
  },
  {
    id: 5,
    title: 'Внутрішньо переміщені особи'
  },
  {
    id: 6,
    title:
      'Постраждалі від збройної агресії РФ у період воєнного стану, зокрема: особи, які втратили житло'
  },
  {
    id: 7,
    title: "Багатодітні сім'ї"
  },
  {
    id: 8,
    title: 'Особи з інвалідністю'
  },
  {
    id: 9,
    title: 'Жінки, яким присвоєне почесне звання “Мати-героїня”'
  },
  {
    id: 10,
    title: 'Пенсіонери'
  },
  {
    id: 11,
    title: 'Громадяни, які постраждали внаслідок Чорнобильської катастрофи'
  },
  {
    id: 12,
    title: 'Одинока матір'
  }
];

export const CALL_RESULT = [
  { id: '1', title: 'Діалог' },
  { id: '2', title: 'Недодзвін' }
];

export const MEET_RESULT = [
  { id: '1', title: 'Прийшов/-ла' },
  { id: '2', title: 'Не прийшов/-ла' }
];

export const EVENT_FORMAT = [
  { id: '1', title: 'Онлайн' },
  { id: '2', title: 'Офлайн' }
];

export const ORG_TYPE = [
  { id: '1', title: 'Благодійний фонд' },
  { id: '2', title: 'Громадська організація' },
  { id: '3', title: 'Інші неприбуткові організації' }
];

export const MONTHS = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень'
];

export const DASHBOARD_COLORS = [
  '#E2C32B',
  '#EF5C2B',
  '#39BD8C',
  '#306FE3',
  '#39BD3E',
  '#F0904A',
  '#39BDA5',
  '#40CCDF',
  '#65A4EE',
  '#B17DF2',
  '#D660E9',
  '#E35BAC',
  '#DF5E4D',
  '#918FFD',
  '#EF2941',
  '#EFB729'
];

export const REGIONS = [
  'Інше',
  'Вінницька',
  'Волинська',
  'Дніпропетровська',
  'Донецька',
  'Житомирська',
  'Закарпатська',
  'Запорізька',
  'Івано-Франківська',
  'Київська',
  'Кіровоградська',
  'Крим',
  'Луганська',
  'Львівська',
  'Миколаївська',
  'Одеська',
  'Полтавська',
  'Рівненська',
  'Сумська',
  'Тернопільська',
  'Харківська',
  'Херсонська',
  'Хмельницька',
  'Черкаська',
  'Чернівецька',
  'Чернігівська'
];
