import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { FormBtns } from '../../../components/form-btns';
import { Input } from '../../../components/input';
import { TextArea } from '../../../components/text-area';
import { InputDate } from '../../../components/input-date';
import { Select } from '../../../components/select';
import type { OptionItem } from '../../../common/types/option-item';
import { useGetUsersQuery } from '../../../api/user-api';
import { mapUsersToProps } from '../../../helpers/map-users-to-option';
import { IconContainer } from '../../../components/icon-container';
import { CloseEditSvg } from '../../../components/svg/close-edit-svg';
import { AddItem } from '../../../components/add-item/indes';
import {
  InputGroup,
  InputsContainer,
  ManagerItem,
  ProjectFormStyled,
  Title
} from './style';

interface ProjectFormProps {
  projectName: string;
  setProjectName: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  setShow: Dispatch<SetStateAction<boolean>>;
  startDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  endDate: string;
  setEndDate: Dispatch<SetStateAction<string>>;
  respManager: OptionItem;
  setRespManager: Dispatch<SetStateAction<OptionItem>>;
  managers: OptionItem[];
  setManagers: Dispatch<SetStateAction<OptionItem[]>>;
  onSubmit: () => void;
  btnPrimaryTitle?: string;
}

export const ProjectForm = ({
  projectName,
  setShow,
  setProjectName,
  description,
  setDescription,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  respManager,
  setRespManager,
  managers,
  setManagers,
  onSubmit,
  btnPrimaryTitle
}: ProjectFormProps) => {
  const { data: users } = useGetUsersQuery({ params: '?size=100' });

  const handleDelete = (i: number) => {
    if (managers.length === 1) {
      setManagers([{ id: '', title: '' }]);
    } else {
      setManagers((state) => state.filter((_, j) => j !== i));
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <ProjectFormStyled onSubmit={handleSubmit}>
      <Title>Характеристики проєкту </Title>
      <InputsContainer>
        <InputGroup>
          <Input
            label="Назва проєкту"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
          />
          <TextArea
            label="Опис проєкту"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </InputGroup>
        <InputGroup>
          <InputDate
            label="Дата початку"
            value={startDate}
            setValue={setStartDate}
            required
          />
          <InputDate
            label="Дата завершення"
            value={endDate}
            setValue={setEndDate}
          />
          <Select
            label="Керівник проєкту"
            value={respManager}
            setValue={setRespManager}
            options={mapUsersToProps(users?.results)}
          />
        </InputGroup>
      </InputsContainer>
      <Title>Менеджери проєкту </Title>
      <InputsContainer>
        <InputGroup>
          {managers.map((item, i) => (
            <ManagerItem key={i}>
              <Select
                value={item}
                setValue={(value) =>
                  setManagers((state) =>
                    state.map((manager, j) => (i === j ? value : manager))
                  )
                }
                options={mapUsersToProps(users?.results, managers)}
              />
              {managers.length === 1 && !managers[0].id ? (
                ''
              ) : (
                <IconContainer onClick={() => handleDelete(i)}>
                  <CloseEditSvg />
                </IconContainer>
              )}
            </ManagerItem>
          ))}
          {managers.filter((item) => item.id).length === managers.length &&
          mapUsersToProps(users?.results, managers).length ? (
            <AddItem
              onClick={() =>
                setManagers((state) => [...state, { id: '', title: '' }])
              }
              title="+ Додати менеджера"
            />
          ) : (
            ''
          )}
        </InputGroup>
      </InputsContainer>
      <FormBtns
        primaryTitle={btnPrimaryTitle || 'Створити'}
        onCancel={() => setShow(false)}
      />
    </ProjectFormStyled>
  );
};
