import type { ChangeEventHandler, FormEventHandler } from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ArrowPaginationSvg } from '../svg/arrow-pagination-svg';
import { Select } from '../select';
import type { OptionItem } from '../../common/types/option-item';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { setPageSize } from '../../api/page-data-slice';
import {
  NextLink,
  PaginationBtnContainer,
  PaginationForm,
  PaginationInput,
  PaginationLink,
  PaginationStyled,
  PrevLink,
  SearchBtn,
  SelectWrapper,
  Truncable
} from './style';
import { perPageOptions } from './data';

interface PaginationProps {
  count: number;
}

const Pagination = ({ count }: PaginationProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { pageSize: perPage, menuIsOpen } = useTypedSelector(
    (state) => state['page-data']
  );
  const currentPage = searchParams.get('page') || '1';
  const [searchPage, setSearchPage] = useState('');
  const pageCount = Math.ceil((count || +perPage) / +perPage);

  const handleChangePage = (page: string) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (searchPage && !isNaN(+searchPage)) {
      handleChangePage(searchPage);
    }
  };

  const handleChangeSearchPage: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (+e.target.value <= pageCount) {
      setSearchPage(e.target.value);
    }
  };

  const generatePageBtns = () => {
    const pageBtns = [];
    if (pageCount <= 7) {
      for (let i = 1; i <= pageCount; i++) {
        pageBtns.push(i);
      }

      return pageBtns;
    }
    if (
      +currentPage <= 2 ||
      +currentPage === pageCount - 1 ||
      +currentPage === pageCount
    ) {
      for (let i = 1; i <= 3; i++) {
        pageBtns.push(i);
      }
      pageBtns.push('...');
      pageBtns.push(pageCount - 2);
      pageBtns.push(pageCount - 1);
      pageBtns.push(pageCount);

      return pageBtns;
    }
    if (+currentPage > 2 && +currentPage <= pageCount - 2) {
      pageBtns.push(1);
      pageBtns.push('...');
      pageBtns.push(+currentPage - 1);
      pageBtns.push(+currentPage);
      pageBtns.push(+currentPage + 1);
      pageBtns.push('...');
      pageBtns.push(pageCount);

      return pageBtns;
    } else {
      pageBtns.push(1);
      pageBtns.push('...');
      pageBtns.push(pageCount - 3);
      pageBtns.push(pageCount - 2);
      pageBtns.push(pageCount - 1);
      pageBtns.push(pageCount);
    }

    return pageBtns;
  };

  const handleSetSearchPage: FormEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    searchParams.set('page', searchPage);
    setSearchParams(searchParams);
  };

  const handleChangePerPage = (value: OptionItem) => {
    searchParams.set('size', `${value.id}`);
    searchParams.delete('page');
    setSearchPage('');
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const size = searchParams.get('size');
    if (size && !isNaN(+size)) {
      dispatch(setPageSize(+size));
    }
  }, [dispatch, searchParams]);

  return (
    <PaginationStyled menuIsOpen={menuIsOpen}>
      <SelectWrapper>
        <Select
          fz="12px"
          openUp
          size={{
            width: '140px'
          }}
          options={perPageOptions}
          value={{ id: perPage, title: `${perPage} на сторінку` }}
          setValue={handleChangePerPage}
        />
      </SelectWrapper>
      <PaginationBtnContainer>
        <PrevLink
          onClick={() =>
            handleChangePage(+currentPage === 1 ? '1' : `${+currentPage - 1}`)
          }
        >
          <ArrowPaginationSvg />
        </PrevLink>
        {generatePageBtns().map((item, i) =>
          item === '...' ? (
            <Truncable key={i}>...</Truncable>
          ) : (
            <PaginationLink
              onClick={() => handleChangePage(`${item}`)}
              key={i}
              isActive={+currentPage === +item}
            >
              {item}
            </PaginationLink>
          )
        )}
        <NextLink
          onClick={() =>
            handleChangePage(
              +currentPage === pageCount
                ? `${pageCount}`
                : `${+currentPage + 1}`
            )
          }
        >
          <ArrowPaginationSvg />
        </NextLink>
        <PaginationForm onSubmit={onSubmit}>
          <PaginationInput
            value={searchPage}
            onChange={handleChangeSearchPage}
            type="number"
          />
          <SearchBtn onSubmit={handleSetSearchPage} type="submit">
            Пошук
          </SearchBtn>
        </PaginationForm>
      </PaginationBtnContainer>
    </PaginationStyled>
  );
};

export { Pagination };
