import type { OptionItem } from '../../common/types/option-item';

interface DataItem {
  liv_city?: string;
  location?: string;
  count: number;
}

export const transformCityList = (
  data: DataItem[]
): OptionItem<{ count: number }>[] => {
  const map: { [key: string]: number } = {};

  data.forEach((item) => {
    let key: string | undefined;

    if (item.liv_city !== undefined) {
      key = item.liv_city.trim();
    } else if (item.location !== undefined) {
      key = item.location.trim();
    }

    if (key !== undefined) {
      const existingKey = Object.keys(map).find((k) => k.trim() === key);
      if (existingKey) {
        map[existingKey] += item.count;
      } else {
        map[key] = item.count;
      }
    }
  });

  const result: OptionItem<{ count: number }>[] = Object.entries(map).map(
    ([key, count]) => ({
      id: key,
      title: key,
      restFields: {
        count
      }
    })
  );

  return result;
};
