import styled from 'styled-components';
import { scrollBar } from '../../../style/helpers';

export const UserRolesStyled = styled.div`
  margin-top: 30px;
  padding: 30px 20px 0;
  border-top: 1px solid ${(p) => p.theme.colors.grey.input};
  height: calc(100vh - 340px);
  ${scrollBar}
`;

export const UserRolesContainer = styled.div`
  margin-bottom: 30px;
`;

export const BtnsContainer = styled.div<{ menuIsOpen: boolean }>`
  position: fixed;
  right: 0;
  bottom: 0;
  width: ${(p) =>
    p.menuIsOpen ? 'calc(100vw - 232px)' : 'calc(100vw - 232px)'};
  padding: 12px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  background: #ffffff;
  box-shadow: 3px 0px 10px rgba(63, 81, 126, 0.11);
  transition: width 0.4s;
`;
