export const setFileNameFromUrl = (url: string) => {
  let decodedUrl = '';
  try {
    decodedUrl = decodeURI(url);
  } catch (err) {
    console.log(err);
  }

  const currentUrl = decodedUrl || url;

  const urlArr = currentUrl.split('/');

  return urlArr[urlArr.length - 1];
};
