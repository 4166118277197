export const ProjectDashboardSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M10.0026 8.63281H5.33594C5.0626 8.63281 4.83594 8.40615 4.83594 8.13281C4.83594 7.85948 5.0626 7.63281 5.33594 7.63281H10.0026C10.2759 7.63281 10.5026 7.85948 10.5026 8.13281C10.5026 8.40615 10.2759 8.63281 10.0026 8.63281Z"
      fill="#9DABC0"
    />
    <path
      d="M8.25594 11.2969H5.33594C5.0626 11.2969 4.83594 11.0702 4.83594 10.7969C4.83594 10.5235 5.0626 10.2969 5.33594 10.2969H8.25594C8.52927 10.2969 8.75594 10.5235 8.75594 10.7969C8.75594 11.0702 8.52927 11.2969 8.25594 11.2969Z"
      fill="#9DABC0"
    />
    <path
      d="M9.33594 4.49479H6.66927C6.02927 4.49479 4.83594 4.49479 4.83594 2.66146C4.83594 0.828125 6.02927 0.828125 6.66927 0.828125H9.33594C9.97594 0.828125 11.1693 0.828125 11.1693 2.66146C11.1693 3.30146 11.1693 4.49479 9.33594 4.49479ZM6.66927 1.82812C6.00927 1.82812 5.83594 1.82812 5.83594 2.66146C5.83594 3.49479 6.00927 3.49479 6.66927 3.49479H9.33594C10.1693 3.49479 10.1693 3.32146 10.1693 2.66146C10.1693 1.82812 9.99594 1.82812 9.33594 1.82812H6.66927Z"
      fill="#9DABC0"
    />
    <path
      d="M10 15.1669H6C2.25333 15.1669 1.5 13.4469 1.5 10.6669V6.66695C1.5 3.62695 2.6 2.32695 5.30667 2.18695C5.57333 2.17361 5.82 2.38028 5.83333 2.66028C5.84667 2.94028 5.63333 3.16695 5.36 3.18028C3.46667 3.28695 2.5 3.85361 2.5 6.66695V10.6669C2.5 13.1336 2.98667 14.1669 6 14.1669H10C13.0133 14.1669 13.5 13.1336 13.5 10.6669V6.66695C13.5 3.85361 12.5333 3.28695 10.64 3.18028C10.3667 3.16695 10.1533 2.92695 10.1667 2.65361C10.18 2.38028 10.42 2.16695 10.6933 2.18028C13.4 2.32695 14.5 3.62695 14.5 6.66028V10.6603C14.5 13.4469 13.7467 15.1669 10 15.1669Z"
      fill="#9DABC0"
    />
  </svg>
);
