import styled from 'styled-components';

export const ConfirmPanelStyled = styled.div<{ menuIsOpen: boolean }>`
  position: fixed;
  right: 0;
  bottom: 0;
  width: ${(p) =>
    p.menuIsOpen ? 'calc(100vw - 232px)' : 'calc(100vw - 56px)'};
  padding: 12px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  background: #ffffff;
  box-shadow: 3px 0px 10px rgba(63, 81, 126, 0.11);
`;
