export const ArchProjectsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M15.2515 5.53787C15.1229 5.38595 14.9341 5.29883 14.7335 5.29883H1.6736C1.47317 5.29883 1.2845 5.38595 1.1559 5.5377C1.03589 5.67937 0.981669 5.86368 1.0055 6.04667L1.7765 14.0927C1.77718 14.0989 1.77785 14.1051 1.77869 14.1112C1.84651 14.6188 2.27759 15.0016 2.78153 15.0016H13.6256C14.1554 15.0016 14.5972 14.5958 14.6306 14.0927L15.4016 6.04684C15.4254 5.86388 15.3713 5.67954 15.2515 5.53787ZM13.6282 13.9954C13.6277 13.9946 13.6252 13.994 13.6256 13.9944L2.79042 13.9959C2.78606 13.9939 2.77967 13.9863 2.77767 13.9805L2.04204 6.30619H14.365L13.6282 13.9954Z"
      fill="#9DABC0"
    />
    <path
      d="M14.1355 3.36397C14.008 3.21709 13.8225 3.13281 13.6264 3.13281H2.79917C2.60327 3.13281 2.41694 3.22027 2.28787 3.37269C2.15812 3.52613 2.10204 3.72589 2.13361 3.91439L2.43576 5.87841L3.43136 5.72531L3.18746 4.14001H13.2383L12.9946 5.72531L13.99 5.87841L14.2929 3.90868C14.321 3.7103 14.2638 3.5117 14.1355 3.36397Z"
      fill="#9DABC0"
    />
    <path
      d="M12.6402 1.2315C12.5126 1.08444 12.3269 1 12.1305 1H4.29128C4.09538 1 3.90905 1.08745 3.77998 1.23988C3.65006 1.39332 3.59415 1.59308 3.62589 1.78293L3.92804 3.71338L4.92314 3.5576L4.68041 2.00719H11.7379L11.4826 3.55338L12.4764 3.71754L12.797 1.77668C12.8255 1.57797 12.7683 1.37939 12.6402 1.2315Z"
      fill="#9DABC0"
    />
    <path
      d="M10.3197 8.4668H6.08953C5.81138 8.4668 5.58594 8.69224 5.58594 8.97039C5.58594 9.24854 5.81138 9.47399 6.08953 9.47399H10.3197C10.5979 9.47399 10.8233 9.24854 10.8233 8.97039C10.8233 8.69224 10.5979 8.4668 10.3197 8.4668Z"
      fill="#9DABC0"
    />
  </svg>
);
