export const ActualProjectsSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <mask
      id="mask0_3614_234826"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="14"
      height="14"
    >
      <path d="M1.5 1.5H14.5V14.5H1.5V1.5Z" fill="white" />
    </mask>
    <g mask="url(#mask0_3614_234826)">
      <path
        d="M13.9922 10.4967H12.0202C11.1788 10.4967 10.4967 11.1788 10.4967 12.0202V13.9922M13.9922 10.4967C13.9922 11.1362 13.7382 11.7495 13.286 12.2016L12.2016 13.286C11.7495 13.7382 11.1362 13.9922 10.4967 13.9922M13.9922 10.4967V4.55068C13.9922 3.87628 13.7243 3.22948 13.2474 2.7526C12.7705 2.27574 12.1237 2.00781 11.4493 2.00781H4.55068C3.87628 2.00781 3.22948 2.27574 2.7526 2.7526C2.27573 3.22948 2.00781 3.87628 2.00781 4.55068V11.4493C2.00781 12.1237 2.27573 12.7705 2.7526 13.2474C3.22948 13.7243 3.87628 13.9922 4.55068 13.9922H9.9974H10.4967M5.00391 10.9961H8M5.00391 5.00391H10.9961M5.00391 8H10.9961"
        stroke="#9DABC0"
        strokeWidth="1.01562"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
