import styled from 'styled-components';

export const DeactivationBtnStyled = styled.div`
  margin-right: 40px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #eb5757;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: #eb5757;
  cursor: pointer;
`;
