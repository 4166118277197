import type { HeaderItem } from '../../components/data-table';
import type { ProjectsTable } from './types';

export const tableHeaders: HeaderItem<ProjectsTable>[] = [
  { id: 'id', title: 'ID', checked: true },
  { id: 'name', title: 'назва проЄкту', checked: true },
  { id: 'description', title: 'опис проєкту', checked: true },
  { id: 'start_date', title: 'ДАТА початку', checked: true },
  { id: 'end_date', title: 'ДАТА завершення', checked: true },
  { id: 'chief_manager', title: 'КЕРІВНИК ПРОЄКТУ', checked: true },
  { id: 'managers', title: 'МЕНЕДЖЕРИ ПРОЄКТУ', checked: true },
  { id: 'budget', title: 'Бали ПРОЄКТУ', checked: true },
  { id: 'contacts_count', title: 'КІЛЬКІСТЬ КОНТАКТІВ', checked: true },
  { id: 'contact_cost', title: 'ВАРТІСТЬ КОНТАКТу', checked: true },
  { id: 'events_count', title: 'КІЛЬКІСТЬ ПОДІЙ', checked: true }
];
