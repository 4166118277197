import type { Dispatch, SetStateAction } from 'react';
import { BtnPrimary } from '../../../components/btn-primary';
import { BtnSecondary } from '../../../components/btn-secondary';
import { FormBtns } from '../../../components/form-btns';
import { BtnsContainer, ChooseTemplateStyled, Title } from './style';

interface ChooseTemplateProps {
  setStep: Dispatch<SetStateAction<'form' | 'choose' | 'template'>>;
  setWithMainTemplate: Dispatch<SetStateAction<boolean>>;
}

export const ChooseTemplate = ({
  setStep,
  setWithMainTemplate
}: ChooseTemplateProps) => {
  const handleWithTemplate = () => {
    setStep('template');
    setWithMainTemplate(true);
  };

  const handleNewTemplate = () => {
    setStep('template');
    setWithMainTemplate(false);
  };

  return (
    <ChooseTemplateStyled>
      <Title>Шаблон події</Title>
      <BtnsContainer>
        <BtnPrimary onClick={handleWithTemplate}>Обрати існуючий</BtnPrimary>
        <BtnSecondary onClick={handleNewTemplate}>Створити новий</BtnSecondary>
      </BtnsContainer>
      <FormBtns
        onlySecondaryBtn
        onCancel={() => setStep('form')}
        secondaryTitle="Назад"
      />
    </ChooseTemplateStyled>
  );
};
