import { NoWrap } from '../../components/no-wrap';
import { StringCutter } from '../../components/string-cutter';
import { dateTableFormat } from '../../helpers/date-helpers';
import type { Project } from '../../models/project';
import type { ProjectsTable } from './types';

export const transformProjectsData = (data: Project[]): ProjectsTable[] =>
  data.map(
    ({
      managers,
      chief_manager,
      description,
      id,
      start_date,
      end_date,
      budget,
      contacts_count,
      events,
      ...rest
    }) => ({
      ...rest,
      id,
      start_date: dateTableFormat(start_date) as string,
      end_date: dateTableFormat(end_date) as string,
      description: <StringCutter lines="2">{description}</StringCutter>,
      managers: (
        <>
          {managers.map((manager) => (
            <NoWrap key={manager.id}>
              {`${manager?.last_name || ''} ${manager?.first_name || ''} ${
                manager?.patronymic || ''
              }`}
            </NoWrap>
          ))}
        </>
      ),
      chief_manager: (
        <NoWrap>{`${chief_manager?.last_name || ''} ${
          chief_manager?.first_name || ''
        } ${chief_manager?.patronymic || ''}`}</NoWrap>
      ),
      budget: `${budget || 0}`,
      contacts_count,
      contact_cost: budget
        ? `${Math.floor(budget / (contacts_count || 1))}`
        : '0',
      events_count: events.length
    })
  );
