import styled from 'styled-components';

export const FormBtnsStyled = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${(p) => p.theme.colors.popup.border};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
