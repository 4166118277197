import type { CSSProperties } from 'react';

export const duration = 200;

export const defaultStyle: CSSProperties = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: '0',
  visibility: 'hidden'
};

export const transitionStyles: Record<string, CSSProperties> = {
  entering: { opacity: 1, visibility: 'visible' },
  entered: { opacity: 1, visibility: 'visible' },
  exiting: { opacity: 0, visibility: 'hidden' },
  exited: { opacity: 0, visibility: 'hidden' },
  unmounted: { opacity: 0, visibility: 'hidden' }
};
