import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useGetUserMeQuery } from '../../api/user-api';
import { checkIsManager } from '../../helpers/is-manager';
import {
  AppRoute,
  DashboardRoute
} from '../../common/enums/app/app-route.enum';
import {
  useGetEventsStatistcsQuery,
  useGetProjectsStatistcsQuery
} from '../../api/dashboard-api';
import { setDashboardColors } from '../../api/dashboard-slice';
import { useAppDispatch } from '../../api/store';
import { Header } from './components/header';
import { Content } from './style';

export const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const { data: userMe } = useGetUserMeQuery('');
  const { pathname } = useLocation();

  const { data } = useGetProjectsStatistcsQuery(
    {
      params: ''
    },
    { refetchOnMountOrArgChange: true }
  );

  const { data: events } = useGetEventsStatistcsQuery('', {
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (data && events) {
      dispatch(setDashboardColors({ projects: data, events }));
    }
  }, [dispatch, data, events]);

  if (!userMe) {
    return null;
  }

  if (checkIsManager(userMe)) {
    return <Navigate to={{ pathname: AppRoute.CONTACTS }} />;
  }

  if (pathname === AppRoute.DASHBOARD || pathname === AppRoute.ROOT) {
    return (
      <Navigate
        to={{ pathname: `${AppRoute.DASHBOARD}/${DashboardRoute.PROJECTS}` }}
      />
    );
  }

  return (
    <>
      <Header />
      <Content>
        <Outlet />
      </Content>
    </>
  );
};
