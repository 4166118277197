import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const activeStyle = css`
  background: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  svg {
    path {
      fill: ${(p) => p.theme.colors.white};
    }
  }
`;

export const TabStyled = styled.li`
  .active {
    ${activeStyle}
  }
`;

export const TabLink = styled(NavLink)<{ $isActive?: boolean }>`
  padding: 9px 12px;
  color: ${(p) => p.theme.colors.violet.dark};
  font-size: 14px;
  font-weight: 500;
  line-height: 125%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  svg {
    margin-right: 9px;
  }
  ${(p) => (p.$isActive ? activeStyle : '')}
`;
