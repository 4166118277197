import { useEffect } from 'react';
import { removeCookie } from 'typescript-cookie';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import type { SerializedError } from '@reduxjs/toolkit';
import { useAppDispatch, useTypedSelector } from '../api/store';
import { logOut, setCredential } from '../api/auth-slice';
import { useVerifyTokenMutation } from '../api/verify-token-api';
import { StorageKey } from '../common/enums/storage/storage-key.enum';
import { AppRoute } from '../common/enums/app/app-route.enum';
import { hasOwnProperty } from '../helpers/has-own-property';

export const useCheckAuth = () => {
  const dispatch = useAppDispatch();
  const { isAuthorized } = useTypedSelector((state) => state.auth);
  const [verifyToken] = useVerifyTokenMutation();

  useEffect(() => {
    if (isAuthorized === 'needToCheck') {
      if (localStorage.getItem(StorageKey.ACCESS_TOKEN)) {
        verifyToken({
          token: localStorage.getItem(StorageKey.ACCESS_TOKEN) as string
        })
          .then((res) => {
            if (
              (res as { error: FetchBaseQueryError | SerializedError }).error ||
              (hasOwnProperty(res, 'detail') &&
                res.detail === 'User is inactive')
            ) {
              dispatch(logOut());
              localStorage.removeItem(StorageKey.ACCESS_TOKEN);
              removeCookie(StorageKey.REFRESH_TOKEN, { path: AppRoute.ROOT });
            } else {
              dispatch(
                setCredential({
                  accessToken: localStorage.getItem(
                    StorageKey.ACCESS_TOKEN
                  ) as string
                })
              );
            }
          })
          .catch(() => {
            dispatch(logOut());
            localStorage.removeItem(StorageKey.ACCESS_TOKEN);
            removeCookie(StorageKey.REFRESH_TOKEN, { path: AppRoute.ROOT });
          });
      } else {
        dispatch(logOut());
        localStorage.removeItem(StorageKey.ACCESS_TOKEN);
        removeCookie(StorageKey.REFRESH_TOKEN, { path: AppRoute.ROOT });
      }
    }
  }, [isAuthorized, verifyToken, dispatch]);
};
