export const DownloadSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.227309 12.5356L0.227309 12.5356C0.228064 13.2962 0.530514 14.0253 1.06829 14.5631C1.60606 15.1009 2.33522 15.4033 3.09574 15.4041H3.09579H12.8425H12.8426C13.6031 15.4033 14.3323 15.1009 14.87 14.5631C15.4078 14.0253 15.7103 13.2962 15.711 12.5356V12.5356V10.6227C15.711 10.4613 15.6469 10.3066 15.5329 10.1926C15.4188 10.0785 15.2641 10.0144 15.1028 10.0144C14.9415 10.0144 14.7868 10.0785 14.6727 10.1926C14.5586 10.3066 14.4945 10.4613 14.4945 10.6227V12.5356C14.4945 12.9737 14.3205 13.3939 14.0107 13.7037C13.7009 14.0135 13.2807 14.1876 12.8425 14.1876H3.09579C2.65765 14.1876 2.23746 14.0135 1.92765 13.7037C1.61784 13.3939 1.44379 12.9737 1.44379 12.5356V10.6227C1.44379 10.4613 1.37971 10.3066 1.26564 10.1926C1.15157 10.0785 0.996864 10.0144 0.835549 10.0144C0.674234 10.0144 0.519526 10.0785 0.405459 10.1926C0.291392 10.3066 0.227309 10.4613 0.227309 10.6227L0.227309 12.5356Z"
      fill="#306FE3"
      stroke="#306FE3"
      strokeWidth="0.0961625"
    />
    <path
      d="M10.7827 7.67662L10.7825 7.67642L10.779 7.67992L8.62044 9.83848L8.62044 1.6362C8.62044 1.47489 8.55635 1.32018 8.44229 1.20611C8.32822 1.09205 8.17351 1.02796 8.0122 1.02796C7.85088 1.02796 7.69617 1.09205 7.58211 1.20611C7.46804 1.32018 7.40396 1.47489 7.40396 1.6362L7.40396 9.83007L5.24541 7.67152C5.1889 7.61501 5.12181 7.57019 5.04798 7.5396C4.97415 7.50902 4.89501 7.49328 4.8151 7.49328C4.73518 7.49328 4.65605 7.50902 4.58221 7.5396C4.50838 7.57019 4.44129 7.61501 4.38478 7.67152C4.32827 7.72803 4.28345 7.79512 4.25287 7.86895C4.22228 7.94278 4.20654 8.02191 4.20654 8.10183C4.20654 8.18175 4.22228 8.26088 4.25287 8.33471C4.28345 8.40855 4.32827 8.47563 4.38478 8.53214L7.58049 11.7278L7.58048 11.7278L7.58101 11.7284C7.66506 11.8099 7.76948 11.8673 7.88335 11.8946L7.88333 11.8947L7.88586 11.8952C7.98408 11.9133 8.08519 11.9075 8.18074 11.8785C8.27629 11.8495 8.3635 11.798 8.43505 11.7283L8.43505 11.7283L8.43546 11.7279L11.6312 8.54059L11.6313 8.54074L11.6342 8.53728C11.7308 8.42108 11.7806 8.27311 11.7741 8.12217C11.7675 7.97123 11.705 7.82812 11.5988 7.72073C11.4925 7.61333 11.3501 7.54932 11.1992 7.54116C11.0484 7.53301 10.8999 7.5813 10.7827 7.67662Z"
      fill="#306FE3"
      stroke="#306FE3"
      strokeWidth="0.0961625"
    />
  </svg>
);
