import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  button {
    outline: none;
    border: none;
  }
  input {
    outline: none;
  }
`;
