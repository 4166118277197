import type { FormEventHandler } from 'react';
import { useState, type Dispatch, type SetStateAction, useEffect } from 'react';
import { Popup } from '../../../components/popup';
import { Select } from '../../../components/select';
import type { OptionItem } from '../../../common/types/option-item';
import { BtnSecondary } from '../../../components/btn-secondary';
import { BtnPrimary } from '../../../components/btn-primary';
import type { IEvent } from '../../../models/event';
import { BtnsContainer, FormStyled, Subtitle } from '../delegate/style';

interface DelegateProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: { stepId: string | number }) => void;
  event: IEvent;
}

export const ChangeStep = ({
  show,
  setShow,
  onSubmit,
  event
}: DelegateProps) => {
  const [step, setStep] = useState<OptionItem>({ id: '', title: '' });

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit({ stepId: step.id });
  };

  useEffect(() => {
    setStep({ id: '', title: '' });
  }, [show]);

  return (
    <Popup
      maxContentWidth="448px"
      title="Змінити етап"
      show={show}
      setShow={setShow}
    >
      <FormStyled onSubmit={handleSubmit}>
        <Subtitle>
          Оберіть етап події на який Ви бажаєте перемістити обрані контакти.
        </Subtitle>
        <Select
          mt="20px"
          placeholder="Перемістити на"
          label="Етап"
          value={step}
          setValue={setStep}
          options={event.steps.map((item) => ({
            id: item.id,
            title: item.name
          }))}
        />
        <BtnsContainer>
          <BtnSecondary onClick={() => setShow(false)}>Відмінити</BtnSecondary>
          <BtnPrimary disabled={!step.id}>Зберегти</BtnPrimary>
        </BtnsContainer>
      </FormStyled>
    </Popup>
  );
};
