import type {
  Dispatch,
  FormEventHandler,
  ReactNode,
  SetStateAction
} from 'react';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { BtnSecondary } from '../btn-secondary';
import { BtnPrimary } from '../btn-primary';
import { useOutsideClick } from '../../hooks/use-outside-click';
import {
  ButtonsContainer,
  ConfirmPopupStyled,
  PopupContent,
  Title
} from './style';
import { defaultStyle, duration, transitionStyles } from './transition';

interface ConfirmPopupProps {
  title: ReactNode;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: () => void;
  show: boolean;
  disabled?: boolean;
  customWidth?: string;
  needTranslate?: boolean;
}

export const ConfirmPopup = ({
  title,
  setShow,
  onSubmit,
  show,
  disabled,
  customWidth,
  needTranslate
}: ConfirmPopupProps) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => setShow(false));

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Transition in={show} mountOnEnter unmountOnExit timeout={duration}>
      {(state) => (
        <ConfirmPopupStyled
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          needTranslate={needTranslate}
        >
          <PopupContent ref={ref} customWidth={customWidth}>
            <Title>{title}</Title>
            <ButtonsContainer onSubmit={handleSubmit}>
              <BtnSecondary onClick={() => setShow(false)}>
                Відмінити
              </BtnSecondary>
              <BtnPrimary disabled={disabled}>Так</BtnPrimary>
            </ButtonsContainer>
          </PopupContent>
        </ConfirmPopupStyled>
      )}
    </Transition>
  );
};
