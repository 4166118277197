import styled from 'styled-components';

export const ChartsSlidesContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
`;

export const ChartsSlide = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  flex: 1;
`;

export const Title = styled.div`
  margin-bottom: 20px;
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
`;

export const Nav = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  z-index: 1;
`;

export const Arrow = styled.div<{ $isActive?: boolean; $isPrev?: boolean }>`
  cursor: ${(p) => (p.$isActive ? 'pointer' : 'default')};
  svg {
    transform: ${(p) => (p.$isPrev ? 'rotate(180deg)' : 'rotate(0)')};
    path {
      stroke: ${(p) =>
        p.$isActive ? p.theme.colors.primary : p.theme.colors.grey.input};
    }
  }
`;
