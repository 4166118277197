import { useRef, useState } from 'react';
import { AngleTableSvg } from '../../svg/angle-table-svg';
import { useOutsideClick } from '../../../hooks/use-outside-click';
import { DateSelectHeader, DateSelectStyled } from './style';
import type { DateSelectProps } from './types';
import { DateSelectOptions } from './date-select-options';

const DateSelect = ({ options, value, onChoose, isYear }: DateSelectProps) => {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  useOutsideClick(ref, () => setShowOptions(false));

  return (
    <DateSelectStyled ref={ref}>
      <DateSelectHeader
        onClick={() => setShowOptions((state) => !state)}
        isActive={showOptions}
      >
        {value}
        <AngleTableSvg />
      </DateSelectHeader>
      {showOptions && (
        <DateSelectOptions
          options={options}
          onChoose={onChoose}
          setShowOptions={setShowOptions}
          isYear={isYear}
        />
      )}
    </DateSelectStyled>
  );
};

export { DateSelect };
