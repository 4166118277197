import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { NewActivity } from '../new-activity';
import { Input } from '../input';
import { Select } from '../select';
import type { OptionItem } from '../../common/types/option-item';
import { CALL_RESULT, SATISFACTION } from '../../common/constants';
import { InputDate } from '../input-date';
import { useCreateContactCallMutation } from '../../api/contact-api';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { useGetUserMeQuery } from '../../api/user-api';
import { ActivityTextArea } from '../activity-textarea';
import { useCreatePartnerCallMutation } from '../../api/partner-api';
import { InputContainer, TextareaWrapper } from './style';

interface NewCallProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  fullName: string;
  id: string | number;
  isPartner?: boolean;
}

export const NewCall = ({
  show,
  setShow,
  fullName,
  id,
  isPartner
}: NewCallProps) => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [result, setResult] = useState<OptionItem>({ id: '', title: '' });
  const [satisfaction, setSatisfaction] = useState<OptionItem>({
    id: '',
    title: ''
  });
  const [date, setDate] = useState('');
  const [message, setMessage] = useState('');
  const [createContactCall] = useCreateContactCallMutation();
  const [createPartnerCall] = useCreatePartnerCallMutation();
  const handleClose = () => {
    setShow(false);
    setResult({ id: '', title: '' });
    setSatisfaction({ id: '', title: '' });
    setDate('');
    setMessage('');
  };

  const handleCreateContactCall = () => {
    createContactCall({
      contact: id,
      text: message,
      result: result.id,
      satisfaction: satisfaction.id,
      date: date.split('/').reverse().join('-'),
      contact_call_owner: userMe?.id || ''
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Дзвінок внесено.',
          message: 'Ви успішно внесли дзвінок.'
        });
        handleClose();
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Поилка',
          message: getApiError(err)
        });
      });
  };

  const handleCreatePartnerCall = () => {
    createPartnerCall({
      partner: id,
      text: message,
      result: result.id,
      satisfaction: satisfaction.id,
      date: date.split('/').reverse().join('-'),
      partner_call_owner: userMe?.id || ''
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Дзвінок внесено.',
          message: 'Ви успішно внесли дзвінок.'
        });
        handleClose();
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Поилка',
          message: getApiError(err)
        });
      });
  };

  return (
    <NewActivity
      title="Внести дзвінок"
      btnTitle="Внести дзвінок"
      show={show}
      onClose={handleClose}
      onSubmit={isPartner ? handleCreatePartnerCall : handleCreateContactCall}
      customHeight="374px"
      disabled={
        !result.id ||
        !satisfaction.id ||
        !date ||
        date === '__/__/____' ||
        !message
      }
    >
      <InputContainer>
        <Input
          label={isPartner ? 'Партнер' : 'Контакт'}
          disabled
          value={fullName}
          onChange={() => {}}
        />
        <Select
          label="Результат"
          value={result}
          options={CALL_RESULT}
          setValue={setResult}
        />
        <InputDate value={date} setValue={setDate} required />
        <Select
          value={satisfaction}
          setValue={setSatisfaction}
          label="Рівень задоволеності/настрій"
          options={SATISFACTION.slice(1)}
        />
      </InputContainer>
      <TextareaWrapper>
        <ActivityTextArea
          value={message}
          setValue={setMessage}
          placeholder="Опишіть дзвінок..."
          required
        />
      </TextareaWrapper>
    </NewActivity>
  );
};
