import styled from 'styled-components';

export const TabsStyled = styled.div`
  margin-top: 40px;
  margin-left: 20px;
  width: 100%;
  max-width: 795px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;
