import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const EditBtnStyled = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border: 1px solid #d7dfe9;
  border-radius: 6px;
  ${centerFlex}
  cursor: pointer;
  transition: background-color 0.2s;
  svg {
    display: block;
    path {
      fill: ${(p) => p.theme.colors.blue.dark};
    }
  }
  :hover {
    background-color: ${(p) => p.theme.colors.blue.bg};
    transition: background-color 0.2s;
  }
`;
