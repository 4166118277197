import styled from 'styled-components';
import { centerFlex, scrollBar } from '../../style/helpers';

export const NoDataStyled = styled.div<{ customHeight?: string }>`
  height: ${(p) => p.customHeight || 'calc(100vh - 250px)'};
  padding: 20px;
  ${centerFlex}
  flex-direction: column;
  flex-wrap: wrap;
  ${scrollBar}
`;

export const NoDataTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.2;
  color: ${(p) => p.theme.colors.grey.primary};
  text-align: center;
`;

export const NoDataSubtitle = styled.div`
  margin-top: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: ${(p) => p.theme.colors.grey.light};
  text-align: center;
`;

export const NoDataIcon = styled.div`
  margin: 0 auto;
  margin-top: 60px;
  width: 500px;
  height: calc(100% - 150px);
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;
