import { useEffect, useState } from 'react';
import { useGetUserRolesQuery } from '../../../../api/user-api';
import { Select } from '../../../../components/select';
import { RoleSelect, RoleTitle } from '../style';
import { UsersAccesTable } from '../users-access-table';
import type { OptionItem } from '../../../../common/types/option-item';

export const ActionAccess = () => {
  const { data: roles } = useGetUserRolesQuery('');
  const [role, setRole] = useState<OptionItem>({
    id: '',
    title: ''
  });

  useEffect(() => {
    if (roles?.results.length && !role?.id) {
      setRole({ id: roles.results[0].id, title: roles.results[0].name });
    }
  }, [roles, role]);

  return (
    <>
      <RoleSelect>
        <RoleTitle>Роль:</RoleTitle>
        <Select
          value={role}
          setValue={(value) => setRole(value)}
          size={{ width: '183px' }}
          options={
            roles?.results.map((item) => ({
              id: item.id,
              title: item.name
            })) || []
          }
        />
      </RoleSelect>
      <UsersAccesTable id={role?.id} />
    </>
  );
};
