import type { Dispatch, SetStateAction } from 'react';
import type { OptionItem } from '../../../common/types/option-item';
import { ActivityTabStyled } from './style';

interface ActivityTabProps {
  id: string | number;
  title: string;
  isActive: boolean;
  setTab: Dispatch<SetStateAction<OptionItem>>;
}

export const ActivityTab = ({
  id,
  title,
  isActive,
  setTab
}: ActivityTabProps) => (
  <ActivityTabStyled onClick={() => setTab({ id, title })} isActive={isActive}>
    {title}
  </ActivityTabStyled>
);
