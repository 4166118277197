import type { FormEventHandler, ReactNode } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Transition } from 'react-transition-group';
import { BtnPrimary } from '../btn-primary';
import { FlexContainer } from '../flex-container';
import { IconContainer } from '../icon-container';
import { AngleAccordion } from '../svg/angle-accordion';
import { CloseEditSvg } from '../svg/close-edit-svg';
import { ExpandSvg } from '../svg/expand-svg';
import {
  defaultStyle,
  duration,
  transitionStyles
} from '../../common/transition/fade-transition';
import { Tooltip } from '../tooltip';
import {
  FormStyled,
  NewActivityStyled,
  ActivityActionBtn,
  NoteFooter,
  ActivityHeader,
  ActivityHeaderTitle,
  NewActivityOverlay
} from './style';

interface NewActivityProps {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  children: ReactNode;
  disabled?: boolean;
  title: string;
  btnTitle: string;
  customHeight?: string;
  expanded?: boolean;
}

export const NewActivity = ({
  show,
  onClose,
  onSubmit,
  children,
  disabled,
  title,
  btnTitle,
  customHeight = '214px',
  expanded = false
}: NewActivityProps) => {
  const [showTooltipExpand, setShowTooltipExpand] = useState(false);
  const [activityState, setActivityState] = useState<
    'default' | 'big' | 'small'
  >(expanded ? 'big' : 'default');

  const handleRollUp = () => {
    if (!expanded) {
      setActivityState((state) => {
        if (state === 'small') {
          return 'default';
        }

        return 'small';
      });
    }
  };

  const handleExpand = () => {
    if (!expanded) {
      setActivityState((state) => {
        if (state === 'big') {
          return 'default';
        }

        return 'big';
      });
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onSubmit();
  };

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  return (
    <Transition in={show} mountOnEnter unmountOnExit timeout={duration}>
      {(state) => (
        <>
          <NewActivityOverlay expand={activityState === 'big'} />
          <NewActivityStyled state={activityState}>
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state]
              }}
            >
              <>
                <ActivityHeader state={activityState}>
                  <ActivityHeaderTitle
                    onClick={handleRollUp}
                    state={activityState}
                    expanded={expanded}
                  >
                    {!expanded && (
                      <IconContainer m="0 12px 0 0">
                        <AngleAccordion />
                      </IconContainer>
                    )}
                    {title}
                  </ActivityHeaderTitle>
                  <FlexContainer>
                    {!expanded && (
                      <ActivityActionBtn
                        onClick={handleExpand}
                        onMouseEnter={() => setShowTooltipExpand(true)}
                        onMouseLeave={() => setShowTooltipExpand(false)}
                      >
                        <ExpandSvg />
                        <Tooltip show={showTooltipExpand} isDown>
                          {activityState === 'big' ? 'Згорнути' : 'Розгорнути'}
                        </Tooltip>
                      </ActivityActionBtn>
                    )}
                    <ActivityActionBtn onClick={onClose}>
                      <CloseEditSvg />
                    </ActivityActionBtn>
                  </FlexContainer>
                </ActivityHeader>
                <FormStyled
                  state={activityState}
                  onSubmit={handleSubmit}
                  customHeight={customHeight}
                >
                  {children}
                  <NoteFooter>
                    <BtnPrimary disabled={disabled} isActivity>
                      {btnTitle}
                    </BtnPrimary>
                  </NoteFooter>
                </FormStyled>
              </>
            </div>
          </NewActivityStyled>
        </>
      )}
    </Transition>
  );
};
