import styled from 'styled-components';

export const BtnSecondaryStyled = styled.button`
  padding: 10px 26px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(p) => p.theme.colors.grey.light};
  height: 40px;
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  border-radius: 6px;
  cursor: pointer;
`;
