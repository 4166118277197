export const ActionsCallSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3649_1485)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.99935 4.66675C1.99935 3.19399 3.19326 2.00008 4.66602 2.00008H4.86985C5.19574 2.00008 5.47387 2.23569 5.52744 2.55715L5.89562 4.76619C5.93101 4.97856 5.86166 5.19495 5.70942 5.34719L4.86128 6.19534C4.70366 6.35296 4.63525 6.57892 4.67896 6.79748L4.73549 7.08015C5.15774 9.19141 6.80802 10.8417 8.91928 11.2639L9.20195 11.3205C9.42048 11.3642 9.64648 11.2957 9.80408 11.1381L10.6522 10.29C10.8045 10.1377 11.0209 10.0684 11.2332 10.1038L13.4423 10.472C13.7637 10.5255 13.9993 10.8037 13.9993 11.1296V11.3334C13.9993 12.8061 12.8054 14.0001 11.3327 14.0001C6.17802 14.0001 1.99935 9.82141 1.99935 4.66675ZM4.66602 0.666748C2.45688 0.666748 0.666016 2.45761 0.666016 4.66675C0.666016 10.5578 5.44164 15.3334 11.3327 15.3334C13.5418 15.3334 15.3327 13.5425 15.3327 11.3334V11.1296C15.3327 10.1519 14.6259 9.31755 13.6615 9.15681L11.4524 8.78862C10.8153 8.68242 10.1661 8.89048 9.70942 9.34722L9.11408 9.94255C7.58482 9.60968 6.38974 8.41461 6.05687 6.88535L6.65224 6.29001C7.10895 5.83328 7.31702 5.18411 7.21082 4.54699L6.84262 2.33795C6.68188 1.37357 5.84752 0.666748 4.86985 0.666748H4.66602ZM11.9993 1.33341C12.3675 1.33341 12.666 1.63189 12.666 2.00008V3.33341H13.9993C14.3675 3.33341 14.666 3.63189 14.666 4.00008C14.666 4.36827 14.3675 4.66675 13.9993 4.66675H12.666V6.00008C12.666 6.36827 12.3675 6.66675 11.9993 6.66675C11.6311 6.66675 11.3327 6.36827 11.3327 6.00008V4.66675H9.99935C9.63115 4.66675 9.33268 4.36827 9.33268 4.00008C9.33268 3.63189 9.63115 3.33341 9.99935 3.33341H11.3327V2.00008C11.3327 1.63189 11.6311 1.33341 11.9993 1.33341Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3649_1485">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
