import React from 'react';
import { Range } from '../range';
import { Label, RangeHorisontalStyled } from './style';

interface RangeHorisontalContainerProps {
  data: { total: number; value: number; label: string; valueLabel?: string }[];
}

export const RangeHorisontalContainer = ({
  data
}: RangeHorisontalContainerProps) => (
  <RangeHorisontalStyled>
    {data.map((item, i) => (
      <React.Fragment key={i}>
        <Label>{item.label}</Label>
        <Range
          total={item.total}
          totalAlign="right"
          totalTitle={item.valueLabel || `${item.value}`}
          value={item.value}
        />
      </React.Fragment>
    ))}
  </RangeHorisontalStyled>
);
