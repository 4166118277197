import styled from 'styled-components';
import { centerFlex } from '../../../../style/helpers';

export const UserRoleStyled = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: minmax(auto, 388px) 76px 16px;
  align-items: center;
  gap: 10px;
  :first-child {
    margin-top: 0;
  }
`;

export const CloseIcon = styled.div`
  margin-left: 6px;
  width: 16px;
  height: 16px;
  ${centerFlex}
  cursor: pointer;
  svg {
    display: block;
    width: 11.43px;
    height: 11.43px;
  }
  :hover {
    svg {
      path {
        fill: ${(p) => p.theme.colors.blue.primary};
      }
    }
  }
`;
