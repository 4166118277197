import styled from 'styled-components';

export const ContactSidebarStyled = styled.div`
  height: 100%;
`;

export const ContactSidebarHeader = styled.div`
  padding: 30px 40px 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.table};
`;

export const HeaderItem = styled.div<{ mt?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(p) => p.mt || 0};

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const FullName = styled.div`
  margin-top: 20px;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  color: ${(p) => p.theme.colors.blue.dark};

  @media (max-width: ${(p) => p.theme.breakpoints.xl}) {
    font-size: 16px;
  }
`;

export const ContactId = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const ContactSatisfaction = styled.div`
  position: relative;
`;

export const ContactSatisfactionHeader = styled.div<{ isOpen?: boolean }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.blue.primary};
  display: flex;
  align-items: center;
  svg {
    transform: ${(p) => (p.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    margin-left: 12px;
    path {
      stroke: ${(p) => p.theme.colors.blue.primary};
    }
  }
  cursor: pointer;
`;

export const AccordionSubTitle = styled.div`
  padding: 0 40px 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
