import type { Dispatch, SetStateAction } from 'react';
import { useState, type ReactNode, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import { ChartContainer } from '../chart-container';
import {
  duration,
  defaultStyle,
  transitionStyles
} from '../../../../common/transition/fade-transition';
import { SliderArrowSvg } from '../../../../components/svg/dashboard/slider-arrow-svg';
import { Arrow, ChartsSlide, ChartsSlidesContainer, Nav, Title } from './style';

interface ChartsSliderProps {
  title: string;
  data: { title: string; chart: ReactNode }[];
  autoHeightChart?: {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
  };
}

export const ChartsSlider = ({
  title,
  data,
  autoHeightChart
}: ChartsSliderProps) => {
  const { pathname } = useLocation();
  const [activeSlide, setActiveSlide] = useState(0);

  const handleNextSlide = () => {
    if (activeSlide + 1 < data.length) {
      setActiveSlide((state) => state + 1);
    }
  };

  const handlePrevSlide = () => {
    if (activeSlide) {
      setActiveSlide((state) => state - 1);
    }
  };

  useEffect(() => {
    setActiveSlide(0);
  }, [pathname]);

  return (
    <ChartContainer
      title={title}
      autoHeightChart={autoHeightChart}
      withNav={data.length > 1}
    >
      <ChartsSlidesContainer>
        {data.map((item, i) => (
          <Transition
            key={i}
            in={i === activeSlide}
            timeout={duration}
            unmountOnExit
            mountOnEnter
          >
            {(state) => (
              <ChartsSlide
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state]
                }}
              >
                <Title>{item.title}</Title>
                {item.chart}
              </ChartsSlide>
            )}
          </Transition>
        ))}
      </ChartsSlidesContainer>
      {data.length > 1 && (
        <Nav>
          <Arrow $isActive={!!activeSlide} onClick={handlePrevSlide} $isPrev>
            <SliderArrowSvg />
          </Arrow>
          <Arrow
            $isActive={activeSlide + 1 < data.length}
            onClick={handleNextSlide}
          >
            <SliderArrowSvg />
          </Arrow>
        </Nav>
      )}
    </ChartContainer>
  );
};
