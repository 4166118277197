import type { OptionItem } from '../common/types/option-item';
import type { User } from '../models/user';

export const mapUsersToProps = (
  users?: User[],
  checckedUsers?: OptionItem[]
): OptionItem[] => {
  if (checckedUsers) {
    return (
      users
        ?.map((item) => ({
          id: `${item.id}`,
          title: `${item.last_name || ''} ${item.first_name || ''} ${
            item.patronymic || ''
          }`
        }))
        .filter(
          (item) =>
            !checckedUsers.some(
              (manager) => manager.id.toString() === item.id.toString()
            )
        ) || []
    );
  }

  return (
    users?.map((item) => ({
      id: `${item.id}`,
      title: item.full_name
    })) || []
  );
};
