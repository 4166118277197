import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import type { ServerFile } from '../../../../common/types/server-file';
import type { Note } from '../../../../models/note';
import type { Call } from '../../../../models/call';
import type { Meet } from '../../../../models/meet';
import type { Project } from '../../../../models/project';
import type { IEvent } from '../../../../models/event';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { PageFiles } from '../../../../components/page-files';
import {
  useDeletePartnerCallMutation,
  useDeletePartnerMeetMutation,
  useDeletePartnerNoteMutation,
  useEditPartnerCallMutation,
  useEditPartnerMeetMutation,
  useEditPartnerMutation,
  useEditPartnerNoteMutation
} from '../../../../api/partner-api';
import { Activity } from '../../../../components/activity';
import type { Partner } from '../../../../models/partner';
import {
  TabContent,
  TabsStyled
} from '../../../contacts/current-contact/current-contact-tabs/style';
import { TabItem } from '../../../../components/tab-item';
import { activityTabs, tabList } from './data';

interface CurrentPartnerTabsProps {
  files?: ServerFile[];
  contactId: string | number;
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  notes: Note[];
  setShowNewCall: Dispatch<SetStateAction<boolean>>;
  calls: Call[];
  setShowNewMeet: Dispatch<SetStateAction<boolean>>;
  meets: Meet[];
  contactFullName: string;
  phone?: string;
  projects?: Project[];
  events?: IEvent[];
}

export const CurrentPartnerTabs = ({
  files,
  contactId,
  setShowNewNote,
  setShowNewCall,
  setShowNewMeet,
  notes,
  calls,
  meets,
  contactFullName,
  phone
}: CurrentPartnerTabsProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const [currentTab, setCurrentTab] = useState('activity');
  const [editPartner, { isLoading }] = useEditPartnerMutation();
  const [editCall] = useEditPartnerCallMutation();
  const [deleteCall] = useDeletePartnerCallMutation();
  const [editMeet] = useEditPartnerMeetMutation();
  const [deleteMeet] = useDeletePartnerMeetMutation();
  const [editNote] = useEditPartnerNoteMutation();
  const [deleteNote] = useDeletePartnerNoteMutation();

  if (!userMe) {
    return null;
  }

  const renderContent = () => {
    switch (currentTab) {
      case 'activity':
        return (
          <Activity
            notes={notes}
            tabs={activityTabs}
            setShowNewNote={setShowNewNote}
            calls={calls}
            setShowNewCall={setShowNewCall}
            contactFullName={contactFullName}
            contactId={contactId}
            meets={meets}
            setShowNewMeet={setShowNewMeet}
            phone={phone}
            editCall={editCall}
            deleteCall={deleteCall}
            editMeet={editMeet}
            deleteMeet={deleteMeet}
            editNote={editNote}
            deleteNote={deleteNote}
            isPartner
          />
        );
      case 'files':
        return (
          <PageFiles<Partner>
            contactId={contactId}
            files={files}
            editTrigger={editPartner}
            isLoading={isLoading}
            isDeleteLoading={isLoading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TabsStyled>
        {tabList.map((item, i) => (
          <TabItem
            onClick={setCurrentTab}
            $isActive={item.id === currentTab}
            {...item}
            key={i}
          />
        ))}
      </TabsStyled>
      <TabContent>{renderContent()}</TabContent>
    </>
  );
};
