import { useGetAllBinotelCallsQuery } from '../../api/binotel-api';
import { activityDate } from '../../helpers/date-helpers';
import { HistoryItem } from '../history-item';
import { LoaderSmall } from '../loader-small';
import { ActionsCallSvg } from '../svg/actions/actions-call-svg';
import { NoCallSvg } from '../svg/no-call-svg';
import {
  CallHistoryStyled,
  Descr,
  HistoryContainer,
  HistoryNoData,
  Title
} from './style';

interface CallHistoryProps {
  contactId: string | number;
  contactFullName: string;
}

export const CallHistory = ({
  contactId,
  contactFullName
}: CallHistoryProps) => {
  const { data, isLoading } = useGetAllBinotelCallsQuery(
    { id: contactId },
    { skip: !contactId }
  );

  const calls = data ? Object.values(data.callDetails) : [];

  return (
    <CallHistoryStyled>
      <Title>Історія дзвінків</Title>
      {isLoading ? (
        <LoaderSmall ac />
      ) : calls.length ? (
        <HistoryContainer>
          {calls.map((item) => (
            <HistoryItem
              key={item.callID}
              date={activityDate(+item.startTime * 1000)}
              title={
                item.crm_user?.full_name
                  ? `${item.crm_user?.full_name} мав/ла дзвінок до ${contactFullName}x`
                  : `дзвінок до ${contactFullName}`
              }
              icon={ActionsCallSvg}
              isBinotel
              callId={item.generalCallID}
              recordUploaded={item.recordingStatus === 'uploaded'}
            />
          ))}
        </HistoryContainer>
      ) : (
        <HistoryNoData>
          <NoCallSvg />
          <Descr>Жодних дзвінків наразі</Descr>
        </HistoryNoData>
      )}
    </CallHistoryStyled>
  );
};
