import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import chroma from 'chroma-js';
import { DASHBOARD_COLORS } from '../common/constants';
import type { DashboardEvents, DashboardProjects } from '../models/dashboard';
import { colorsForChart } from '../pages/dashboard/helpers/colors-for-chart';

type DashboardState = {
  colors: {
    projectId: string | number;
    color: string;
    events: { eventId: string | number; color: string }[];
  }[];
  date: { start: string; end: string };
};

const initialState: DashboardState = {
  colors: [],
  date: {
    start: '',
    end: ''
  }
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardColors: (
      state,
      {
        payload: { projects, events }
      }: PayloadAction<{
        projects: DashboardProjects[];
        events: DashboardEvents[];
      }>
    ) => {
      const projectColors = colorsForChart(projects.length, DASHBOARD_COLORS);

      state.colors = projects.map((item, i) => {
        const eventsColors = chroma
          .scale([projectColors[i], 'rgb(200, 200, 200)'])
          .colors(item.events_count + 1)
          .slice(0, item.events_count);

        return {
          projectId: item.id,
          color: projectColors[i],
          events: events
            .filter((event) => event.project.id === item.id)
            .map((event, j) => ({
              eventId: event.id,
              color: eventsColors[j]
            }))
        };
      });
    },
    setDashboardDate: (
      state,
      { payload }: PayloadAction<{ start: string; end: string }>
    ) => {
      state.date = payload;
    }
  }
});

export const { setDashboardColors, setDashboardDate } = dashboardSlice.actions;

export default dashboardSlice.reducer;
