import styled from 'styled-components';

export const CounterStyled = styled.div`
  margin-right: 10px;
  padding: 2px 8px;
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  border-radius: 6px;
  display: flex;
  align-items: center;
`;

export const Count = styled.div`
  margin-left: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${(p) => p.theme.colors.grey.light};
`;
