import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const ProjectHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 20px 20px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.input};
`;

export const BackBtn = styled.div`
  margin-right: 16px;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  ${centerFlex}
  cursor: pointer;
  svg {
    transform: rotate(90deg);
    max-width: 6px;
    path {
      stroke: ${(p) => p.theme.colors.blue.dark};
      transition: stroke 0.2s;
    }
  }
  background-color: transparent;
  transition: background-color 0.2s;
  :hover {
    background-color: ${(p) => p.theme.colors.blue.bg};
    transition: background-color 0.2s;
    svg {
      path {
        stroke: ${(p) => p.theme.colors.blue.primary};
        transition: stroke 0.2s;
      }
    }
  }
`;

export const Title = styled.div`
  margin-right: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const ProjectId = styled.div`
  margin-right: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const EditBtn = styled.div`
  width: 38px;
  height: 36px;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.colors.slateGrey};
  cursor: pointer;
`;
