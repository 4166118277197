import styled from 'styled-components';

export const ViewProjectsStyled = styled.div`
  padding: 24px;
  margin-top: 20px;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.05), 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${(p) => p.theme.colors.blue.dark};
  display: flex;
  align-items: center;
`;

export const ButtonAdd = styled.div`
  height: 24px;
  padding: 2px 12px;
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.grey.light};
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.grey.secondary};
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.2px;
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
`;

export const Descr = styled.div`
  margin-top: 25px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;
