export const SuccessSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3061_14626)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99987 1.51677C5.47991 0.527841 7.21997 0 9 0C11.3861 0.00281493 13.6736 0.95193 15.3609 2.63915C17.0481 4.32636 17.9972 6.61391 18 9C18 10.78 17.4722 12.5201 16.4832 14.0001C15.4943 15.4802 14.0887 16.6337 12.4442 17.3149C10.7996 17.9961 8.99002 18.1743 7.24419 17.8271C5.49836 17.4798 3.89471 16.6226 2.63604 15.364C1.37737 14.1053 0.520203 12.5016 0.172936 10.7558C-0.17433 9.00998 0.00389958 7.20038 0.685088 5.55585C1.36628 3.91131 2.51983 2.50571 3.99987 1.51677ZM4.90899 15.1226C6.11993 15.9318 7.54361 16.3636 9 16.3636C10.9523 16.3615 12.824 15.585 14.2045 14.2045C15.585 12.824 16.3615 10.9523 16.3636 9C16.3636 7.54361 15.9318 6.11993 15.1226 4.90898C14.3135 3.69804 13.1635 2.75422 11.8179 2.19689C10.4724 1.63955 8.99183 1.49373 7.56343 1.77785C6.13502 2.06198 4.82295 2.7633 3.79313 3.79312C2.7633 4.82295 2.06199 6.13502 1.77786 7.56343C1.49373 8.99183 1.63955 10.4724 2.19689 11.8179C2.75423 13.1635 3.69804 14.3135 4.90899 15.1226ZM8.2063 10.3222L12.5377 6.3516C12.6977 6.20491 12.9093 6.12776 13.1261 6.13712C13.3429 6.14648 13.5472 6.24159 13.6938 6.40151C13.8405 6.56144 13.9177 6.77309 13.9083 6.98989C13.899 7.2067 13.8039 7.41092 13.6439 7.5576L8.73484 12.0576C8.57941 12.2001 8.37496 12.2771 8.16414 12.2726C7.95331 12.268 7.7524 12.1822 7.6033 12.0331L5.14875 9.57851C5.07061 9.50304 5.00828 9.41276 4.96539 9.31293C4.92251 9.21311 4.89994 9.10575 4.899 8.99711C4.89806 8.88848 4.91876 8.78074 4.9599 8.68019C5.00104 8.57963 5.06179 8.48828 5.13861 8.41146C5.21543 8.33464 5.30678 8.27389 5.40733 8.23275C5.50788 8.19161 5.61562 8.17091 5.72426 8.17185C5.8329 8.1728 5.94026 8.19537 6.04008 8.23825C6.1399 8.28113 6.23018 8.34346 6.30566 8.4216L8.2063 10.3222Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3061_14626">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
