import styled from 'styled-components';
import { scrollBar } from '../../../../style/helpers';

export const Tabs = styled.ul<{ $menuIsOpen?: boolean }>`
  display: flex;
  align-items: center;
  padding-right: 14px;
  gap: 9px;
  max-width: ${(p) =>
    p.$menuIsOpen ? 'calc(100vw - 545px)' : 'calc(100vw - 370px)'};
  height: 100%;
  ${scrollBar}
  ::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
  }
`;

export const Calendar = styled.div`
  padding-left: 4px;
  border-left: 1px solid ${(p) => p.theme.colors.border};
`;
