import styled from 'styled-components';

export const Title = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #000;
  span {
    font-weight: 600;
  }
`;
