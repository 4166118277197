import { EditSvg } from '../svg/edit-svg';
import { EditBtnStyled } from './style';

interface EditBtnProps {
  onClick: () => void;
}

export const EditBtn = ({ onClick }: EditBtnProps) => (
  <EditBtnStyled onClick={onClick}>
    <EditSvg />
  </EditBtnStyled>
);
