import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DoughnutChart } from '../../components/doughnut-chart';
import { useTypedSelector } from '../../../../api/store';
import { useGetProjectsStatistcsQuery } from '../../../../api/dashboard-api';
import { ChartsLayout } from '../../components/charts-layout';
import { ChartsSlider } from '../../components/charts-slider';
import { BarChart } from '../../components/bar-chart';
import { splitArr } from '../../../../helpers/split-arr';
import type { DashboardProjects } from '../../../../models/dashboard';
import {
  AppRoute,
  DashboardRoute
} from '../../../../common/enums/app/app-route.enum';
import { useClearDate } from '../../hooks/use-clear-date';
import { Loader } from '../../../../components/loader';
import { groupByRegion } from '../../helpers/group-by-region';

export const AllDashboardProjects = () => {
  useClearDate();
  const navigate = useNavigate();
  const { colors, date } = useTypedSelector((state) => state.dashboard);

  const [activeCity, setActiveCity] = useState({ id: '', name: '' });
  const [activeRegion, setActiveRegion] = useState({ id: '', name: '' });

  const [secondChartIsOpen, setSecondChartIsOpen] = useState(true);

  const { data, isFetching } = useGetProjectsStatistcsQuery(
    {
      params: `?liv_city=${activeCity.name}&liv_region=${activeRegion.name}&created_start=${date.start}&created_end=${date.end}`
    },
    { refetchOnMountOrArgChange: true }
  );

  const doughnutTitle = useMemo(() => {
    if (activeCity.name && activeRegion.name) {
      return `${activeCity.name} та ${activeRegion.name} область`;
    }

    if (activeCity.name) {
      return `${activeCity.name}`;
    }

    if (activeRegion.name) {
      return `${activeRegion.name} область`;
    }

    return `Проєкти (${data?.length || 0})`;
  }, [activeCity, activeRegion, data?.length]);

  if (!data) {
    return <Loader />;
  }

  return (
    <ChartsLayout
      activeCity={activeCity}
      activeRegion={activeRegion}
      setActiveCity={setActiveCity}
      setActiveRegion={setActiveRegion}
      total={data[0] ? data[0].all_contacts : 0}
      secondChartIsOpen={secondChartIsOpen}
      isProject
      isFetching={isFetching}
      dataByRegion={groupByRegion(data[0].all_contacts_map)}
    >
      <DoughnutChart
        title={doughnutTitle}
        data={data.map((item) => ({
          name: item.name,
          value: item.contacts_count,
          onClick: () => {
            navigate(
              `${AppRoute.DASHBOARD}/${DashboardRoute.PROJECTS}/${item.id}/`
            );
          }
        }))}
        colors={colors.map((item) => item.color)}
        totalValue={data[0] ? data[0].all_contacts : 0}
        totalLabel="загальна кількість"
      />
      <ChartsSlider
        title="Вартість проєктів"
        autoHeightChart={{
          isOpen: secondChartIsOpen,
          setIsOpen: setSecondChartIsOpen
        }}
        data={splitArr<DashboardProjects>(data, 4).map((item, i) => ({
          title: '',
          chart: (
            <BarChart
              yMax={Math.max(...data.map((item) => item.budget))}
              data={item.map((subItem) => ({
                name: subItem.name,
                value: subItem.budget
              }))}
              color={splitArr(colors, 4)[i]?.map((item) => item.color)}
              tickMaxWidth={80}
            />
          )
        }))}
      />
    </ChartsLayout>
  );
};
