import type { Dispatch, SetStateAction } from 'react';
import { useRef } from 'react';
import { CloseFilterSvg } from '../svg/close-filter-svg';
import { BtnSecondary } from '../btn-secondary';
import { BtnPrimary } from '../btn-primary';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { FilterItem } from '../filter-tem';
import { CheckBox } from '../checkbox';
import { Input } from '../input';
import type { Filter } from '../../common/types/filters';
import { Select } from '../select';
import {
  CloseIcon,
  FilterFooter,
  FilterHeader,
  FiltersContainer,
  FiltersStyled,
  ItemWrapper,
  SubItemWrapper
} from './style';
import {
  handleChangeCheckbox,
  handleChangeSelect,
  handleChangeText
} from './helpers';

interface FiltersProps {
  setShow: Dispatch<SetStateAction<boolean>>;
  filters: Filter[];
  setFilters: Dispatch<SetStateAction<Filter[]>>;
  onCancel(): void;
  onSubmit(): void;
  disabled?: boolean;
}

export const Filters = ({
  setShow,
  filters,
  setFilters,
  onCancel,
  onSubmit,
  disabled
}: FiltersProps) => {
  const ref = useRef(null);
  const { scrollHeight } = useScrollHeight(ref);

  const renderFilters = (filter: Filter, i: number) => {
    switch (filter.type) {
      case 'checkbox':
        return (
          <FilterItem
            isActive={!!filter.checkList.filter((item) => item.checked).length}
            title={filter.title}
            key={i}
          >
            {filter.checkList.map((item) => {
              if (item.sub) {
                return (
                  <ItemWrapper key={item.id}>
                    <CheckBox
                      fz="12px"
                      alignStart
                      label={item.title}
                      checked={item.checked}
                      setChecked={(checked) =>
                        setFilters((state) =>
                          handleChangeCheckbox({
                            filters: state,
                            currentFilter: filter,
                            checked,
                            id: item.id,
                            currentCheckList: item
                          })
                        )
                      }
                    />
                    {item.checked &&
                      item.sub.map((subList) => (
                        <SubItemWrapper key={subList.id}>
                          <CheckBox
                            fz="12px"
                            alignStart
                            label={subList.title}
                            checked={subList.checked}
                            setChecked={(checked) =>
                              setFilters((state) =>
                                handleChangeCheckbox({
                                  filters: state,
                                  currentFilter: filter,
                                  checked: item.checked,
                                  id: item.id,
                                  currentCheckList: item,
                                  subId: subList.id,
                                  subChecked: checked
                                })
                              )
                            }
                          />
                        </SubItemWrapper>
                      ))}
                  </ItemWrapper>
                );
              } else {
                return (
                  <ItemWrapper key={item.id}>
                    <CheckBox
                      fz="12px"
                      alignStart
                      label={item.title}
                      checked={item.checked}
                      setChecked={(checked) =>
                        setFilters((state) =>
                          handleChangeCheckbox({
                            filters: state,
                            currentFilter: filter,
                            checked,
                            id: item.id,
                            currentCheckList: item
                          })
                        )
                      }
                    />
                  </ItemWrapper>
                );
              }
            })}
          </FilterItem>
        );
      case 'select':
        return (
          <FilterItem
            title={filter.title}
            key={i}
            isActive={
              !!filter.selectList.filter((item) => item.value.id).length
            }
          >
            {filter.selectList.map((item) => (
              <ItemWrapper key={item.id}>
                <Select
                  isStatic
                  value={item.value}
                  placeholder={item.placeholder || item.title}
                  options={item.options}
                  setValue={(value) =>
                    setFilters((state) =>
                      handleChangeSelect({
                        filters: state,
                        currentFilter: filter,
                        value,
                        currentSelectList: item,
                        id: item.id
                      })
                    )
                  }
                />
              </ItemWrapper>
            ))}
          </FilterItem>
        );
      case 'text':
        return (
          <FilterItem key={i} title={filter.title} isActive={!!filter.value}>
            <Input
              mb="8px"
              value={filter.value}
              onChange={(e) =>
                setFilters((state) =>
                  handleChangeText({
                    filters: state,
                    currentFilter: filter,
                    value: e.target.value
                  })
                )
              }
            />
          </FilterItem>
        );
      case 'sublist':
        return (
          <FilterItem
            key={i}
            title={filter.title}
            isActive={
              !!filter.list.filter((item) => {
                if (item.type === 'checkbox') {
                  return !!item.checkList.filter((item) => item.checked).length;
                }
                if (item.type === 'text') {
                  return !!item.value;
                }
                if (item.type === 'select') {
                  return !!item.selectList.filter((item) => item.value.id)
                    .length;
                }

                return false;
              }).length
            }
          >
            {filter.list.map(renderFilters)}
          </FilterItem>
        );
      default:
        return null;
    }
  };

  return (
    <FiltersStyled>
      <FilterHeader>
        Фільтри{' '}
        <CloseIcon onClick={() => setShow(false)}>
          {' '}
          <CloseFilterSvg />
        </CloseIcon>
      </FilterHeader>
      <FiltersContainer
        ref={ref}
        style={{ maxHeight: `${scrollHeight - 75}px` }}
      >
        {filters.map(renderFilters)}
      </FiltersContainer>
      <FilterFooter>
        <BtnSecondary onClick={onCancel}>Скинути</BtnSecondary>
        <BtnPrimary disabled={disabled} onClick={onSubmit}>
          Зберегти
        </BtnPrimary>
      </FilterFooter>
    </FiltersStyled>
  );
};
