import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FormStyled = styled.form`
  width: 100%;
  max-width: 406px;
  margin: 0 auto;
  margin-top: 24px;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 7px;
`;

export const ForgotPassword = styled(Link)`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin: -7px 0 20px;
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: ${(p) => p.theme.colors.primary};
    cursor: pointer;
  }
`;
