import type { FC } from 'react';
import { IconContainer } from '../icon-container';
import { TabItemLink, TabItemStyled } from './style';

interface TabItemProps {
  path?: string;
  title: string;
  icon?: FC;
  $isActive: boolean;
  id?: string;
  onClick?: (id: string) => void;
}

export const TabItem = ({
  path,
  title,
  icon: Icon,
  $isActive,
  id,
  onClick
}: TabItemProps) =>
  path ? (
    <TabItemLink to={path} $isActive={$isActive}>
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      <span>{title}</span>
    </TabItemLink>
  ) : (
    <TabItemStyled
      onClick={onClick && id ? () => onClick(id) : undefined}
      $isActive={$isActive}
    >
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      <span>{title}</span>
    </TabItemStyled>
  );
