export const DragSvg = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 3.75195C5.69036 3.75195 6.25 3.19231 6.25 2.50195C6.25 1.8116 5.69036 1.25195 5 1.25195C4.30964 1.25195 3.75 1.8116 3.75 2.50195C3.75 3.19231 4.30964 3.75195 5 3.75195Z"
      fill="black"
    />
    <path
      d="M5 8.74805C5.69036 8.74805 6.25 8.1884 6.25 7.49805C6.25 6.80769 5.69036 6.24805 5 6.24805C4.30964 6.24805 3.75 6.80769 3.75 7.49805C3.75 8.1884 4.30964 8.74805 5 8.74805Z"
      fill="black"
    />
    <path
      d="M5 13.75C5.69036 13.75 6.25 13.1904 6.25 12.5C6.25 11.8096 5.69036 11.25 5 11.25C4.30964 11.25 3.75 11.8096 3.75 12.5C3.75 13.1904 4.30964 13.75 5 13.75Z"
      fill="black"
    />
    <path
      d="M10 3.75195C10.6904 3.75195 11.25 3.19231 11.25 2.50195C11.25 1.8116 10.6904 1.25195 10 1.25195C9.30964 1.25195 8.75 1.8116 8.75 2.50195C8.75 3.19231 9.30964 3.75195 10 3.75195Z"
      fill="black"
    />
    <path
      d="M10 8.74805C10.6904 8.74805 11.25 8.1884 11.25 7.49805C11.25 6.80769 10.6904 6.24805 10 6.24805C9.30964 6.24805 8.75 6.80769 8.75 7.49805C8.75 8.1884 9.30964 8.74805 10 8.74805Z"
      fill="black"
    />
    <path
      d="M10 13.75C10.6904 13.75 11.25 13.1904 11.25 12.5C11.25 11.8096 10.6904 11.25 10 11.25C9.30964 11.25 8.75 11.8096 8.75 12.5C8.75 13.1904 9.30964 13.75 10 13.75Z"
      fill="black"
    />
  </svg>
);
