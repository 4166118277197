import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageTitle } from '../../components/page-title';
import { SubHeader } from '../../components/subheader';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { useTypedSelector } from '../../api/store';
import { DataTable } from '../../components/data-table';
import { Popup } from '../../components/popup';
import { ButtonAdd } from '../../components/button-add';
import { FlexContainer } from '../../components/flex-container';
import { SubheaderSelect } from '../../components/subheader-select';
import { Filters } from '../../components/filters';
import { FilterSvg } from '../../components/svg/filter-svg';
import { Counter } from '../../components/counter';
import { useFilter } from '../../hooks/use-filter';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { ConfirmPopup } from '../../components/confirm-popup';
import {
  useDeletePartnerMutation,
  useGetPartnersQuery
} from '../../api/partner-api';
import { useGetUserMeQuery } from '../../api/user-api';
import { NoData } from '../../components/no-data';
import { initialFilters, tableHeaders } from './data';
import type { PartnersTable } from './types';
import { AddPartner } from './add-partner';
import { CurrentPartner } from './current-partner';

export const PartnersPage = () => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const tableRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    filters,
    filtersLength,
    showFilter,
    setShowFilter,
    setFilters,
    badges,
    resetFilters,
    aplyFilter
  } = useFilter({
    initialFilters
  });
  const { scrollHeight } = useScrollHeight(tableRef);
  const { pageSize } = useTypedSelector((state) => state['page-data']);
  const { data, isLoading, isFetching } = useGetPartnersQuery(
    {
      params: `?size=${pageSize}&${searchParams.toString()}`
    },
    { refetchOnMountOrArgChange: true }
  );
  const [deletePartner] = useDeletePartnerMutation();
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [showAddPartner, setShowPartner] = useState(false);
  const [showConfirmDelete, setShowConfimDelete] = useState(false);

  const handleDelete = () => {
    deletePartner({ ids: checkedList })
      .unwrap()
      .then(() => {
        notification({
          title: 'Партнера видалено!',
          message: 'Ви успішно видалили обраних партнерів.',
          type: 'success'
        });
        setShowConfimDelete(false);
        setCheckedList([]);
      })
      .catch((err) => {
        notification({
          title: 'Помилка',
          message: getApiError(err),
          type: 'error'
        });
      });
  };

  if (!userMe) {
    return null;
  }

  const canView = userMe.role?.view_partner || userMe?.is_superuser;
  const canAdd = userMe.role?.add_partner || userMe?.is_superuser;
  const canDelete = userMe.role?.delete_partner || userMe?.is_superuser;

  if (!canView) {
    return null;
  }

  return (
    <>
      <SubHeader mt="20px">
        <FlexContainer>
          <PageTitle>Партнери</PageTitle>
          <Counter>{data?.count || 0}</Counter>
          <SubheaderSelect
            show={showFilter}
            setShow={setShowFilter}
            icon={FilterSvg}
            title={`Фільтри (${filtersLength})`}
            isActive={!!filtersLength}
          >
            <Filters
              setFilters={setFilters}
              filters={filters}
              setShow={setShowFilter}
              onCancel={resetFilters}
              onSubmit={aplyFilter}
              disabled={!filtersLength && !badges.length}
            />
          </SubheaderSelect>
        </FlexContainer>
        {canAdd && (
          <ButtonAdd onClick={() => setShowPartner(true)}>
            Новий партнер
          </ButtonAdd>
        )}
      </SubHeader>
      {data && !data.results.length ? (
        <NoData subTitle="" />
      ) : (
        <div ref={tableRef}>
          <DataTable<PartnersTable>
            onRowClick={(id) => {
              searchParams.append('id', `${id}`);
              setSearchParams(searchParams);
            }}
            customHeight={
              (data?.count || 0) >= pageSize ? scrollHeight - 64 : scrollHeight
            }
            actions={[
              { title: 'Видалити', handler: () => setShowConfimDelete(true) }
            ]}
            count={data?.count || 0}
            headers={tableHeaders}
            data={
              data?.results.map((item) => ({
                ...item,
                org_type: item.org_type?.title || '',
                region: item.region?.title || ''
              })) || []
            }
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            withOutCheck={!canDelete}
            isFetching={isFetching || isLoading}
          />
        </div>
      )}
      <Popup
        title="Новий партнер"
        show={showAddPartner}
        setShow={setShowPartner}
      >
        <AddPartner setShow={setShowPartner} />
      </Popup>
      <CurrentPartner id={searchParams.get('id')} />
      <ConfirmPopup
        show={showConfirmDelete}
        title={
          checkedList.length === 1
            ? `Чи дійсно бажаєте видалити партнера ${
                data?.results.find((item) => item.id === checkedList[0])
                  ?.name || ''
              }?`
            : `Чи дійсно бажаєте видалити ${checkedList.length} партнерів`
        }
        setShow={setShowConfimDelete}
        onSubmit={handleDelete}
      />
    </>
  );
};
