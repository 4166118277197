import { useState } from 'react';
import { useTheme } from 'styled-components';
import { ChartsLayout } from '../components/charts-layout';
import { ChartContainer } from '../components/chart-container';
import { useGetParnersStatisticsQuery } from '../../../api/dashboard-api';
import { useTypedSelector } from '../../../api/store';
import { Select } from '../../../components/select';
import type { OptionItem } from '../../../common/types/option-item';
import { Range } from '../components/range';
import { SelectCityWrapper } from '../volunteers/style';
import { DoughnutChart } from '../components/doughnut-chart';
import { DASHBOARD_COLORS } from '../../../common/constants';
import { colorsForChart } from '../helpers/colors-for-chart';
import { BarChart } from '../components/bar-chart';
import { Loader } from '../../../components/loader';
import { groupByRegion } from '../helpers/group-by-region';
import { useGetPartnersCitiesQuery } from '../../../api/partner-api';
import { transformCityList } from '../helpers';

export const DashboardPartnersPage = () => {
  const theme = useTheme();
  const [activeCity, setActiveCity] = useState({ id: '', name: '' });
  const [activeRegion, setActiveRegion] = useState({ id: '', name: '' });
  const { date } = useTypedSelector((state) => state.dashboard);

  const [city, setCity] = useState<OptionItem<{ count: number }>>({
    id: '',
    title: ''
  });

  const { data: partnersCities } = useGetPartnersCitiesQuery('');
  const { data, isFetching } = useGetParnersStatisticsQuery(
    {
      params: `?liv_city=${activeCity.name}&liv_region=${activeRegion.name}&created_start=${date.start}&created_end=${date.end}`
    },
    { refetchOnMountOrArgChange: true }
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <ChartsLayout
      activeCity={activeCity}
      activeRegion={activeRegion}
      setActiveCity={setActiveCity}
      setActiveRegion={setActiveRegion}
      total={data.data.partners_count}
      mt="0"
      isFetching={isFetching}
      dataByRegion={groupByRegion(data.data.map)}
    >
      <ChartContainer title="Кількість організацій">
        <Range
          value={data.data.partners_count}
          total={data.data.partners_count}
          totalAlign="center"
          totalTitle={`${data.data.partners_count}`}
        />
      </ChartContainer>
      <DoughnutChart
        revrse
        title={'Типи організацій'}
        data={data.data.by_org_type.map((item) => {
          const [_, [name, value]] = Object.entries(item);

          return {
            name,
            value
          };
        })}
        colors={colorsForChart(data.data.by_org_type.length, DASHBOARD_COLORS)}
        totalValue={data.data.partners_count}
        totalLabel="загальна кількість"
      />
      <DoughnutChart
        revrse
        title={'За напрямами'}
        data={Object.entries(data.data.by_activity).map(([name, value]) => ({
          name,
          value
        }))}
        colors={colorsForChart(
          Object.entries(data.data.by_activity).length,
          DASHBOARD_COLORS
        )}
        totalValue={data.data.partners_count}
        totalLabel="загальна кількість"
      />
      <ChartContainer title="Місцезнаходження">
        <SelectCityWrapper>
          <Select
            label="Місто"
            options={transformCityList(partnersCities || [])}
            placeholder="Оберіть зі списку"
            value={city}
            setValue={setCity}
          />
          {!!city.id && (
            <BarChart
              tickAlign="center"
              margin={{ left: -50, top: 40 }}
              tickMaxWidth={80}
              barCount={1}
              aspect={1}
              color={theme.colors.primary}
              data={[
                {
                  name: city.title,
                  value: city.restFields?.count || 0
                }
              ]}
            />
          )}
        </SelectCityWrapper>
      </ChartContainer>
    </ChartsLayout>
  );
};
