import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useGetUserMeQuery } from '../../api/user-api';
import { AngleSvg } from '../svg/angle-svg';
import { FlexContainer } from '../flex-container';
import { TrashSvg } from '../svg/trash-svg';
import { EditSvg } from '../svg/edit-svg';
import { dateWithTime } from '../../helpers/date-helpers';
import {
  ActionBtn,
  ActionContainer,
  ActivityItemHeader,
  ActivityItemStyled,
  ActivityItemTitle,
  HeaderDate,
  TitleAngle
} from './style';

interface ActivityItemProps {
  title: ReactNode;
  ownerId?: string;
  createdAt: string;
  expand?: boolean;
  setExpand?: Dispatch<SetStateAction<boolean>>;
  setEdit?: Dispatch<SetStateAction<boolean>>;
  setShowConfirm?: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
}

export const ActivityItem = ({
  title,
  ownerId,
  createdAt,
  expand,
  setExpand,
  children,
  setEdit,
  setShowConfirm
}: ActivityItemProps) => {
  const { data: userMe } = useGetUserMeQuery('');

  const handleExpand = () => {
    if (setExpand) {
      setExpand((state) => !state);
    }
  };

  return (
    <ActivityItemStyled>
      <ActivityItemHeader>
        <ActivityItemTitle onClick={handleExpand}>
          <TitleAngle isOpen={expand}>
            {!!setShowConfirm && <AngleSvg />}
          </TitleAngle>
          {title}
        </ActivityItemTitle>
        <FlexContainer>
          {expand &&
            setEdit &&
            setShowConfirm &&
            ((userMe?.id || '') === ownerId || userMe?.is_superuser) && (
              <ActionContainer>
                <ActionBtn onClick={() => setShowConfirm(true)}>
                  <TrashSvg />
                </ActionBtn>{' '}
                |{' '}
                <ActionBtn onClick={() => setEdit((state) => !state)}>
                  <EditSvg />
                </ActionBtn>
              </ActionContainer>
            )}
          <HeaderDate>{dateWithTime(createdAt)}</HeaderDate>
        </FlexContainer>
      </ActivityItemHeader>
      {children}
    </ActivityItemStyled>
  );
};
