export const ProtectionSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2860_9858)">
      <path
        d="M8 16C7.92845 16 7.85695 15.9846 7.79055 15.9541L6.293 15.263C3.07765 13.7788 1 10.5318 1 6.99052V2.00003C1 1.74588 1.19065 1.53202 1.44335 1.50318C2.4309 1.39038 3.42335 1.22508 4.39255 1.01173C5.5547 0.755875 6.71215 0.425075 7.83325 0.028575C7.94115 -0.009525 8.05885 -0.009525 8.16675 0.028575C9.28785 0.425075 10.4453 0.755875 11.6074 1.01173C12.5766 1.22513 13.569 1.39038 14.5566 1.50318C14.8093 1.53198 15 1.74588 15 2.00003V6.99052C15 10.5318 12.9223 13.7789 9.707 15.263L8.20945 15.9541C8.14305 15.9846 8.0715 16 8 16ZM2 2.44288V6.99052C2 10.1431 3.8496 13.0337 6.7119 14.3548L8 14.9493L9.2881 14.3548C12.1504 13.0337 14 10.1431 14 6.99052V2.44288C13.1252 2.32958 12.2502 2.17703 11.3926 1.98828C10.248 1.73633 9.10745 1.41408 8 1.02978C6.8926 1.41408 5.75195 1.73633 4.6074 1.98828C3.74975 2.17698 2.87475 2.32958 2 2.44288Z"
        fill="#9DABC0"
      />
      <path
        d="M10 11.5H6C5.4485 11.5 5 11.0515 5 10.5V7C5 6.4485 5.4485 6 6 6H10C10.5515 6 11 6.4485 11 7V10.5C11 11.0515 10.5515 11.5 10 11.5ZM6 7V10.5H10.0007L10 7H6Z"
        fill="#9DABC0"
      />
      <path
        d="M9.5 7H6.5C6.2239 7 6 6.7761 6 6.5V5.5C6 4.3972 6.8972 3.5 8 3.5C9.1028 3.5 10 4.3972 10 5.5V6.5C10 6.7761 9.7761 7 9.5 7ZM7 6H9V5.5C9 4.9485 8.5515 4.5 8 4.5C7.4485 4.5 7 4.9485 7 5.5V6Z"
        fill="#9DABC0"
      />
    </g>
    <defs>
      <clipPath id="clip0_2860_9858">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
