export const ActivitySvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33398 3.8335C5.06065 3.8335 4.83398 3.60683 4.83398 3.3335V1.3335C4.83398 1.06016 5.06065 0.833496 5.33398 0.833496C5.60732 0.833496 5.83398 1.06016 5.83398 1.3335V3.3335C5.83398 3.60683 5.60732 3.8335 5.33398 3.8335Z"
      fill="#9DABC0"
    />
    <path
      d="M10.666 3.8335C10.3927 3.8335 10.166 3.60683 10.166 3.3335V1.3335C10.166 1.06016 10.3927 0.833496 10.666 0.833496C10.9393 0.833496 11.166 1.06016 11.166 1.3335V3.3335C11.166 3.60683 10.9393 3.8335 10.666 3.8335Z"
      fill="#9DABC0"
    />
    <path
      d="M9.99935 7.8335H4.66602C4.39268 7.8335 4.16602 7.60683 4.16602 7.3335C4.16602 7.06016 4.39268 6.8335 4.66602 6.8335H9.99935C10.2727 6.8335 10.4993 7.06016 10.4993 7.3335C10.4993 7.60683 10.2727 7.8335 9.99935 7.8335Z"
      fill="#9DABC0"
    />
    <path
      d="M7.99935 10.5H4.66602C4.39268 10.5 4.16602 10.2733 4.16602 10C4.16602 9.72667 4.39268 9.5 4.66602 9.5H7.99935C8.27268 9.5 8.49935 9.72667 8.49935 10C8.49935 10.2733 8.27268 10.5 7.99935 10.5Z"
      fill="#9DABC0"
    />
    <path
      d="M10 15.1668H6C2.25333 15.1668 1.5 13.4002 1.5 10.5468V6.4335C1.5 3.2735 2.56667 1.98683 5.30667 1.8335H10.6667C13.4333 1.98683 14.5 3.2735 14.5 6.4335V10.6668C14.5 10.9402 14.2733 11.1668 14 11.1668C13.7267 11.1668 13.5 10.9402 13.5 10.6668V6.4335C13.5 3.52683 12.5333 2.94016 10.64 2.8335H5.33333C3.46667 2.94016 2.5 3.52683 2.5 6.4335V10.5468C2.5 13.1002 2.98667 14.1668 6 14.1668H10C10.2733 14.1668 10.5 14.3935 10.5 14.6668C10.5 14.9402 10.2733 15.1668 10 15.1668Z"
      fill="#9DABC0"
    />
    <path
      d="M10 15.1665C9.93333 15.1665 9.87333 15.1532 9.80667 15.1265C9.62 15.0465 9.5 14.8665 9.5 14.6665V12.6665C9.5 11.0532 10.3867 10.1665 12 10.1665H14C14.2 10.1665 14.3867 10.2865 14.46 10.4732C14.54 10.6598 14.4933 10.8732 14.3533 11.0198L10.3533 15.0198C10.26 15.1132 10.1333 15.1665 10 15.1665ZM12 11.1665C10.9467 11.1665 10.5 11.6132 10.5 12.6665V13.4598L12.7933 11.1665H12Z"
      fill="#9DABC0"
    />
  </svg>
);
