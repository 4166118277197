import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { Filter } from '../common/types/filters';
import {
  setFiltersFromQueryString,
  transformFilterToObj
} from '../components/filters/helpers';
import { useGetUserRolesQuery, useGetUsersQuery } from '../api/user-api';
import { setBadges } from '../components/filter-badges/helpers';
import { useGetContactChannelsQuery } from '../api/contact-api';
import type { Step } from '../models/event';

interface UseFilterArgs {
  initialFilters: Filter[];
  usersFilterName?: string;
  rolesFilterName?: string;
  channelsFilterName?: string;
  steps?: Step[];
  setCheckedList?: Dispatch<SetStateAction<number[]>>;
}

export const useFilter = ({
  initialFilters,
  usersFilterName,
  rolesFilterName,
  channelsFilterName,
  steps,
  setCheckedList
}: UseFilterArgs) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<Filter[]>(initialFilters);
  const [initialFiltersServerData, setInitialFilterWithServerData] =
    useState<Filter[]>(initialFilters);
  const [showFilter, setShowFilter] = useState(false);

  const { data: users } = useGetUsersQuery(
    { params: '?size=100' },
    { skip: !usersFilterName }
  );
  const { data: roles } = useGetUserRolesQuery('', { skip: !rolesFilterName });
  const { data: channels } = useGetContactChannelsQuery('', {
    skip: !channelsFilterName
  });

  const resetFilters = useCallback(() => {
    Object.keys(transformFilterToObj(filters)).forEach((item) => {
      searchParams.delete(item);
    });
    setSearchParams(searchParams);
    setShowFilter(false);
    setFilters(initialFiltersServerData);
    if (setCheckedList) {
      setCheckedList([]);
    }
  }, [
    setSearchParams,
    filters,
    searchParams,
    initialFiltersServerData,
    setCheckedList
  ]);

  const aplyFilter = () => {
    setSearchParams(transformFilterToObj(filters));
    setShowFilter(false);
    if (setCheckedList) {
      setCheckedList([]);
    }
  };

  useEffect(() => {
    let tempFiltersWithServerData = initialFilters;
    if (usersFilterName && users) {
      const addUsersToFilters = () =>
        initialFilters.map((item) =>
          item.name === usersFilterName
            ? {
                ...item,
                checkList: users.results.map((item) => ({
                  id: item.id,
                  title: `${item.last_name || ''} ${item.first_name || ''} ${
                    item.patronymic || ''
                  }`,
                  checked: false
                }))
              }
            : item
        );
      tempFiltersWithServerData = addUsersToFilters();
    }

    if (rolesFilterName && roles) {
      const addRolesToFilters = () =>
        tempFiltersWithServerData.map((item) =>
          item.name === rolesFilterName
            ? {
                ...item,
                checkList: roles.results.map((item) => ({
                  id: item.id,
                  checked: false,
                  title: item.name,
                  name: `${item.id}`
                }))
              }
            : item
        );
      tempFiltersWithServerData = addRolesToFilters();
    }

    if (channelsFilterName && channels) {
      const addChannelsToFilters = () =>
        tempFiltersWithServerData.map((item) =>
          item.name === channelsFilterName
            ? {
                ...item,
                checkList: channels.results.map((item) => ({
                  id: item.id,
                  checked: false,
                  title: item.name,
                  name: `${item.id}`
                }))
              }
            : item
        );
      tempFiltersWithServerData = addChannelsToFilters();
    }

    if (steps) {
      const addStepsToFilters = () =>
        tempFiltersWithServerData.map((item) => {
          if (item.name === 'current_steps') {
            return {
              ...item,
              checkList:
                steps.map((item) => ({
                  id: item.id,
                  title: item.name,
                  checked: false
                })) || []
            };
          }

          return item;
        });
      tempFiltersWithServerData = addStepsToFilters();
    }

    setFilters(() =>
      setFiltersFromQueryString(
        searchParams.toString(),
        tempFiltersWithServerData
      )
    );
    setInitialFilterWithServerData(() =>
      setFiltersFromQueryString(
        searchParams.toString(),
        tempFiltersWithServerData
      )
    );
  }, [
    searchParams,
    users,
    initialFilters,
    usersFilterName,
    roles,
    rolesFilterName,
    channels,
    channelsFilterName,
    steps
  ]);

  const filtersLength = setBadges(filters, '').length;
  const badges = setBadges(initialFiltersServerData, searchParams.toString());

  return {
    filters,
    setFilters,
    initialFilters: initialFiltersServerData,
    resetFilters,
    aplyFilter,
    showFilter,
    setShowFilter,
    badges,
    filtersLength
  };
};
