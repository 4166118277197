import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { dateToApiFormat, isValidNewDate } from '../helpers/date-helpers';

interface UseDateFilterArgs {
  startDateName: string;
  endDateName: string;
  setCheckedList?: Dispatch<SetStateAction<number[]>>;
}

export const useDateFilter = ({
  startDateName,
  endDateName,
  setCheckedList
}: UseDateFilterArgs) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const applyDate = () => {
    if (startDate) {
      searchParams.set(startDateName, dateToApiFormat(startDate));
      searchParams.set('page', '1');
    }
    if (endDate) {
      searchParams.set(endDateName, dateToApiFormat(endDate));
      searchParams.set('page', '1');
    }
    setSearchParams(searchParams);
    setShowCalendar(false);
    if (setCheckedList) {
      setCheckedList([]);
    }
  };

  const resetDate = () => {
    setStartDate(null);
    setEndDate(null);
    searchParams.delete(startDateName);
    searchParams.delete(endDateName);
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    setShowCalendar(false);
    if (setCheckedList) {
      setCheckedList([]);
    }
  };

  useEffect(() => {
    if (isValidNewDate(searchParams.get(startDateName))) {
      setStartDate(new Date(searchParams.get(startDateName) as string));
    } else {
      setStartDate(null);
    }
    if (isValidNewDate(searchParams.get(endDateName))) {
      setEndDate(new Date(searchParams.get(endDateName) as string));
    } else {
      setEndDate(null);
    }
  }, [searchParams, startDateName, endDateName]);

  return {
    showCalendar,
    setShowCalendar,
    applyDate,
    resetDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  };
};
