import { NoData } from '../../components/no-data';
import { PageTitle } from '../../components/page-title';
import { SubHeader } from '../../components/subheader';

export const ReportsPage = () => (
  <>
    <SubHeader>
      <PageTitle>Звіти</PageTitle>
    </SubHeader>
    <NoData subTitle="Будь ласка, додайте новий контакт або підвантажте файли" />
  </>
);
