export const NoCallSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.84085 23.8301L3.63265 30.0383C3.17278 30.4998 2.42314 30.4998 1.96327 30.0383C1.50183 29.5785 1.50183 28.8288 1.96327 28.3689L27.1614 3.17079C27.6213 2.70935 28.371 2.70935 28.8308 3.17079C29.2923 3.63065 29.2923 4.3803 28.8308 4.84017L15.5782 18.0928C16.4585 18.8173 17.4428 19.4189 18.509 19.8756C18.5893 19.9102 18.6681 19.9433 18.7421 19.9764C18.9815 20.0788 19.2524 20.0819 19.4933 19.9843C19.8162 19.8551 20.2036 19.7008 20.6005 19.5417C22.3943 18.8236 24.4164 18.9448 26.1141 19.8709C26.8008 20.2457 27.5898 20.6756 28.3851 21.1087C29.7568 21.8584 30.6199 23.2868 30.6419 24.8491C30.664 26.4114 29.8435 27.865 28.4938 28.6524C27.7205 29.1044 26.9378 29.5611 26.2181 29.9801C23.925 31.3187 21.095 31.3408 18.7815 30.0383C18.1578 29.6887 17.4759 29.3044 16.7688 28.906C14.2521 27.4917 11.9291 25.7846 9.84085 23.8301ZM13.9009 19.7701L11.5134 22.1592C13.4473 23.9624 15.5986 25.5389 17.9263 26.8476C18.6334 27.2461 19.3154 27.6288 19.939 27.98C21.5218 28.8698 23.4589 28.8556 25.0275 27.9406C25.7472 27.5201 26.5299 27.0634 27.3032 26.6114C27.9174 26.2539 28.2906 25.594 28.2796 24.8837C28.2702 24.1735 27.878 23.523 27.2544 23.1829C26.4575 22.7482 25.67 22.3182 24.9834 21.945C23.903 21.356 22.6179 21.2788 21.4777 21.7355C21.0808 21.8946 20.6934 22.0489 20.3705 22.1781C19.5469 22.5072 18.6271 22.4962 17.8113 22.1466C17.7373 22.1151 17.6586 22.082 17.5783 22.0474C16.2333 21.471 14.997 20.7008 13.9009 19.7701Z"
      fill="#306FE3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.92402 18.241C6.90192 16.9292 5.97431 15.537 5.15222 14.0755C4.75377 13.3683 4.37107 12.6864 4.01987 12.0628C3.13006 10.48 3.14424 8.54289 4.05925 6.97431C4.54431 6.14277 5.0782 5.22776 5.59633 4.33952C5.94281 3.74737 6.57276 3.37727 7.25941 3.36309C7.94606 3.3505 8.59019 3.6954 8.95872 4.27495C9.51308 5.14744 10.0769 6.0341 10.5745 6.81367C11.2958 7.94759 11.3147 9.39176 10.6234 10.543C10.4612 10.8123 10.3037 11.0737 10.1635 11.31C9.59655 12.2533 9.51937 13.4109 9.95247 14.4219C10.1241 14.8204 10.3115 15.211 10.5147 15.5905C10.825 16.1638 11.5415 16.3795 12.1148 16.0693C12.6896 15.7606 12.9038 15.044 12.5951 14.4692C12.425 14.1526 12.2676 13.8266 12.1242 13.4928C12.1242 13.4928 12.1242 13.4928 12.1242 13.4912C11.9888 13.1778 12.014 12.8171 12.1888 12.5242C12.3305 12.2895 12.4865 12.0281 12.6487 11.7588C13.7999 9.83902 13.7684 7.43417 12.5668 5.54589C12.0707 4.76632 11.5069 3.87808 10.9509 3.00717C10.1399 1.73151 8.72406 0.972419 7.21374 1.00077C5.70343 1.03069 4.31753 1.84491 3.55686 3.14891C3.03872 4.03715 2.50484 4.95215 2.01977 5.78369C0.681118 8.07673 0.659069 10.9068 1.9615 13.2203C2.31112 13.844 2.6954 14.5259 3.09384 15.233C3.97263 16.7984 4.96638 18.2883 6.06093 19.6931C6.46095 20.2065 7.20429 20.2994 7.71771 19.8994C8.23269 19.4978 8.32404 18.756 7.92402 18.241Z"
      fill="#306FE3"
    />
  </svg>
);
