import styled from 'styled-components';

export const DragItemStyled = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    text-transform: uppercase;
  }
`;

export const DragIcon = styled.div`
  cursor: move;
`;
