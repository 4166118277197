import styled from 'styled-components';

export const ChartContainerStyled = styled.div<{
  $isOpen: boolean;
  $withNav?: boolean;
}>`
  padding: 20px;
  padding-bottom: ${(p) => (p.$withNav ? '40px' : '20px')};
  border-radius: 9px;
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Title = styled.div<{ $isOpen: boolean }>`
  margin-bottom: ${(p) => (p.$isOpen ? '20px' : 0)};
  display: flex;
  justify-content: space-between;
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  cursor: pointer;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    font-size: 18px;
  }
`;

export const Arrow = styled.div<{ $isOpen: boolean }>`
  svg {
    transform: ${(p) => (p.$isOpen ? 'rotate(0)' : 'rotate(180deg)')};
  }
`;
