export const ExportSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.37349 4.83293C6.50016 4.83293 6.62682 4.78626 6.72682 4.68626L8.08016 3.33293L9.43349 4.68626C9.62682 4.8796 9.94682 4.8796 10.1402 4.68626C10.3335 4.49293 10.3335 4.17293 10.1402 3.9796L8.43349 2.27293C8.24016 2.0796 7.92016 2.0796 7.72682 2.27293L6.02016 3.9796C5.82682 4.17293 5.82682 4.49293 6.02016 4.68626C6.12016 4.78626 6.24682 4.83293 6.37349 4.83293Z"
      fill="#292D32"
    />
    <path
      d="M8.07812 9.95285C8.35146 9.95285 8.57812 9.72619 8.57812 9.45285V2.67285C8.57812 2.39952 8.35146 2.17285 8.07812 2.17285C7.80479 2.17285 7.57812 2.39952 7.57812 2.67285V9.45285C7.57812 9.73285 7.80479 9.95285 8.07812 9.95285Z"
      fill="#292D32"
    />
    <path
      d="M7.9987 13.8333C11.432 13.8333 13.832 11.4333 13.832 8C13.832 7.72667 13.6054 7.5 13.332 7.5C13.0587 7.5 12.832 7.72667 12.832 8C12.832 10.8467 10.8454 12.8333 7.9987 12.8333C5.15203 12.8333 3.16537 10.8467 3.16537 8C3.16537 7.72667 2.9387 7.5 2.66537 7.5C2.39203 7.5 2.16537 7.72667 2.16537 8C2.16537 11.4333 4.56536 13.8333 7.9987 13.8333Z"
      fill="#292D32"
    />
  </svg>
);
