import { useSearchParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { Transition } from 'react-transition-group';
import { CardLayout } from '../../../layouts/card-layout';
import {
  useCreatePartnerNoteMutation,
  useGetCurrentPartnerQuery
} from '../../../api/partner-api';
import { useGetUserMeQuery } from '../../../api/user-api';
import { NewNote } from '../../../components/new-note';
import { NewCall } from '../../../components/new-call';
import { NewMeet } from '../../../components/new-meet';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { CurrentPartnerTabs } from './current-partner-tabs';
import { EditPartner } from './edit-partner';
import { CurrentPartnerSidebar } from './current-partner-sidebar';
import {
  defaultContentStyle,
  defaultStyle,
  duration,
  transitionContentStyles,
  transitionStyles
} from './transition';

interface CurrentPartnerProps {
  id: string | null;
}

export const CurrentPartner = ({ id }: CurrentPartnerProps) => {
  const notification = useNotification();
  const { data: userMe } = useGetUserMeQuery('');
  const [edit, setEdit] = useState(false);
  const [showNewNote, setShowNewNote] = useState(false);
  const [showNewCall, setShowNewCall] = useState(false);
  const [showNewMeet, setShowNewMeet] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { data } = useGetCurrentPartnerQuery(
    { id },
    { refetchOnMountOrArgChange: true, skip: !id }
  );
  const [createNote] = useCreatePartnerNoteMutation();

  const handleClose = useCallback(() => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleAddNote = () => {
    createNote({ partner: id, text: newNote, partner_note_owner: userMe?.id })
      .unwrap()
      .then(() => {
        setNewNote('');
        setShowNewNote(false);
        notification({
          type: 'success',
          title: 'Нотатку додано!',
          message: 'Ви успішно створили нотатку. '
        });
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const canView = userMe?.role?.view_partner || userMe?.is_superuser;

  if (!userMe || !canView) {
    return null;
  }

  return (
    <Transition in={!!id} timeout={duration} unmountOnExit mountOnEnter>
      {(state) => (
        <CardLayout
          onClose={handleClose}
          sidebar={<CurrentPartnerSidebar setEdit={setEdit} partner={data} />}
          transitionStyle={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          transitionContentStyle={{
            ...defaultContentStyle,
            ...transitionContentStyles[state]
          }}
        >
          <CurrentPartnerTabs
            notes={data?.notes || []}
            calls={data?.calls || []}
            meets={data?.meets || []}
            contactId={id || ''}
            setShowNewCall={setShowNewCall}
            setShowNewMeet={setShowNewMeet}
            setShowNewNote={setShowNewNote}
            contactFullName={data?.name || ''}
            files={data?.files || []}
          />
          {!!data && id && id === `${data.id}` && (
            <EditPartner edit={edit} setEdit={setEdit} partner={data} />
          )}
          <NewNote
            show={showNewNote}
            setShow={setShowNewNote}
            value={newNote}
            setValue={setNewNote}
            onSubmit={handleAddNote}
          />
          <NewCall
            show={showNewCall}
            setShow={setShowNewCall}
            id={data?.id || ''}
            fullName={data?.name || ''}
            isPartner
          />
          <NewMeet
            show={showNewMeet}
            setShow={setShowNewMeet}
            id={data?.id || ''}
            fullName={data?.name || ''}
            isPartner
          />
        </CardLayout>
      )}
    </Transition>
  );
};
