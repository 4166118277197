import { SATISFACTION } from '../../../common/constants';
import type { HeaderItem } from '../../../components/data-table';
import type { Filter } from '../../users/types';
import type { CurrentEventsTable } from './types';

export const tableHeaders: HeaderItem<CurrentEventsTable>[] = [
  { id: 'id', title: 'ID', checked: true },
  { id: 'last_name', title: 'Прізвище', checked: true },
  { id: 'first_name', title: "ім'я", checked: true },
  { id: 'patronymic', title: 'ПО БАТЬКОВІ', checked: true },
  { id: 'managers', title: 'менеджер', checked: true },
  { id: 'created_at', title: 'ДАТА потрапляння в базу', checked: true },
  { id: 'events_date', title: 'ДАТА потрапляння в подію', checked: true },
  { id: 'channels', title: 'ДЖЕРЕЛО', checked: true },
  { id: 'current_steps', title: 'ЕТАП ПОДІЇ', checked: true },
  { id: 'satisfaction', title: 'Рівень задоволеності', checked: true }
];

export const initialFilters: Filter[] = [
  {
    type: 'checkbox',
    checkList: [],
    title: 'Відповідальний менеджер',
    name: 'resp_manager'
  },
  {
    type: 'checkbox',
    checkList: [],
    title: 'Джерело',
    name: 'channels'
  },
  {
    type: 'checkbox',
    checkList: [],
    title: 'Етап події',
    name: 'current_steps'
  },
  {
    type: 'checkbox',
    checkList: SATISFACTION.map((item) => ({ ...item, checked: false })),
    title: 'Рівень задоволеності',
    name: 'satisfaction'
  }
];
