import { useRef, useState } from 'react';
import { CustomDatePicker } from '../custom-date-picker';
import { Input } from '../input';
import { CalendarSvg } from '../svg/calendar-svg';
import { useOutsideClick } from '../../hooks/use-outside-click';
import { dateToUIFormat, isValidNewDate } from '../../helpers/date-helpers';
import { CalendarIcon, DatePickerWrapper, InputDateStyled } from './style';

interface InputDateProps {
  value: string;
  setValue: (date: string) => void;
  required?: boolean;
  disabled?: boolean;
  openUp?: boolean;
  label?: string;
  maxDate?: Date;
}

export const InputDate = ({
  value,
  setValue,
  label,
  required,
  disabled,
  openUp,
  maxDate
}: InputDateProps) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => setShowDatePicker(false));

  const handleShowPicker = () => {
    if (!disabled) {
      setShowDatePicker((state) => !state);
    }
  };

  const handleChange = (date: Date | [Date | null, Date | null] | null) => {
    setValue(dateToUIFormat(date as Date));
    setShowDatePicker(false);
  };

  return (
    <InputDateStyled>
      <Input
        label={label || 'Дата'}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        mask="99/99/9999"
        required={required}
        disabled={disabled}
      />
      <CalendarIcon disabled={disabled} onClick={handleShowPicker}>
        <CalendarSvg />
      </CalendarIcon>
      {showDatePicker && (
        <DatePickerWrapper openUp={openUp} ref={ref}>
          <CustomDatePicker
            maxDate={maxDate}
            selected={
              isValidNewDate(value.split('/').reverse().join('-'))
                ? new Date(value.split('/').reverse().join('-'))
                : new Date()
            }
            onChange={handleChange}
          />
        </DatePickerWrapper>
      )}
    </InputDateStyled>
  );
};
