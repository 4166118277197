import type { OptionItem } from '../../../common/types/option-item';
import type { Manager } from '../../../models/project';

export const mapManagers = (
  managers: Manager[] | undefined,
  checkedManagers: OptionItem[]
) =>
  managers
    ?.map((item) => ({
      id: item.id,
      title: `${item.last_name || ''} ${item.first_name || ''} ${
        item.patronymic || ''
      }`
    }))
    ?.filter(
      (item) =>
        !checkedManagers.some((checkedManager) => checkedManager.id === item.id)
    ) || [];
