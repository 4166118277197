import { useTheme } from 'styled-components';

interface PasswordSvgProps {
  $focus?: boolean;
}

export const PasswordSvg = ({ $focus }: PasswordSvgProps) => {
  const theme = useTheme();

  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6875 6.375C4.6875 6.65114 4.91136 6.875 5.1875 6.875C5.46364 6.875 5.6875 6.65114 5.6875 6.375H4.6875ZM5.1875 3.5625H4.6875H5.1875ZM8 0.75V0.25V0.75ZM10.3125 6.375C10.3125 6.65114 10.5364 6.875 10.8125 6.875C11.0886 6.875 11.3125 6.65114 11.3125 6.375H10.3125ZM1.75 6.875H14.25V5.875H1.75V6.875ZM14.25 6.875C14.319 6.875 14.375 6.93096 14.375 7H15.375C15.375 6.37868 14.8713 5.875 14.25 5.875V6.875ZM14.375 7V15.75H15.375V7H14.375ZM14.375 15.75C14.375 15.819 14.319 15.875 14.25 15.875V16.875C14.8713 16.875 15.375 16.3713 15.375 15.75H14.375ZM14.25 15.875H1.75V16.875H14.25V15.875ZM1.75 15.875C1.68096 15.875 1.625 15.819 1.625 15.75H0.625C0.625 16.3713 1.12868 16.875 1.75 16.875V15.875ZM1.625 15.75V7H0.625V15.75H1.625ZM1.625 7C1.625 6.93096 1.68096 6.875 1.75 6.875V5.875C1.12868 5.875 0.625 6.37868 0.625 7H1.625ZM8.4375 11.375C8.4375 11.6166 8.24162 11.8125 8 11.8125V12.8125C8.79391 12.8125 9.4375 12.1689 9.4375 11.375H8.4375ZM8 11.8125C7.75838 11.8125 7.5625 11.6166 7.5625 11.375H6.5625C6.5625 12.1689 7.20609 12.8125 8 12.8125V11.8125ZM7.5625 11.375C7.5625 11.1334 7.75838 10.9375 8 10.9375V9.9375C7.20609 9.9375 6.5625 10.5811 6.5625 11.375H7.5625ZM8 10.9375C8.24162 10.9375 8.4375 11.1334 8.4375 11.375H9.4375C9.4375 10.5811 8.79391 9.9375 8 9.9375V10.9375ZM5.6875 6.375V3.5625H4.6875V6.375H5.6875ZM5.6875 3.5625C5.6875 2.94919 5.93114 2.36099 6.36482 1.92732L5.65771 1.22021C5.03649 1.84142 4.6875 2.68397 4.6875 3.5625H5.6875ZM6.36482 1.92732C6.79849 1.49364 7.38669 1.25 8 1.25V0.25C7.12147 0.25 6.27892 0.598995 5.65771 1.22021L6.36482 1.92732ZM8 1.25C8.61331 1.25 9.20151 1.49364 9.63518 1.92732L10.3423 1.22021C9.72108 0.598995 8.87853 0.25 8 0.25V1.25ZM9.63518 1.92732C10.0689 2.36099 10.3125 2.94919 10.3125 3.5625H11.3125C11.3125 2.68397 10.9635 1.84142 10.3423 1.22021L9.63518 1.92732ZM10.3125 3.5625V6.375H11.3125V3.5625H10.3125Z"
        fill={$focus ? theme?.colors.blue.dark : theme?.colors.grey.light}
      />
    </svg>
  );
};
