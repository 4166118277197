export const ArrowPaginationSvg = () => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.60948 2.38995C8.13017 1.84321 8.13017 0.956786 7.60948 0.410051C7.08878 -0.136683 6.24456 -0.136683 5.72386 0.410051L0.390524 6.01005C-0.130175 6.55679 -0.130175 7.44321 0.390524 7.98995L5.72386 13.5899C6.24456 14.1367 7.08878 14.1367 7.60948 13.5899C8.13017 13.0432 8.13017 12.1568 7.60948 11.6101L3.21895 7L7.60948 2.38995Z"
      fill="#9DABC0"
    />
  </svg>
);
