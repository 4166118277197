import type { Dispatch, FormEventHandler, SetStateAction } from 'react';
import { Select } from '../../../../components/select';
import type { OptionItem } from '../../../../common/types/option-item';
import { FormBtns } from '../../../../components/form-btns';
import { Descr, UserRatingStyled } from './style';

interface UserRatingProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
  setShow: Dispatch<SetStateAction<boolean>>;
  rateDischarged: OptionItem;
  setRateDischarged: Dispatch<SetStateAction<OptionItem>>;
}

export const UserRating = ({
  onSubmit,
  setShow,
  rateDischarged,
  setRateDischarged
}: UserRatingProps) => (
  <UserRatingStyled onSubmit={onSubmit}>
    <Descr>
      Перед звіленням даного співробітника, будь ласка, оцініть його по шкалі
      від 1 до 5 (де 5 - найвища оцінка, а 1 - найнижча):
    </Descr>
    <Select
      mb="20px"
      value={rateDischarged}
      setValue={setRateDischarged}
      options={new Array(5)
        .fill('')
        .map((_, i) => ({ id: `${i + 1}`, title: `${i + 1}` }))
        .reverse()}
    />
    <FormBtns onCancel={() => setShow(false)} />
  </UserRatingStyled>
);
