import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

interface CheckBoxStyledProps {
  checked: boolean;
  disabled?: boolean;
  alignStart?: boolean;
}

export const CheckBoxLabel = styled.label<{
  disabled?: boolean;
  fw?: string;
  fz?: string;
  alignStart?: boolean;
  fullWidth?: boolean;
}>`
  display: flex;
  ${(p) => (p.fullWidth ? `width: 100%; height: 100%;` : '')}
  align-items: ${(p) => (p.alignStart ? 'flex-start' : 'center')};
  font-weight: ${(p) => p.fw || '400'};
  font-size: ${(p) => p.fz || '14px'};
  line-height: 1.4;
  color: ${(p) => p.theme.colors.blue.dark};
  span {
    margin-left: 8px;
  }
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
`;

export const CheckBoxStyled = styled.div<CheckBoxStyledProps>`
  input {
    display: none;
  }
  margin-top: ${(p) => (p.alignStart ? '2px' : 0)};
  width: 14px;
  min-width: 14px;
  height: 14px;
  border: 1px solid
    ${(p) =>
      p.checked ? p.theme.colors.blue.primary : p.theme.colors.grey.input};
  border-radius: 4px;
  opacity: ${(p) => (p.disabled ? '0.7' : '1')};
  background: ${(p) =>
    p.checked ? p.theme.colors.blue.primary : p.theme.colors.white};
  ${centerFlex}
  svg {
    display: ${(p) => (p.checked ? 'block' : 'none')};
  }
  :hover {
    border: 1px solid
      ${(p) =>
        p.checked ? p.theme.colors.blue.primary : p.theme.colors.grey.light};
  }
`;
