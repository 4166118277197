export const ColumnsSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3622_52359)">
      <path
        d="M13.5013 0.666992H2.5013C1.49022 0.666992 0.667969 1.48924 0.667969 2.50033V13.5003C0.667969 14.5114 1.49022 15.3337 2.5013 15.3337H13.5013C14.5124 15.3337 15.3346 14.5114 15.3346 13.5003V2.50033C15.3346 1.48924 14.5124 0.666992 13.5013 0.666992ZM2.5013 1.88921H13.5013C13.8383 1.88921 14.1124 2.1633 14.1124 2.50033V4.94477H1.89019V2.50033C1.89019 2.1633 2.16427 1.88921 2.5013 1.88921ZM9.83464 6.16699V14.1114H6.16797V6.16699H9.83464ZM1.89019 13.5003V6.16699H4.94575V14.1114H2.5013C2.16427 14.1114 1.89019 13.8374 1.89019 13.5003ZM13.5013 14.1114H11.0569V6.16699H14.1124V13.5003C14.1124 13.8374 13.8383 14.1114 13.5013 14.1114Z"
        fill="#9DABC0"
      />
    </g>
    <defs>
      <clipPath id="clip0_3622_52359">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
