import styled from 'styled-components';

export const AddUserStyled = styled.form`
  padding-top: 20px;
`;

export const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 30px;
  padding-bottom: 20px;
  align-items: start;
`;

export const InputsGroupSingle = styled.div<{ mt?: string }>`
  margin-top: ${(p) => p.mt || 0};
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

export const InputsGroupDouble = styled.div<{ mt?: string }>`
  margin-top: ${(p) => p.mt || 0};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

export const InputsGroupTitle = styled.p<{ fz?: string }>`
  font-weight: 600;
  font-size: ${(p) => p.fz || '16px'};
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const InputsGroupDoubleTitle = styled(InputsGroupTitle)`
  grid-column: span 2;
`;

export const SpanTwoColumns = styled.div`
  grid-column: span 2;
`;
