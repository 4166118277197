import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { TabItem } from '../../../../components/tab-item';
import { History } from '../../../../components/history';
import { ViewProjects } from '../../../../components/view-projects';
import type { ServerFile } from '../../../../common/types/server-file';
import { PageFiles } from '../../../../components/page-files';
import {
  useDeleteContactCallMutation,
  useDeleteContactMeetMutation,
  useDeleteContactNoteMutation,
  useEditContactCallMutation,
  useEditContactMeetMutation,
  useEditContactMutation,
  useEditContactNoteMutation,
  useGetContactActivitiesQuery
} from '../../../../api/contact-api';
import type { Contact } from '../../../../models/contact';
import { Activity } from '../../../../components/activity';
import type { Note } from '../../../../models/note';
import type { Call } from '../../../../models/call';
import type { Meet } from '../../../../models/meet';
import type { Project } from '../../../../models/project';
import type { IEvent } from '../../../../models/event';
import { AppRoute } from '../../../../common/enums/app/app-route.enum';
import { useGetUserMeQuery } from '../../../../api/user-api';
import { CallHistory } from '../../../../components/call-history';
import { checkIsManager } from '../../../../helpers/is-manager';
import { isFieldInProject } from '../../../../helpers/is-field-allowed';
import { ScrollContainer, TabContent, TabsStyled } from './style';
import { activityTabs, tabList } from './data';

interface CurrentContactTabsProps {
  files?: ServerFile[] | null;
  passportFiles?: ServerFile[] | null;
  innFiles?: ServerFile[] | null;
  contactId: string | number;
  setShowNewNote: Dispatch<SetStateAction<boolean>>;
  notes: Note[];
  setShowNewCall: Dispatch<SetStateAction<boolean>>;
  calls: Call[];
  setShowNewMeet: Dispatch<SetStateAction<boolean>>;
  meets: Meet[];
  contactFullName: string;
  phone?: string;
  projects?: Project[] | null;
  events?: IEvent[] | null;
  dbFiles?: ServerFile[];
  allowedFields?: string[];
}

export const CurrentContactTabs = ({
  files,
  contactId,
  passportFiles,
  innFiles,
  setShowNewNote,
  setShowNewCall,
  setShowNewMeet,
  notes,
  calls,
  meets,
  contactFullName,
  phone,
  projects,
  events,
  dbFiles,
  allowedFields
}: CurrentContactTabsProps) => {
  const { data: userMe } = useGetUserMeQuery('');
  const [currentTab, setCurrentTab] = useState('views');
  const [editContact, { isLoading }] = useEditContactMutation();
  const [deleteCall] = useDeleteContactCallMutation();
  const [editCall] = useEditContactCallMutation();
  const [editMeet] = useEditContactMeetMutation();
  const [deleteMeet] = useDeleteContactMeetMutation();
  const [editNote] = useEditContactNoteMutation();
  const [deleteNote] = useDeleteContactNoteMutation();
  const { data: activities, isLoading: activitiesLoading } =
    useGetContactActivitiesQuery({ id: contactId }, { skip: !contactId });

  const isManager = checkIsManager(userMe);

  if (!userMe) {
    return null;
  }

  const renderContent = () => {
    switch (currentTab) {
      case 'views':
        return (
          <ScrollContainer>
            <History
              histories={activities || { activities: [] }}
              isLoading={activitiesLoading}
            />
            <CallHistory
              contactId={contactId}
              contactFullName={contactFullName}
            />
            {!isManager && (
              <ViewProjects
                title="Проєкти"
                items={projects?.map((item) => ({
                  title: item.name,
                  link: `${AppRoute.PROJECTS}/${item.id}`
                }))}
              />
            )}
            <ViewProjects
              title="Події"
              items={
                isManager
                  ? events
                      ?.filter((item) =>
                        item.managers?.some((item) => item.id === userMe.id)
                      )
                      .map((item) => ({
                        title: item.name,
                        link: `${AppRoute.PROJECTS}${AppRoute.EVENTS}/${item.id}`
                      }))
                  : events?.map((item) => ({
                      title: item.name,
                      link: `${AppRoute.PROJECTS}${AppRoute.EVENTS}/${item.id}`
                    }))
              }
            />
          </ScrollContainer>
        );
      case 'activity':
        return (
          <Activity
            notes={notes}
            tabs={activityTabs}
            setShowNewNote={setShowNewNote}
            calls={calls}
            setShowNewCall={setShowNewCall}
            contactFullName={contactFullName}
            contactId={contactId}
            meets={meets}
            setShowNewMeet={setShowNewMeet}
            phone={phone}
            editCall={editCall}
            deleteCall={deleteCall}
            deleteMeet={deleteMeet}
            editMeet={editMeet}
            editNote={editNote}
            deleteNote={deleteNote}
          />
        );
      case 'files':
        return (
          <PageFiles<Contact>
            contactId={contactId}
            files={files || []}
            passportFiles={passportFiles || []}
            innFiles={innFiles || []}
            editTrigger={editContact}
            isLoading={isLoading}
            dbFiles={dbFiles}
            isDeleteLoading={isLoading}
          />
        );
      default:
        return (
          <ScrollContainer>
            <History
              histories={activities || { activities: [] }}
              isLoading={activitiesLoading}
            />
            <ViewProjects title="Проєкти" />
            <ViewProjects title="Події" />
          </ScrollContainer>
        );
    }
  };

  return (
    <>
      <TabsStyled>
        {tabList
          .filter((item) =>
            item.id === 'files'
              ? isFieldInProject('files', allowedFields)
              : true
          )
          .map((item, i) => (
            <TabItem
              onClick={setCurrentTab}
              $isActive={item.id === currentTab}
              {...item}
              key={i}
            />
          ))}
      </TabsStyled>
      <TabContent>{renderContent()}</TabContent>
    </>
  );
};
