import { generateRandomColor } from '../../../helpers/generate-random-color';

export const colorsForChart = (
  length: number,
  initialColors: string[]
): string[] => {
  if (!length) {
    return [];
  }

  const uniqueColors = new Set(initialColors);

  while (uniqueColors.size < length) {
    const rndColor = generateRandomColor();
    uniqueColors.add(rndColor);
  }

  return Array.from(uniqueColors);
};
