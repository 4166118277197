import styled from 'styled-components';
import { scrollBar } from '../../style/helpers';

export const FiltersStyled = styled.div`
  background: ${(p) => p.theme.colors.white};
  box-shadow: -3px 0px 10px rgba(63, 81, 126, 0.11),
    3px 0px 10px rgba(63, 81, 126, 0.11);
  border-radius: 6px;
  min-width: 288px;
`;

export const FilterHeader = styled.div`
  padding: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  border-bottom: 1px solid ${(p) => p.theme.colors.grey.table};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIcon = styled.div`
  cursor: pointer;
`;

export const FiltersContainer = styled.div`
  max-height: 500px;
  ${scrollBar}
`;

export const FilterFooter = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  border-top: 1px solid ${(p) => p.theme.colors.grey.table};
`;

export const ItemWrapper = styled.div`
  margin-bottom: 12px;
`;

export const SubItemWrapper = styled.div`
  margin-top: 12px;
  padding-left: 20px;
`;
