import styled from 'styled-components';
import { scrollBar } from '../../style/helpers';

export const SearchStyled = styled.div`
  position: relative;
  width: 100%;
  max-width: 350px;
`;

export const SearchIcon = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 11px;
  top: calc(50% - 8px);
  svg {
    max-width: 100%;
  }
`;

export const IconClose = styled(SearchIcon)`
  right: 11px;
  left: unset;
  cursor: pointer;
  svg {
    max-width: 12px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  display: block;
  padding: 12px 45px;
  height: 40px;
  border: 1px solid ${(p) => p.theme.colors.grey.input};
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
  ::placeholder {
    color: ${(p) => p.theme.colors.grey.light};
  }
  :focus {
    border: 1px solid ${(p) => p.theme.colors.blue.primary};
  }
  :focus + ${SearchIcon} {
    svg {
      path {
        fill: ${(p) => p.theme.colors.blue.dark};
      }
    }
  }
`;

export const SelectOptions = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: calc(100% + 6px);
  z-index: 1;
  border: 1px solid #e7e9f6;
  box-shadow: -3px -3px 9px rgba(63, 81, 126, 0.06),
    4px 5px 9px rgba(63, 81, 126, 0.08);
  border-radius: 6px;
  background: ${(p) => p.theme.colors.white};
  max-height: 300px;
  ${scrollBar}
`;

export const SelectOptionItem = styled.div<{ openUp?: boolean }>`
  padding: 9px 24px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
  background-color: ${(p) => p.theme.colors.white};
  transition: background-color 0.2s;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.blue.bg};
    transition: background-color 0.2s;
  }
`;

export const SearchName = styled.div`
  text-align: end;
`;
