import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useContactsDelegateMutation,
  useDeleteContactMutation,
  useGetContactsQuery,
  useGetCurrentContactQuery
} from '../../api/contact-api';
import { DataTable } from '../../components/data-table';
import { FlexContainer } from '../../components/flex-container';
import { SubHeader } from '../../components/subheader';
import type { DynamicContact } from '../../models/contact';
import { setYearsLabel } from '../../helpers/set-yars-label';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { ConfirmPopup } from '../../components/confirm-popup';
import { NoData } from '../../components/no-data';
import { useGetUserMeQuery } from '../../api/user-api';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { useScrollHeight } from '../../hooks/use-scroll-height';
import { TableLoader } from '../../components/data-table/table-loader';
import { tableHeaders } from '../contacts/data';
import { transformcontactData } from '../contacts/helpers';
import { Delegate } from '../contacts/delegate';
import { checkIsManager } from '../../helpers/is-manager';
import { isFieldAllowed } from '../../helpers/is-field-allowed';
import { MailingListPopup } from '../../components/mailing-list-popup';
import { setPhoneSearchTerm } from '../../api/page-data-slice';
import { Title } from './style';

export const SearchPage = () => {
  const tableRef = useRef(null);
  const dispatch = useAppDispatch();
  const { scrollHeight, manuallySetScrollHeight } = useScrollHeight(tableRef);
  const { pageSize, phoneSearchTerm } = useTypedSelector(
    (state) => state['page-data']
  );
  const { data: userMe } = useGetUserMeQuery('');
  const [searchParams, setSearhParams] = useSearchParams();
  const notification = useNotification();
  const [showMailingList, setShowMailingList] = useState(false);
  const [showDelegate, setShowDelegate] = useState(false);
  const { data, isLoading, isFetching, isSuccess } = useGetContactsQuery({
    params: `?size=${pageSize}&phone_search=${encodeURIComponent(
      phoneSearchTerm
    )}&page=${searchParams.get('page') || 1}`
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [checkedContactId, setCheckedContactId] = useState<number | null>(null);
  const [deleteContact] = useDeleteContactMutation();
  const [delegate] = useContactsDelegateMutation();
  const { data: checkedContact } = useGetCurrentContactQuery(
    { id: checkedContactId },
    { skip: !checkedContactId, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isSuccess) {
      manuallySetScrollHeight();
    }
  }, [manuallySetScrollHeight, isSuccess]);

  useEffect(
    () => () => {
      dispatch(setPhoneSearchTerm(''));
    },
    [dispatch]
  );

  const findMaxChildren = () => {
    if (data?.results.length) {
      return Math.max(
        ...data.results.map((item) =>
          item.children_num ? +item.children_num : 0
        )
      );
    }

    return 0;
  };

  const handleDelete = () => {
    deleteContact({ ids: checkedList })
      .unwrap()
      .then(() => {
        setCheckedList([]);
        setConfirmMessage('');
        setShowConfirm(false);
        setCheckedContactId(null);
        notification({
          type: 'success',
          title:
            checkedList.length === 1
              ? 'Контакт видалено'
              : `${checkedList.length} Контактів видалено`,
          message: ''
        });
        const currentPage = searchParams.get('page');
        if (
          checkedList.length >= (data?.results.length || 0) &&
          currentPage &&
          currentPage !== '1' &&
          !isNaN(+currentPage)
        ) {
          searchParams.set('page', `${+currentPage - 1}`);
          setSearhParams(searchParams);
        }
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleConfirm = () => {
    setShowConfirm(true);
    if (checkedList.length === 1) {
      if (checkedContact && checkedList[0] === checkedContactId) {
        setConfirmMessage(
          `Чи дійсно бажаєте видалити контакт ${checkedContact.full_name}`
        );
      } else {
        setCheckedContactId(checkedList[0]);
      }

      return;
    }
    setConfirmMessage(
      `Чи дійсно бажаєте видалити ${checkedList.length} контактів`
    );
  };

  useEffect(() => {
    if (checkedContact) {
      setConfirmMessage(
        `Чи дійсно бажаєте видалити співробітника ${checkedContact.full_name}`
      );
    }
  }, [checkedContact]);

  const handleDelegate = ({
    manager,
    project,
    event
  }: {
    manager: string | number;
    project: string | number;
    event: string | number;
  }) => {
    delegate({
      delegate: checkedList.join(','),
      new_owner: manager,
      to_project: project,
      to_event: event
    })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Контакти делеговано!',
          message: 'Ви успішно делегували обрані контакти. '
        });
        setShowDelegate(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const canView = userMe?.role?.view_contact || userMe?.is_superuser;

  if (!userMe || !canView) {
    return null;
  }

  const canRemove = userMe.role?.delete_contact || userMe.is_superuser;
  const isManager = checkIsManager(userMe);

  if (!data) {
    return <TableLoader />;
  }

  return (
    <>
      <SubHeader>
        <FlexContainer>
          <Title>
            Результати пошуку для “<span>{phoneSearchTerm}</span>”
          </Title>
        </FlexContainer>
      </SubHeader>
      {(!userMe?.role?.view_contact && !userMe?.is_superuser) ||
      (data && !data.results.length) ? (
        <NoData
          title="Результатів не знайдено"
          subTitle="Жодних свпівпадінь по Вашому запиту не знайдено. Будь ласка, спробуйте ще раз."
        />
      ) : (
        <div ref={tableRef}>
          {' '}
          <DataTable<DynamicContact>
            onRowClick={(id) => {
              searchParams.set('contact_id', `${id}`);
              setSearhParams(searchParams);
            }}
            isFetching={isLoading || isFetching}
            actions={
              isManager
                ? [
                    {
                      title: 'Додати до розсилки',
                      handler: () => setShowMailingList(true)
                    }
                  ]
                : canRemove
                ? [
                    {
                      title: 'Додати до розсилки',
                      handler: () => setShowMailingList(true)
                    },
                    {
                      title: 'Додати в проєкт',
                      handler: () => setShowDelegate(true)
                    },
                    { title: 'Видалити', handler: handleConfirm }
                  ]
                : [{ title: 'Делегувати' }, { title: 'Додати до розсилки' }]
            }
            customHeight={
              data.count >= pageSize ? scrollHeight - 64 : scrollHeight
            }
            headers={tableHeaders
              .filter((item) =>
                isFieldAllowed(item.id as string, userMe, item.subFields)
              )
              .map((item) =>
                item.id === 'children_num'
                  ? {
                      ...item,
                      subFields: new Array(findMaxChildren())
                        .fill('')
                        .map((_, i) => ({
                          id: `child${i + 1}`,
                          title: `Дит ${i + 1}`
                        }))
                    }
                  : item
              )}
            data={transformcontactData(data?.results).map((item) =>
              item.children_num
                ? {
                    ...item,
                    ...Object.assign(
                      {},
                      ...item.children.map((child, i) => ({
                        [`child${i + 1}`]: `${child.gender.title}, ${
                          child.age
                        } ${setYearsLabel(child.age)}`
                      }))
                    )
                  }
                : item
            )}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            count={data.count}
          />
          <ConfirmPopup
            show={showConfirm}
            title={confirmMessage}
            setShow={setShowConfirm}
            onSubmit={handleDelete}
          />
        </div>
      )}
      <Delegate
        onSubmit={handleDelegate}
        show={showDelegate}
        setShow={setShowDelegate}
      />
      <MailingListPopup
        show={showMailingList}
        setShow={setShowMailingList}
        contactIds={checkedList}
      />
    </>
  );
};
