export const SatisfactionNegativeSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.58879 0 0 3.58879 0 8C0 12.4112 3.58879 16 8 16C12.4112 16 15.9999 12.4112 15.9999 8C15.9999 3.58879 12.4112 0 8 0ZM8 14.9359C4.17546 14.9359 1.06402 11.8245 1.06402 8C1.06402 4.17553 4.17546 1.06408 8 1.06408C11.8245 1.06408 14.9359 4.17553 14.9359 8C14.9359 11.8245 11.8245 14.9359 8 14.9359ZM6.6221 6.17767C6.6221 5.32467 5.9281 4.63066 5.07509 4.63066C4.22201 4.63066 3.52801 5.32467 3.52801 6.17767C3.52801 7.03068 4.22201 7.72469 5.07509 7.72469C5.9281 7.72469 6.6221 7.03068 6.6221 6.17767ZM4.59203 6.17767C4.59203 5.91134 4.80869 5.69468 5.07509 5.69468C5.34142 5.69468 5.55808 5.91134 5.55808 6.17767C5.55808 6.44401 5.34142 6.66067 5.07509 6.66067C4.80875 6.66067 4.59203 6.44401 4.59203 6.17767ZM10.9248 4.63073C10.0718 4.63073 9.37777 5.32473 9.37777 6.17774C9.37777 7.03075 10.0718 7.72475 10.9248 7.72475C11.7778 7.72475 12.4718 7.03075 12.4718 6.17774C12.4718 5.32473 11.7778 4.63073 10.9248 4.63073ZM10.9248 6.66067C10.6584 6.66067 10.4418 6.44401 10.4418 6.17767C10.4418 5.91134 10.6584 5.69468 10.9248 5.69468C11.1911 5.69468 11.4078 5.91134 11.4078 6.17767C11.4078 6.44401 11.1911 6.66067 10.9248 6.66067ZM11.5742 10.7853C11.7844 10.9905 11.7884 11.3273 11.5832 11.5376C11.479 11.6443 11.3407 11.6979 11.2025 11.6979C11.0684 11.6979 10.9344 11.6476 10.8309 11.5466C10.0693 10.8031 9.06395 10.3936 8.00007 10.3936C6.93605 10.3936 5.93069 10.8031 5.16912 11.5466C4.95891 11.7519 4.62208 11.7478 4.41686 11.5376C4.21164 11.3273 4.21563 10.9905 4.42591 10.7853C5.38738 9.8466 6.65675 9.32962 8.00013 9.32962C9.34332 9.32969 10.6126 9.8466 11.5742 10.7853Z"
      fill="#171C3D"
    />
  </svg>
);
