import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { TextAreaStyled } from './style';

interface TextAreaProps {
  placeholder?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  required?: boolean;
}

export const ActivityTextArea = ({
  value,
  setValue,
  placeholder,
  required
}: TextAreaProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <TextAreaStyled
      ref={ref}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder}
      required={required}
    ></TextAreaStyled>
  );
};
