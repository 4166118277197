import styled from 'styled-components';
import { scrollBar } from '../../style/helpers';

export const EditColumnsStyled = styled.div`
  width: 288px;
  background: #ffffff;
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  box-shadow: 3px 0px 10px rgba(63, 81, 126, 0.11),
    0px 4px 8px rgba(63, 81, 126, 0.09);
  border-radius: 6px;
`;

export const EditColumnsHeader = styled.div`
  padding: 20px 16px 8px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const SubTitle = styled.div`
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const ColumnsContainer = styled.div`
  margin: 8px 0;
  max-height: 280px;
  ${scrollBar}
`;

export const EditColumnsFooter = styled.div`
  padding: 16px;
  border-top: 1px solid ${(p) => p.theme.colors.grey.table};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;
