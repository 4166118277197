import { useCallback, useEffect, useRef, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { DataTable } from '../../../components/data-table';
import {
  useDeleteUserMutation,
  useGetCurrentUserQuery,
  useGetUserMeQuery,
  useGetUsersQuery
} from '../../../api/user-api';
import { useNotification } from '../../../hooks/use-notification';
import { getApiError } from '../../../helpers/get-api-error';
import { ConfirmPopup } from '../../../components/confirm-popup';
import { NoData } from '../../../components/no-data';
import { TrashSvg } from '../../../components/svg/trash-svg';
import { CurrentUser } from '../current-user';
import { useScrollHeight } from '../../../hooks/use-scroll-height';
import { useTypedSelector } from '../../../api/store';
import { filterQueryParams } from '../../../helpers/filter-query-params';
import { tableHeaders } from './data';
import type { UsersTable } from './types';
import { transformUserData } from './helpers';

export const UsersData = () => {
  const tableRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { badgesLength, showAllBadges } = useOutletContext<{
    badgesLength: number;
    showAllBadges: boolean;
  }>();
  const { scrollHeight, manuallySetScrollHeight } = useScrollHeight(tableRef);
  const notification = useNotification();

  const { pageSize } = useTypedSelector((state) => state['page-data']);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [checkedUserId, setCheckedUsrId] = useState<number | null>(null);
  const [deleteUser] = useDeleteUserMutation();
  const { data: userMe } = useGetUserMeQuery('');
  const { data, isLoading, isFetching } = useGetUsersQuery(
    {
      params: `?size=${pageSize}&${filterQueryParams(
        searchParams.toString(),
        'id'
      )}`
    },
    { refetchOnMountOrArgChange: true }
  );
  const { data: checkedUser } = useGetCurrentUserQuery(
    { id: checkedUserId },
    { skip: !checkedUserId, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    manuallySetScrollHeight();
  }, [badgesLength, showAllBadges, manuallySetScrollHeight]);

  const handleDelete = () => {
    deleteUser({ ids: checkedList })
      .unwrap()
      .then(() => {
        setCheckedList([]);
        setConfirmMessage('');
        setShowConfirm(false);
        setCheckedUsrId(null);
        notification({
          type: 'success',
          title:
            checkedList.length === 1
              ? 'Співробітника видалено'
              : 'Співробітників видалено',
          message: ''
        });
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleConfirm = useCallback(() => {
    setShowConfirm(true);
    if (checkedList.length === 1) {
      if (checkedUser && checkedList[0] === checkedUserId) {
        setConfirmMessage(
          `Чи дійсно бажаєте видалити співробітника ${checkedUser.last_name} ${checkedUser.first_name} ${checkedUser.patronymic}`
        );
      } else {
        setCheckedUsrId(checkedList[0]);
      }

      return;
    }
    setConfirmMessage(
      `Чи дійсно бажаєте видалити ${checkedList.length} співробітників`
    );
  }, [checkedList, checkedUser, checkedUserId]);

  useEffect(() => {
    if (checkedUser) {
      setConfirmMessage(
        `Чи дійсно бажаєте видалити співробітника ${checkedUser.full_name}`
      );
    }
  }, [checkedUser]);

  return (userMe && userMe.role?.view_user) || userMe?.is_superuser ? (
    <div ref={tableRef}>
      {data?.count ? (
        <>
          <DataTable<UsersTable>
            count={data?.count}
            customHeight={
              data?.count && data.count >= pageSize
                ? scrollHeight - 64
                : scrollHeight
            }
            onRowClick={(id) => {
              searchParams.append('id', `${id}`);
              setSearchParams(searchParams);
            }}
            actions={
              (userMe && userMe?.role?.delete_user) || userMe?.is_superuser
                ? [
                    {
                      title: 'Видалити',
                      icon: TrashSvg,
                      handler: handleConfirm
                    }
                  ]
                : []
            }
            headers={tableHeaders}
            data={transformUserData(data?.results)}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            isFetching={isLoading || isFetching}
          />
          <ConfirmPopup
            customWidth="465px"
            show={showConfirm}
            title={confirmMessage}
            setShow={setShowConfirm}
            onSubmit={handleDelete}
          />
          <CurrentUser id={searchParams.get('id')} />
        </>
      ) : (
        <NoData customHeight="calc(100vh - 300px)" />
      )}
    </div>
  ) : (
    <NoData customHeight="calc(100vh - 250px)" />
  );
};
