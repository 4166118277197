import { ApiPath } from '../common/enums/http/api-path.enum';
import { HttpMethod } from '../common/enums/http/http-method.enum';
import type { BinotelCall } from '../models/binotel-call';
import type { CallRecord } from '../models/call-record';
import { commonApi } from './common-api';

export const binotelApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBinotelCalls: builder.query<BinotelCall, { id: number | string }>({
      query: ({ id }) => `${ApiPath.CONTACT_CALLS_HISTORY}${ApiPath.ROOT}${id}`
    }),
    getCallRecord: builder.query<CallRecord, { id: number | string }>({
      query: ({ id }) => `${ApiPath.CALL_RECORD}${ApiPath.ROOT}${id}`
    }),
    makeCall: builder.mutation<
      { status: string; message: string; code: number },
      { contact_id: string | number; internal_number: string | number }
    >({
      query: (body) => ({
        url: ApiPath.MAKE_CALL,
        method: HttpMethod.POST,
        body
      })
    })
  }),
  overrideExisting: false
});

export const {
  useMakeCallMutation,
  useGetAllBinotelCallsQuery,
  useGetCallRecordQuery
} = binotelApi;
