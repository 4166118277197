import { ORG_TYPE, REGIONS } from '../../common/constants';
import type { Filter } from '../../common/types/filters';
import type { HeaderItem } from '../../components/data-table';
import type { PartnersTable } from './types';

export const tableHeaders: HeaderItem<PartnersTable>[] = [
  { id: 'id', title: 'ID' },
  { id: 'name', title: 'Назва Юридичної особи' },
  { id: 'edrpou', title: 'ЄДРПО' },
  { id: 'org_type', title: 'ТИп Організації' },
  { id: 'location', title: 'Місто' },
  { id: 'region', title: 'Область' },
  { id: 'email', title: 'EMAIL' },
  { id: 'phone', title: 'Телефон' },
  { id: 'contact_person', title: 'Контактна особа' },
  { id: 'activity_field', title: 'Напрям діяльності' },
  { id: 'employees_number', title: 'Кількість людей' },
  { id: 'comment', title: 'Коментар' }
];

export const initialFilters: Filter[] = [
  { type: 'text', title: 'Назва юридичної особи', value: '', name: 'name' },
  {
    type: 'checkbox',
    checkList: ORG_TYPE.map((item) => ({ ...item, checked: false })),
    title: 'Тип організації',
    name: 'org_type'
  },
  {
    type: 'text',
    title: 'Напрям діяльності',
    value: '',
    name: 'activity_field'
  },
  { type: 'text', title: 'Місто', value: '', name: 'location' },
  {
    type: 'select',
    title: 'Область',
    selectList: [
      {
        id: 'region',
        placeholder: 'Оберіть зі списку',
        value: { id: '', title: '' },
        options: REGIONS.filter((_, i) => i).map((item, i) => ({
          id: `${i + 1}`,
          title: item
        })),
        title: 'Область'
      }
    ],
    name: 'region'
  }
];
