import styled from 'styled-components';
import { centerFlex } from '../../style/helpers';

export const AudioPlayerStyled = styled.div`
  margin-right: 38px;
  ${centerFlex}
`;

export const PlayBtn = styled.div`
  cursor: pointer;
`;

export const ProgressBarContainer = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

export const TimeStyled = styled.div<{ isStart?: boolean }>`
  color: ${(p) =>
    p.isStart ? p.theme.colors.primary : p.theme.colors.blue.dark};
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  min-width: 28px;
`;

export const SeekSlider = styled.input`
  margin: 0 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  ::-webkit-slider-runnable-track {
    border-radius: 50px;
    position: relative;
    height: 2px;
    background: #e0e8f9;
    cursor: pointer;
    background: ${(p) => `linear-gradient(
      to right,
      ${p.theme.colors.primary}  var(--seek-before-width),
      #E0E8F9  var(--seek-before-width)
    )`};
  }
  ::-moz-range-track {
    height: 4px;
    background: #e0e8f9;
    cursor: pointer;
    &::before {
      position: absolute;
      content: '';
      top: 8px;
      left: 0;
      width: 40px;
      height: 4px;
      background-color: ${(p) => p.theme.colors.primary};
      cursor: pointer;
    }
  }
  ::-webkit-slider-thumb {
    opacity: 0;
  }
  ::-moz-range-thumb {
    opacity: 0;
  }
`;
