import styled from 'styled-components';

export const MapStyled = styled.div`
  padding: 20px;
  border-radius: 9px;
  border: 1px solid ${(p) => p.theme.colors.grey.table};
  min-height: 300px;
  max-height: 762px;
  height: 100%;
`;

export const MapHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.div`
  color: ${(p) => p.theme.colors.blue.dark};
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    font-size: 18px;
  }
`;

export const Total = styled.div`
  color: ${(p) => p.theme.colors.blue.primary};
  font-size: 16px;
  font-weight: 700;
`;

export const MapFooter = styled.div<{ $isActive: boolean }>`
  color: ${(p) =>
    p.$isActive ? p.theme.colors.primary : p.theme.colors.blue.dark};
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;

  @media (max-width: ${(p) => p.theme.breakpoints.xxl}) {
    font-size: 14px;
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const MapCount = styled.text`
  font-size: 16px;
  fill: ${(p) => p.theme.colors.blue.dark};
  pointer-events: none;
  font-weight: 500;
`;
