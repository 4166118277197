import styled from 'styled-components';

export const FormStyled = styled.form`
  padding-top: 20px;
`;

export const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${(p) => p.theme.colors.blue.dark};
`;

export const ManagerItem = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 16px;
  gap: 12px;
  align-items: center;
  svg {
    max-width: 11px;
    path {
      fill: ${(p) => p.theme.colors.grey.light};
    }
  }
`;

export const ManagersWrapper = styled.div`
  padding-bottom: 20px;
`;

export const AddItemWrapper = styled.div`
  display: flex;
`;
