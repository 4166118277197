import styled from 'styled-components';

export const TooltipFixedStyled = styled.div``;

export const TooltipStyled = styled.div<{
  isDown?: boolean;
  canBreak?: boolean;
}>`
  padding: 6px 10px;
  position: fixed;
  max-width: ${(p) => (p.canBreak ? '304px' : 'unset')};
  background: ${(p) => p.theme.colors.blue.dark};
  border-radius: 4px;
  color: ${(p) => p.theme.colors.white};
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;
  z-index: 10;
  white-space: ${(p) => (p.canBreak ? 'normal' : 'nowrap')};
`;
