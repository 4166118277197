import { MONTHS } from '../common/constants';

export const splitByMonth = <T extends { created_at: string }>(items: T[]) => {
  let tempArr: { title: string; list: T[] }[] = [];
  items?.forEach((item) => {
    const currentTitle = `${
      MONTHS[new Date(item.created_at).getMonth()]
    } ${new Date(item.created_at).getFullYear()}`;

    const currentObj = tempArr.find((obj) => obj.title === currentTitle);
    if (!currentObj) {
      tempArr = [...tempArr, { title: currentTitle, list: [item] }];
    } else {
      tempArr = tempArr.map((el) =>
        el.title === currentTitle ? { ...el, list: [...el.list, item] } : el
      );
    }
  });

  return tempArr;
};
