import type { Dispatch, SetStateAction } from 'react';
import { FlexContainer } from '../flex-container';
import { Search } from '../search';
// import { HeaderNotif } from './header-notif';
import { DeactivationBtn } from '../deactivation-btn';
import { useGetUserMeQuery } from '../../api/user-api';
import { HeaderUser } from './header-user';
import { HeaderStyled } from './style';

interface HeaderProps {
  showResults: boolean;
  setShowResults: Dispatch<SetStateAction<boolean>>;
  withOutGlobalSearch?: boolean;
}

export const Header = ({
  setShowResults,
  showResults,
  withOutGlobalSearch
}: HeaderProps) => {
  const { data: userMe } = useGetUserMeQuery('');

  return (
    <HeaderStyled>
      <Search
        showResults={showResults}
        setShowResults={setShowResults}
        placeholder="Пошук по контактам"
        withOutGlobalSearch={withOutGlobalSearch}
      />
      <FlexContainer>
        {/* <HeaderNotif /> */}
        {userMe &&
          (userMe.is_superuser ||
            (userMe.role &&
              userMe.role.name.toLocaleLowerCase() === 'директор')) && (
            <DeactivationBtn />
          )}
        <HeaderUser />
      </FlexContainer>
    </HeaderStyled>
  );
};
