import { useEffect } from 'react';
import { useAppDispatch, useTypedSelector } from '../../api/store';
import { SuccessSvg } from '../svg/success-svg';
import { closeNotification } from '../../api/notification-slice';
import { Content, Message, NotificationStyled, Status, Title } from './style';

export const Notification = () => {
  const dispatch = useAppDispatch();
  const {
    options: { title, type, message }
  } = useTypedSelector((state) => state.notification);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(closeNotification());
    }, 3000);

    return () => clearTimeout(timer);
  }, [dispatch, message]);

  return (
    <NotificationStyled>
      <Status type={type}>
        <SuccessSvg />
      </Status>
      <Content>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </Content>
    </NotificationStyled>
  );
};
