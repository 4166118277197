export const duration = 500;

export const defaultStyle = {
  transition: `background-color ${duration}ms ease-in-out`,
  backgroundColor: 'rgba(41, 48, 74, 0)'
};

export const defaultContentStyle = {
  transition: `transform ${duration}ms ease-in-out`,
  transform: 'translateX(100%)'
};

export const transitionStyles = {
  entering: { backgroundColor: 'rgba(41, 48, 74, 0.7)' },
  entered: { backgroundColor: 'rgba(41, 48, 74, 0.7)' },
  exiting: { backgroundColor: 'rgba(41, 48, 74, 0)' },
  exited: { backgroundColor: 'rgba(41, 48, 74, 0)' },
  unmounted: { backgroundColor: 'rgba(41, 48, 74, 0)' }
};

export const transitionContentStyles = {
  entering: { transform: 'translateX(0)' },
  entered: { transform: 'translateX(0)' },
  exiting: { transform: 'translateX(100%)' },
  exited: { transform: 'translateX(100%)' },
  unmounted: { transform: 'translateX(100%)' }
};
