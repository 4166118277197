/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, type Dispatch, type SetStateAction, useEffect } from 'react';
import { Popup } from '../popup';
import { BtnSecondary } from '../btn-secondary';
import { BtnPrimary } from '../btn-primary';
import { useNotification } from '../../hooks/use-notification';
import { getApiError } from '../../helpers/get-api-error';
import { Input } from '../input';
import { Select } from '../select';
import type { OptionItem } from '../../common/types/option-item';
import {
  useAddContactsToAddressBookMutation,
  useCreateAddressBookMutation,
  useGetAddressBooksQuery
} from '../../api/send-puls-api';
import { BtnsContainer, Content } from './style';

interface MailingListPopupProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  needTranslate?: boolean;
  contactIds: number[];
}

export const MailingListPopup = ({
  show,
  setShow,
  needTranslate,
  contactIds
}: MailingListPopupProps) => {
  const notification = useNotification();
  const [newListName, setNewListName] = useState('');
  const [selectedList, setSelectedList] = useState<OptionItem>({
    id: '',
    title: ''
  });
  const [listType, setListType] = useState<'new' | 'existing' | null>(null);
  const { data: addressbooks } = useGetAddressBooksQuery('');
  const [createAddressBook, { isLoading: isLoadingCreate }] =
    useCreateAddressBookMutation();
  const [addContactsToAddressBook, { isLoading: isLoadingAdd }] =
    useAddContactsToAddressBookMutation();

  const handleAddContacts = (bookId: string | number) => {
    addContactsToAddressBook({ bookId, contactsIds: contactIds })
      .unwrap()
      .then(() => {
        notification({
          type: 'success',
          title: 'Контакти додані до розсилки',
          message: ''
        });
        setShow(false);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  const handleCreateAddressBook = () => {
    createAddressBook({ name: newListName })
      .unwrap()
      .then((res) => {
        handleAddContacts(res.id);
      })
      .catch((err) => {
        notification({
          type: 'error',
          title: 'Помилка',
          message: getApiError(err)
        });
      });
  };

  useEffect(() => {
    if (!show) {
      setNewListName('');
      setSelectedList({
        id: '',
        title: ''
      });
      setListType(null);
    }
  }, [show]);

  const renderContent = () => {
    switch (listType) {
      case 'new':
        return (
          <>
            <Content>
              <Input
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                label="Назва розсилки"
              />
            </Content>
            <BtnsContainer>
              <BtnSecondary onClick={() => setListType(null)}>
                Назад
              </BtnSecondary>
              <BtnPrimary
                disabled={isLoadingCreate || isLoadingAdd}
                type="button"
                onClick={handleCreateAddressBook}
              >
                Стоворити
              </BtnPrimary>
            </BtnsContainer>
          </>
        );
      case 'existing':
        return (
          <>
            <Content>
              <Select
                label="Вибрати розсилку"
                value={selectedList}
                setValue={setSelectedList}
                options={
                  addressbooks?.map((item) => ({
                    id: item.id,
                    title: item.name
                  })) || []
                }
              />
            </Content>
            <BtnsContainer>
              <BtnSecondary onClick={() => setListType(null)}>
                Назад
              </BtnSecondary>
              <BtnPrimary
                disabled={isLoadingAdd}
                type="button"
                onClick={() => handleAddContacts(selectedList.id)}
              >
                Додати
              </BtnPrimary>
            </BtnsContainer>
          </>
        );
      default:
        return (
          <>
            <Content>
              <BtnPrimary type="button" onClick={() => setListType('existing')}>
                Додати в існуючу
              </BtnPrimary>
              <BtnSecondary onClick={() => setListType('new')}>
                Стоворити нову
              </BtnSecondary>
            </Content>
            <BtnsContainer>
              <BtnSecondary onClick={() => setShow(false)}>
                Відмінити
              </BtnSecondary>
            </BtnsContainer>
          </>
        );
    }
  };

  return (
    <Popup
      maxContentWidth="448px"
      title="Додати до розсилки"
      show={show}
      setShow={setShow}
      customPosition={needTranslate}
    >
      {renderContent()}
    </Popup>
  );
};
